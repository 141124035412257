import { Box, Toolbar, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridColumnGroupingModel,
  GridRowId,
} from "@mui/x-data-grid";
import { AxiosError } from "axios";
import { format } from "date-fns-tz";
import * as React from "react";
import { useContext } from "react";
import { deleteUser } from "../api/DeleteUser";
import { getUser } from "../api/GetUser";
import { checkJwt } from "../api/TokenVerification";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { AuthContext } from "../context/AuthContext";
import LoadingContext from "../context/LoadingContext";
import { User } from "../models/User";
import calculateColumnWidth from "../share/calculateColumnWidth";
import AddDeleteButton from "./AddDeleteButton";
import CustomMasterContainer from "./CustomMasterContainer";
import CustomPageSnackbar from "./CustomPageSnackbar";
import CustomSearchField from "./CustomSearchField";
import { gridContainerStyles } from "./CustomStyle";
import UserModal from "./UserModal";

type GridDataRow = User & {
  id: number;
};

const headerName = {
  userName: "ユーザー名",
  loginId: "ログインID",
  email: "メールアドレス",
  phoneNumber: "電話番号",
  siteName: "サイト",
  permissionName: "権限",
  password: "パスワード",
  sendNotificationFlag: "通知一括ON/OFF",
  notificationStartTime: "通知開始時間",
  notificationStopTime: "通知停止時間",
  notificationNames: "通知",
  state: "状態",
  registrationDate: "登録日",
  registrantId: "登録者ID",
};

function UserPage() {
  const initialValue: string = "";
  const [rows, setRows] = React.useState<GridDataRow[]>([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [editingUser, setEditingRow] = React.useState<User>();
  const [selectedRows, setSelectedRows] = React.useState<GridRowId[]>([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [inputValue, setInputValue] = React.useState(initialValue); // <-- Update
  const [searchTerm, setSearchTerm] = React.useState(initialValue);
  const auth = React.useContext(AuthContext);
  const [columnWidths, setColumnWidths] = React.useState<any>();
  const { setLoading } = useContext(LoadingContext);

  const columns: GridColDef[] = [
    {
      field: "userName",
      headerName: headerName.userName,
      minWidth: calculateColumnWidth([headerName]).userName,
      width: columnWidths?.userName || 0,
    },
    {
      field: "loginId",
      headerName: headerName.loginId,
      minWidth: calculateColumnWidth([headerName]).loginId,
      width: columnWidths?.loginId || 0,
    },
    {
      field: "password",
      headerName: headerName.password,
      minWidth: calculateColumnWidth([headerName]).password,
      width: columnWidths?.password || 0,
      valueFormatter: () => "****",
    },
    {
      field: "email",
      headerName: headerName.email,
      minWidth: calculateColumnWidth([headerName]).email,
      width: columnWidths?.email || 0,
    },
    {
      field: "phoneNumber",
      headerName: headerName.phoneNumber,
      minWidth: calculateColumnWidth([headerName]).phoneNumber,
      width: columnWidths?.phoneNumber || 0,
    },
    {
      field: "siteName",
      headerName: headerName.siteName,
      minWidth: calculateColumnWidth([headerName]).siteName,
      width: columnWidths?.siteName || 0,
    },
    {
      field: "permissionName",
      headerName: headerName.permissionName,
      minWidth: calculateColumnWidth([headerName]).permissionName,
      width: columnWidths?.permissionName || 0,
    },
    {
      field: "notificationNames", // Change to notificationNames
      headerName: headerName.notificationNames,
      minWidth: calculateColumnWidth([headerName]).notificationNames,
      width: columnWidths?.notificationNames || 0,
    },
    {
      field: "sendNotificationFlag",
      headerName: headerName.sendNotificationFlag,
      minWidth: calculateColumnWidth([headerName]).sendNotificationFlag,
      width: columnWidths?.sendNotificationFlag || 0,
      valueFormatter: (params) => (params.value ? "有効" : "無効"),
    },
    {
      field: "notificationStartTime",
      headerName: headerName.notificationStartTime,
      minWidth: calculateColumnWidth([headerName]).notificationStartTime,
      // width: columnWidths?.notificationStartTime || 0,
      valueFormatter: (params) => {
        if (!!params.value && (params.value as Date)) {
          return format(new Date(params.value), "HH:mm:ss");
        } else {
          return "";
        }
      },
    },
    {
      field: "notificationStopTime",
      headerName: headerName.notificationStopTime,
      minWidth: calculateColumnWidth([headerName]).notificationStopTime,
      // width: columnWidths?.notificationStopTime || 0,
      valueFormatter: (params) => {
        if (!!params.value && (params.value as Date)) {
          return format(new Date(params.value), "HH:mm:ss");
        } else {
          return "";
        }
      },
    },
    {
      field: "state",
      headerName: headerName.state,
      minWidth: calculateColumnWidth([headerName]).state,
      width: columnWidths?.state || 0,
      valueFormatter: (params) => (params.value ? "有効" : "無効"),
    },
    {
      field: "registrationDate",
      headerName: headerName.registrationDate,
      minWidth: calculateColumnWidth([headerName]).registrationDate,
      width: columnWidths?.registrationDate || 0,
      valueFormatter: (params) => {
        if (params.value) {
          return format(params.value, "yyyy/MM/dd");
        }
      },
    },
    {
      field: "registrantId",
      headerName: headerName.registrantId,
      minWidth: calculateColumnWidth([headerName]).registrantId,
      width: columnWidths?.registrantId || 0,
    },
  ];

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: "通知要否設定",
      headerAlign: "center",
      children: [
        { field: "notificationNames" },
        { field: "sendNotificationFlag" },
        { field: "notificationStartTime" },
        { field: "notificationStopTime" },
      ],
    },
  ];

  const fetchData = React.useCallback(async () => {
    await checkJwt(auth.siteId);
    const records = await getUser(auth.siteId, auth.userId, true, searchTerm);
    if (records) {
      const rows: GridDataRow[] = records.map((record: any, i: number) => {
        return {
          id: i + 1,
          ...record,
        };
      });
      setRows(rows);
      setColumnWidths(calculateColumnWidth(rows));
    }
  }, [searchTerm, auth.siteId, auth.userId]);

  const fetchDataWithLoading = React.useCallback(async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  }, [fetchData, setLoading, auth.user]);

  React.useEffect(() => {
    fetchDataWithLoading();
  }, [fetchDataWithLoading]);

  const handleRowEditStart = (rowId: GridRowId) => {
    const row = rows.find((row) => row.id === rowId);
    if (row) {
      setEditingRow(row);
      setOpenModal(true);
    }
  };

  const handleRowEditStop = () => {
    setEditingRow(undefined);
    setOpenModal(false);
  };

  const handleAddRow = () => {
    setEditingRow(undefined);
    setOpenModal(true);
  };

  const handleDeleteSelected = async (selectedRows: GridRowId[]) => {
    await checkJwt(auth.siteId);

    // 削除対象の siteId の配列を作成
    const RowsToDelete = selectedRows
      .map((rowId) => rows.find((row) => row.id === rowId)?.userId)
      .filter((userId) => userId != null) as number[];

    if (RowsToDelete.length === 0) {
      setErrorMessage(ErrorMessages.SelectionError);
      return;
    }

    const deleteTasks = RowsToDelete.map((userId) => {
      return deleteUser(userId, auth.siteId, auth.userId);
    });

    try {
      await Promise.all(deleteTasks);
      fetchDataWithLoading();
      setSelectedRows([]);
    } catch (err) {
      const axiosError = err as AxiosError;
      const errNum = axiosError.response?.data;
      if (errNum === ErrorCodes.ForeignKey) {
        setErrorMessage(ErrorMessages.RecordInUseError);
      } else {
        setErrorMessage(ErrorMessages.UnknownError);
      }
    }
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearchTerm(inputValue);
    }
  };

  return (
    <>
      <CustomMasterContainer>
        <Box sx={{ mb: 1 }}>
          <Typography variant="subtitle1" component="div">
            検索条件
          </Typography>
        </Box>
        <CustomSearchField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Box sx={gridContainerStyles}>
          <DataGrid
            experimentalFeatures={{ columnGrouping: true }}
            rows={rows}
            columns={columns}
            onRowClick={(param) => handleRowEditStart(param.id)}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={selectedRows}
            onRowSelectionModelChange={(newSelection) => {
              setSelectedRows(newSelection);
            }}
            columnGroupingModel={columnGroupingModel}
            showColumnVerticalBorder
            slots={{
              toolbar: () => (
                <Toolbar>
                  <AddDeleteButton
                    AddButtonOnClick={() => handleAddRow()}
                    DeleteButtonOnClick={() =>
                      handleDeleteSelected(selectedRows)
                    }
                  />
                </Toolbar>
              ),
            }}
          />
        </Box>
      </CustomMasterContainer>
      <UserModal
        open={openModal}
        onClose={handleRowEditStop}
        editingUser={editingUser as User}
      />
      <CustomPageSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}

export default UserPage;
