enum ErrorMessages {
  NoPermission = "権限がありません。",
  UnknownError = "未知のエラーが発生しました。",
  RecordInUseError = "このレコードは他のテーブルで使用されていて削除できません。",
  SelectionError = "1件以上選択してください。",
  DisplaySettingOverFlor = "登録件数が1000件を超過します。",
  InvalidParamError = "入力された値が不正です。",
}

export default ErrorMessages;
