import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

interface CustomSearchFieldProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

function CustomTextField({
  value,
  onChange,
  onKeyDown,
}: CustomSearchFieldProps) {
  return (
    <StyledTextField
      label={"検索条件"}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      fullWidth
    />
  );
}

export default CustomTextField;
