import axios from "axios";
import { Fan } from "../models/Fan";

const url = process.env.REACT_APP_API_URL + "/UpdateFan";

export async function updateFan(
  fan: Fan,
  oldFan: Fan,
  siteId: number,
  userId: number
) {
  const body = {
    fan,
    oldFan,
    siteId,
    userId,
  };
  const response = await axios.post(url, body);
  return response.data;
}
