import axios from "axios";
import { SensorAlarmSetting } from "../models/SensorAlarmSetting";

const url = process.env.REACT_APP_API_URL + "/UpdateSensorAlarmSetting";

export async function updateSensorAlarmSetting(
  oldSensorAlarmSetting: SensorAlarmSetting,
  newSensorAlarmSetting: SensorAlarmSetting,
  siteId: number,
  userId: number
) {
  const body = {
    oldSensorAlarmSetting,
    newSensorAlarmSetting,
    siteId,
    userId,
  };
  const response = await axios.post(url, body);
  return response.data;
}
