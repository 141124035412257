import { Container } from "@mui/material";
import React from "react";

interface CustomContainerProps {
  children: React.ReactNode;
}

function CustomContainer({ children }: CustomContainerProps) {
  return (
    <Container
      sx={{
        marginTop: 2,
        height: "calc(100vh - 115px)",
      }}
      maxWidth={false}
    >
      {children}
    </Container>
  );
}

export default CustomContainer;
