import {
  Grid,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Site } from "../models/Site";

import AddIcon from "@mui/icons-material/Add";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import * as ja from "yup-locale-ja";
import { getPermissions } from "../api/GetPermissions";
import { getSites } from "../api/GetSites";
import { insertUser } from "../api/InsertUser";
import { checkJwt } from "../api/TokenVerification";
import { updateUser } from "../api/UpdateUser";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { PageId } from "../constants/page";
import { AuthContext } from "../context/AuthContext";
import { Permission } from "../models/Permission";
import { User } from "../models/User";
import { CancelSaveButtons } from "./CancelSaveButtons";
import CustomAutoComplete from "./CustomAutoComplete";
import CustomModalSnackbar from "./CustomModalSnackbar";
import { MasterModal } from "./MasterModal";
import SiteModal from "./SiteModal";
import {
  yupEmail,
  yupLoginId,
  yupLoginPassword,
  yupNotificationStartTime,
  yupNotificationStopTime,
  yupPermissionId,
  yupPhoneNumber,
  yupSiteId,
  yupUserName,
} from "./ValidationSchema";
import NotificationSetting from "./NotificationSetting";
import { LogLevel } from "../models/LogLevel";
import { UserNotification } from "../models/UserNotification";
import { getLogLevels } from "../api/GetLogLevels";
import { getUsersNotification } from "../api/GetUsersNotification";

yup.setLocale(ja.suggestive);

interface UserModalProps {
  open: boolean;
  onClose: () => void;
  editingUser?: User;
}

const UserValidationSchema = (isUpdate: boolean | undefined) =>
  yup.object({
    loginId: yupLoginId.required(),
    userName: yupUserName.required(),
    email: yupEmail.required(),
    phoneNumber: yupPhoneNumber.required(),
    permissionId: yupPermissionId.required(),
    siteId: yupSiteId.required(),
    password: isUpdate ? yupLoginPassword : yupLoginPassword.required(),
    notificationStartTime: yupNotificationStartTime.nullable(),
    notificationStopTime: yupNotificationStopTime
      .test(
        "both null/require",
        "通知開始時間／通知停止時間は片方のみの設定は出来ません",
        function (this: yup.TestContext, value: string | undefined | null) {
          if (!value) {
            return !this.parent.notificationStartTime;
          } else {
            return !!this.parent.notificationStartTime;
          }
        }
      )
      .test(
        "same time",
        "通知開始時間／通知停止時間は同じ時刻を設定出来ません",
        function (this: yup.TestContext, value: string | undefined | null) {
          if (!!value && value === this.parent.notificationStartTime) {
            return false;
          } else {
            return true;
          }
        }
      )
      .nullable(),
  });

function UserModal({ open, onClose, editingUser }: UserModalProps) {
  const auth = React.useContext(AuthContext);
  const [logLevels, setLogLevels] = useState<LogLevel[]>();
  const [notifications, setNotifications] = useState<UserNotification[]>();
  const isUpdate = editingUser && editingUser.userId != null;
  const registerUserId = auth.userId;
  const [sites, setSites] = useState<Site[]>();
  const [permissions, setPermissions] = useState<Permission[]>();
  const [openSite, setOpenSite] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchData = React.useCallback(async () => {
    await checkJwt(auth.siteId);
    setLogLevels(await getLogLevels(auth.siteId, auth.userId));
    setNotifications(
      await getUsersNotification(auth.siteId, auth.userId, editingUser?.userId)
    );
    getSites(auth.siteId, auth.userId).then(setSites);
    getPermissions(auth.siteId, auth.userId).then(setPermissions);
  }, [auth.siteId, auth.userId, editingUser]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [initialValues] = useState<User>({
    sendNotificationFlag: true,
    state: true,
  });

  useEffect(() => {
    setPasswordInput(isUpdate ? "****" : "");
  }, [open, isUpdate]);

  // Inside your component
  const [passwordInput, setPasswordInput] = useState(isUpdate ? "****" : "");

  const handleFocus = () => {
    if (isUpdate && passwordInput === "****") {
      setPasswordInput("");
    }
  };

  const handleBlur = () => {
    if (isUpdate && passwordInput === "") {
      setPasswordInput("****");
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingUser || initialValues,
    validationSchema: UserValidationSchema(isUpdate),
    onSubmit: async () => {
      await checkJwt(auth.siteId);
      try {
        if (isUpdate) {
          await updateUser(
            formik.values,
            registerUserId,
            auth.siteId,
            auth.userId,
            notifications
              ?.filter((n) => n.isSelected)
              .map((n) => n.notificationId)
          );
        } else {
          await insertUser(
            formik.values,
            registerUserId,
            auth.siteId,
            auth.userId,
            notifications
              ?.filter((n) => n.isSelected)
              .map((n) => n.notificationId)
          );
        }

        formik.resetForm();
        setErrorMessage("");
        setPasswordInput("****"); // reset the password field
        auth.setUser(formik.values);
        onClose();
      } catch (err) {
        const axiosError = err as AxiosError;
        const errCode = axiosError.response?.data;
        if (errCode === ErrorCodes.UniqueKey) {
          setErrorMessage("ログインIDが重複しています。");
        } else if (errCode === ErrorCodes.DataOverflow) {
          setErrorMessage(
            `${
              sites?.find((site) => site.siteId === formik.values.siteId)
                ?.siteName
            }の登録ユーザーが10人を越えています。`
          );
        } else {
          setErrorMessage(ErrorMessages.UnknownError);
        }
      }
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    setErrorMessage("");
    onClose();
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {auth.pages?.find((page) => page.id === PageId.UserPage)?.name}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="ユーザー名 *"
                name="userName"
                value={formik.values.userName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.userName)}
                helperText={formik.errors.userName}
                fullWidth
                autoComplete="new-password"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="ログインID *"
                name="loginId"
                value={formik.values.loginId ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.loginId)}
                helperText={formik.errors.loginId}
                fullWidth
                autoComplete="new-password"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="パスワード *"
                type="password"
                name="password"
                value={passwordInput ?? ""}
                onChange={(event) => {
                  formik.handleChange(event);
                  setPasswordInput(event.target.value);
                }}
                error={Boolean(formik.errors.password)}
                helperText={formik.errors.password}
                fullWidth
                autoComplete="new-password"
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="メールアドレス *"
                name="email"
                value={formik.values.email ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.email)}
                helperText={formik.errors.email}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="電話番号 *"
                name="phoneNumber"
                value={formik.values.phoneNumber ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.phoneNumber)}
                helperText={formik.errors.phoneNumber}
                fullWidth
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="サイト *"
                  name="siteId"
                  error={formik.errors.siteId}
                  value={formik.values.siteId}
                  onChange={formik.handleChange}
                  arrangement={sites}
                  format={(site) => site.siteName}
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton onClick={() => setOpenSite(true)} color="primary">
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <CustomAutoComplete
                label="権限 *"
                name="permissionId"
                error={formik.errors.permissionId}
                value={formik.values.permissionId}
                onChange={formik.handleChange}
                arrangement={permissions}
                format={(permission) => permission.permissionName}
              />
            </Grid>

            <NotificationSetting
              logLevels={logLevels}
              notifications={notifications}
              setNotifications={setNotifications}
              formik={formik}
            ></NotificationSetting>
            <Grid item xs={12}>
              <Grid item>
                <Typography variant="subtitle1">状態</Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={formik.values.state}
                  exclusive
                  onChange={(
                    _event: React.MouseEvent<HTMLElement>,
                    newValue: boolean
                  ) => {
                    if (newValue !== null) {
                      formik.setFieldValue("state", newValue);
                    }
                  }}
                >
                  <ToggleButton value={true}>有効</ToggleButton>
                  <ToggleButton value={false}>無効</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>

            <CancelSaveButtons handleCancel={handleCancel} />
            <SiteModal
              open={openSite}
              onClose={() => setOpenSite(false)}
              onUpdate={fetchData}
            />
          </Grid>
        </form>
      </MasterModal>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}
export default UserModal;
