import axios from "axios";
import { ControlDetailInformation } from "../models/ControlDetailnformation";

const url = process.env.REACT_APP_API_URL + "/GetSiteDetailInformations";

export async function getSiteDetailInformations(
  siteId: number,
  userId: number
) {
  try {
    const body = {
      siteId,
      userId,
    };

    const response = await axios.post(url, body);
    const data: any[] = response.data;
    return data.map((d) => {
      const convert: ControlDetailInformation = {
        ...d,
        registrationDate: d.registrationDate && new Date(d.registrationDate),
      };
      return convert;
    });
  } catch (error) {
    return;
  } finally {
  }
}
