import axios from "axios";

const url = process.env.REACT_APP_API_URL + "/DeleteSiteLocation";

export async function deleteSiteLocation(siteId: number, userId: number) {
  const body = {
    siteId,
    userId,
  };
  const response = await axios.post(url, body);
  return response.data;
}
