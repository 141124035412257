import { Box, Toolbar, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { AxiosError } from "axios";
import React, { useCallback, useContext } from "react";
import { deleteDisplayGroup } from "../api/DeleteDisplayGroup";
import { getDisplayGroups } from "../api/GetDisplayGroups";
import { checkJwt } from "../api/TokenVerification";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { AuthContext } from "../context/AuthContext";
import LoadingContext from "../context/LoadingContext";
import { DisplayGroup } from "../models/DisplayGroup";
import calculateColumnWidth from "../share/calculateColumnWidth";
import AddDeleteButton from "./AddDeleteButton";
import CustomMasterContainer from "./CustomMasterContainer";
import CustomPageSnackbar from "./CustomPageSnackbar";
import CustomSearchField from "./CustomSearchField";
import { gridContainerStyles } from "./CustomStyle";
import DisplayGroupModal from "./DisplayGroupModal";

type DataGridRow = DisplayGroup & {
  id: number;
};

const headerName = {
  displayGroupName: "表示グループ名",
  displayGroupOrder: "表示順",
};

function DisplayGroupPage() {
  const auth = React.useContext(AuthContext);
  const siteId = auth.siteId;
  const userId = auth.userId;
  const [rows, setRows] = React.useState<DataGridRow[]>([]);
  const [editingDisplayGroup, setEditingDisplayGroup] =
    React.useState<DisplayGroup | null>(null);
  const [selectedRows, setSelectedRows] = React.useState<GridRowId[]>([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [columnWidths, setColumnWidths] = React.useState<any>();
  const [inputValue, setInputValue] = React.useState("");
  const [searchTerm, setSearchTerm] = React.useState("");
  const { setLoading } = useContext(LoadingContext);

  const columns: GridColDef[] = [
    {
      field: "displayGroupName",
      headerName: headerName.displayGroupName,
      minWidth: calculateColumnWidth([headerName]).displayGroupName,
      width: columnWidths?.displayGroupName || 0,
    },
    {
      field: "displayGroupOrder",
      headerName: headerName.displayGroupOrder,
      minWidth: calculateColumnWidth([headerName]).displayGroupOrder,
      width: columnWidths?.displayGroupOrder || 0,
    },
  ];

  //DBからデータを取得している
  const fetchData = useCallback(async () => {
    await checkJwt(siteId);
    const records = await getDisplayGroups(siteId, userId, searchTerm);
    if (records) {
      const rows: DataGridRow[] = records.map((record: any, i: number) => {
        return {
          id: i + 1,
          ...record,
        };
      });
      setRows(rows);
      setColumnWidths(calculateColumnWidth(rows));
    }
  }, [siteId, userId, searchTerm]);

  const fetchDataWithLoading = React.useCallback(async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  }, [fetchData, setLoading]);

  React.useEffect(() => {
    fetchDataWithLoading();
  }, [fetchDataWithLoading]);

  const handleRowEditStart = (rowId: GridRowId) => {
    const row = rows.find((row) => row.id === rowId);
    if (row) setEditingDisplayGroup(row);
  };

  const handleRowEditStop = () => {
    setEditingDisplayGroup(null);
  };

  const handleAddRow = () => {
    const displayGroup: DisplayGroup = {
      siteId,
      displayGroupId: null,
      displayGroupName: null,
      displayGroupOrder: null,
    };
    setEditingDisplayGroup(displayGroup);
  };

  const handleDeleteSelected = async (selectedRows: GridRowId[]) => {
    await checkJwt(siteId);

    // 削除対象の siteId の配列を作成
    const RowsToDelete = selectedRows
      .map((rowId) => rows.find((row) => row.id === rowId)?.displayGroupId)
      .filter((displayGroupId) => displayGroupId != null) as number[];

    if (RowsToDelete.length === 0) {
      setErrorMessage(ErrorMessages.SelectionError);
      return;
    }

    const deleteTasks = RowsToDelete.map((displayGroupId) => {
      return deleteDisplayGroup(displayGroupId, siteId, userId);
    });

    try {
      await Promise.all(deleteTasks);
      fetchDataWithLoading();
      setSelectedRows([]);
    } catch (err) {
      const axiosError = err as AxiosError;
      const errNum = axiosError.response?.data;
      if (errNum === ErrorCodes.ForeignKey) {
        setErrorMessage(ErrorMessages.RecordInUseError);
      } else {
        setErrorMessage(ErrorMessages.UnknownError);
      }
    }
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearchTerm(inputValue);
    }
  };

  return (
    <>
      <CustomMasterContainer>
        <Box sx={{ mb: 1 }}>
          <Typography variant="subtitle1" component="div">
            検索条件
          </Typography>
        </Box>
        <CustomSearchField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Box sx={gridContainerStyles}>
          <DataGrid
            rows={rows}
            columns={columns}
            onRowClick={(param) => handleRowEditStart(param.id)}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={selectedRows}
            onRowSelectionModelChange={(newSelection) => {
              setSelectedRows(newSelection);
            }}
            showColumnVerticalBorder
            slots={{
              toolbar: () => (
                <Toolbar>
                  <AddDeleteButton
                    AddButtonOnClick={() => handleAddRow()}
                    DeleteButtonOnClick={() =>
                      handleDeleteSelected(selectedRows)
                    }
                  />
                </Toolbar>
              ),
            }}
          />
        </Box>
      </CustomMasterContainer>
      <DisplayGroupModal
        open={!!editingDisplayGroup}
        onClose={handleRowEditStop}
        onUpdate={fetchDataWithLoading}
        editingDisplayGroup={editingDisplayGroup as DisplayGroup}
      />
      <CustomPageSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}

export default DisplayGroupPage;
