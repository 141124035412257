import axios, { AxiosError } from "axios";
import { DeviceTwin } from "../models/DeviceTwin";

const url = process.env.REACT_APP_API_URL + "/InsertDeviceTwin";

export async function insertDeviceTwin(
  deviceTwin: DeviceTwin,
  siteId: number,
  userId: number
) {
  try {
    const body = {
      deviceTwin,
      siteId,
      userId,
    };
    const response = await axios.post(url, body);
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response && axiosError.response.status === 409) {
      throw new Error("Unique key violation");
    }
  } finally {
  }
}
