import axios from "axios";

const url = process.env.REACT_APP_API_URL + "/GetSiteId";

export async function getSiteId(siteName: string) {
  try {
    const body = {
      siteName: decodeURI(siteName),
    };

    const response = await axios.post(url, body);
    return response.data.siteId;
  } catch (error) {
    return;
  } finally {
  }
}
