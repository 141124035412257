import AddIcon from "@mui/icons-material/Add";
import {
  Grid,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { getControlTypes } from "../api/GetControlTypes";
import { getDisplayGroups } from "../api/GetDisplayGroups";
import { insertControlCard } from "../api/InsertControlCard";
import { checkJwt } from "../api/TokenVerification";
import { updateControlCard } from "../api/UpdateControlCard";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { PageId } from "../constants/page";
import { AuthContext } from "../context/AuthContext";
import { ControlCard } from "../models/ControlCard";
import { ControlType } from "../models/ControlType";
import { DisplayGroup } from "../models/DisplayGroup";
import { CancelSaveButtons } from "./CancelSaveButtons";
import ControlTypeModal from "./ControlTypeModal";
import CustomAutoComplete from "./CustomAutoComplete";
import CustomModalSnackbar from "./CustomModalSnackbar";
import DisplayGroupModal from "./DisplayGroupModal";
import { MasterModal } from "./MasterModal";
import {
  yupControlTypeId,
  yupDataName,
  yupDisplayGroupId,
  yupDisplayOrder,
} from "./ValidationSchema";

interface ControlCardModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  editingItem?: ControlCard;
}

const LocationValidationSchema = yup.object().shape({
  controlTypeId: yupControlTypeId.required(),
  displayGroupId: yupDisplayGroupId.required(),
  dataName: yupDataName.required(),
  displayOrder: yupDisplayOrder.nullable(),
});

function ControlCardModal({
  open,
  onClose,
  onUpdate,
  editingItem,
}: ControlCardModalProps) {
  const isUpdate = editingItem?.controlCardId != null;
  const auth = React.useContext(AuthContext);
  const [openControlType, setOpenControlType] = React.useState<boolean>(false);
  const [controlTypes, setControlTypes] = useState<ControlType[]>([]);
  const [openDisplayGroup, setOpenDisplayGroup] =
    React.useState<boolean>(false);
  const [displayGroups, setDisplayGroups] = useState<DisplayGroup[]>([]);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchData = React.useCallback(async () => {
    await checkJwt(auth.siteId);

    setControlTypes((await getControlTypes(auth.siteId, auth.userId)) || []);
    setDisplayGroups((await getDisplayGroups(auth.siteId, auth.userId)) || []);
  }, [auth.siteId, auth.userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [initialValues] = useState<ControlCard>({
    isOneTimePasswordRequired: true,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingItem || initialValues,
    validationSchema: LocationValidationSchema,
    onSubmit: async () => {
      await checkJwt(auth.siteId);

      try {
        if (isUpdate) {
          await updateControlCard(formik.values, auth.siteId, auth.userId);
        } else {
          await insertControlCard(formik.values, auth.siteId, auth.userId);
        }
        formik.resetForm();
        onUpdate();
        onClose();
      } catch (err) {
        const axiosError = err as AxiosError;
        const errCode = axiosError.response?.data;
        if (errCode === ErrorCodes.DataOverflow) {
          setErrorMessage(ErrorMessages.DisplaySettingOverFlor);
        } else {
          setErrorMessage(ErrorMessages.UnknownError);
        }
      }
    },
  });

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleCancel = () => {
    formik.resetForm();
    setErrorMessage("");
    onClose();
  };

  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {!isUpdate && (
                <Typography variant="h6" gutterBottom>
                  {
                    auth.pages?.find(
                      (page) => page.id === PageId.ControlCardPage
                    )?.name
                  }
                </Typography>
              )}
            </Grid>
            {/* 制御種別 */}
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="制御種別 *"
                  name="controlTypeId"
                  error={formik.errors.controlTypeId}
                  value={formik.values.controlTypeId}
                  onChange={formik.handleChange}
                  arrangement={controlTypes}
                  format={(controlType) => controlType.controlTypeName}
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton
                  onClick={() => setOpenControlType(true)}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
            {/* 表示グループ */}
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="表示グループ *"
                  name="displayGroupId"
                  error={formik.errors.displayGroupId}
                  value={formik.values.displayGroupId}
                  onChange={formik.handleChange}
                  arrangement={displayGroups}
                  format={(displayGroup) => displayGroup.displayGroupName}
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton
                  onClick={() => setOpenDisplayGroup(true)}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            {/* 設備名称 */}
            <Grid item xs={12}>
              <TextField
                label="設備名称 *"
                name="dataName"
                value={formik.values.dataName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.dataName)}
                helperText={formik.errors.dataName}
                fullWidth
              />
            </Grid>

            {/* 表示順 */}
            <Grid item xs={12}>
              <TextField
                type="number"
                label="表示順"
                name="displayOrder"
                value={formik.values.displayOrder ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.displayOrder)}
                helperText={formik.errors.displayOrder}
                fullWidth
              />
            </Grid>

            {/* パスワード表示 */}

            <Grid item xs={12}>
              <Typography variant="subtitle1">ワンタイムパスワード</Typography>
              <ToggleButtonGroup
                color="primary"
                value={formik.values.isOneTimePasswordRequired}
                exclusive
                onChange={(
                  _event: React.MouseEvent<HTMLElement>,
                  newValue: boolean | null
                ) => {
                  if (newValue !== null) {
                    formik.setFieldValue("isOneTimePasswordRequired", newValue);
                  }
                }}
              >
                <ToggleButton value={true}>有効</ToggleButton>
                <ToggleButton value={false}>無効</ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            <CancelSaveButtons handleCancel={handleCancel} />
            <ControlTypeModal
              open={openControlType}
              onClose={() => setOpenControlType(false)}
              onUpdate={fetchData}
            />
            <DisplayGroupModal
              open={openDisplayGroup}
              onClose={() => setOpenDisplayGroup(false)}
              onUpdate={fetchData}
            />
          </Grid>
        </form>
      </MasterModal>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}
export default ControlCardModal;
