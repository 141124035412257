import { Box, Toolbar, Typography } from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowId,
} from "@mui/x-data-grid";
import { AxiosError } from "axios";
import * as React from "react";
import { useContext } from "react";
import { deleteAlarm } from "../api/DeleteAlarm";
import { getAlarms } from "../api/GetAlarms";
import { checkJwt } from "../api/TokenVerification";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { AuthContext } from "../context/AuthContext";
import LoadingContext from "../context/LoadingContext";
import { Alarm } from "../models/Alarm";
import calculateColumnWidth from "../share/calculateColumnWidth";
import AddDeleteButton from "./AddDeleteButton";
import AlarmModal from "./AlarmModal";
import CustomMasterContainer from "./CustomMasterContainer";
import CustomPageSnackbar from "./CustomPageSnackbar";
import CustomSearchField from "./CustomSearchField";
import { gridContainerStyles } from "./CustomStyle";

type GridDataRow = Alarm & {
  id: number;
};

const headerName = {
  alarmName: "警報名",
  palette: "色",
  notificationName: "通知",
};

function AlarmPage() {
  const [rows, setRows] = React.useState<GridDataRow[]>([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [editingAlarm, setEditingAlarm] = React.useState<Alarm>();
  const [selectedRows, setSelectedRows] = React.useState<GridRowId[]>([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const auth = useContext(AuthContext);
  const [inputValue, setInputValue] = React.useState(""); // <-- Update
  const [searchTerm, setSearchTerm] = React.useState("");
  const [columnWidths, setColumnWidths] = React.useState<any>();
  const { setLoading } = useContext(LoadingContext);

  const columns: GridColDef[] = [
    {
      field: "alarmName",
      headerName: headerName.alarmName,
      minWidth: calculateColumnWidth([headerName]).alarmName,
      width: columnWidths?.alarmName || 0,
    },
    {
      field: "palette",
      headerName: headerName.palette,
      minWidth: calculateColumnWidth([headerName]).palette,
      width: columnWidths?.palette || 0,
      renderCell: (params: any) => {
        const palette = params.row.palette;
        return (
          <Typography
            color={palette && "#FFFFFF"}
            bgcolor={palette && palette}
            sx={{
              display: "flex",
              alignItems: "center",
              p: 1,
            }}
          >
            {palette}
          </Typography>
        );
      },
    },
    {
      field: "notificationName",
      headerName: headerName.notificationName,
      minWidth: calculateColumnWidth([headerName]).notificationName,
      width: columnWidths?.notificationName || 0,
    },
  ];

  //DBからデータを取得している
  const fetchData = React.useCallback(async () => {
    await checkJwt(auth.siteId);
    const records = await getAlarms(auth.siteId, auth.userId, searchTerm);
    if (records) {
      const rows: GridDataRow[] = records.map((record: any, i: number) => {
        return {
          id: i + 1,
          ...record,
        };
      });
      setRows(rows);
      setColumnWidths(calculateColumnWidth(rows));
    }
  }, [auth.siteId, auth.userId, searchTerm]);

  const fetchDataWithLoading = React.useCallback(async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  }, [fetchData, setLoading]);

  React.useEffect(() => {
    fetchDataWithLoading();
  }, [fetchDataWithLoading]);

  const handleRowEditStart = (rowId: GridRowId) => {
    const row = rows.find((row) => row.id === rowId);
    if (row) {
      setEditingAlarm(row);
      setOpenModal(true);
    }
  };

  const handleAddRow = () => {
    setEditingAlarm(undefined);
    setOpenModal(true);
  };

  const handleRowEditStop = () => {
    setEditingAlarm(undefined);
    setOpenModal(false);
  };

  const handleDeleteSelected = async (selectedRows: GridRowId[]) => {
    await checkJwt(auth.siteId);

    // 削除対象の siteId の配列を作成
    const RowsToDelete = selectedRows
      .map((rowId) => rows.find((row) => row.id === rowId)?.alarmId)
      .filter((alarmId) => alarmId != null) as number[];

    if (RowsToDelete.length === 0) {
      setErrorMessage(ErrorMessages.SelectionError);
      return;
    }

    const deleteTasks = RowsToDelete.map((alarmId) => {
      return deleteAlarm(alarmId, auth.siteId, auth.userId);
    });

    try {
      await Promise.all(deleteTasks);
      fetchDataWithLoading();
      setSelectedRows([]);
    } catch (err) {
      const axiosError = err as AxiosError;
      const errNum = axiosError.response?.data;
      if (errNum === ErrorCodes.ForeignKey) {
        setErrorMessage(ErrorMessages.RecordInUseError);
      } else {
        setErrorMessage(ErrorMessages.UnknownError);
      }
    }
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearchTerm(inputValue);
    }
  };

  return (
    <>
      <CustomMasterContainer>
        <Box sx={{ mb: 1 }}>
          <Typography variant="subtitle1" component="div">
            検索条件
          </Typography>
        </Box>
        <CustomSearchField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />

        <Box sx={gridContainerStyles}>
          <DataGrid
            rows={rows}
            columns={columns}
            onRowClick={(param) => handleRowEditStart(param.id)}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={selectedRows}
            onRowSelectionModelChange={(newSelection) => {
              setSelectedRows(newSelection);
            }}
            getCellClassName={(params: GridCellParams<any, any, string>) => {
              if (params.field === "palette") {
                return params.value;
              }
              return "";
            }}
            showColumnVerticalBorder
            slots={{
              toolbar: () => (
                <Toolbar>
                  <AddDeleteButton
                    AddButtonOnClick={handleAddRow}
                    DeleteButtonOnClick={() =>
                      handleDeleteSelected(selectedRows)
                    }
                  />
                </Toolbar>
              ),
            }}
          />
        </Box>
      </CustomMasterContainer>
      <AlarmModal
        open={openModal}
        onClose={handleRowEditStop}
        onUpdate={fetchDataWithLoading}
        editingAlarm={editingAlarm as Alarm}
      />
      <CustomPageSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}

export default AlarmPage;
