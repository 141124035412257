import axios from "axios";

const url = process.env.REACT_APP_API_URL + "/DeleteControlCard";

export async function deleteControlCard(
  controlCardId: number,
  siteId: number,
  userId: number
) {
  const body = {
    controlCardId,
    siteId,
    userId,
  };
  const response = await axios.post(url, body);
  return response.data;
}
