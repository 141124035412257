import { Grid, TextField, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { insertCalculation } from "../api/InsertCalculation";
import { checkJwt } from "../api/TokenVerification";
import { updateCalculation } from "../api/UpdateCalculation";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { PageId } from "../constants/page";
import { AuthContext } from "../context/AuthContext";
import { Calculation } from "../models/Calculation";
import { CancelSaveButtons } from "./CancelSaveButtons";
import CustomModalSnackbar from "./CustomModalSnackbar";
import { MasterModal } from "./MasterModal";
import { yupCalculation, yupCalculationName } from "./ValidationSchema";

interface CalculationProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  editingCalculation?: Calculation;
}

const CalculationValidationSchema = yup.object({
  calculationName: yupCalculationName.required(),
  calculation: yupCalculation.required(),
});

function CalculationModal({
  open,
  onClose,
  onUpdate,
  editingCalculation,
}: CalculationProps) {
  const isUpdate =
    editingCalculation && editingCalculation.calculationId != null;
  const [initialValues] = useState<Calculation>({});
  const auth = React.useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingCalculation || initialValues,
    validationSchema: CalculationValidationSchema,
    onSubmit: async () => {
      await checkJwt(auth.siteId);
      try {
        if (isUpdate) {
          await updateCalculation(formik.values, auth.siteId, auth.userId);
        } else {
          await insertCalculation(formik.values, auth.siteId, auth.userId);
        }
        formik.resetForm();
        setErrorMessage("");
        onUpdate();
        onClose();
      } catch (err) {
        const axiosError = err as AxiosError;
        const errNum = axiosError.response?.data;
        if (errNum === ErrorCodes.UniqueKey) {
          setErrorMessage("計算名が重複しています。");
        } else if (errNum === 400) {
          setErrorMessage("計算式が不正です。");
        } else {
          setErrorMessage(ErrorMessages.UnknownError);
        }
      }
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    setErrorMessage("");
    onClose();
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            {/* Title */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {
                  auth.pages?.find((page) => page.id === PageId.CalculationPage)
                    ?.name
                }
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="計算名 *"
                name="calculationName"
                value={formik.values.calculationName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.calculationName)}
                helperText={formik.errors.calculationName}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="計算式 *"
                name="calculation"
                value={formik.values.calculation ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.calculation)}
                helperText={formik.errors.calculation}
                fullWidth
              />
            </Grid>
            <CancelSaveButtons handleCancel={handleCancel} />
          </Grid>
        </form>
      </MasterModal>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}
export default CalculationModal;
