export const DisplayTimeId = {
  RECENT: 1,
  MIN_5: 2,
  MIN_15: 3,
  MIN_30: 4,
  HOUR_1: 5,
  HOUR_4: 6,
  HOUR_8: 7,
  HOUR_12: 8,
  DAY_1: 9,
  WEEK_1: 10,
  MONTH_1: 11,
  YEAR_1: 12,
};
