import { CardMedia, Grid, TextField, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { MuiFileInput } from "mui-file-input";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { insertReceiveDataType } from "../api/InsertReceiveDataType";
import { checkJwt } from "../api/TokenVerification";
import { updateReceiveDataType } from "../api/UpdateReceiveDataType";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { PageId } from "../constants/page";
import { AuthContext } from "../context/AuthContext";
import { ReceiveDataType } from "../models/ReceiveDataType";
import { CancelSaveButtons } from "./CancelSaveButtons";
import CustomModalSnackbar from "./CustomModalSnackbar";
import { MasterModal } from "./MasterModal";
import {
  yupImage,
  yupMaximum,
  yupMinimum,
  yupReceiveDataTypeName,
  yupSign,
  yupUnit,
} from "./ValidationSchema";

const MaxSizeKB = 128;
const KILO = 1024;
const fileTypes = ["image/png", "image/jpeg"];

interface ReceiveDataTypeModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  editingReceiveDataType?: ReceiveDataType;
}

const ReceiveDataValidationSchema = yup.object().shape({
  receiveDataTypeName: yupReceiveDataTypeName.required(),
  unit: yupUnit.nullable(),
  image: yupImage.nullable(),
  sign: yupSign.nullable(),
  minimum: yupMinimum.nullable(),
  maximum: yupMaximum.nullable(),
});

function ReceveDataTypeModal({
  open,
  onClose,
  onUpdate,
  editingReceiveDataType,
}: ReceiveDataTypeModalProps) {
  const isUpdate =
    editingReceiveDataType && editingReceiveDataType.receiveDataTypeId != null;
  const [initialValues] = useState<ReceiveDataType>({});

  const [image, setImage] = useState<File | null>();
  const [imageErrorMessage, setImageErrorMessage] = useState<string>("");
  const auth = React.useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingReceiveDataType || initialValues,
    validationSchema: ReceiveDataValidationSchema,
    onSubmit: async () => {
      await checkJwt(auth.siteId);

      try {
        if (isUpdate) {
          await updateReceiveDataType(formik.values, auth.siteId, auth.userId);
        } else {
          await insertReceiveDataType(formik.values, auth.siteId, auth.userId);
        }
        formik.resetForm();
        setErrorMessage("");
        onUpdate();
        onClose();
      } catch (err) {
        const axiosError = err as AxiosError;
        const errNum = axiosError.response?.data;
        if (errNum === ErrorCodes.UniqueKey) {
          setErrorMessage("受信データ種別名、単位が重複しています。");
        } else {
          setErrorMessage(ErrorMessages.UnknownError);
        }
      }
    },
  });

  //ファイルhandleChange関数
  const handleChangeFile = (newFile: File | null) => {
    if (newFile) {
      if (!fileTypes.includes(newFile.type)) {
        // ファイル種別対象外
        setImage(null);
        formik.setFieldValue("image", "", true);
        setImageErrorMessage("このファイルタイプはサポートしていません。");
      } else if (newFile.size > MaxSizeKB * KILO) {
        // ファイルサイズ超過
        setImage(null);
        formik.setFieldValue("image", "", true);
        setImageErrorMessage("ファイルサイズが大きすぎます。");
      } else {
        // ファイルサイズOK
        const reader = new FileReader();
        reader.onloadend = () => {
          if (typeof reader.result === "string") {
            // formik.values.image = reader.result;
            formik.setFieldValue("image", reader.result, true);
          }
        };
        reader.readAsDataURL(newFile);
        setImageErrorMessage("");
        // setIsError(false);
      }
    } else {
      // 削除の場合
      setImage(null);
      formik.setFieldValue("image", "", true);
      setImageErrorMessage("");
    }
  };

  const formikImage = formik.values.image;
  const setFieldValue = formik.setFieldValue;

  // モーダルオープン時に
  useEffect(() => {
    if (formikImage) {
      // base64文字列からFileに変換
      const fileData = formikImage.replace(/^data:.*;base64,/, "");
      const contentType = formikImage.replace(/^data:(.*);base64,.*/, "$1");
      const byteString = window.atob(fileData);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      const file = new File([ab], "image", {
        type: contentType,
      });

      if (!fileTypes.includes(file.type)) {
        // ファイル種別対象外
        setImage(null);
        setFieldValue("image", "", true);
        setImageErrorMessage("このファイルタイプはサポートしていません。");
      } else if (file.size > MaxSizeKB * KILO) {
        // ファイルサイズ超過
        setImage(null);
        setFieldValue("image", "", true);
        setImageErrorMessage("ファイルサイズが大きすぎます。");
      } else {
        setImage(file);
        setImageErrorMessage("");
      }
    } else {
      setImage(null);
    }
  }, [formikImage, setFieldValue]);

  const handleCancel = () => {
    formik.resetForm();
    setImageErrorMessage("");
    setErrorMessage("");
    onClose();
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {
                  auth.pages?.find(
                    (page) => page.id === PageId.ReceiveDataTypePage
                  )?.name
                }
              </Typography>
            </Grid>

            {/* 受信データ種別名 */}
            <Grid item xs={12}>
              <TextField
                label="受信データ種別名 *"
                name="receiveDataTypeName"
                value={formik.values.receiveDataTypeName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.receiveDataTypeName)}
                helperText={formik.errors.receiveDataTypeName}
                fullWidth
              />
            </Grid>

            {/* 単位 */}
            <Grid item xs={12}>
              <TextField
                label="単位"
                name="unit"
                value={formik.values.unit ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.unit)}
                helperText={formik.errors.unit}
                fullWidth
              />
            </Grid>

            {/* 画像 */}
            <Grid item xs={12} style={{ display: "none" }}>
              <TextField
                label="画像"
                name="image"
                value={formik.values.image ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.image)}
                helperText={formik.errors.image}
                fullWidth
              />
            </Grid>
            {/* 画像 */}
            <Grid item xs={12}>
              <MuiFileInput
                label="画像"
                name="image"
                fullWidth
                value={image}
                onChange={handleChangeFile}
                variant="outlined"
                error={
                  Boolean(formik.errors.image) || Boolean(imageErrorMessage)
                }
                helperText={
                  (imageErrorMessage || "") + (formik.errors.image || "")
                }
              />
              <Typography variant="caption" component="div" gutterBottom>
                {fileTypes.join("/").toUpperCase().replaceAll("IMAGE/", "")}
                ファイルのみ、ファイルサイズは
                {MaxSizeKB}KB以内。
              </Typography>
            </Grid>

            {/* 画像プレビュー */}
            <Grid item xs={12}>
              <Typography variant="caption" component="div" gutterBottom>
                プレビュー
              </Typography>
              <CardMedia
                sx={{ height: "80px", width: "80px" }}
                component="img"
                image={formik.values.image || ""}
                title="image"
              />
            </Grid>

            {/* 符号 */}
            <Grid item xs={12}>
              <TextField
                label="符号"
                name="sign"
                value={formik.values.sign ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.sign)}
                helperText={formik.errors.sign}
                fullWidth
              />
            </Grid>
            {/* 最小値 */}
            <Grid item xs={12}>
              <TextField
                label="最小値"
                name="minimum"
                type="text"
                inputProps={{ inputMode: "numeric" }}
                value={formik.values.minimum ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.minimum)}
                helperText={formik.errors.minimum}
                fullWidth
              />
            </Grid>
            {/* 最大値 */}
            <Grid item xs={12}>
              <TextField
                label="最大値"
                name="maximum"
                inputProps={{ inputMode: "numeric" }}
                type="text"
                value={formik.values.maximum ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.maximum)}
                helperText={formik.errors.maximum}
                fullWidth
              />
            </Grid>
            <CancelSaveButtons handleCancel={handleCancel} />
          </Grid>
        </form>
      </MasterModal>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}
export default ReceveDataTypeModal;
