import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { getControlRecords } from "../api/GetControlRecords";
import { getDisplayGroups } from "../api/GetDisplayGroups";
import { getImages } from "../api/GetImages";
import { getObserveRecords } from "../api/GetObserveRecords";
import { checkJwt } from "../api/TokenVerification";
import { AuthContext } from "../context/AuthContext";
import LoadingContext from "../context/LoadingContext";
import { ControlRecord } from "../models/ControlRecord";
import { DisplayGroup } from "../models/DisplayGroup";
import { Image } from "../models/Image";
import { ObserveRecord } from "../models/ObserveRecord";
import { sortRecord } from "../share/sort";
import ControlCard from "./ControlCard";
import ObserveCard from "./ObserveCard";

const TopPage = () => {
  const auth = useContext(AuthContext);
  const { setLoading } = useContext(LoadingContext);
  const [displayGroups, setDisplayGroups] = useState<DisplayGroup[]>([]);
  const [images, setImages] = useState<Image[]>();
  const [records, setRecords] = useState<(ObserveRecord | ControlRecord)[]>([]);

  const fetchDisplayGroups = useCallback(async () => {
    setDisplayGroups(await getDisplayGroups(auth.siteId, auth.userId));
  }, [auth.siteId, auth.userId]);

  const fetchImages = useCallback(async () => {
    setImages(await getImages(auth.siteId, auth.userId));
  }, [auth.siteId, auth.userId]);

  useEffect(() => {
    fetchDisplayGroups();
  }, [fetchDisplayGroups]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  const fetchCardRecords = React.useCallback(async () => {
    if (!images) {
      return;
    }

    const records: any[] = [];
    await checkJwt(auth.siteId);
    await Promise.all([
      getObserveRecords(auth.userId, auth.siteId).then((results) =>
        records.push(...results)
      ),
      getControlRecords(auth.userId, auth.siteId).then(
        (results) => results && records.push(...results)
      ),
    ]);
    records.forEach((record) => {
      const image = images?.find(
        (image) =>
          image.receiveDataTypeId === record.receiveDataTypeId ||
          image.controlTypeId === record.controlTypeId
      );
      if (image) {
        record.image = image.image;
      }
    });
    setRecords(records.sort(sortRecord));
  }, [auth.siteId, auth.userId, images]);

  const fetchCardRecordsWithLoading = useCallback(async () => {
    setLoading(true);
    await fetchCardRecords();
    setLoading(false);
  }, [fetchCardRecords, setLoading]);

  useEffect(() => {
    fetchCardRecordsWithLoading().then(() => {
      const timer = setInterval(fetchCardRecords, 5000);
      return () => clearInterval(timer);
    });
  }, [fetchCardRecords, fetchCardRecordsWithLoading]);

  if (!Array.isArray(displayGroups) || displayGroups.length === 0) {
    return <></>;
  }

  return (
    <Box
      height="calc(100vh - 140px)"
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 3,
        flexWrap: "nowrap",
        overflow: "auto",
      }}
    >
      {displayGroups.map((displayGroup) => {
        return (
          <Accordion defaultExpanded key={displayGroup.displayGroupId}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ backgroundColor: "primary.light" }}
            >
              <Typography color={"primary.contrastText"}>
                {displayGroup.displayGroupName}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {records
                  .filter(
                    (record) =>
                      record.displayGroupId === displayGroup.displayGroupId
                  )
                  .map((record) => {
                    if (
                      (record as ObserveRecord).receiveDataTypeName !==
                      undefined
                    ) {
                      const observedRecord = record as ObserveRecord;
                      return (
                        <ObserveCard
                          {...observedRecord}
                          key={`observed-card-${observedRecord.sensorId}`}
                        />
                      );
                    } else if (
                      (record as ControlRecord).controlTypeName !== undefined
                    ) {
                      const controlRecord = record as ControlRecord;
                      return (
                        <ControlCard
                          controlRecord={controlRecord}
                          fetchRecords={fetchCardRecords}
                          key={`control-card-${controlRecord.controlId}`}
                        />
                      );
                    } else {
                      return <></>;
                    }
                  })}
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

export default TopPage;
