import {
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { LogLevel } from "../models/LogLevel";
import { UserNotification } from "../models/UserNotification";
import NotificationCheckbox from "./NotificationCheckbox";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ja } from "date-fns/locale";
import { FormikContextType } from "formik";
import { User } from "../models/User";

type Props = {
  logLevels?: LogLevel[];
  notifications?: UserNotification[];
  formik: FormikContextType<User>;
  setNotifications: React.Dispatch<
    React.SetStateAction<UserNotification[] | undefined>
  >;
};

const NotificationSetting = ({
  logLevels,
  notifications,
  formik,
  setNotifications,
}: Props) => {
  return (
    <>
      <Grid item xs={12}>
        <Grid item>
          <Typography variant="subtitle1">通知一括ON/OFF</Typography>
          <ToggleButtonGroup
            color="primary"
            value={formik.values.sendNotificationFlag}
            exclusive
            onChange={(
              _event: React.MouseEvent<HTMLElement>,
              newValue: boolean | null
            ) => {
              if (newValue !== null) {
                formik.setFieldValue("sendNotificationFlag", newValue);
              }
            }}
          >
            <ToggleButton value={true}>有効</ToggleButton>
            <ToggleButton value={false}>無効</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
          <MobileTimePicker
            label="通知開始時間"
            value={
              !!formik.values.notificationStartTime
                ? new Date(formik.values.notificationStartTime)
                : null
            }
            format="HH:mm:ss"
            views={["hours", "minutes"]}
            onChange={(date) => {
              if (date as Date) {
                formik.setFieldValue("notificationStartTime", date);
              } else {
                formik.setFieldValue("notificationStartTime", undefined);
              }
            }}
            slotProps={{
              actionBar: {
                actions: ["clear", "cancel", "accept"],
              },
              textField: {
                name: "notificationStartTime",

                error: Boolean(formik.errors.notificationStartTime),
                helperText: formik.errors.notificationStartTime,
                fullWidth: true,
              },
            }}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
          <MobileTimePicker
            label="通知停止時間"
            value={
              !!formik.values.notificationStopTime
                ? new Date(formik.values.notificationStopTime)
                : null
            }
            format="HH:mm:ss"
            views={["hours", "minutes"]}
            onChange={(date) => {
              if (date as Date) {
                formik.setFieldValue("notificationStopTime", date);
              } else {
                formik.setFieldValue("notificationStopTime", undefined);
              }
            }}
            slotProps={{
              actionBar: {
                actions: ["clear", "cancel", "accept"],
              },
              textField: {
                name: "notificationStopTime",
                error: Boolean(formik.errors.notificationStopTime),
                helperText: !!formik.errors.notificationStopTime
                  ? formik.errors.notificationStopTime
                  : "",
                fullWidth: true,
              },
            }}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item xs={12}>
        <NotificationCheckbox
          logLevels={logLevels}
          notifications={notifications}
          setNotifications={setNotifications}
        />
      </Grid>
    </>
  );
};

export default NotificationSetting;
