import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useCallback, useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { getDisplayTimes } from "../api/GetDisplayTimes";
import { getThresholds } from "../api/GetThresholds";
import { checkJwt } from "../api/TokenVerification";
import { updateDetailInformation } from "../api/UpdateDetailInformation";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { AuthContext } from "../context/AuthContext";
import { DetailInformation } from "../models/DetailInformation";
import { DisplayTime } from "../models/DisplayTime";
import { CancelSaveButtons } from "./CancelSaveButtons";
import CustomModalSnackbar from "./CustomModalSnackbar";
import { MasterModal } from "./MasterModal";
import {
  yupDataName,
  yupDefaultDisplayTimeId,
  yupLowerLimit1Threshold,
  yupLowerLimit2Threshold,
  yupState,
  yupUpperLimit1Threshold,
  yupUpperLimit2Threshold,
} from "./ValidationSchema";

interface DetailInformationProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  editingDetailInformation?: DetailInformation;
}

const isNumber = (value: any) => {
  if (typeof value === "number") {
    return !isNaN(value);
  } else if (typeof value === "string" && value !== "") {
    return !isNaN(Number(value));
  } else {
    return false;
  }
};

const getMinThreshold = (thresholds: any[]) => {
  const nums: number[] = [];
  thresholds.forEach((threshold: any) => {
    if (isNumber(threshold)) {
      nums.push(threshold);
    }
  });
  return Math.min(...nums);
};

const DetailInformationValidationSchema = yup.object({
  newUpperLimit2Threshold: yupUpperLimit2Threshold
    .nullable()
    .when("ratingMinDisplayValue", ([ratingMinDisplayValue], schema) => {
      return isNumber(ratingMinDisplayValue)
        ? schema.moreThan(Number(ratingMinDisplayValue))
        : schema;
    })
    .when("ratingMaxDisplayValue", ([ratingMaxDisplayValue], schema) => {
      return isNumber(ratingMaxDisplayValue)
        ? schema.lessThan(Number(ratingMaxDisplayValue))
        : schema;
    }),
  newUpperLimit1Threshold: yupUpperLimit1Threshold
    .nullable()
    .when("ratingMinDisplayValue", ([ratingMinDisplayValue], schema) => {
      return isNumber(ratingMinDisplayValue)
        ? schema.moreThan(Number(ratingMinDisplayValue))
        : schema;
    })
    .when("ratingMaxDisplayValue", ([ratingMaxDisplayValue], schema) => {
      return isNumber(ratingMaxDisplayValue)
        ? schema.lessThan(Number(ratingMaxDisplayValue))
        : schema;
    })
    .when("newUpperLimit2Threshold", ([newUpperLimit2Threshold], schema) => {
      return isNumber(newUpperLimit2Threshold)
        ? schema.lessThan(Number(newUpperLimit2Threshold))
        : schema;
    }),
  newLowerLimit1Threshold: yupLowerLimit1Threshold
    .nullable()
    .when("ratingMinDisplayValue", ([ratingMinDisplayValue], schema) => {
      return isNumber(ratingMinDisplayValue)
        ? schema.moreThan(Number(ratingMinDisplayValue))
        : schema;
    })
    .when("ratingMaxDisplayValue", ([ratingMaxDisplayValue], schema) => {
      return isNumber(ratingMaxDisplayValue)
        ? schema.lessThan(Number(ratingMaxDisplayValue))
        : schema;
    })
    .when(
      ["newUpperLimit2Threshold", "newUpperLimit1Threshold"],
      ([newUpperLimit2Threshold, newUpperLimit1Threshold], schema) => {
        const minThreshold = getMinThreshold([
          newUpperLimit2Threshold,
          newUpperLimit1Threshold,
        ]);
        if (minThreshold) {
          return schema.lessThan(minThreshold);
        } else {
          return schema;
        }
      }
    ),
  newLowerLimit2Threshold: yupLowerLimit2Threshold
    .nullable()
    .when("ratingMinDisplayValue", ([ratingMinDisplayValue], schema) => {
      return isNumber(ratingMinDisplayValue)
        ? schema.moreThan(Number(ratingMinDisplayValue))
        : schema;
    })
    .when("ratingMaxDisplayValue", ([ratingMaxDisplayValue], schema) => {
      return isNumber(ratingMaxDisplayValue)
        ? schema.lessThan(Number(ratingMaxDisplayValue))
        : schema;
    })
    .when(
      [
        "newUpperLimit2Threshold",
        "newUpperLimit1Threshold",
        "newLowerLimit1Threshold",
      ],
      (
        [
          newUpperLimit2Threshold,
          newUpperLimit1Threshold,
          newLowerLimit1Threshold,
        ],
        schema
      ) => {
        const minThreshold = getMinThreshold([
          newUpperLimit2Threshold,
          newUpperLimit1Threshold,
          newLowerLimit1Threshold,
        ]);
        if (minThreshold) {
          return schema.lessThan(minThreshold);
        } else {
          return schema;
        }
      }
    ),
  newDataName: yupDataName.required(),
  newDefaultDisplayTimeId: yupDefaultDisplayTimeId.required(),
  newState: yupState.required(),
});

function DetailInformationModal({
  open,
  onClose,
  onUpdate,
  editingDetailInformation,
}: DetailInformationProps) {
  const [displayTimes, setDisplayTimes] = useState<DisplayTime[]>();
  const [errorMessage, setErrorMessage] = useState("");
  const auth = useContext(AuthContext);

  // 新しいstateを定義
  const [confirmOpen, setConfirmOpen] = useState(false);

  const fetchDisplayTimes = useCallback(async () => {
    await checkJwt(auth.siteId);
    getDisplayTimes(auth.siteId, auth.userId).then(setDisplayTimes);
  }, [auth.siteId, auth.userId]);

  useEffect(() => {
    fetchDisplayTimes();
  }, [fetchDisplayTimes]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      state: true,
      isCumulative: true,
      ...editingDetailInformation,
      newDefaultDisplayTimeId: editingDetailInformation?.defaultDisplayTimeId,
      newDefaultDisplayTimeName:
        editingDetailInformation?.defaultDisplayTimeName,
      newDataName: editingDetailInformation?.dataName,
      newState: editingDetailInformation?.state ?? true,
    },
    validationSchema: DetailInformationValidationSchema,
    onSubmit: async () => setConfirmOpen(true),
  });

  const handleConfirm = async () => {
    await checkJwt(auth.siteId);
    try {
      await updateDetailInformation(auth.siteId, auth.userId, formik.values);

      formik.resetForm();
      setErrorMessage("");
      onUpdate();
      onClose();
    } catch (err) {
      const axiosError = err as AxiosError;
      const errNum = axiosError.response?.data;
      if (errNum === ErrorCodes.UniqueKey) {
        setErrorMessage("警報IDとセンサーIDと表示時間IDが重複しています。");
      } else if (errNum === ErrorCodes.NoPermission) {
        setErrorMessage(ErrorMessages.NoPermission);
      } else {
        setErrorMessage(ErrorMessages.UnknownError);
      }
    } finally {
      setConfirmOpen(false);
    }
  };

  const { newDefaultDisplayTimeId, sensorId } = formik.values;
  const setFieldValue = formik.setFieldValue;

  const fetchThreshold = useCallback(async () => {
    await checkJwt(auth.siteId);
    const fetchedThresholds = await getThresholds(
      auth.siteId,
      newDefaultDisplayTimeId,
      sensorId,
      auth.userId
    );
    if (fetchedThresholds && fetchedThresholds.length > 0) {
      const fetchedThreshold = fetchedThresholds[0];

      setFieldValue(
        "upperLimit2Threshold",
        fetchedThreshold.upperLimit2Threshold
      );
      setFieldValue(
        "upperLimit1Threshold",
        fetchedThreshold.upperLimit1Threshold
      );
      setFieldValue(
        "lowerLimit1Threshold",
        fetchedThreshold.lowerLimit1Threshold
      );
      setFieldValue(
        "lowerLimit2Threshold",
        fetchedThreshold.lowerLimit2Threshold
      );
      setFieldValue(
        "newUpperLimit2Threshold",
        fetchedThreshold.upperLimit2Threshold
      );
      setFieldValue(
        "newUpperLimit1Threshold",
        fetchedThreshold.upperLimit1Threshold
      );
      setFieldValue(
        "newLowerLimit1Threshold",
        fetchedThreshold.lowerLimit1Threshold
      );
      setFieldValue(
        "newLowerLimit2Threshold",
        fetchedThreshold.lowerLimit2Threshold
      );
    }
  }, [
    auth.siteId,
    auth.userId,
    newDefaultDisplayTimeId,
    sensorId,
    setFieldValue,
  ]);

  useEffect(() => {
    fetchThreshold();
  }, [fetchThreshold]);

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleCancel = () => {
    formik.resetForm();
    setErrorMessage("");
    onUpdate();
    onClose();
  };

  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            {/* 設備名称 */}
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="center" gutterBottom>
                設備名称
              </Typography>

              <TextField
                name="dataName"
                value={formik.values.dataName ?? ""}
                fullWidth
                disabled
                sx={{ bgcolor: "lightgray" }}
              />

              <TextField
                name="newDataName"
                value={formik.values.newDataName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.newDataName)}
                helperText={formik.errors.newDataName}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" align="center">
                機器状態
              </Typography>
              <Grid
                container
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <ToggleButtonGroup
                  disabled
                  color="primary"
                  value={formik.values.state}
                  exclusive
                >
                  <ToggleButton value={true}>有効</ToggleButton>
                  <ToggleButton value={false}>無効</ToggleButton>
                </ToggleButtonGroup>

                <ToggleButtonGroup
                  color="primary"
                  value={formik.values.newState}
                  exclusive
                  onChange={(
                    _event: React.MouseEvent<HTMLElement>,
                    newValue: boolean | null
                  ) => {
                    if (newValue !== null) {
                      formik.setFieldValue("newState", newValue);
                    }
                  }}
                >
                  <ToggleButton value={true}>有効</ToggleButton>
                  <ToggleButton value={false}>無効</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>

            {formik.values.isCumulative && (
              <>
                {/* 表示時間単位 (積算値) */}

                <Grid item xs={12}>
                  <Typography variant="subtitle1" align="center" gutterBottom>
                    表示時間単位
                  </Typography>
                  <FormControl fullWidth>
                    <Autocomplete
                      id="defaultDisplayTimeId"
                      value={
                        displayTimes?.find(
                          (dt) =>
                            dt.displayTimeId ===
                            formik.values.defaultDisplayTimeId
                        ) || null
                      }
                      options={displayTimes?.filter(
                        (displayTime) =>
                          displayTime.second === null ||
                          editingDetailInformation?.receivingCycle ===
                            undefined ||
                          displayTime.second >=
                            editingDetailInformation?.receivingCycle
                      ) ?? []}
                      getOptionLabel={(option) => option.displayTimeName ?? ""}
                      fullWidth
                      disabled
                      sx={{
                        bgcolor: "lightgray",
                        "& .MuiInputBase-input.Mui-disabled": {
                          color: "black",
                        },
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" disabled />
                      )}
                      onChange={fetchThreshold}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Autocomplete
                      id="newDefaultDisplayTimeId"
                      value={
                        displayTimes?.find(
                          (dt) =>
                            dt.displayTimeId ===
                            formik.values.newDefaultDisplayTimeId
                        ) ?? null
                      }
                      onChange={(_, newValue) => {
                        formik.setFieldValue(
                          "newDefaultDisplayTimeId",
                          newValue ? newValue.displayTimeId : ""
                        );
                        formik.setFieldValue(
                          "newDefaultDisplayTimeName",
                          newValue ? newValue.displayTimeName : ""
                        );
                      }}
                      onBlur={formik.handleBlur}
                      options={displayTimes ?? []}
                      getOptionLabel={(option) => option.displayTimeName ?? ""}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={Boolean(formik.errors.newDefaultDisplayTimeId)}
                          helperText={formik.errors.newDefaultDisplayTimeId}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </>
            )}

            {/* 閾値 */}
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="center" gutterBottom>
                閾値
              </Typography>
              <Grid
                container
                direction="row"
                justifyContent="space-between" // 変更
              >
                <Typography variant="subtitle1" gutterBottom>
                  上限2(異常)
                </Typography>
                <Grid item xs={6}>
                  <TextField
                    name="upperLimit2Threshold"
                    value={formik.values.upperLimit2Threshold ?? ""}
                    disabled
                    fullWidth
                    sx={{ bgcolor: "lightgray" }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-between" // 変更
              >
                <Typography variant="subtitle1" gutterBottom>
                  上限1(警告)
                </Typography>
                <Grid item xs={6}>
                  <TextField
                    name="upperLimit1Threshold"
                    value={formik.values.upperLimit1Threshold ?? ""}
                    disabled
                    sx={{ bgcolor: "lightgray" }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-between" // 変更
              >
                <Typography variant="subtitle1" gutterBottom>
                  下限1(警告)
                </Typography>

                <Grid item xs={6}>
                  <TextField
                    name="lowerLimit1Threshold"
                    value={formik.values.lowerLimit1Threshold ?? ""}
                    disabled
                    sx={{ bgcolor: "lightgray" }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-between" // 変更
              >
                <Typography variant="subtitle1" gutterBottom>
                  下限2(異常)
                </Typography>
                <Grid item xs={6}>
                  <TextField
                    name="lowerLimit2Threshold"
                    value={formik.values.lowerLimit2Threshold ?? ""}
                    disabled
                    sx={{ bgcolor: "lightgray" }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Box sx={{ height: "20px" }}></Box>
              <Grid
                container
                direction="row"
                justifyContent="space-between" // 変更
              >
                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    上限2(異常)
                  </Typography>
                  <Typography
                    fontSize={12}
                    variant="body1"
                    align="center"
                    gutterBottom
                  >
                    {!!formik.values.ratingMaxDisplayValue
                      ? "最大　" +
                        formik.values.ratingMaxDisplayValue +
                        "　まで"
                      : "定格超過上限未設定"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="newUpperLimit2Threshold"
                    type="number"
                    value={formik.values.newUpperLimit2Threshold ?? ""}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.newUpperLimit2Threshold)}
                    helperText={formik.errors.newUpperLimit2Threshold}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-between" // 変更
              >
                <Typography variant="subtitle1" gutterBottom>
                  上限1(警告)
                </Typography>
                <Grid item xs={6}>
                  <TextField
                    name="newUpperLimit1Threshold"
                    type="number"
                    value={formik.values.newUpperLimit1Threshold ?? ""}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.newUpperLimit1Threshold)}
                    helperText={formik.errors.newUpperLimit1Threshold}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-between" // 変更
              >
                <Typography variant="subtitle1" gutterBottom>
                  下限1(警告)
                </Typography>
                <Grid item xs={6}>
                  <TextField
                    name="newLowerLimit1Threshold"
                    type="number"
                    value={formik.values.newLowerLimit1Threshold ?? ""}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.newLowerLimit1Threshold || "")}
                    helperText={formik.errors.newLowerLimit1Threshold}
                    disabled={formik.values.isCumulative}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-between" // 変更
              >
                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    下限2(異常)
                  </Typography>
                  <Typography
                    fontSize={12}
                    align="center"
                    variant="body1"
                    gutterBottom
                  >
                    {!!formik.values.ratingMinDisplayValue
                      ? "最小　" +
                        formik.values.ratingMinDisplayValue +
                        "　まで"
                      : "定格超過下限未設定"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="newLowerLimit2Threshold"
                    type="number"
                    value={formik.values.newLowerLimit2Threshold ?? ""}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.newLowerLimit2Threshold || "")}
                    helperText={formik.errors.newLowerLimit2Threshold}
                    disabled={formik.values.isCumulative}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Cancel and Save Buttons */}
            <CancelSaveButtons handleCancel={handleCancel} />
          </Grid>
        </form>
      </MasterModal>
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogContent>
          <DialogContentText>本当に実行しますか？</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm}>OK</Button>
          <Button onClick={() => setConfirmOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}
export default DetailInformationModal;
