import { Box, Toolbar, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { AxiosError } from "axios";
import { format } from "date-fns-tz";
import * as React from "react";
import { deleteSensor } from "../api/DeleteSensor";
import { getSensors } from "../api/GetSensors";
import { checkJwt } from "../api/TokenVerification";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { AuthContext } from "../context/AuthContext";
import LoadingContext from "../context/LoadingContext";
import { Sensor } from "../models/Sensor";
import calculateColumnWidth from "../share/calculateColumnWidth";
import AddDeleteButton from "./AddDeleteButton";
import CustomMasterContainer from "./CustomMasterContainer";
import CustomPageSnackbar from "./CustomPageSnackbar";
import CustomSearchField from "./CustomSearchField";
import { gridContainerStyles } from "./CustomStyle";
import SensorModal from "./SensorModal";

const headerName = {
  sensorName: "センサー名称",
  iothubDeviceId: "デバイス",
  locationName: "設置場所",
  decodeName: "デコード",
  calculationName: "計算式",
  sensorTypeName: "センサー種別",
  pulse: "パルス値",
  ratingMinDisplayValue: "定格超過下限",
  ratingMaxDisplayValue: "定格超過上限",
  rolloverError: "ロールオーバー時のカウンタ表示",
  rolloverCorrectionMax: "ロールオーバー補正値",
  sensorSerialNumber: "センサー製造番号",
  setUpDate: "設置日",
  removalDate: "撤去日",
  operationStartDate: "運用開始日",
  state: "状態",
};

type GridDataRow = Sensor & {
  id: number;
};

function SensorPage() {
  const initialValue: string = "";
  const [rows, setRows] = React.useState<GridDataRow[]>([]);
  const [editingSensor, setEditingSensor] = React.useState<Sensor>();
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [selectedRows, setSelectedRows] = React.useState<GridRowId[]>([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [inputValue, setInputValue] = React.useState(initialValue); // <-- Update
  const [searchTerm, setSearchTerm] = React.useState(initialValue);
  const auth = React.useContext(AuthContext);
  const [columnWidths, setcolumnWidths] = React.useState<any>();
  const { setLoading } = React.useContext(LoadingContext);

  const columns: GridColDef[] = [
    {
      field: "sensorName",
      headerName: headerName.sensorName,
      minWidth: calculateColumnWidth([headerName]).sensorName,
      width: columnWidths?.sensorName || 0,
    },
    {
      field: "sensorTypeName",
      headerName: headerName.sensorTypeName,
      minWidth: calculateColumnWidth([headerName]).sensorTypeName,
      width: columnWidths?.sensorTypeName || 0,
    },
    {
      field: "iothubDeviceId",
      headerName: headerName.iothubDeviceId,
      minWidth: calculateColumnWidth([headerName]).iothubDeviceId,
      width: columnWidths?.iothubDeviceId || 0,
    },
    {
      field: "decodeName",
      headerName: headerName.decodeName,
      minWidth: calculateColumnWidth([headerName]).decodeName,
      width: columnWidths?.decodeName || 0,
    },
    {
      field: "calculationName",
      headerName: headerName.calculationName,
      minWidth: calculateColumnWidth([headerName]).calculationName,
      width: columnWidths?.calculationName || 0,
    },
    {
      field: "locationName",
      headerName: headerName.locationName,
      minWidth: calculateColumnWidth([headerName]).locationName,
      width: columnWidths?.locationName || 0,
    },
    {
      field: "pulse",
      headerName: headerName.pulse,
      minWidth: calculateColumnWidth([headerName]).pulse,
      width: columnWidths?.pulse || 0,
    },
    {
      field: "ratingMinDisplayValue",
      headerName: headerName.ratingMinDisplayValue,
      minWidth: calculateColumnWidth([headerName]).ratingMinDisplayValue,
      width: columnWidths?.ratingMinDisplayValue || 0,
    },
    {
      field: "ratingMaxDisplayValue",
      headerName: headerName.ratingMaxDisplayValue,
      minWidth: calculateColumnWidth([headerName]).ratingMaxDisplayValue,
      width: columnWidths?.ratingMaxDisplayValue || 0,
    },
    {
      field: "rolloverError",
      headerName: headerName.rolloverError,
      minWidth: calculateColumnWidth([headerName]).rolloverError,
      width: columnWidths?.rolloverError || 0,
      valueFormatter: (params) => {
        switch (params.value) {
          case 0:
            return "1に戻る";
          case 1:
            return "0に戻る";
          default:
            return "";
        }
      },
    },
    {
      field: "rolloverCorrectionMax",
      headerName: headerName.rolloverCorrectionMax,
      minWidth: calculateColumnWidth([headerName]).rolloverCorrectionMax,
      width: columnWidths?.rolloverCorrectionMax || 0,
    },
    {
      field: "sensorSerialNumber",
      headerName: headerName.sensorSerialNumber,
      minWidth: calculateColumnWidth([headerName]).sensorSerialNumber,
      width: columnWidths?.sensorSerialNumber || 0,
    },
    {
      field: "setUpDate",
      headerName: headerName.setUpDate,
      minWidth: calculateColumnWidth([headerName]).setUpDate,
      width: columnWidths?.setUpDate || 0,
      valueFormatter: (params) => {
        if (params.value) {
          return format(params.value, "yyyy/MM/dd");
        }
      },
    },
    {
      field: "removalDate",
      headerName: headerName.removalDate,
      minWidth: calculateColumnWidth([headerName]).removalDate,
      width: columnWidths?.removalDate || 0,
      valueFormatter: (params) => {
        if (params.value) {
          return format(params.value, "yyyy/MM/dd");
        }
      },
    },
    {
      field: "operationStartDate",
      headerName: headerName.operationStartDate,
      minWidth: calculateColumnWidth([headerName]).operationStartDate,
      width: columnWidths?.operationStartDate || 0,
      valueFormatter: (params) => {
        if (params.value) {
          return format(params.value, "yyyy/MM/dd");
        }
      },
    },
    {
      field: "state",
      headerName: headerName.state,
      minWidth: calculateColumnWidth([headerName]).state,
      width: columnWidths?.state || 0,
      valueFormatter: (params) => (params.value ? "有効" : "無効"),
    },
  ];

  const fetchData = React.useCallback(async () => {
    await checkJwt(auth.siteId);
    const records = await getSensors(auth.siteId, auth.userId, searchTerm);
    if (records) {
      const rows: GridDataRow[] = records.map((record: any, i: number) => {
        return {
          id: i + 1,
          ...record,
        };
      });
      setRows(rows);
      setcolumnWidths(calculateColumnWidth(rows));
    }
  }, [searchTerm, auth.siteId, auth.userId]);

  const fetchDataWithLoading = React.useCallback(async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  }, [fetchData, setLoading]);

  React.useEffect(() => {
    fetchDataWithLoading();
  }, [fetchDataWithLoading]);

  const handleRowEditStart = (rowId: GridRowId) => {
    const row = rows.find((row) => row.id === rowId);
    if (row) {
      setEditingSensor(row);
      setOpenModal(true);
    }
  };

  const handleAddRow = () => {
    setEditingSensor(undefined);
    setOpenModal(true);
  };

  const handleDeleteSelected = async (selectedRows: GridRowId[]) => {
    await checkJwt(auth.siteId);

    // 削除対象の siteId の配列を作成
    const RowsToDelete = selectedRows
      .map((rowId) => rows.find((row) => row.id === rowId)?.sensorId)
      .filter((sensorId) => sensorId != null) as number[];

    if (RowsToDelete.length === 0) {
      setErrorMessage(ErrorMessages.SelectionError);
      return;
    }

    const deleteTasks = RowsToDelete.map((sensorId) => {
      return deleteSensor(sensorId, auth.siteId, auth.userId);
    });

    try {
      await Promise.all(deleteTasks);
      fetchDataWithLoading();
      setSelectedRows([]);
    } catch (err) {
      const axiosError = err as AxiosError;
      const errNum = axiosError.response?.data;
      if (errNum === ErrorCodes.ForeignKey) {
        setErrorMessage(ErrorMessages.RecordInUseError);
      } else {
        setErrorMessage(ErrorMessages.UnknownError);
      }
    }
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearchTerm(inputValue);
    }
  };

  return (
    <>
      <CustomMasterContainer>
        <Box sx={{ mb: 1 }}>
          <Typography variant="subtitle1" component="div">
            検索条件
          </Typography>
        </Box>
        <CustomSearchField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Box sx={gridContainerStyles}>
          <DataGrid
            rows={rows}
            columns={columns}
            onRowClick={(param) => handleRowEditStart(param.id)}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={selectedRows}
            onRowSelectionModelChange={(newSelection) => {
              setSelectedRows(newSelection);
            }}
            showColumnVerticalBorder
            slots={{
              toolbar: () => (
                <Toolbar>
                  <AddDeleteButton
                    AddButtonOnClick={() => handleAddRow()}
                    DeleteButtonOnClick={() =>
                      handleDeleteSelected(selectedRows)
                    }
                  />
                </Toolbar>
              ),
            }}
          />
        </Box>
      </CustomMasterContainer>
      <SensorModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onUpdate={fetchDataWithLoading}
        editingSensor={editingSensor as Sensor}
      />

      <CustomPageSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}

export default SensorPage;
