import axios from "axios";
import { ControlRecord } from "../models/ControlRecord";

const url = process.env.REACT_APP_API_URL + "/GetControlRecords";

export async function getControlRecords(
  userId: number,
  siteId: number,
  searchText?: string
) {
  const body = {
    userId,
    siteId,
    searchText,
  };

  const response = await axios.post(url, body);
  const data: any[] = response.data;
  return data.map((d) => {
    const convert: ControlRecord = {
      ...d,
      isOff: Boolean(d.isOff),
      isDisabled: Boolean(d.isDisabled),
      collectionDateTime: d.collectionDateTime
        ? new Date(d.collectionDateTime)
        : d.collectionDateTime,
    };
    return convert;
  });
}
