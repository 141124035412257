import AddIcon from "@mui/icons-material/Add";
import { Grid, IconButton, TextField, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { getControlCards } from "../api/GetControlCards";
import { getControls } from "../api/GetControls";
import { getEqualitySigns } from "../api/GetEqualitySigns";
import { getSensors } from "../api/GetSensors";
import { insertControlSetting } from "../api/InsertControlSetting";
import { checkJwt } from "../api/TokenVerification";
import { updateControlSetting } from "../api/UpdateControlSetting";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { PageId } from "../constants/page";
import { AuthContext } from "../context/AuthContext";
import Control from "../models/Control";
import { ControlCard } from "../models/ControlCard";
import { ControlSetting } from "../models/ControlSetting";
import { EqualitySign } from "../models/EqualitySign";
import { Sensor } from "../models/Sensor";
import { CancelSaveButtons } from "./CancelSaveButtons";
import ControlTypeModal from "./ControlTypeModal";
import CustomAutoComplete from "./CustomAutoComplete";
import CustomModalSnackbar from "./CustomModalSnackbar";
import DisplayGroupModal from "./DisplayGroupModal";
import { MasterModal } from "./MasterModal";
import SensorModal from "./SensorModal";
import {
  yupControlCardId,
  yupControlId,
  yupEqualitySignId,
  yupRadioButtonName,
  yupRadioButtonOrder,
  yupSensorId,
  yupThreshold,
} from "./ValidationSchema";

interface ControlCardModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  editingItem?: ControlSetting;
}

const LocationValidationSchema = yup.object({
  controlCardId: yupControlCardId.required(),
  controlId: yupControlId.required(),
  radioButtonName: yupRadioButtonName.required(),
  radioButtonOrder: yupRadioButtonOrder.required(),
  anserbackSensorId: yupSensorId.required(),
  equalitySignId: yupEqualitySignId.required(),
  threshold: yupThreshold.required(),
});

function ControlCardModal({
  open,
  onClose,
  onUpdate,
  editingItem,
}: ControlCardModalProps) {
  const isUpdate = editingItem && editingItem.controlCardId != null;
  const auth = React.useContext(AuthContext);
  const [openControlCard, setOpenControlCard] = React.useState<boolean>(false);
  const [controlCards, setControlCards] = useState<ControlCard[]>([]);
  const [openControl, setOpenControl] = React.useState<boolean>(false);
  const [controls, setControls] = useState<Control[]>([]);

  const [openSensor, setOpenSensor] = React.useState<boolean>(false);
  const [sensors, setSensors] = useState<Sensor[]>([]);

  const [equalitySigns, setEqualitySigns] = useState<EqualitySign[]>([]);

  const [errorMessage, setErrorMessage] = useState("");

  const fetchData = React.useCallback(async () => {
    await checkJwt(auth.siteId);

    setControlCards((await getControlCards(auth.siteId, auth.userId)) || []);
    setControls((await getControls(auth.siteId, auth.userId)) || []);
    setSensors((await getSensors(auth.siteId, auth.userId)) || []);
    setEqualitySigns((await getEqualitySigns(auth.siteId, auth.userId)) || []);
  }, [auth.siteId, auth.userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [initialValues] = useState<ControlSetting>({});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingItem || initialValues,
    validationSchema: LocationValidationSchema,
    onSubmit: async () => {
      await checkJwt(auth.siteId);

      try {
        if (isUpdate) {
          await updateControlSetting(formik.values, auth.siteId, auth.userId);
        } else {
          await insertControlSetting(formik.values, auth.siteId, auth.userId);
        }
        formik.resetForm();
        setErrorMessage("");
        onUpdate();
        onClose();
      } catch (err) {
        const axiosError = err as AxiosError;
        const errNum = axiosError.response?.data;
        if (errNum === ErrorCodes.UniqueKey) {
          setErrorMessage(
            "制御カードIDと制御IDまたは、制御カードIDとラジオボタン順が重複しています。"
          );
        } else {
          setErrorMessage(ErrorMessages.UnknownError);
        }
      }
    },
  });

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleCancel = () => {
    formik.resetForm();
    setErrorMessage("");
    onClose();
  };

  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {!isUpdate && (
                <Typography variant="h6" gutterBottom>
                  {
                    auth.pages?.find(
                      (page) => page.id === PageId.ControlSettingPage
                    )?.name
                  }
                </Typography>
              )}
            </Grid>
            {/* 制御カード */}
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  disabled={isUpdate}
                  label="制御カード *"
                  name="controlCardId"
                  error={formik.errors.controlCardId}
                  value={formik.values.controlCardId}
                  onChange={formik.handleChange}
                  arrangement={controlCards}
                  format={(controlCard) =>
                    `${controlCard.controlTypeName} > ${controlCard.displayGroupName} > ${controlCard.dataName}`
                  }
                />
              </Grid>
              {!isUpdate && (
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => setOpenControlCard(true)}
                    color="primary"
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            {/* 制御 */}
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  disabled={isUpdate}
                  label="制御 *"
                  name="controlId"
                  error={formik.errors.controlId}
                  value={formik.values.controlId}
                  onChange={formik.handleChange}
                  arrangement={controls}
                  format={(control) =>
                    `${control.iothubDeviceId} > ${control.directMethodName} > ${control.payload}`
                  }
                />
              </Grid>
              {!isUpdate && (
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => setOpenControl(true)}
                    color="primary"
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>

            {/* 設備名称 */}
            <Grid item xs={12}>
              <TextField
                label="ラジオボタン名 *"
                name="radioButtonName"
                value={formik.values.radioButtonName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.radioButtonName)}
                helperText={formik.errors.radioButtonName}
                fullWidth
              />
            </Grid>

            {/* ラジオボタン順 */}
            <Grid item xs={12}>
              <TextField
                type="number"
                label="ラジオボタン順"
                name="radioButtonOrder"
                value={formik.values.radioButtonOrder ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.radioButtonOrder)}
                helperText={formik.errors.radioButtonOrder}
                fullWidth
              />
            </Grid>

            {/* アンサーバックセンサー */}

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="アンサーバックセンサー *"
                  name="sensorId"
                  error={formik.errors.anserbackSensorId}
                  value={formik.values.anserbackSensorId}
                  onChange={(event) => {
                    formik.setFieldValue(
                      "anserbackSensorId",
                      event.target.value
                    );
                  }}
                  arrangement={sensors}
                  format={(sensor) => sensor.sensorName}
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton onClick={() => setOpenSensor(true)} color="primary">
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CustomAutoComplete
                label="等号 *"
                name="equalitySignId"
                error={formik.errors.equalitySignId}
                value={formik.values.equalitySignId}
                onChange={formik.handleChange}
                arrangement={equalitySigns}
                format={(equalitySign) => equalitySign.equalitySign}
              />
            </Grid>

            {/* しきい値 */}
            <Grid item xs={12}>
              <TextField
                label="しきい値"
                name="threshold"
                inputProps={{ inputMode: "numeric" }}
                value={formik.values.threshold ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.threshold)}
                helperText={formik.errors.threshold}
                fullWidth
              />
            </Grid>

            <CancelSaveButtons handleCancel={handleCancel} />
            <ControlTypeModal
              open={openControlCard}
              onClose={() => setOpenControlCard(false)}
              onUpdate={fetchData}
            />
            <DisplayGroupModal
              open={openControl}
              onClose={() => setOpenControl(false)}
              onUpdate={fetchData}
            />
            <SensorModal
              open={openSensor}
              onClose={() => setOpenSensor(false)}
              onUpdate={fetchData}
            />
          </Grid>
        </form>
      </MasterModal>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}
export default ControlCardModal;
