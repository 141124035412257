import { Box, Toolbar, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { AxiosError } from "axios";
import { format } from "date-fns-tz";
import * as React from "react";
import { deleteDevice } from "../api/DeleteDevice";
import { getDevices } from "../api/GetDevices";
import { checkJwt } from "../api/TokenVerification";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { AuthContext } from "../context/AuthContext";
import LoadingContext from "../context/LoadingContext";
import { Device } from "../models/Device";
import calculateColumnWidth from "../share/calculateColumnWidth";
import AddDeleteButton from "./AddDeleteButton";
import CustomMasterContainer from "./CustomMasterContainer";
import CustomPageSnackbar from "./CustomPageSnackbar";
import CustomSearchField from "./CustomSearchField";
import { gridContainerStyles } from "./CustomStyle";
import DeviceModal from "./DeviceModal";

type GridDataRow = Device & {
  id: number;
};

const headerName = {
  deviceName: "デバイス種別",
  iothubName: "IotHub名",
  iothubDeviceId: "IothubデバイスID",
  dataDeviceId: "データデバイスID",
  receivingCycle: "受信周期（秒）",
  missingMarginSecond: "欠測余白時間（秒）",
  communicationFailuresSecond: "通信障害判定時間（秒）",
  locationName: "設置場所",
  serialNumber: "製造番号",
  pacCode: "PACコード",
  sensorCount: "収容センサー台数",
  setUpDate: "設置日",
  removalDate: "撤去日",
  state: "状態",
};

function DevicePage() {
  const initialValue: string = "";
  const [rows, setRows] = React.useState<GridDataRow[]>([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [editingDevice, setEditingDevice] = React.useState<Device>();
  const [selectedRows, setSelectedRows] = React.useState<GridRowId[]>([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [inputValue, setInputValue] = React.useState(initialValue); // <-- Update
  const [searchTerm, setSearchTerm] = React.useState(initialValue);
  const auth = React.useContext(AuthContext);
  const [columnWidths, setColumnWidths] = React.useState<any>();
  const { setLoading } = React.useContext(LoadingContext);

  const columns: GridColDef[] = [
    {
      field: "deviceName",
      headerName: headerName.deviceName,
      minWidth: calculateColumnWidth([headerName]).deviceName,
      width: columnWidths?.deviceName || 0,
    },
    {
      field: "iothubName",
      headerName: headerName.iothubName,
      minWidth: calculateColumnWidth([headerName]).iothubName,
      width: columnWidths?.iothubName || 0,
    },
    {
      field: "iothubDeviceId",
      headerName: headerName.iothubDeviceId,
      minWidth: calculateColumnWidth([headerName]).iothubDeviceId,
      width: columnWidths?.iothubDeviceId || 0,
    },
    {
      field: "dataDeviceId",
      headerName: headerName.dataDeviceId,
      minWidth: calculateColumnWidth([headerName]).dataDeviceId,
      width: columnWidths?.dataDeviceId || 0,
    },
    {
      field: "receivingCycle",
      headerName: headerName.receivingCycle,
      minWidth: calculateColumnWidth([headerName]).receivingCycle,
      width: columnWidths?.receivingCycle || 0,
    },
    {
      field: "missingMarginSecond",
      headerName: headerName.missingMarginSecond,
      minWidth: calculateColumnWidth([headerName]).missingMarginSecond,
      width: columnWidths?.missingMarginSecond || 0,
    },
    {
      field: "communicationFailuresSecond",
      headerName: headerName.communicationFailuresSecond,
      minWidth: calculateColumnWidth([headerName]).communicationFailuresSecond,
      width: columnWidths?.communicationFailuresSecond || 0,
    },
    {
      field: "locationName",
      headerName: headerName.locationName,
      minWidth: calculateColumnWidth([headerName]).locationName,
      width: columnWidths?.locationName || 0,
    },
    {
      field: "serialNumber",
      headerName: headerName.serialNumber,
      minWidth: calculateColumnWidth([headerName]).serialNumber,
      width: columnWidths?.serialNumber || 0,
    },
    {
      field: "pacCode",
      headerName: headerName.pacCode,
      minWidth: calculateColumnWidth([headerName]).pacCode,
      width: columnWidths?.pacCode || 0,
    },
    {
      field: "sensorCount",
      headerName: headerName.sensorCount,
      minWidth: calculateColumnWidth([headerName]).sensorCount,
      width: columnWidths?.sensorCount || 0,
    },
    {
      field: "setUpDate",
      headerName: headerName.setUpDate,
      minWidth: calculateColumnWidth([headerName]).setUpDate,
      width: columnWidths?.setUpDate || 0,
      valueFormatter: (params) => {
        if (params.value === null) {
          return "";
        }
        return format(params.value, "yyyy/MM/dd");
      },
    },
    {
      field: "removalDate",
      headerName: headerName.removalDate,
      minWidth: calculateColumnWidth([headerName]).removalDate,
      width: columnWidths?.removalDate || 0,
      valueFormatter: (params) => {
        if (params.value === null) {
          return "";
        }
        return format(params.value, "yyyy/MM/dd");
      },
    },
    {
      field: "state",
      headerName: headerName.state,
      minWidth: calculateColumnWidth([headerName]).state,
      width: columnWidths?.state || 0,
      valueFormatter: (params) => (params.value ? "有効" : "無効"),
    },
  ];

  const fetchData = React.useCallback(async () => {
    await checkJwt(auth.siteId);
    const records = await getDevices(auth.siteId, auth.userId, searchTerm);
    if (records) {
      const rows: GridDataRow[] = records.map((record: any, i: number) => {
        return {
          id: i + 1,
          ...record,
        };
      });
      setRows(rows);
      setColumnWidths(calculateColumnWidth(rows));
    }
  }, [searchTerm, auth.siteId, auth.userId]);

  const fetchDataWithLoading = React.useCallback(async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  }, [fetchData, setLoading]);

  React.useEffect(() => {
    fetchDataWithLoading();
  }, [fetchDataWithLoading]);

  const handleRowEditStart = (rowId: GridRowId) => {
    const row = rows.find((row) => row.id === rowId);
    if (row) {
      setEditingDevice(row);
      setOpenModal(true);
    }
  };

  const handleRowEditStop = () => {
    setEditingDevice(undefined);
    setOpenModal(false);
  };

  const handleAddRow = () => {
    setEditingDevice(undefined);
    setOpenModal(true);
  };

  const handleDeleteSelected = async (selectedRows: GridRowId[]) => {
    await checkJwt(auth.siteId);

    // 削除対象の siteId の配列を作成
    const RowsToDelete = selectedRows
      .map((rowId) => rows.find((row) => row.id === rowId)?.deviceId)
      .filter((deviceId) => deviceId != null) as number[];

    if (RowsToDelete.length === 0) {
      setErrorMessage(ErrorMessages.SelectionError);
      return;
    }

    const deleteTasks = RowsToDelete.map((deviceId) => {
      return deleteDevice(deviceId, auth.siteId, auth.userId);
    });

    try {
      await Promise.all(deleteTasks);
      fetchDataWithLoading();
      setSelectedRows([]);
    } catch (err) {
      const axiosError = err as AxiosError;
      const errNum = axiosError.response?.data;
      if (errNum === ErrorCodes.ForeignKey) {
        setErrorMessage(ErrorMessages.RecordInUseError);
      } else {
        setErrorMessage(ErrorMessages.UnknownError);
      }
    }
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearchTerm(inputValue);
    }
  };

  return (
    <>
      <CustomMasterContainer>
        <Box sx={{ mb: 1 }}>
          <Typography variant="subtitle1" component="div">
            検索条件
          </Typography>
        </Box>
        <CustomSearchField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Box sx={gridContainerStyles}>
          <DataGrid
            rows={rows}
            columns={columns}
            onRowClick={(param) => handleRowEditStart(param.id)}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={selectedRows}
            onRowSelectionModelChange={(newSelection) => {
              setSelectedRows(newSelection);
            }}
            showColumnVerticalBorder
            slots={{
              toolbar: () => (
                <Toolbar>
                  <AddDeleteButton
                    AddButtonOnClick={() => handleAddRow()}
                    DeleteButtonOnClick={() =>
                      handleDeleteSelected(selectedRows)
                    }
                  />
                </Toolbar>
              ),
            }}
          />
        </Box>
      </CustomMasterContainer>
      <DeviceModal
        open={openModal}
        onClose={handleRowEditStop}
        onUpdate={fetchDataWithLoading}
        editingDevice={editingDevice as Device}
      />
      <CustomPageSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}

export default DevicePage;
