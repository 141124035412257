import axios from "axios";
import { ControlSetting } from "../models/ControlSetting";

const url = process.env.REACT_APP_API_URL + "/InsertControlSetting";

export async function insertControlSetting(
  controlSetting: ControlSetting,
  siteId: number,
  userId: number
) {
  const body = {
    controlSetting,
    siteId,
    userId,
  };
  const response = await axios.post(url, body);
  return response.data;
}
