import AddIcon from "@mui/icons-material/Add";
import { Grid, IconButton, TextField, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { getDisplayGroups } from "../api/GetDisplayGroups";
import { getDisplayTimes } from "../api/GetDisplayTimes";
import { getReceiveDataTypes } from "../api/GetReceiveDataTypes";
import { getSensors } from "../api/GetSensors";
import { insertReceiveDataDisplaySetting } from "../api/InsertReceiveDataDisplaySetting";
import { checkJwt } from "../api/TokenVerification";
import { updateReceiveDataDisplaySetting } from "../api/UpdateReceiveDataDisplaySetting";
import { DisplayTimeId } from "../constants/displayTime";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { PageId } from "../constants/page";
import { AuthContext } from "../context/AuthContext";
import { DisplayGroup } from "../models/DisplayGroup";
import { DisplayTime } from "../models/DisplayTime";
import { ReceiveDataDisplaySetting } from "../models/ReceiveDataDisplaySetting";
import { ReceiveDataType } from "../models/ReceiveDataType";
import { Sensor } from "../models/Sensor";
import { CancelSaveButtons } from "./CancelSaveButtons";
import CustomAutoComplete from "./CustomAutoComplete";
import CustomModalSnackbar from "./CustomModalSnackbar";
import { componentSpacing } from "./CustomStyle";
import DisplayGroupModal from "./DisplayGroupModal";
import { MasterModal } from "./MasterModal";
import ReceiveDataTypeModal from "./ReceiveDataTypeModal";
import SensorModal from "./SensorModal";
import {
  yupDataName,
  yupDisplayGroupId,
  yupDisplayOrder,
  yupDisplayTimeId,
  yupReceiveDataTypeId,
  yupSensorId,
} from "./ValidationSchema";

interface ReceiveDataDisplaySettingModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  editingReceiveDataDisplaySetting?: ReceiveDataDisplaySetting;
}

const ReceiveDataDisplaySettingValidationSchema = yup.object({
  sensorId: yupSensorId.required(),
  receiveDataTypeId: yupReceiveDataTypeId.required(),
  displayGroupId: yupDisplayGroupId.required(),
  dataName: yupDataName.required(),
  defaultDisplayTimeId: yupDisplayTimeId.required(),
  displayOrder: yupDisplayOrder.nullable(),
});

function ReceiveDataDisplaySettingModal({
  open,
  onClose,
  onUpdate,
  editingReceiveDataDisplaySetting,
}: ReceiveDataDisplaySettingModalProps) {
  const auth = React.useContext(AuthContext);
  const isUpdate =
    editingReceiveDataDisplaySetting &&
    editingReceiveDataDisplaySetting.sensorId != null;
  const [sensors, setSensors] = useState<Sensor[]>();
  const [editingSensor, setEditingSensor] = useState<Sensor | null>(null);
  const [receiveDataTypes, setReceiveDataTypes] = useState<ReceiveDataType[]>();
  const [openReceiveDataType, setOpenReceiveDataType] =
    useState<boolean>(false);
  const [displayGroups, setDisplayGroups] = useState<DisplayGroup[]>();
  const [editingDisplayGroup, setEditingDisplayGroup] =
    useState<DisplayGroup | null>(null);

  const [displayTimes, setDisplayTimes] = useState<DisplayTime[]>();
  const [errorMessage, setErrorMessage] = useState("");

  const fetchData = useCallback(async () => {
    await checkJwt(auth.siteId);
    getSensors(auth.siteId, auth.userId).then(setSensors);
    getReceiveDataTypes(auth.siteId, auth.userId).then(setReceiveDataTypes);
    getDisplayGroups(auth.siteId, auth.userId).then(setDisplayGroups);
    getDisplayTimes(auth.siteId, auth.userId).then(setDisplayTimes);
  }, [auth.siteId, auth.userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [initialValues] = useState<ReceiveDataDisplaySetting>({
    siteId: auth.siteId,
    sensorId: null,
    displayGroupId: null,
    receiveDataTypeId: null,
    dataName: null,
    defaultDisplayTimeId: 1,
    displayOrder: null,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingReceiveDataDisplaySetting || initialValues,
    validationSchema: ReceiveDataDisplaySettingValidationSchema,
    onSubmit: async () => {
      await checkJwt(auth.siteId);
      try {
        if (isUpdate) {
          await updateReceiveDataDisplaySetting(
            formik.values,
            auth.siteId,
            auth.userId,
          );
        } else {
          await insertReceiveDataDisplaySetting(
            formik.values,
            auth.siteId,
            auth.userId,
          );
        }
        formik.resetForm();
        setErrorMessage("");
        onUpdate();
        onClose();
      } catch (err) {
        const axiosError = err as AxiosError;
        const errCode = axiosError.response?.data;
        if (errCode === ErrorCodes.UniqueKey) {
          setErrorMessage("センサーIDが重複しています。");
        } else if (errCode === ErrorCodes.DataOverflow) {
          setErrorMessage(ErrorMessages.DisplaySettingOverFlor);
        } else {
          setErrorMessage(ErrorMessages.UnknownError);
        }
      }
    },
  });

  const handleAddDisplayGroup = () => {
    const displayGroup: DisplayGroup = {
      siteId: auth.siteId,
      displayGroupId: null,
      displayGroupName: null,
      displayGroupOrder: null,
    };

    setEditingDisplayGroup(displayGroup);
  };

  const handleRowEditStop = () => {
    setEditingSensor(null);
    setEditingDisplayGroup(null);
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleCancel = () => {
    formik.resetForm();
    setErrorMessage("");
    onClose();
  };

  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            {/* Title */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {
                  auth.pages?.find(
                    (page) => page.id === PageId.ReceiveDataDisplaySettingPage,
                  )?.name
                }
              </Typography>
            </Grid>

            <Grid item xs={12} sx={componentSpacing}>
              <Grid item>
                <CustomAutoComplete
                  label="センサー *"
                  name="sensorId"
                  error={formik.errors.sensorId}
                  value={formik.values.sensorId}
                  onChange={formik.handleChange}
                  arrangement={
                    sensors?.some((sensor) => sensor.siteId === auth.siteId)
                      ? sensors?.filter(
                          (sensor) => sensor.siteId === auth.siteId,
                        )
                      : sensors
                  }
                  format={(sensor) =>
                    `${sensor.iothubDeviceId}：${sensor.sensorName}`
                  }
                  disabled={isUpdate}
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                ...componentSpacing,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="受信データ種別 *"
                  name="receiveDataTypeId"
                  error={formik.errors.receiveDataTypeId}
                  value={formik.values.receiveDataTypeId}
                  onChange={formik.handleChange}
                  arrangement={receiveDataTypes}
                  format={(receiveDataType) =>
                    `${receiveDataType.receiveDataTypeName} (単位：${
                      receiveDataType.unit ? receiveDataType.unit : ""
                    })`
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  onClick={() => setOpenReceiveDataType(true)}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                ...componentSpacing,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="表示グループ *"
                  name="displayGroupId"
                  error={formik.errors.displayGroupId}
                  value={formik.values.displayGroupId}
                  onChange={formik.handleChange}
                  arrangement={displayGroups}
                  format={(displayGroup) => displayGroup.displayGroupName}
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton onClick={handleAddDisplayGroup} color="primary">
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={componentSpacing}>
              <TextField
                label="データ *"
                name="dataName"
                value={formik.values.dataName || ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.dataName)}
                helperText={formik.errors.dataName}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={componentSpacing}>
              <Grid item>
                <CustomAutoComplete
                  label="表示時間 *"
                  name="displayTimeId"
                  error={formik.errors.defaultDisplayTimeId}
                  value={formik.values.defaultDisplayTimeId}
                  onChange={(event) => {
                    formik.setFieldValue(
                      "defaultDisplayTimeId",
                      event.target.value,
                    );
                  }}
                  arrangement={displayTimes?.filter((displayTIme) => {
                    if (displayTIme.displayTimeId === DisplayTimeId.RECENT) {
                      return true;
                    }
                    const sensor = sensors?.find(
                      (sensor) => sensor.sensorId === formik.values.sensorId,
                    );
                    if (sensor?.isCumulative && sensor?.receivingCycle) {
                      return displayTIme.second >= sensor.receivingCycle;
                    }

                    return false;
                  })}
                  format={(displayTime) => displayTime.displayTimeName}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sx={componentSpacing}>
              <TextField
                label="並び順"
                name="displayOrder"
                type="number"
                value={
                  formik.values.displayOrder !== null
                    ? formik.values.displayOrder
                    : ""
                }
                onChange={formik.handleChange}
                error={Boolean(formik.errors.displayOrder)}
                helperText={formik.errors.displayOrder}
                fullWidth
              />
            </Grid>

            <CancelSaveButtons handleCancel={handleCancel} />
            <SensorModal
              open={!!editingSensor}
              onClose={handleRowEditStop}
              onUpdate={fetchData}
              editingSensor={editingSensor as Sensor}
            />
            <ReceiveDataTypeModal
              open={openReceiveDataType}
              onClose={() => setOpenReceiveDataType(false)}
              onUpdate={fetchData}
            />
            <DisplayGroupModal
              open={!!editingDisplayGroup}
              onClose={handleRowEditStop}
              onUpdate={fetchData}
              editingDisplayGroup={editingDisplayGroup as DisplayGroup}
            />
          </Grid>
        </form>
      </MasterModal>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}
export default ReceiveDataDisplaySettingModal;
