import axios from "axios";

const url = process.env.REACT_APP_API_URL + "/DeleteSensor";

export async function deleteSensor(
  sensorId: number,
  siteId: number,
  userId: number
) {
  const body = {
    sensorId,
    siteId,
    userId,
  };
  const response = await axios.post(url, body);
  return response.data;
}
