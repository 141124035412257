import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../api/TokenVerification";
import { AuthContext } from "../context/AuthContext";
import { MenuInformation } from "../models/MenuInformation";
import CommonDialog, { ButtonType } from "./CommonDialog";

const LoginPage = () => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };
  const auth = React.useContext(AuthContext);
  const [loginId, setLoginId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  // ダイアログ用のstate
  const [digOpen, setDigOpen] = React.useState(false);

  const signin = (isForce: boolean) => {
    setIsLoading(true);
    login(loginId, password, isForce)
      .then((response) => {
        if (!response) {
          throw new Error("Not found");
        }
        // メニュー取得
        const dataArray: MenuInformation[] = JSON.parse(
          response.data.menus
        ).recordset.map((item: any) => {
          return {
            id: item.id,
            icon: item.icon,
            name: item.name,
            url: item.url,
          } as MenuInformation;
        });
        auth.setMenu(dataArray);
        auth.setPermissionAbbreviation(response.data.permissionAbbreviation);
        auth.setCanCreate(response.data.canCreate);
        auth.setCanMonitor(response.data.canMonitor);
        auth.setCanControl(response.data.canControl);
        auth.setCanModify(response.data.canModify);
        auth.setPages(response.data.pages);
        auth.login(
          response.data.userId,
          response.data.autoLogoutSpan,
          response.data.tokenExpiration
        );
        navigate("/" + response.data.siteName + dataArray[0].url);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 300) {
          setDigOpen(true);
        } else {
          setIsError(true);
        }
        setIsLoading(false);
      });
  };

  return (
    <>
      <CommonDialog
        title="確認"
        message="本アカウントはログイン中です。ログインを上書きしますか？"
        buttonType={ButtonType.YesNo}
        open={digOpen}
        onAccept={() => signin(true)}
        onClose={() => setDigOpen(false)}
      />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            ログイン
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="loginId"
              label="ログインID"
              name="loginId"
              autoComplete="loginId"
              autoFocus
              onChange={(e) => setLoginId(e.target.value)}
              error={isError}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="パスワード"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
              error={isError}
              helperText={
                isError ? "ユーザIDかパスワードに誤りがあります。" : ""
              }
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => signin(false)}
              disabled={isLoading}
            >
              {"ログイン"}
            </Button>
            <Link to="/SendChangePasswordEmailPage">
              パスワードをお忘れの場合
            </Link>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default LoginPage;
