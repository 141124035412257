import axios from "axios";
import { DeviceAlarmSetting } from "../models/DeviceAlarmSetting";

const url = process.env.REACT_APP_API_URL + "/UpdateDeviceAlarmSetting";

export async function updateDeviceAlarmSetting(
  deviceAlarmSetting: DeviceAlarmSetting,
  newDeviceAlarmSetting: DeviceAlarmSetting,
  siteId: number,
  userId: number
) {
  const body = {
    deviceAlarmSetting,
    newDeviceAlarmSetting,
    siteId,
    userId,
  };
  const response = await axios.post(url, body);
  return response.data;
}
