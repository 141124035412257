import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridColumnGroupingModel,
  GridRowId,
} from "@mui/x-data-grid";
import { format } from "date-fns-tz";
import * as React from "react";
import { getAdminUsers } from "../api/GetAdminUsers";
import { getSiteDetailInformations } from "../api/GetSiteDetailInformations";
import { checkJwt } from "../api/TokenVerification";
import { AuthContext } from "../context/AuthContext";
import LoadingContext from "../context/LoadingContext";
import { User } from "../models/User";
import { ControlDetailInformation } from "../models/ControlDetailnformation";
import calculateColumnWidth from "../share/calculateColumnWidth";
import AdminUserModal from "./AdminUserModal";
import CustomListContainer from "./CustomListContainer";
import CustomSearchField from "./CustomSearchField";

type GridDataRow = User & {
  id: number;
};

const headerName = {
  id: "No",
  userName: "ユーザー名",
  loginId: "ログインID",
  password: "パスワード",
  email: "メールアドレス",
  phoneNumber: "電話番号",
  sendNotificationFlag: "通知一括ON/OFF",
  notificationStartTime: "通知開始時間",
  notificationStopTime: "通知停止時間",
  notificationNames: "通知",
};
function AdminUserPage() {
  const [rows, setRows] = React.useState<GridDataRow[]>([]);
  const [editingRow, setEditingRow] = React.useState<User | null>(null);
  const [selectedRows, setSelectedRows] = React.useState<GridRowId[]>([]);
  const auth = React.useContext(AuthContext);
  const [inputValue, setInputValue] = React.useState(""); // <-- Update
  const [searchTerm, setSearchTerm] = React.useState("");
  const [siteDetailInformation, setSiteDetailInformation] =
    React.useState<ControlDetailInformation | null>(null);
  const [columnWidths, setColumnWIdths] = React.useState<any>();
  const { setLoading } = React.useContext(LoadingContext);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: headerName.id,
      minWidth: calculateColumnWidth([headerName]).id,
      width: columnWidths?.id || 0,
    },
    {
      field: "userName",
      headerName: headerName.userName,
      minWidth: calculateColumnWidth([headerName]).userName,
      width: columnWidths?.userName || 0,
    },
    {
      field: "loginId",
      headerName: headerName.loginId,
      minWidth: calculateColumnWidth([headerName]).loginId,
      width: columnWidths?.loginId || 0,
    },
    {
      field: "password",
      headerName: headerName.password,
      minWidth: calculateColumnWidth([headerName]).password,
      width: columnWidths?.password || 0,
      valueFormatter: () => "****",
    },
    {
      field: "email",
      headerName: headerName.email,
      minWidth: calculateColumnWidth([headerName]).email,
      width: columnWidths?.email || 0,
    },
    {
      field: "phoneNumber",
      headerName: headerName.phoneNumber,
      minWidth: calculateColumnWidth([headerName]).phoneNumber,
      width: columnWidths?.phoneNumber || 0,
    },
    {
      field: "notificationNames", // Change to notificationNames
      headerName: headerName.notificationNames,
      minWidth: calculateColumnWidth([headerName]).notificationNames,
      width: columnWidths?.notificationNames || 0,
    },
    {
      field: "sendNotificationFlag",
      headerName: headerName.sendNotificationFlag,
      minWidth: calculateColumnWidth([headerName]).sendNotificationFlag,
      width: columnWidths?.sendNotificationFlag || 0,
      valueFormatter: (params) => (params.value ? "有効" : "無効"),
    },
    {
      field: "notificationStartTime",
      headerName: headerName.notificationStartTime,
      minWidth: calculateColumnWidth([headerName]).notificationStartTime,
      // width: columnWidths?.notificationStartTime || 0,
      valueFormatter: (params) => {
        if (!!params.value && (params.value as Date)) {
          return format(new Date(params.value), "HH:mm:ss");
        } else {
          return "";
        }
      },
    },
    {
      field: "notificationStopTime",
      headerName: headerName.notificationStopTime,
      minWidth: calculateColumnWidth([headerName]).notificationStopTime,
      // width: columnWidths?.notificationStopTime || 0,
      valueFormatter: (params) => {
        if (!!params.value && (params.value as Date)) {
          return format(new Date(params.value), "HH:mm:ss");
        } else {
          return "";
        }
      },
    },
  ];

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: "通知要否設定",
      headerAlign: "center",
      children: [
        { field: "notificationNames" },
        { field: "sendNotificationFlag" },
        { field: "notificationStartTime" },
        { field: "notificationStopTime" },
      ],
    },
  ];

  const fetchData = React.useCallback(async () => {
    await checkJwt(auth.siteId);
    const records1 = await getAdminUsers(auth.siteId, auth.userId, searchTerm);
    if (records1) {
      const rows: GridDataRow[] = records1.map((record: any, i: number) => {
        return {
          id: i + 1,
          ...record,
        };
      });
      setRows(rows);
      setColumnWIdths(calculateColumnWidth(rows));
    }

    const records2 = await getSiteDetailInformations(auth.siteId, auth.userId);
    if (records2 && records2.length > 0) {
      const record: ControlDetailInformation = records2[0]; // recordsの最初の要素を取得
      setSiteDetailInformation(record);
    }
  }, [searchTerm, auth.siteId, auth.userId]);

  const fetchDataWithLoading = React.useCallback(async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  }, [fetchData, setLoading, auth.user]);

  React.useEffect(() => {
    fetchDataWithLoading();
  }, [fetchDataWithLoading]);

  const handleRowEditStart = (rowId: GridRowId) => {
    if (!auth.canModify) return; // auth.canModifyがfalseなら何もしない
    const row = rows.find((row) => row.id === rowId);

    if (row) setEditingRow(row);
  };

  const handleRowEditStop = () => {
    setEditingRow(null);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearchTerm(inputValue);
    }
  };

  return (
    <>
      <CustomListContainer>
        {/* Gridコンテナの構造を変更する部分 */}
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Box sx={{ mb: 1 }}>
              <Typography variant="subtitle2" component="div">
                検索条件
              </Typography>
            </Box>
            <CustomSearchField
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </Grid>
          <Grid item xs={3}>
            <Box sx={{ mb: 1 }}>
              <Typography variant="subtitle2" component="div">
                サイト情報
              </Typography>
            </Box>
            <Stack
              direction="row"
              justifyContent="flex-end" // Align text fields to the right
              alignItems="center"
              spacing={2}
            >
              {/* 運用開始日と所有アカウント数の値を取得するロジックは省略 */}
              <TextField
                label="運用開始日"
                value={
                  siteDetailInformation?.registrationDate
                    ? siteDetailInformation?.registrationDate.toLocaleDateString()
                    : ""
                }
                disabled={true}
              />
              <TextField
                label="所有アカウント数"
                value={
                  siteDetailInformation?.userNumber
                    ? siteDetailInformation.userNumber
                    : ""
                }
                disabled={true}
              />
            </Stack>
          </Grid>
        </Grid>
        <Typography variant="subtitle2" component="div">
          ユーザー情報
        </Typography>
        <Box
          sx={{ height: "calc(100% - 70px)", width: "100%", marginBottom: 2 }}
        >
          <DataGrid
            experimentalFeatures={{ columnGrouping: true }}
            rows={rows}
            columns={columns}
            onRowClick={(param) => handleRowEditStart(param.id)}
            disableRowSelectionOnClick
            rowSelectionModel={selectedRows}
            onRowSelectionModelChange={(newSelection) => {
              setSelectedRows(newSelection);
            }}
            columnGroupingModel={columnGroupingModel}
            showColumnVerticalBorder
          />
        </Box>
      </CustomListContainer>
      <AdminUserModal
        open={!!editingRow}
        onClose={handleRowEditStop}
        // onUpdate={fetchDataWithLoading}
        title={""}
        editingAdminUser={editingRow as User}
      />
    </>
  );
}

export default AdminUserPage;
