import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { deleteGraphPattern } from "../api/DeleteGraphPattern";
import { getGraphPatternModal } from "../api/GetGraphPatternModal";
import { registGraphPatterns } from "../api/RegistGraphPatterns";
import { checkJwt } from "../api/TokenVerification";
import { AuthContext } from "../context/AuthContext";
import { GraphPattern } from "../models/GraphPattern";
import { yupGraphPatternName } from "./ValidationSchema";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  pattern: GraphPattern | null;
  setPattern: React.Dispatch<React.SetStateAction<GraphPattern | null>>;
};

type GraphRow = {
  id: number;
  sensorId: number;
  displayGroupName: string;
  locationName: string;
  dataName: string;
  receiveDataTypeName: string;
  unit: string;
  isChecked: boolean;
};

const GraphPatternValidationSchema = yup.object().shape({
  graphPatternName: yupGraphPatternName.required(),
});

export const GraphPatternModal: React.FC<Props> = ({
  isOpen,
  onClose,
  pattern,
  setPattern,
}) => {
  const auth = useContext(AuthContext);

  const columns: GridColDef[] = [
    { field: "displayGroupName", headerName: "表示グループ" },
    { field: "dataName", headerName: "データ名", width: 300 },
    { field: "receiveDataTypeName", headerName: "データ種別" },
    { field: "unit", headerName: "単位", width: 50 },
  ];

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [rows, setRows] = useState<Array<GraphRow>>([]);

  useEffect(() => {
    checkJwt(auth.siteId).then(() => {
      getGraphPatternModal(auth.userId, auth.siteId).then(
        (results: Array<any>) => {
          if (results) {
            const rows: GraphRow[] = results.map((record, i) => {
              return {
                id: i + 1,
                ...record,
              };
            });
            setRows(rows);
            setSelectedRows(
              rows
                .filter((row) =>
                  pattern?.sensorIds.some(
                    (sensorId) => sensorId === row.sensorId
                  )
                )
                .map((row) => row.id)
            );
          }
        }
      );
    });
  }, [pattern, auth.userId, auth.siteId]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      graphPatternName: pattern?.graphPatternName,
    },
    validationSchema: GraphPatternValidationSchema,
    onSubmit: async (values) => {
      await checkJwt(auth.siteId);

      const newPattern = {
        graphPatternName: values.graphPatternName,
        sensorIds: rows
          .filter((row) =>
            selectedRows.some((selectedRow) => selectedRow === row.id)
          )
          .map((row) => {
            return row.sensorId;
          }),
      };

      await deleteGraphPattern(
        auth.userId,
        Number(auth.siteId),
        values?.graphPatternName
      );
      await registGraphPatterns(auth.userId, auth.siteId, newPattern);
      setPattern(newPattern);
      onClose();
    },
  });

  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80vw",
          maxHeight: "80vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflow: "auto",
        }}
      >
        <Grid container direction="row" justifyContent="space-between">
          <Stack direction="row">
            <Button
              variant="contained"
              onClick={() => {
                setPattern({
                  sensorIds: rows
                    .filter((row) =>
                      selectedRows.some((selectedRow) => selectedRow === row.id)
                    )
                    .map((row) => row.sensorId),
                });
                onClose();
              }}
              sx={{ margin: 1 }}
            >
              表示
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={onClose}
              sx={{ margin: 1 }}
            >
              キャンセル
            </Button>
          </Stack>
          <form onSubmit={formik.handleSubmit}>
            <Stack direction="row" spacing={1} sx={{ margin: 1 }}>
              <TextField
                required
                label="パターン名"
                variant="outlined"
                name="graphPatternName"
                value={formik.values.graphPatternName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.graphPatternName)}
              />
              <Button variant="contained" type="submit">
                登録
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={async () => {
                  await checkJwt(auth.siteId);
                  await deleteGraphPattern(
                    auth.userId,
                    auth.siteId,
                    formik.values?.graphPatternName
                  );
                  setPattern(null);
                  onClose();
                }}
              >
                削除
              </Button>
            </Stack>
          </form>
        </Grid>
        <DataGrid
          sx={{ height: "calc(80vh - 100px)" }}
          rows={rows}
          columns={columns}
          checkboxSelection
          rowSelectionModel={selectedRows}
          onRowSelectionModelChange={(newSelectionModel) => {
            setSelectedRows(newSelectionModel);
          }}
        />
      </Box>
    </Modal>
  );
};
