import { Grid, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { checkJwt } from "../api/TokenVerification";
import { AuthContext } from "../context/AuthContext";
import { CancelSaveButtons } from "./CancelSaveButtons";
import { MasterModal } from "./MasterModal";
import { yupDeviceTwin, yupDeviceTwinName } from "./ValidationSchema";

import { DeviceTwin } from "../models/DeviceTwin";
import CustomModalSnackbar from "./CustomModalSnackbar";

import { insertDeviceTwin } from "../api/InsertDeviceTwin";
interface DeviceTwinModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  editingItem?: DeviceTwin;
}

const LocationValidationSchema = yup.object().shape({
  deviceTwinName: yupDeviceTwinName.required(),
  deviceTwin: yupDeviceTwin.required(),
});

function DeviceTwinModal({
  open,
  onClose,
  onUpdate,
  editingItem,
}: DeviceTwinModalProps) {
  const isUpdate = editingItem && editingItem.deviceTwinId != null;
  const auth = React.useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");

  const [initialValues] = useState<DeviceTwin>({});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingItem || initialValues,
    validationSchema: LocationValidationSchema,
    onSubmit: async () => {
      await checkJwt(auth.siteId);

      try {
        if (isUpdate) {
          // await updateControlCard(formik.values, auth.siteId, auth.userId);
        } else {
          await insertDeviceTwin(formik.values, auth.siteId, auth.userId);
        }
        formik.resetForm();
        onUpdate();
        onClose();
      } catch (error) {
        // Handle the error if it is not an instance of Error
        setErrorMessage("未知のエラーが発生しました。");
      }
    },
  });

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleCancel = () => {
    formik.resetForm();
    setErrorMessage("");
    onClose();
  };

  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {!isUpdate && (
                <Typography variant="h6" gutterBottom>
                  デバイスツイン
                </Typography>
              )}
            </Grid>
            {/* デバイスツイン名 */}
            <Grid item xs={12}>
              <TextField
                label="デバイスツイン名 *"
                name="deviceTwinName"
                value={formik.values.deviceTwinName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.deviceTwinName)}
                helperText={formik.errors.deviceTwinName}
                fullWidth
              />
            </Grid>
            {/* デバイスツイン */}
            <Grid item xs={12}>
              <TextField
                label="デバイスツイン *"
                name="deviceTwin"
                value={formik.values.deviceTwin ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.deviceTwin)}
                helperText={formik.errors.deviceTwin}
                fullWidth
              />
            </Grid>

            <CancelSaveButtons handleCancel={handleCancel} />
          </Grid>
        </form>
      </MasterModal>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}
export default DeviceTwinModal;
