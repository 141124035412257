import AddIcon from "@mui/icons-material/Add";
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import * as ja from "yup-locale-ja";
import { getColors } from "../api/GetColors";
import getNotifications from "../api/GetNotifications";
import { insertAlarm } from "../api/InsertAlarm";
import { checkJwt } from "../api/TokenVerification";
import { updateAlarm } from "../api/UpdateAlarm";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { PageId } from "../constants/page";
import { AuthContext } from "../context/AuthContext";
import { Alarm } from "../models/Alarm";
import { Color } from "../models/Color";
import { Notification } from "../models/Notification";
import { CancelSaveButtons } from "./CancelSaveButtons";
import ColorModal from "./ColorModal";
import CustomAutoComplete from "./CustomAutoComplete";
import CustomModalSnackbar from "./CustomModalSnackbar";
import { MasterModal } from "./MasterModal";
import {
  yupAlarmName,
  yupColorId,
  yupNotificationId,
} from "./ValidationSchema";

yup.setLocale(ja.suggestive);

interface AlarmModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  editingAlarm?: Alarm;
}

const ArarmValidationSchema = yup.object({
  alarmName: yupAlarmName.required(),
  colorId: yupColorId,
  notificationId: yupNotificationId.required(),
});

function AlarmModal({
  open,
  onClose,
  onUpdate,
  editingAlarm,
}: AlarmModalProps) {
  const isUpdate = editingAlarm && editingAlarm.alarmId != null;
  const [colors, setColors] = useState<Color[]>();
  const [openColor, setOpenColor] = useState<boolean>(false);
  const auth = React.useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [notifications, setNotifications] = useState<Notification[]>();

  const fetchData = useCallback(async () => {
    await checkJwt(auth.siteId)
    getColors(auth.siteId, auth.userId).then(setColors);
    getNotifications(auth.siteId, auth.userId).then(setNotifications);
  }, [auth.siteId, auth.userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [initialValues] = useState<Alarm>({});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingAlarm || initialValues,
    validationSchema: ArarmValidationSchema,
    onSubmit: async () => {
      await checkJwt(auth.siteId);
      try {
        if (isUpdate) {
          await updateAlarm(formik.values, auth.siteId, auth.userId);
        } else {
          await insertAlarm(formik.values, auth.siteId, auth.userId);
        }
        formik.resetForm();
        onUpdate();
        onClose();
      } catch (err) {
        const axiosError = err as AxiosError;
        const errNum = axiosError.response?.data;
        if (errNum === ErrorCodes.UniqueKey) {
          setErrorMessage("警報名が重複しています。");
        } else {
          setErrorMessage(ErrorMessages.UnknownError);
        }
      }
    },
  });

  const handleCancel = () => {
    formik.resetForm();

    onClose();
  };

  const styles = {
    select: {
      width: 320,
    },
  };

  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {auth.pages?.find((page) => page.id === PageId.AlarmPage)?.name}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="アラーム名 *"
                name="alarmName"
                value={formik.values.alarmName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.alarmName)}
                helperText={formik.errors.alarmName}
                fullWidth
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <FormControl
                  sx={{
                    bgcolor: formik.values.colorId
                      ? colors?.find(
                          (color) => color.colorId === formik.values.colorId
                        )?.palette
                      : undefined,
                  }}
                >
                  <InputLabel id="colorId-label">色</InputLabel>
                  <Select
                    labelId="colorId-label"
                    id="colorId"
                    name="colorId"
                    label="色"
                    value={formik.values.colorId ?? ""}
                    onChange={formik.handleChange}
                    style={styles.select}
                    sx={{
                      color: "primary.contrastText",
                    }}
                    error={Boolean(formik.errors.colorId)}
                    fullWidth
                  >
                    <MenuItem sx={{ background: undefined }}>-</MenuItem>
                    {colors &&
                      colors.map((color) => (
                        <MenuItem
                          key={color.colorId}
                          value={color.colorId}
                          sx={{
                            color:
                              color?.palette &&
                              color.palette.split(".")[0] + ".contrastText",
                            backgroundColor: color.palette,
                          }}
                        >
                          {color.palette}
                        </MenuItem>
                      ))}
                  </Select>
                  {formik.errors.colorId && (
                    <FormHelperText error>
                      {formik.errors.colorId}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={() => setOpenColor(true)} color="primary">
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="通知 *"
                  name="notificationId"
                  error={formik.errors.notificationId}
                  value={formik.values.notificationId}
                  onChange={formik.handleChange}
                  arrangement={notifications ?? []}
                  format={(notification) => notification.notificationName}
                />
              </Grid>
            </Grid>

            <CancelSaveButtons handleCancel={handleCancel} />
            <ColorModal
              open={openColor}
              onClose={() => setOpenColor(false)}
              onUpdate={fetchData}
            />
          </Grid>
        </form>
      </MasterModal>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={() => setErrorMessage("")}
      />
    </>
  );
}
export default AlarmModal;
