import { Box, Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import { LogLevel } from "../models/LogLevel";
import { UserNotification } from "../models/UserNotification";

type Props = {
  logLevels?: LogLevel[];
  notifications?: UserNotification[];
  setNotifications: React.Dispatch<
    React.SetStateAction<UserNotification[] | undefined>
  >;
};

const NotificationCheckbox = ({
  logLevels,
  notifications,
  setNotifications,
}: Props) => {
  return (
    <>
      {logLevels
        ?.filter((l) =>
          notifications?.some((n) => n.logLevelId === l.logLevelId)
        )
        .map((logLevel) => {
          return (
            <Box key={`${logLevel.logLevelId}`}>
              <Box
                key={`${logLevel.logLevelId}`}
                bgcolor={"primary.light"}
                color={"primary.contrastText"}
                sx={{ pl: 1 }}
              >
                <FormControlLabel
                  key={`${logLevel.logLevelId}`}
                  label={logLevel.logLevelName}
                  control={
                    <Checkbox
                      key={`${logLevel.logLevelId}`}
                      color={"default"}
                      checked={notifications
                        ?.filter((n) => n.logLevelId === logLevel.logLevelId)
                        .every((n) => n.isSelected)}
                      indeterminate={
                        notifications
                          ?.filter((n) => n.logLevelId === logLevel.logLevelId)
                          .some((n) => n.isSelected) &&
                        notifications
                          ?.filter((n) => n.logLevelId === logLevel.logLevelId)
                          .some((n) => !n.isSelected)
                      }
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setNotifications(
                          notifications?.map((n) => {
                            if (n.logLevelId === logLevel.logLevelId) {
                              n.isSelected = event.target.checked;
                            }
                            return n;
                          })
                        );
                      }}
                    />
                  }
                />
              </Box>
              {notifications
                ?.filter((n) => n.logLevelId === logLevel.logLevelId)
                .map((n) => {
                  return (
                    <Box
                      key={`${n.notificationId}`}
                      sx={{
                        ml: 3,
                      }}
                    >
                      <FormControlLabel
                        key={`${n.notificationId}`}
                        label={n.notificationName}
                        control={
                          <Checkbox
                            key={`${n.notificationId}`}
                            checked={n.isSelected}
                            onChange={() => {
                              setNotifications([
                                ...notifications?.map((item) => {
                                  if (
                                    item.notificationId === n.notificationId
                                  ) {
                                    item.isSelected = !item.isSelected;
                                  }
                                  return item;
                                }),
                              ]);
                            }}
                          />
                        }
                      />
                    </Box>
                  );
                })}
            </Box>
          );
        })}
    </>
  );
};

export default NotificationCheckbox;
