import axios from "axios";
import { Color } from "../models/Color";

const url = process.env.REACT_APP_API_URL + "/InsertColor";

export async function insertColor(
  color: Color,
  siteId: number,
  userId: number
) {
  const body = {
    color,
    siteId,
    userId,
  };
  const response = await axios.post(url, body);
  return response.data;
}
