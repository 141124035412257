import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { HashRouter } from "react-router-dom";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    observedAbnormal: Palette["primary"];
    statusDisabled: Palette["primary"];
  }

  interface PaletteOptions {
    observedAbnormal?: PaletteOptions["primary"];
    statusDisabled?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    observedAbnormal: true;
    statusDisabled: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#00978d",
      light: "#33ABA3",
      dark: "#006962",
    },
    secondary: {
      main: "#97000A",
      light: "#AB333B",
      dark: "#690007",
    },
    text: {
      disabled: "black",
    },
    observedAbnormal: {
      main: "#8d0097",
      light: "#aa37aa",
      dark: "#6b0088",
      contrastText: "#ffffff",
    },
    statusDisabled: {
      main: "lightgray",
      light: "lightgray",
      dark: "lightgray",
      contrastText: "#ffffff",
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            backgroundColor: "lightgray", // ライトグレーの背景色
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            backgroundColor: "lightgray", // ライトグレーの背景色
          },
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
    />

    <StyledEngineProvider injectFirst>
      <HashRouter>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </HashRouter>
    </StyledEngineProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
