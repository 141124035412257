import { Box, Toolbar, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { AxiosError } from "axios";
import * as React from "react";
import { deleteControlSetting } from "../api/DeleteControlSetting";
import { getControlSettings } from "../api/GetControlSettings";
import { checkJwt } from "../api/TokenVerification";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { AuthContext } from "../context/AuthContext";
import LoadingContext from "../context/LoadingContext";
import { ControlSetting } from "../models/ControlSetting";
import calculateColumnWidth from "../share/calculateColumnWidth";
import AddDeleteButton from "./AddDeleteButton";
import ControlSettingModal from "./ControlSettingModal";
import CustomMasterContainer from "./CustomMasterContainer";
import CustomPageSnackbar from "./CustomPageSnackbar";
import CustomSearchField from "./CustomSearchField";
import { gridContainerStyles } from "./CustomStyle";

type DataGridRow = ControlSetting & {
  id: number;
};

const headerName = {
  controlCardInfo: "制御カード",
  controlInfo: "制御",
  radioButtonName: "ラジオボタン名",
  radioButtonOrder: "ラジオボタン順",
  anserbackSensorName: "アンサーバックセンサー",
  equalitySign: "等号",
  threshold: "閾値",
};

function ControlSettingPage() {
  const initialValue: string = "";
  const [rows, setRows] = React.useState<DataGridRow[]>([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [editingItem, setEditingItem] = React.useState<ControlSetting>();
  const [selectedRows, setSelectedRows] = React.useState<GridRowId[]>([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const auth = React.useContext(AuthContext);
  const [columnWidths, setColumnWidths] = React.useState<any>();
  const [inputValue, setInputValue] = React.useState(initialValue); // <-- Update
  const [searchTerm, setSearchTerm] = React.useState(initialValue);
  const { setLoading } = React.useContext(LoadingContext);

  const columns: GridColDef[] = [
    {
      field: "controlCardInfo",
      headerName: headerName.controlCardInfo,
      minWidth: calculateColumnWidth([headerName]).controlCardInfo,
      width: columnWidths?.controlCardInfo || 0,
    },
    {
      field: "controlInfo",
      headerName: headerName.controlInfo,
      minWidth: calculateColumnWidth([headerName]).controlInfo,
      width: columnWidths?.controlInfo || 0,
    },
    {
      field: "radioButtonName",
      headerName: headerName.radioButtonName,
      minWidth: calculateColumnWidth([headerName]).radioButtonName,
      width: columnWidths?.radioButtonName || 0,
    },
    {
      field: "radioButtonOrder",
      headerName: headerName.radioButtonOrder,
      minWidth: calculateColumnWidth([headerName]).radioButtonOrder,
      width: columnWidths?.radioButtonOrder || 0,
    },
    {
      field: "anserbackSensorName",
      headerName: headerName.anserbackSensorName,
      minWidth: calculateColumnWidth([headerName]).anserbackSensorName,
      width: columnWidths?.anserbackSensorName || 0,
    },
    {
      field: "equalitySign",
      headerName: headerName.equalitySign,
      minWidth: calculateColumnWidth([headerName]).equalitySign,
      width: columnWidths?.equalitySign || 0,
    },
    {
      field: "threshold",
      headerName: headerName.threshold,
      minWidth: calculateColumnWidth([headerName]).threshold,
      width: columnWidths?.threshold || 0,
    },
  ];

  //DBからデータを取得している
  const fetchData = React.useCallback(async () => {
    await checkJwt(auth.siteId);
    const records = await getControlSettings(
      auth.siteId,
      auth.userId,
      searchTerm
    );
    if (records) {
      const row: DataGridRow[] = records.map((record: any, i: number) => {
        return {
          id: i + 1,
          ...record,
        };
      });
      setRows(row);
      setColumnWidths(calculateColumnWidth(row));
    }
  }, [auth.siteId, auth.userId, searchTerm]);

  const fetchDataWithLoading = React.useCallback(async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  }, [fetchData, setLoading]);

  React.useEffect(() => {
    fetchDataWithLoading();
  }, [fetchDataWithLoading]);

  const handleRowEditStart = (rowId: GridRowId) => {
    const row = rows.find((row) => row.id === rowId);
    if (row) {
      setEditingItem(row);
      setOpenModal(true);
    }
  };

  const handleRowEditStop = () => {
    setEditingItem(undefined);
    setOpenModal(false);
  };

  const handleAddItem = () => {
    setEditingItem(undefined);
    setOpenModal(true);
  };

  const handleDeleteSelected = async (selectedRows: GridRowId[]) => {
    await checkJwt(auth.siteId);

    const RowsToDelete = selectedRows
      .map((rowId) => rows.find((row) => row.id === rowId))
      .filter((row): row is DataGridRow => row !== undefined);

    if (RowsToDelete.length === 0) {
      setErrorMessage(ErrorMessages.SelectionError);
      return;
    }

    const deleteTasks = RowsToDelete.map((row) =>
      deleteControlSetting(
        row.controlCardId!,
        row.controlId!,
        auth.siteId,
        auth.userId
      )
    );

    try {
      await Promise.all(deleteTasks);
      fetchDataWithLoading();
      setSelectedRows([]);
    } catch (err) {
      const axiosError = err as AxiosError;
      const errNum = axiosError.response?.data;
      if (errNum === ErrorCodes.ForeignKey) {
        setErrorMessage(ErrorMessages.RecordInUseError);
      } else {
        setErrorMessage(ErrorMessages.UnknownError);
      }
    }
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearchTerm(inputValue);
    }
  };

  return (
    <>
      <CustomMasterContainer>
        <Box sx={{ mb: 1 }}>
          <Typography variant="subtitle1" component="div">
            検索条件
          </Typography>
        </Box>
        <CustomSearchField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />

        <Box sx={gridContainerStyles}>
          <DataGrid
            rows={rows}
            columns={columns}
            onRowClick={(param) => handleRowEditStart(param.id)}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={selectedRows}
            onRowSelectionModelChange={(newSelection) => {
              setSelectedRows(newSelection);
            }}
            showColumnVerticalBorder
            slots={{
              toolbar: () => (
                <Toolbar>
                  <AddDeleteButton
                    AddButtonOnClick={() => handleAddItem()}
                    DeleteButtonOnClick={() =>
                      handleDeleteSelected(selectedRows)
                    }
                  />
                </Toolbar>
              ),
            }}
          />
        </Box>
      </CustomMasterContainer>
      <ControlSettingModal
        open={openModal}
        onClose={handleRowEditStop}
        onUpdate={fetchDataWithLoading}
        editingItem={editingItem as ControlSetting}
      />
      <CustomPageSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}

export default ControlSettingPage;
