import axios from "axios";

const url = process.env.REACT_APP_API_URL + "/GetSites";

export async function getSites(
  siteId: number,
  userId: number,
  searchText?: string
) {
  try {
    const body = {
      siteId,
      userId,
      searchText,
    };
    const response = await axios.post(url, body, { withCredentials: true });

    const data: any[] = response.data;
    return data.map((d) => {
      return {
        ...d,
        registrationDate: d.registrationDate && new Date(d.registrationDate),
      };
    });
  } catch (error) {
    return;
  } finally {
  }
}
