import axios from "axios";
import { ReceiveDataType } from "../models/ReceiveDataType";

const url = process.env.REACT_APP_API_URL + "/InsertReceiveDataType";

export async function insertReceiveDataType(
  receiveDataType: ReceiveDataType,
  siteId: number,
  userId: number
) {
  const body = {
    receiveDataType,
    siteId,
    userId,
  };
  const response = await axios.post(url, body);
  return response.data;
}
