import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ReactNode } from "react";

interface MasterModalProps {
  open: boolean;
  children: ReactNode;
}

export function MasterModal({ open, children }: MasterModalProps) {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          m: "auto",
          width: "70%",
          maxWidth: 400,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        {children}
      </Box>
    </Modal>
  );
}
