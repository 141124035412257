import { Box, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridColumnGroupingModel,
  GridRowId,
} from "@mui/x-data-grid";
import { format } from "date-fns-tz";
import React, { useCallback, useContext, useState } from "react";
import { useLocation } from "react-router";
import { getDetailInformations } from "../api/GetDetailInformations";
import { checkJwt } from "../api/TokenVerification";
import { AuthContext } from "../context/AuthContext";
import LoadingContext from "../context/LoadingContext";
import { DetailInformation } from "../models/DetailInformation";
import calculateColumnWidth from "../share/calculateColumnWidth";
import CustomListContainer from "./CustomListContainer";
import CustomSearchField from "./CustomSearchField";
import { gridContainerStyles } from "./CustomStyle";
import DetailInformationModal from "./DetailInformationModal";

type GridRow = DetailInformation & {
  id: number;
};

const headerName = {
  id: "No",
  dataName: "設備名称",
  state: "機器状態",
  defaultDisplayTimeName: "表示時間単位",
  upperLimit2Threshold: "上限2(異常)",
  upperLimit1Threshold: "上限1(警告)",
  lowerLimit1Threshold: "下限1(警告)",
  lowerLimit2Threshold: "下限2(異常)",
  sensorTypeName: "項目",
  model: "センサー型式",
  sensorSerialNumber: "センサー製造番号",
  setUpDate: "設置年月日",
};

// Main Component
const SensorDetailInformationPage: React.FC = () => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const [columnWidths, setColumnWIdths] = useState<any>();
  const { setLoading } = useContext(LoadingContext);

  // Check if location.state exists before trying to access the property
  const initialValue: string = location.state?.dataName || "";

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: headerName.id,
      minWidth: calculateColumnWidth([headerName]).id,
      width: columnWidths?.id || 0,
    },
    {
      field: "dataName",
      headerName: headerName.dataName,
      minWidth: calculateColumnWidth([headerName]).dataName,
      width: columnWidths?.dataName || 0,
    },
    {
      field: "state",
      headerName: headerName.state,
      minWidth: calculateColumnWidth([headerName]).state,
      width: columnWidths?.state || 0,
      valueFormatter: (params) => (params.value ? "有効" : "無効"),
    },
    {
      field: "defaultDisplayTimeName",
      headerName: headerName.defaultDisplayTimeName,
      minWidth: calculateColumnWidth([headerName]).defaultDisplayTimeName,
      width: columnWidths?.defaultDisplayTimeName || 0,
      valueFormatter: (params) =>
        rows.find((row) => row.id === params.id)?.isCumulative
          ? params.value
          : "-",
    },
    {
      field: "upperLimit2Threshold",
      headerName: headerName.upperLimit2Threshold,
      minWidth: calculateColumnWidth([headerName]).upperLimit2Threshold,
      width: columnWidths?.upperLimit2Threshold || 0,
    },
    {
      field: "upperLimit1Threshold",
      headerName: headerName.upperLimit1Threshold,
      minWidth: calculateColumnWidth([headerName]).upperLimit1Threshold,
      width: columnWidths?.upperLimit1Threshold || 0,
    },
    {
      field: "lowerLimit1Threshold",
      headerName: headerName.lowerLimit1Threshold,
      minWidth: calculateColumnWidth([headerName]).lowerLimit1Threshold,
      width: columnWidths?.lowerLimit1Threshold || 0,
    },
    {
      field: "lowerLimit2Threshold",
      headerName: headerName.lowerLimit2Threshold,
      minWidth: calculateColumnWidth([headerName]).lowerLimit2Threshold,
      width: columnWidths?.lowerLimit2Threshold || 0,
    },
    {
      field: "sensorTypeName",
      headerName: headerName.sensorTypeName,
      minWidth: calculateColumnWidth([headerName]).sensorTypeName,
      width: columnWidths?.sensorTypeName || 0,
    },
    {
      field: "model",
      headerName: headerName.model,
      minWidth: calculateColumnWidth([headerName]).model,
      width: columnWidths?.model || 0,
    },
    {
      field: "sensorSerialNumber",
      headerName: headerName.sensorSerialNumber,
      minWidth: calculateColumnWidth([headerName]).sensorSerialNumber,
      width: columnWidths?.sensorSerialNumber || 0,
    },
    {
      field: "setUpDate",
      headerName: headerName.setUpDate,
      minWidth: calculateColumnWidth([headerName]).setUpDate,
      width: columnWidths?.setUpDate || 0,

      valueFormatter: (params: { value: Date }) => {
        if (params.value === null) {
          return "";
        }
        return format(params.value, "yyyy/MM/dd");
      },
    },
  ];

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: "閾値",
      headerAlign: "center",
      children: [
        { field: "upperLimit2Threshold" },
        { field: "upperLimit1Threshold" },
        { field: "lowerLimit1Threshold" },
        { field: "lowerLimit2Threshold" },
      ],
    },
  ];

  // Initialize inputValue with initialValue (props received from the previous screen)
  const [inputValue, setInputValue] = useState(initialValue); // <-- Update
  const [searchTerm, setSearchTerm] = useState(initialValue);
  const [rows, setRows] = useState<GridRow[]>([]);
  const [editingDetailInformation, setEditingDetailInformation] =
    React.useState<DetailInformation | null>(null);

  const fetchData = useCallback(async () => {
    await checkJwt(auth.siteId);
    const records = await getDetailInformations(
      auth.siteId,
      auth.userId,
      searchTerm
    );
    if (records) {
      const row: GridRow[] = records.map((record: any, i: number) => {
        return {
          id: i + 1,
          ...record,
        };
      });

      setRows(row);
      setColumnWIdths(calculateColumnWidth(row));
    }
  }, [searchTerm, auth.userId, auth.siteId]);

  const fetchDataWithLoading = React.useCallback(async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  }, [fetchData, setLoading]);

  React.useEffect(() => {
    fetchDataWithLoading();
  }, [fetchDataWithLoading]);

  const handleRowEditStart = (rowId: GridRowId) => {
    if (!auth.canModify) return; // auth.canModifyがfalseなら何もしない

    const row = rows.find((row) => row.id === rowId);
    if (row) setEditingDetailInformation(row);
  };

  const handleRowEditStop = () => {
    setEditingDetailInformation(null);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearchTerm(inputValue);
    }
  };

  return (
    <>
      <CustomListContainer>
        <Box sx={{ mb: 1 }}>
          <Typography variant="subtitle1" component="div">
            検索条件
          </Typography>
        </Box>
        <CustomSearchField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />

        <Typography variant="subtitle1" component="div">
          監視項目詳細情報
        </Typography>
        <Box sx={gridContainerStyles}>
          <DataGrid
            experimentalFeatures={{ columnGrouping: true }}
            rows={rows}
            columns={columns}
            onRowClick={(param) => handleRowEditStart(param.id)}
            columnGroupingModel={columnGroupingModel}
            showColumnVerticalBorder
          />
        </Box>
      </CustomListContainer>
      <DetailInformationModal
        open={!!editingDetailInformation}
        onClose={handleRowEditStop}
        onUpdate={fetchDataWithLoading}
        editingDetailInformation={editingDetailInformation as DetailInformation}
      />
    </>
  );
};

export default SensorDetailInformationPage;
