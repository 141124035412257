import axios from "axios";
import { DetailInformation } from "../models/DetailInformation";

const url = process.env.REACT_APP_API_URL + "/GetDetailInformations";

export async function getDetailInformations(
  siteId: number,
  userId: number,
  searchText?: string
) {
  try {
    const body = {
      siteId,
      userId,
      searchText,
    };

    const response = await axios.post(url, body);

    const data: any[] = response.data;
    return data.map((d) => {
      const convert: DetailInformation = {
        ...d,
        setUpDate: d.setUpDate ? new Date(d.setUpDate) : d.setUpDate,
      };
      return convert;
    });
  } catch (error) {
    console.log(error);
    return;
  }
}
