import React, { useState, ReactNode } from "react";
import LoadingContext from "./LoadingContext";

interface LoadingProviderProps {
  children: ReactNode;
}

const LoadingProvider: React.FC<LoadingProviderProps> = ({ children }) => {
  const [isCheckJwt, setCheckJwt] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isCsvDownloading, setDownloading] = useState(false);

  return (
    <LoadingContext.Provider
      value={{
        isCheckJwt,
        setCheckJwt,
        isLoading,
        setLoading,
        isCsvDownloading,
        setCsvDownloading: setDownloading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
