import axios from "axios";

const url = process.env.REACT_APP_API_URL + "/DeleteControl";

export async function deleteControl(
  controlId: number,
  siteId: number,
  userId: number
) {
  const body = {
    controlId,
    siteId,
    userId,
  };
  const response = await axios.post(url, body);
  return response.data;
}
