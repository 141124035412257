import axios from "axios";
import { SiteLocation } from "../models/SiteLocation";

const url = process.env.REACT_APP_API_URL + "/InsertSiteLocation";

export async function insertSiteLocation(
  siteLocation: SiteLocation,
  siteId: number,
  userId: number
) {
  const body = {
    siteLocation,
    siteId,
    userId,
  };
  const response = await axios.post(url, body);
  return response.data;
}
