import axios from "axios";
import { DisplayGroup } from "../models/DisplayGroup";

const url = process.env.REACT_APP_API_URL + "/InsertDisplayGroup";

export async function insertDisplayGroup(
  displayGroup: DisplayGroup,
  siteId: number,
  userId: number
) {
  const body = {
    displayGroup,
    siteId,
    userId,
  };
  const response = await axios.post(url, body);
  return response.data;
}
