import {
  Autocomplete,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import React from "react";

interface CustomAutoCompleteProps {
  label: string;
  name: string;
  error: string | undefined;
  value: any;
  onChange: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => void;
  arrangement?: any[];
  format?: (option: any) => string;
  disabled?: boolean;
}

function CustomAutoComplete({
  label,
  name,
  error,
  value,
  onChange,
  arrangement,
  format,
  disabled,
}: CustomAutoCompleteProps) {
  const options = arrangement?.map((option) => ({
    value: option[name],
    label: format ? format(option) : option[name],
  }));

  return (
    <FormControl fullWidth>
      <Autocomplete
        id={name}
        options={options ?? []}
        value={options?.find((opt) => opt.value === value) || null}
        onChange={(_, newValue) => {
          const selectedValue = newValue ? newValue.value : null;
          onChange({ target: { name, value: selectedValue } } as any);
        }}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            error={Boolean(error)}
            fullWidth
            disabled={disabled}
          />
        )}
        sx={{ maxWidth: "320px" }}
      />
      {<FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
}

export default CustomAutoComplete;
