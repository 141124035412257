import axios from "axios";
import { Sensor } from "../models/Sensor";

const url = process.env.REACT_APP_API_URL + "/InsertSensor";

export async function insertSensor(
  sensor: Sensor,
  siteId: number,
  userId: number
) {
  const body = {
    sensor,
    siteId,
    userId,
  };
  const response = await axios.post(url, body);
  return response.data;
}
