import AddIcon from "@mui/icons-material/Add";
import { Grid, IconButton, TextField, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { getAlarms } from "../api/GetAlarms";
import { getDevices } from "../api/GetDevices";
import { getEqualitySigns } from "../api/GetEqualitySigns";
import { getSensors } from "../api/GetSensors";
import { insertDeviceAlarmSetting } from "../api/InsertDeviceAlarmSetting";
import { checkJwt } from "../api/TokenVerification";
import { updateDeviceAlarmSetting } from "../api/UpdateDeviceAlarmSetting";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { PageId } from "../constants/page";
import { AuthContext } from "../context/AuthContext";
import { Alarm } from "../models/Alarm";
import { Device } from "../models/Device";
import { DeviceAlarmSetting } from "../models/DeviceAlarmSetting";
import { EqualitySign } from "../models/EqualitySign";
import { Sensor } from "../models/Sensor";
import AlarmModal from "./AlarmModal";
import { CancelSaveButtons } from "./CancelSaveButtons";
import CustomAutoComplete from "./CustomAutoComplete";
import CustomModalSnackbar from "./CustomModalSnackbar";
import DeviceModal from "./DeviceModal";
import { MasterModal } from "./MasterModal";
import SensorModal from "./SensorModal";
import {
  yupAlarmId,
  yupDeviceId,
  yupEqualitySignId,
  yupSensorId,
  yupThreshold,
} from "./ValidationSchema";

interface DeviceAlarmSettingProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  editingDeviceAlarmSetting?: DeviceAlarmSetting;
}

const DeviceAlarmSettingValidationSchema = yup.object({
  alarmId: yupAlarmId.required(),
  deviceId: yupDeviceId.required(),
  sensorId: yupSensorId.required(),
  equalitySignId: yupEqualitySignId.required(),
  threshold: yupThreshold.required(),
});

function DeviceAlarmSettingModal({
  open,
  onClose,
  onUpdate,
  editingDeviceAlarmSetting,
}: DeviceAlarmSettingProps) {
  const isUpdate =
    editingDeviceAlarmSetting && editingDeviceAlarmSetting.alarmId != null;
  const [alarms, setAlarms] = useState<Alarm[]>();
  const [openAlarm, setOpenAlarm] = useState<boolean>(false);
  const [devices, setDevices] = useState<Device[]>();
  const [openDevice, setOpenDevice] = useState<boolean>(false);
  const [sensors, setSensors] = useState<Sensor[]>();
  const [openSensor, setOpenSensor] = useState<boolean>(false);
  const [equalitySigns, setEqualitySigns] = useState<EqualitySign[]>();
  const [errorMessage, setErrorMessage] = useState("");
  const auth = React.useContext(AuthContext);

  const fetchData = useCallback(async () => {
    await checkJwt(auth.siteId);
    getAlarms(auth.siteId, auth.userId).then(setAlarms);
    getDevices(auth.siteId, auth.userId).then(setDevices);
    getSensors(auth.siteId, auth.userId).then(setSensors);
    getEqualitySigns(auth.siteId, auth.userId).then(setEqualitySigns);
  }, [auth.siteId, auth.userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [initialValues] = useState<DeviceAlarmSetting>({});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingDeviceAlarmSetting || initialValues,
    validationSchema: DeviceAlarmSettingValidationSchema,
    onSubmit: async () => {
      await checkJwt(auth.siteId);
      try {
        if (isUpdate) {
          await updateDeviceAlarmSetting(
            editingDeviceAlarmSetting,
            formik.values,
            auth.siteId,
            auth.userId
          );
        } else {
          await insertDeviceAlarmSetting(
            formik.values,
            auth.siteId,
            auth.userId
          );
        }

        formik.resetForm();
        setErrorMessage("");
        onUpdate();
        onClose();
      } catch (err) {
        const axiosError = err as AxiosError;
        const errNum = axiosError.response?.data;
        if (errNum === ErrorCodes.UniqueKey) {
          setErrorMessage("警報IDとデバイスIDとセンサーIDが重複しています。");
        } else {
          setErrorMessage(ErrorMessages.UnknownError);
        }
      }
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    setErrorMessage("");
    onClose();
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            {/* Title */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {
                  auth.pages?.find(
                    (page) => page.id === PageId.DeviceAlarmSettingPage
                  )?.name
                }
              </Typography>
            </Grid>

            {/* デバイス */}
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="デバイス *"
                  name="deviceId"
                  error={formik.errors.deviceId}
                  value={formik.values.deviceId}
                  onChange={formik.handleChange}
                  arrangement={devices}
                  format={(devices) => `${devices.iothubDeviceId}`}
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton onClick={() => setOpenDevice(true)} color="primary">
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
            {/* センサー名 */}
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="センサー名 *"
                  name="sensorId"
                  error={formik.errors.sensorId}
                  value={formik.values.sensorId}
                  onChange={formik.handleChange}
                  arrangement={sensors}
                  format={(sensor) =>
                    `${sensor.sensorName}：${sensor.iothubDeviceId}`
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={() => setOpenSensor(true)} color="primary">
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="警報名 *"
                  name="alarmId"
                  error={formik.errors.alarmId}
                  value={formik.values.alarmId}
                  onChange={formik.handleChange}
                  arrangement={alarms}
                  format={(alarm) => `${alarm.alarmName}`}
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton onClick={() => setOpenAlarm(true)} color="primary">
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            {/* 等号 */}
            <Grid item xs={12}>
              <Grid item>
                <CustomAutoComplete
                  label="等号 *"
                  name="equalitySignId"
                  error={formik.errors.equalitySignId}
                  value={formik.values.equalitySignId}
                  onChange={formik.handleChange}
                  arrangement={equalitySigns}
                  format={(equalitySign) => `${equalitySign.equalitySign}`}
                />
              </Grid>
            </Grid>

            {/* しきい値 */}
            <Grid item xs={12}>
              <TextField
                label="しきい値 *"
                name="threshold"
                inputProps={{ inputMode: "numeric" }}
                value={formik.values.threshold ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.threshold)}
                helperText={formik.errors.threshold}
                fullWidth
              />
            </Grid>

            <CancelSaveButtons handleCancel={handleCancel} />
            <AlarmModal
              open={openAlarm}
              onClose={() => setOpenAlarm(false)}
              onUpdate={fetchData}
            />
            <DeviceModal
              open={openDevice}
              onClose={() => setOpenDevice(false)}
              onUpdate={fetchData}
            />
            <SensorModal
              open={openSensor}
              onClose={() => setOpenSensor(false)}
              onUpdate={fetchData}
            />
          </Grid>
        </form>
      </MasterModal>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}
export default DeviceAlarmSettingModal;
