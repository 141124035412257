import AddIcon from "@mui/icons-material/Add";
import { Grid, IconButton, TextField, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { getSites } from "../api/GetSites";
import { insertLocation } from "../api/InsertLocation";
import { checkJwt } from "../api/TokenVerification";
import { updateLocation } from "../api/UpdateLocation";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { PageId } from "../constants/page";
import { AuthContext } from "../context/AuthContext";
import { Location } from "../models/Location";
import { Site } from "../models/Site";
import { CancelSaveButtons } from "./CancelSaveButtons";
import CustomAutoComplete from "./CustomAutoComplete";
import CustomModalSnackbar from "./CustomModalSnackbar";
import { MasterModal } from "./MasterModal";
import SiteModal from "./SiteModal";
import {
  yupLatitude,
  yupLocationName,
  yupLongitude,
  yupSiteId,
} from "./ValidationSchema";

interface LocationModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  editingLocation?: Location;
}

const LocationValidationSchema = yup.object().shape({
  siteId: yupSiteId.required(),
  locationName: yupLocationName.required(),
  latitude: yupLatitude.nullable(),
  longitude: yupLongitude.nullable(),
});

function LocationModal({
  open,
  onClose,
  onUpdate,
  editingLocation,
}: LocationModalProps) {
  const isUpdate = editingLocation && editingLocation.locationId != null;
  const [openSite, setOpenSite] = React.useState<boolean>(false);
  const auth = React.useContext(AuthContext);
  const [sites, setSites] = useState<Site[]>();
  const [errorMessage, setErrorMessage] = useState("");

  const fetchData = React.useCallback(async () => {
    await checkJwt(auth.siteId);
    getSites(auth.siteId, auth.userId).then(setSites);
  }, [auth.siteId, auth.userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [initialValues] = useState<Location>({});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingLocation || initialValues,
    validationSchema: LocationValidationSchema,
    onSubmit: async () => {
      await checkJwt(auth.siteId);
      try {
        if (isUpdate) {
          await updateLocation(formik.values, auth.siteId, auth.userId);
        } else {
          await insertLocation(formik.values, auth.siteId, auth.userId);
        }
        formik.resetForm();
        onUpdate();
        onClose();
      } catch (err) {
        const axiosError = err as AxiosError;
        const errNum = axiosError.response?.data;
        if (errNum === ErrorCodes.UniqueKey) {
          setErrorMessage("サイト名と、位置名が重複しています。");
        } else {
          setErrorMessage(ErrorMessages.UnknownError);
        }
      }
    },
  });

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleCancel = () => {
    formik.resetForm();
    setErrorMessage("");
    onClose();
  };

  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {
                  auth.pages?.find((page) => page.id === PageId.LocationPage)
                    ?.name
                }
              </Typography>
            </Grid>
            {/* Location Name */}
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="サイト名 *"
                  name="siteId"
                  error={formik.errors.siteId}
                  value={formik.values.siteId}
                  onChange={formik.handleChange}
                  arrangement={sites}
                  format={(site) => site.siteName}
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton onClick={() => setOpenSite(true)} color="primary">
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="位置名 *"
                name="locationName"
                value={formik.values.locationName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.locationName)}
                helperText={formik.errors.locationName}
                fullWidth
              />
            </Grid>

            {/* Latitude */}
            <Grid item xs={12}>
              <TextField
                label="緯度"
                name="latitude"
                type="text"
                inputProps={{ inputMode: "numeric" }}
                value={formik.values.latitude ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.latitude)}
                helperText={formik.errors.latitude}
                fullWidth
              />
            </Grid>

            {/* Longitude */}
            <Grid item xs={12}>
              <TextField
                label="経度"
                type="text"
                inputProps={{ inputMode: "numeric" }}
                name="longitude"
                value={formik.values.longitude ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.longitude)}
                helperText={formik.errors.longitude}
                fullWidth
              />
            </Grid>

            <CancelSaveButtons handleCancel={handleCancel} />
            <SiteModal
              open={openSite}
              onClose={() => setOpenSite(false)}
              onUpdate={fetchData}
            />
          </Grid>
        </form>
      </MasterModal>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}
export default LocationModal;
