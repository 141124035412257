import axios from "axios";

const url = process.env.REACT_APP_API_URL + "/GetFans";

export async function getFans(
  siteId: number,
  userId: number,
  searchText?: string
) {
  try {
    const body = {
      searchText,
      siteId,
      userId,
    };

    const response = await axios.post(url, body);
    return response.data;
  } catch (error) {
    return;
  } finally {
  }
}
