import axios from "axios";
import { Device } from "../models/Device";

const url = process.env.REACT_APP_API_URL + "/GetDevices";

export async function getDevices(
  siteId: number,
  userId: number,
  searchText?: string
) {
  try {
    const body = {
      siteId,
      userId,
      searchText,
    };

    const response = await axios.post(url, body);
    const data: any[] = response.data;
    return data.map((d) => {
      const convert: Device = {
        ...d,
        setUpDate: d.setUpDate && new Date(d.setUpDate),
        removalDate: d.removalDate && new Date(d.removalDate),
        state: Boolean(d.state),
      };
      return convert;
    });
  } catch (error) {
    return;
  } finally {
  }
}
