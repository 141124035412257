import axios from "axios";

const url = process.env.REACT_APP_API_URL + "/GetEqualitySigns";

export async function getEqualitySigns(siteId: number, userId: number) {
  try {
    const body = { siteId, userId };
    const response = await axios.post(url, body);
    return response.data;
  } catch (error) {
    return;
  } finally {
  }
}
