import axios from "axios";
import { Fan } from "../models/Fan";

const url = process.env.REACT_APP_API_URL + "/InsertFan";

export async function insertFan(fan: Fan, siteId: number, userId: number) {
  const body = {
    fan,
    siteId,
    userId,
  };
  const response = await axios.post(url, body);
  return response.data;
}
