import axios from "axios";
import DirectMethod from "../models/DirectMethod";

const url = process.env.REACT_APP_API_URL + "/UpdateDirectMethod";

export async function updateDirectMethod(
  directMethod: DirectMethod,
  siteId: number,
  userId: number
) {
  try {
    const body = {
      directMethod,
      siteId,
      userId,
    };
    const response = await axios.post(url, body);
    return response.data;
  } catch (error) {
    return;
  } finally {
  }
}
