import AddIcon from "@mui/icons-material/Add";
import {
  Grid,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { getDataFormats } from "../api/GetDataFormats";
import { insertDecode } from "../api/InsertDecode";
import { checkJwt } from "../api/TokenVerification";
import { updateDecode } from "../api/UpdateDecode";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { PageId } from "../constants/page";
import { AuthContext } from "../context/AuthContext";
import { DataFormat } from "../models/DataFormat";
import { Decode } from "../models/Decode";
import { CancelSaveButtons } from "./CancelSaveButtons";
import CustomAutoComplete from "./CustomAutoComplete";
import CustomModalSnackbar from "./CustomModalSnackbar";
import DataFormatModal from "./DataFormatModal";
import { MasterModal } from "./MasterModal";
import {
  yupDataFormatId,
  yupDecodeName,
  yupEndPosition,
  yupStartPosition,
} from "./ValidationSchema";

interface DecodeModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  editingDecode?: Decode;
}

const DecoodeValidationSchema = yup.object({
  decodeName: yupDecodeName.required(),
  dataFormatId: yupDataFormatId.required(),
  startPosition: yupStartPosition.nullable(),
  endPosition: yupEndPosition.nullable(),
});

function DecodeModal({
  open,
  onClose,
  onUpdate,
  editingDecode,
}: DecodeModalProps) {
  const isUpdate = editingDecode && editingDecode.decodeId != null;

  const [dataFormats, setDataFormats] = useState<DataFormat[]>();
  const [openDataFormat, setOpenDataFormat] = React.useState<boolean>(false);
  const auth = React.useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchData = useCallback(async () => {
    await checkJwt(auth.siteId);
    getDataFormats(auth.siteId, auth.userId).then(setDataFormats);
  }, [auth.siteId, auth.userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [initialValues] = useState<Decode>({
    isAnserback: false,
    isCumulative: false,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingDecode || initialValues,
    validationSchema: DecoodeValidationSchema,
    onSubmit: async () => {
      await checkJwt(auth.siteId);

      try {
        if (isUpdate) {
          await updateDecode(formik.values, auth.siteId, auth.userId);
        } else {
          await insertDecode(formik.values, auth.siteId, auth.userId);
        }
        formik.resetForm();
        setErrorMessage("");
        onUpdate();
        onClose();
      } catch (err) {
        const axiosError = err as AxiosError;
        const errNum = axiosError.response?.data;
        if (errNum === ErrorCodes.UniqueKey) {
          setErrorMessage("デコード名が重複しています。");
        } else if (errNum === ErrorCodes.InvalidParamKey) {
          setErrorMessage(ErrorMessages.InvalidParamError);
        } else {
          setErrorMessage(ErrorMessages.UnknownError);
        }
      }
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    setErrorMessage("");
    onClose();
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            {/* Title */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {
                  auth.pages?.find((page) => page.id === PageId.DecodePage)
                    ?.name
                }
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="デコード名 *"
                name="decodeName"
                value={formik.values.decodeName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.decodeName)}
                helperText={formik.errors.decodeName}
                fullWidth
                margin="normal"
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="データフォーマット"
                  name="dataFormatId"
                  error={formik.errors.dataFormatId}
                  value={formik.values.dataFormatId}
                  onChange={formik.handleChange}
                  arrangement={dataFormats}
                  format={(dataFormats) => `${dataFormats.dataFormatName}`}
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton
                  onClick={() => setOpenDataFormat(true)}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="開始位置"
                name="startPosition"
                type="number"
                value={formik.values.startPosition}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.startPosition)}
                helperText={formik.errors.startPosition}
                fullWidth
                margin="normal"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="終了位置"
                name="endPosition"
                type="number"
                value={formik.values.endPosition ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.endPosition)}
                helperText={formik.errors.endPosition}
                fullWidth
                margin="normal"
              />
            </Grid>

            <Grid item xs={12}>
              <Grid item>
                <ToggleButtonGroup
                  color="primary"
                  value={formik.values.isAnserback}
                  exclusive
                  onChange={(
                    _event: React.MouseEvent<HTMLElement>,
                    newValue: boolean | null
                  ) => {
                    if (newValue !== null) {
                      formik.setFieldValue("isAnserback", newValue);
                    }
                  }}
                >
                  <ToggleButton value={false}>観測値</ToggleButton>
                  <ToggleButton value={true}>アンサーバック</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid item>
                <ToggleButtonGroup
                  color="primary"
                  value={formik.values.isCumulative}
                  exclusive
                  onChange={(
                    _event: React.MouseEvent<HTMLElement>,
                    newValue: boolean | null
                  ) => {
                    if (newValue !== null) {
                      formik.setFieldValue("isCumulative", newValue);
                    }
                  }}
                >
                  <ToggleButton value={false}>瞬時値</ToggleButton>
                  <ToggleButton value={true}>積算値</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>

            <CancelSaveButtons handleCancel={handleCancel} />

            <DataFormatModal
              open={openDataFormat}
              onClose={() => setOpenDataFormat(false)}
              onUpdate={fetchData}
            />
          </Grid>
        </form>
      </MasterModal>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}
export default DecodeModal;
