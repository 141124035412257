import axios from "axios";
import { User } from "../models/User";

const url = process.env.REACT_APP_API_URL + "/UpdateUser";

export async function updateUser(
  user: User,
  registerUserId: number,
  siteId: number,
  userId: number,
  notificationIds?: number[]
) {
  const body = {
    user,
    registerUserId,
    siteId,
    userId,
    notificationIds,
  };
  const response = await axios.post(url, body);
  return response.data;
}
