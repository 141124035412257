import axios from "axios";
import { Site } from "../models/Site";

const url = process.env.REACT_APP_API_URL + "/UpdateSite";

export async function updateSite(site: Site, siteId: number, userId: number) {
  const body = {
    site,
    siteId,
    userId,
  };
  const response = await axios.post(url, body, { withCredentials: true });
  return response.data;
}
