import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getSiteId } from "../api/GetSiteId";
import { checkJwt, logout as logoutApi } from "../api/TokenVerification";
import { MenuInformation } from "../models/MenuInformation";
import { Page } from "../models/Page";
import { User } from "../models/User";

let logoutTimer: NodeJS.Timeout;

enum LogoutParams {
  manualLogout = 1,
  timeoutLogout = 2,
  lastWinsLogout = 3,
}

export const useAuth = () => {
  const [tokenExpirationDate, setTokenExpirationDate] = useState<Date | null>();
  const [autoTimeoutDate, setAutoTimeoutDate] = useState<Date | null>();
  const [userId, setUserId] = useState<number>(0);
  const [userName, setUserName] = useState<string>("");
  const [siteId, setSiteId] = useState<number>(0);
  const [siteName, setSiteName] = useState<string>("");
  const [checked, setChecked] = useState<boolean>(false);
  const [permissionAbbreviation, setPermissionAbbreviation] = useState<
    string | null
  >(null);
  const [canCreate, setCanCreate] = useState<boolean>(false);
  const [canMonitor, setCanMonitor] = useState<boolean>(false);
  const [canControl, setCanControl] = useState<boolean>(false);
  const [canModify, setCanModify] = useState<boolean>(false);
  const [pages, setPages] = useState<Page[]>([]);
  const [user, setUser] = useState<User | null>(null);

  const [timeoutInterval, setTimeoutInterval] = useState<number>(10 * 60000);
  const DATE_MAX = 8640000000000000; // Date型の最大引数
  const INTERVAL_MAX = 2147483647; // logoutTimerの最大

  const location = useLocation();

  const login = useCallback(
    (newUserId: number, autoLogoutSpan?: number, tokenExpiration?: number) => {
      if (autoLogoutSpan != null && tokenExpiration != null) {
        // 初回ログイン
        setTimeoutInterval(autoLogoutSpan);
        // トークン自体の有効期限
        const now = new Date().getTime();
        const tokenExpirationDate = new Date(
          0 < tokenExpiration && now + tokenExpiration < DATE_MAX
            ? now + tokenExpiration
            : DATE_MAX
        ); //トークンの有効期限と合わせる
        setTokenExpirationDate(tokenExpirationDate);
        // 自動ログアウト用の期限
        const autoTimeoutDate = new Date(
          0 < autoLogoutSpan &&
          new Date(now + autoLogoutSpan) < tokenExpirationDate
            ? now + autoLogoutSpan
            : tokenExpirationDate
        ); // タイムアウト時間ミリ秒 初回ログイン時からのみの期限とする
        setAutoTimeoutDate(autoTimeoutDate);

        // ローカルストレージに保存
        localStorage.setItem(
          "userData",
          JSON.stringify({
            userId: newUserId,
          })
        );
        localStorage.setItem(
          "timeout",
          JSON.stringify({
            timeoutInterval: autoLogoutSpan,
            expiration: tokenExpirationDate.toISOString(),
            autoTimeoutDate: autoTimeoutDate.toISOString(),
          })
        );
        // ユーザID更新は再描画がかかってしまうため処理の最後で実行
        setUserId(newUserId);
      } else {
        const storedData = JSON.parse(
          localStorage.getItem("timeout") as string
        );
        setTimeoutInterval(storedData.timeoutInterval);
        setTokenExpirationDate(new Date(storedData.expiration));
        setAutoTimeoutDate(new Date(storedData.autoTimeoutDate));
        // ローカルストレージに保存
        localStorage.setItem(
          "userData",
          JSON.stringify({
            userId: newUserId,
          })
        );
        localStorage.setItem(
          "timeout",
          JSON.stringify({
            timeoutInterval: storedData.timeoutInterval,
            expiration: new Date(storedData.expiration),
            autoTimeoutDate: new Date(storedData.autoTimeoutDate),
          })
        );
        // ユーザID更新は再描画がかかってしまうため処理の最後で実行
        setUserId(newUserId);
      }
    },
    []
  );

  const logout = useCallback((logoutParam: LogoutParams, siteId: number) => {
    logoutApi(logoutParam, siteId)
      .then(() => {
        localStorage.setItem(
          "timeout",
          JSON.stringify({
            timeoutInterval: 0,
            expiration: new Date(),
            autoTimeoutDate: new Date(),
          })
        );
        localStorage.removeItem("userData");
        localStorage.removeItem("timeout");
        setTokenExpirationDate(null);
        setUserId(0);
        setSiteId(0);
        setPermissionAbbreviation(null);
        setCanCreate(false);
        setCanMonitor(false);
        setCanControl(false);
        setCanModify(false);
        setMenu([]);
        setPages([]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const extendAutoTimeoutDate = useCallback(() => {
    if (userId) {
      const now = new Date().getTime();
      const newAutoTimeoutDate =
        0 < timeoutInterval &&
        new Date(now + timeoutInterval) < (tokenExpirationDate as Date)
          ? new Date(now + timeoutInterval)
          : tokenExpirationDate;
      const storedData = JSON.parse(localStorage.getItem("timeout") as string);
      // ローカルストレージに保存
      localStorage.setItem(
        "timeout",
        JSON.stringify({
          timeoutInterval: storedData?.timeoutInterval,
          expiration: tokenExpirationDate?.toISOString(),
          autoTimeoutDate: newAutoTimeoutDate?.toISOString(),
        })
      );
    }
  }, [userId, tokenExpirationDate, timeoutInterval]);

  useEffect(() => {
    window.addEventListener("mousemove", extendAutoTimeoutDate); // マウス移動
    window.addEventListener("click", extendAutoTimeoutDate); // クリック
    window.addEventListener("keypress", extendAutoTimeoutDate); // キー
    window.addEventListener("touchstart", extendAutoTimeoutDate); // タッチ操作開始
    window.addEventListener("touchmove", extendAutoTimeoutDate); // タッチ移動

    return () => {
      window.removeEventListener("mousemove", extendAutoTimeoutDate); // コンポーネントがアンマウントされたときに、リスナーを解除する
      window.removeEventListener("click", extendAutoTimeoutDate); // クリック
      window.removeEventListener("keypress", extendAutoTimeoutDate); // キー
      window.removeEventListener("touchstart", extendAutoTimeoutDate); // タッチ操作開始
      window.removeEventListener("touchmove", extendAutoTimeoutDate); // タッチ移動
    };
  }, [extendAutoTimeoutDate]);

  // ストレージの時間変更時のイベント
  useEffect(() => {
    function handleStorage(event: StorageEvent) {
      if (event.key === "timeout") {
        const storedData = JSON.parse(
          localStorage.getItem("timeout") as string
        );
        // 他のタブのタイムアウト時間を再セットする処理をここに記述する
        setTimeoutInterval(storedData.timeoutInterval);
        setTokenExpirationDate(new Date(storedData.expiration));
        setAutoTimeoutDate(new Date(storedData.autoTimeoutDate));
      }
    }
    window.addEventListener("storage", handleStorage);

    return () => {
      window.removeEventListener("storage", handleStorage);
    };
  }, [autoTimeoutDate, tokenExpirationDate]);

  // 起動時自動ログアウト設定
  // 起動時自動ログアウト設定
  useEffect(() => {
    if (userId && autoTimeoutDate) {
      const remainingTime =
        autoTimeoutDate.getTime() - new Date().getTime() <= INTERVAL_MAX
          ? autoTimeoutDate.getTime() - new Date().getTime()
          : INTERVAL_MAX;
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(() => {
        // siteIdの取得
        const currentURL = location.pathname.split("/");
        const siteId = Number(currentURL.length < 3 ? "" : currentURL[1]);

        // 取得したsiteIdをlogout関数の第2引数に渡す
        logout(LogoutParams.timeoutLogout, siteId);
      }, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [userId, logout, autoTimeoutDate, location.pathname]);

  // ローカルストレージtokenExpirationDateと現在時刻の差分ミリ秒後にlogout実行

  // 起動時自動ログイン（ローカルストレージから呼び出し）
  useEffect(() => {
    setChecked(false);
    const handleCheckJwt = async () => {
      try {
        // useEffect内でuseParamsが使用できない為、siteId位置固定で抽出
        const currentURL = location.pathname.split("/");
        const siteId = await getSiteId(
          currentURL.length < 3 ? "" : currentURL[1]
        );
        //バックエンドでJWTの検証
        const response = await checkJwt(siteId);
        if (response.data.userId) {
          // メニュー取得
          const menu = JSON.parse(response.data.menus).recordset.map(
            (item: any) => {
              return {
                id: item.id,
                icon: item.icon,
                name: item.name,
                url: item.url,
              } as MenuInformation;
            }
          );
          setUserName(response.data.userName);
          setSiteId(siteId);
          setSiteName(response.data.siteName);
          setPermissionAbbreviation(response.data.permissionAbbreviation);
          setCanCreate(response.data.canCreate);
          setCanMonitor(response.data.canMonitor);
          setCanControl(response.data.canControl);
          setCanModify(response.data.canModify);
          setMenu(menu);
          setPages(response.data.pages);
          login(response.data.userId);
          setChecked(true);
        } else {
          // siteIdの取得
          const currentURL = location.pathname.split("/");
          const siteId = Number(currentURL.length < 3 ? "" : currentURL[1]);

          // 取得したsiteIdをlogout関数の第2引数に渡す
          logout(LogoutParams.manualLogout, siteId);

          setChecked(true);
        }
      } catch (error) {
        // siteIdの取得
        const currentURL = location.pathname.split("/");
        const siteId = Number(currentURL.length < 3 ? "" : currentURL[1]);

        // 取得したsiteIdをlogout関数の第2引数に渡す
        logout(LogoutParams.manualLogout, siteId);

        setChecked(true);
      }
    };
    handleCheckJwt();
  }, [login, logout, location]);

  const [menuInformations, setMenuInformations] = useState<MenuInformation[]>(
    []
  );

  const setMenu = (menuInformations: MenuInformation[]) => {
    setMenuInformations(menuInformations);
  };

  return {
    login,
    logout,
    setMenu,
    setPages,
    setPermissionAbbreviation,
    setCanCreate,
    setCanMonitor,
    setCanControl,
    setCanModify,
    setUserName,
    setUser,
    menuInformations,
    pages,
    checked,
    userId,
    userName,
    siteId,
    siteName,
    autoTimeoutDate,
    tokenExpirationDate,
    permissionAbbreviation,
    canCreate,
    canMonitor,
    canControl,
    canModify,
    user,
  };
};
