import axios from "axios";
import { format } from "date-fns";
import { GraphPattern } from "../models/GraphPattern";

const url = process.env.REACT_APP_API_URL + "/GetGraphCSV";

export async function getGraphCSV(
  userId: number,
  siteId: number,
  isCsv: boolean,
  pattern: GraphPattern | null,
  timeFrom: Date | null,
  timeTo: Date | null
) {
  let responseArray: any[][][] = [];
  let csvArray: any[][] = [];
  try {
    if (pattern) {
      for (const sensorId of pattern.sensorIds) {
        const body = {
          userId,
          siteId,
          isCsv,
          pattern,
          timeFrom,
          timeTo,
          sensorId,
        };
        const response = await axios.post<any[][]>(url, body);

        // header以外の日付をフォーマット
        response.data.forEach((data, i) => {
          if (i > 0) {
            data[0] = format(new Date(data[0]), "yyyy/MM/dd HH:mm:ss");
          }
        });
        responseArray.push(response.data);
      }

      responseArray[0].forEach((response0, i) => {
        const row = [];
        row.push(response0[0]); // 時間列生成
        responseArray.forEach((response) => {
          row.push(response[i][1]); // 時間列生成
        });
        csvArray.push(row);
      });
    }

    return csvArray;
  } catch (error) {
    return [];
  } finally {
  }
}
