const calculateColumnWidth = (data: any[]): any => {
  const result: any = {};
  data.forEach((item) => {
    for (const key in item) {
      const items = data.map((item) => {
        if (item[key] == null) {
          return 0;
        } else if (item[key] instanceof Date) {
          return 10;
        } else if (typeof item[key] === "boolean") {
          return 6;
        }
        return String(item[key]).length;
      });
      const width = Math.max(...items) * 20 + 10;
      result[key] = width > 500 ? 500 : width;
    }
  });
  return result;
};

export default calculateColumnWidth;
