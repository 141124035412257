import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";
import Encoding from "encoding-japanese";
import Papa from "papaparse";
import { useCallback } from "react";

interface ExportButtonProps {
  defaultFileName: string;
  csvData: any[];
}

function ExportButton({ defaultFileName, csvData }: ExportButtonProps) {
  const getFileName = useCallback(() => {
    const filename = window.prompt(
      "CSVファイル名を入力してください。",
      defaultFileName
    );
    return filename;
  }, [defaultFileName]);

  const downloadCsv = useCallback(() => {
    const fileName = getFileName();

    if (!fileName) {
      return;
    }

    const csvString = Papa.unparse(csvData);
    const shiftJisArray = Encoding.convert(csvString, {
      to: "SJIS",
      from: "UNICODE",
      type: "array",
    });

    // Create blob from Shift_JIS array without BOM
    const csvBlob = new Blob([new Uint8Array(shiftJisArray)], {
      type: "text/csv;charset=shift_jis;",
    });

    const csvURL = URL.createObjectURL(csvBlob);
    const tempLink = document.createElement("a");
    tempLink.href = csvURL;

    if (fileName) {
      tempLink.setAttribute("download", fileName);
    }

    tempLink.click();
  }, [csvData, getFileName]);

  return (
    <GridToolbarContainer>
      <Button
        variant="outlined"
        startIcon={<DownloadIcon />}
        onClick={downloadCsv}
      >
        Export
      </Button>
    </GridToolbarContainer>
  );
}

export default ExportButton;
