import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import * as yup from "yup";
import * as ja from "yup-locale-ja";
import { getControlRadioButtons } from "../api/GetControlSendRadioButtons";
import { sendMessage } from "../api/SendMessage";
import { checkJwt } from "../api/TokenVerification";
import { AuthContext } from "../context/AuthContext";
import { ControlRecord } from "../models/ControlRecord";
import { ControlRadioButton } from "../models/ControlSendRadioButton";
import { componentSpacing } from "./CustomStyle";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  controlRecord?: ControlRecord;
  fetchRecords: () => Promise<void>;
};

const SendControlWithoutPasswordModal: React.FC<Props> = ({
  isOpen,
  onClose,
  controlRecord,
  fetchRecords,
}) => {
  const auth = useContext(AuthContext);

  yup.setLocale(ja.suggestive);
  const [controlRadioButtons, setControlRadioRecords] = useState<
    ControlRadioButton[] | null
  >(null);
  const [selectedControlRadioButton, setSelectedControlRadioButton] = useState<
    number | null
  >(null);
  const [isOpenConfirm, setOpenConfirm] = useState<boolean>(false);
  const [isSending, setSending] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string | null>(null);

  const fetchControlRadioButtons = useCallback(async () => {
    if (controlRecord?.controlCardId) {
      await checkJwt(auth.siteId);
      const records = await getControlRadioButtons(
        auth.userId,
        auth.siteId,
        controlRecord?.controlCardId
      );
      setControlRadioRecords(records);
    }
  }, [auth.userId, auth.siteId, controlRecord?.controlCardId]);

  const initSelected = useCallback(() => {
    if (isOpen) {
      if (controlRecord?.controlId) {
        setSelectedControlRadioButton(controlRecord.controlId);
      }
    }
  }, [isOpen, controlRecord?.controlId]);

  useEffect(() => {
    fetchControlRadioButtons();
  }, [fetchControlRadioButtons]);

  useEffect(() => {
    initSelected();
  }, [initSelected]);

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  useEffect(() => {
    if ((isOpen || isOpenConfirm) && controlRecord?.isDisabled && !isSending) {
      onClose();
      setOpenConfirm(false);
      setModalMessage(`別ユーザーが制御操作を行いました。
最初からやり直してください。`);
    }
  }, [isOpen, isOpenConfirm, controlRecord?.isDisabled, isSending, onClose]);

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedControlRadioButton(Number(event.target.value));
  };

  const handleOpenConfirm = async () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOk = async () => {
    setSending(true);
    setOpenConfirm(false);
    try {
      if (controlRecord?.controlCardId && selectedControlRadioButton) {
        await checkJwt(auth.siteId);
        await sendMessage(
          auth.userId,
          auth.siteId,
          controlRecord.controlCardId,
          selectedControlRadioButton
        );
        await fetchRecords();
      }
    } finally {
      setSending(false);
      onClose();
    }
  };

  const handleCancel = () => {
    setSelectedControlRadioButton(null);
    onClose();
  };

  return (
    <>
      <Modal open={isOpen && controlRadioButtons !== null} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Grid
            item
            xs={12}
            container
            spacing={2}
            justifyContent="center"
            sx={componentSpacing}
          >
            <Typography>
              現在の状態：{controlRecord?.currentStateName}
            </Typography>
          </Grid>
          <Grid container spacing={2} sx={componentSpacing}>
            <Grid
              item
              xs={12}
              container
              spacing={2}
              justifyContent="center"
              sx={componentSpacing}
            >
              {controlRecord && (
                <RadioGroup
                  row
                  name="position"
                  value={selectedControlRadioButton}
                  onChange={handleChangeRadio}
                >
                  {controlRadioButtons?.map((controlRadioButton) => {
                    return (
                      <FormControlLabel
                        key={controlRadioButton.controlId}
                        value={controlRadioButton.controlId}
                        control={<Radio />}
                        label={controlRadioButton.radioButtonName}
                        labelPlacement="top"
                      />
                    );
                  })}
                </RadioGroup>
              )}
            </Grid>

            <Grid item xs={12} container spacing={2} sx={componentSpacing}>
              <Grid item xs={6}>
                <Box sx={{ position: "relative" }}>
                  <Button
                    onClick={handleOpenConfirm}
                    type="button"
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    disabled={isSending}
                    fullWidth
                  >
                    OK
                  </Button>

                  {isSending && (
                    <CircularProgress
                      size={24}
                      color="secondary"
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={handleCancel}
                  type="button"
                  variant="outlined"
                  color="secondary"
                  startIcon={<CancelIcon />}
                  disabled={isSending}
                  fullWidth
                >
                  キャンセル
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Dialog open={isOpenConfirm} onClose={handleCloseConfirm}>
            <DialogContent>
              <DialogContentText>本当に実行しますか？</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleOk}>OK</Button>
              <Button onClick={handleCloseConfirm}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Modal>
      <Modal open={modalMessage !== null} onClose={() => setModalMessage(null)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography>{modalMessage}</Typography>
        </Box>
      </Modal>
    </>
  );
};

export default SendControlWithoutPasswordModal;
