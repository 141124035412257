import { Box, Toolbar, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import * as React from "react";

import { AxiosError } from "axios";
import { deleteReceiveDataType } from "../api/DeleteReceiveDataType";
import { getReceiveDataTypes } from "../api/GetReceiveDataTypes";
import { checkJwt } from "../api/TokenVerification";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { AuthContext } from "../context/AuthContext";
import LoadingContext from "../context/LoadingContext";
import { ReceiveDataType } from "../models/ReceiveDataType";
import calculateColumnWidth from "../share/calculateColumnWidth";
import AddDeleteButton from "./AddDeleteButton";
import CustomMasterContainer from "./CustomMasterContainer";
import CustomPageSnackbar from "./CustomPageSnackbar";
import CustomSearchField from "./CustomSearchField";
import { gridContainerStyles } from "./CustomStyle";
import ReceiveDataTypeModal from "./ReceiveDataTypeModal";

type GridDataRow = ReceiveDataType & {
  id: number;
};

const headerName = {
  receiveDataTypeName: "受信データ種別名",
  unit: "単位",
  image: "画像",
  sign: "符号",
  minimum: "最小値",
  maximum: "最大値",
};
function ReceiveDataTypePage() {
  const initialValue: string = "";
  const [rows, setRows] = React.useState<GridDataRow[]>([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [editingReceiveDataType, setEditingReceiveDataType] =
    React.useState<ReceiveDataType>();
  const [selectedRows, setSelectedRows] = React.useState<GridRowId[]>([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [inputValue, setInputValue] = React.useState(initialValue); // <-- Update
  const [searchTerm, setSearchTerm] = React.useState(initialValue);
  const auth = React.useContext(AuthContext);
  const [columnWidths, setColumnWidths] = React.useState<any>();
  const { setLoading } = React.useContext(LoadingContext);

  const columns: GridColDef[] = [
    {
      field: "receiveDataTypeName",
      headerName: headerName.receiveDataTypeName,
      minWidth: calculateColumnWidth([headerName]).receiveDataTypeName,
      width: columnWidths?.receiveDataTypeName || 0,
    },
    {
      field: "unit",
      headerName: headerName.unit,
      minWidth: calculateColumnWidth([headerName]).unit,
      width: columnWidths?.unit || 0,
    },
    {
      field: "image",
      headerName: headerName.image,
      width: 100,
      renderCell: (params) => (
        <img title="image" src={params.value} alt="" height="100%" /> // 画像表示
      ),
    },
    {
      field: "sign",
      headerName: headerName.sign,
      minWidth: calculateColumnWidth([headerName]).sign,
      width: columnWidths?.sign || 0,
    },
    {
      field: "minimum",
      headerName: headerName.minimum,
      minWidth: calculateColumnWidth([headerName]).minimum,
      width: columnWidths?.minimum || 0,
    },
    {
      field: "maximum",
      headerName: headerName.maximum,
      minWidth: calculateColumnWidth([headerName]).maximum,
      width: columnWidths?.maximum || 0,
    },
  ];
  const fetchData = React.useCallback(async () => {
    // デバイスの取得処理
    await checkJwt(auth.siteId);
    const records = await getReceiveDataTypes(
      auth.siteId,
      auth.userId,
      searchTerm
    );
    if (records) {
      const rows: GridDataRow[] = records.map((record: any, i: number) => {
        return {
          id: i + 1,
          ...record,
        };
      });
      setRows(rows);
      setColumnWidths(calculateColumnWidth(rows));
    }
  }, [searchTerm, auth.siteId, auth.userId]);

  const fetchDataWithLoading = React.useCallback(async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  }, [fetchData, setLoading]);

  React.useEffect(() => {
    fetchDataWithLoading();
  }, [fetchDataWithLoading]);

  const handleRowEditStart = (rowId: GridRowId) => {
    const row = rows.find((row) => row.id === rowId);
    if (row) {
      setEditingReceiveDataType(row);
      setOpenModal(true);
    }
  };

  const handleRowEditStop = () => {
    setEditingReceiveDataType(undefined);
    setOpenModal(false);
  };

  const handleAddRow = () => {
    setEditingReceiveDataType(undefined);
    setOpenModal(true);
  };

  const handleDeleteSelected = async (selectedRows: GridRowId[]) => {
    await checkJwt(auth.siteId);

    // 削除対象の siteId の配列を作成
    const RowsToDelete = selectedRows
      .map((rowId) => rows.find((row) => row.id === rowId)?.receiveDataTypeId)
      .filter((receiveDataTypeId) => receiveDataTypeId != null) as number[];

    if (RowsToDelete.length === 0) {
      setErrorMessage(ErrorMessages.SelectionError);
      return;
    }

    const deleteTasks = RowsToDelete.map((receiveDataTypeId) => {
      return deleteReceiveDataType(receiveDataTypeId, auth.siteId, auth.userId);
    });

    try {
      await Promise.all(deleteTasks);
      fetchDataWithLoading();
      setSelectedRows([]);
    } catch (err) {
      const axiosError = err as AxiosError;
      const errNum = axiosError.response?.data;
      if (errNum === ErrorCodes.ForeignKey) {
        setErrorMessage(ErrorMessages.RecordInUseError);
      } else {
        setErrorMessage(ErrorMessages.UnknownError);
      }
    }
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearchTerm(inputValue);
    }
  };

  return (
    <>
      <CustomMasterContainer>
        <Box sx={{ mb: 1 }}>
          <Typography variant="subtitle1" component="div">
            検索条件
          </Typography>
        </Box>
        <CustomSearchField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Box sx={gridContainerStyles}>
          <DataGrid
            rows={rows}
            columns={columns}
            onRowClick={(param) => handleRowEditStart(param.id)}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={selectedRows}
            onRowSelectionModelChange={(newSelection) => {
              setSelectedRows(newSelection);
            }}
            showColumnVerticalBorder
            slots={{
              toolbar: () => (
                <Toolbar>
                  <AddDeleteButton
                    AddButtonOnClick={() => handleAddRow()}
                    DeleteButtonOnClick={() =>
                      handleDeleteSelected(selectedRows)
                    }
                  />
                </Toolbar>
              ),
            }}
          />
        </Box>
      </CustomMasterContainer>
      <ReceiveDataTypeModal
        open={openModal}
        onClose={handleRowEditStop}
        onUpdate={fetchDataWithLoading}
        editingReceiveDataType={editingReceiveDataType as ReceiveDataType}
      />
      <CustomPageSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}

export default ReceiveDataTypePage;
