import { Box, Link, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns-tz";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { getControlRecords } from "../api/GetControlRecords";
import { checkJwt } from "../api/TokenVerification";
import { AuthContext } from "../context/AuthContext";
import LoadingContext from "../context/LoadingContext";
import { ControlRecord } from "../models/ControlRecord";
import calculateColumnWidth from "../share/calculateColumnWidth";
import { sortRecord } from "../share/sort";
import ControlSendButton from "./ControlSendButton";
import CustomContainer from "./CustomContainer";
import CustomSearchField from "./CustomSearchField";
import { gridContainerStyles } from "./CustomStyle";

type GridRow = ControlRecord & {
  id: number;
};

const headerName = {
  id: "No",
  locationName: "設置場所",
  dataName: "データ名称",
  controlTypeName: "項目",
  currentStateName: "状態",
  buttonLabel: "制御指示",
  collectionDateTime: "最終更新日",
};

// Main Component
const ControlListPage = () => {
  const auth = useContext(AuthContext);
  const siteName = auth.siteName;
  const { setLoading } = useContext(LoadingContext);
  const [inputValue, setInputValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [rows, setRows] = useState<GridRow[]>([]);
  const [columnWidths, setColumnWidths] = useState<any>();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearchTerm(inputValue);
    }
  };

  const fetchRecords = useCallback(async () => {
    await checkJwt(auth.siteId);
    const records = await getControlRecords(
      auth.userId,
      auth.siteId,
      searchTerm
    );
    if (records) {
      const row: GridRow[] = records
        .sort(sortRecord)
        .map((record: any, i: number) => {
          return {
            ...record,
            id: i + 1,
          };
        });
      setRows(row);
      setColumnWidths(calculateColumnWidth(row));
    }
  }, [searchTerm, auth.userId, auth.siteId]);

  useEffect(() => {
    setLoading(true);
    fetchRecords().finally(() => setLoading(false));

    const timer = setInterval(fetchRecords, 5000);
    return () => clearInterval(timer);
  }, [fetchRecords, setLoading]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: headerName.id,
      minWidth: calculateColumnWidth([headerName]).id,
      width: columnWidths?.id || 0,
    },
    {
      field: "locationName",
      headerName: headerName.locationName,
      minWidth: calculateColumnWidth([headerName]).locationName,
      width: columnWidths?.locationName || 0,
    },
    {
      field: "dataName",
      headerName: headerName.dataName,
      minWidth: calculateColumnWidth([headerName]).dataName,
      width: columnWidths?.dataName || 0,
    },
    {
      field: "controlTypeName",
      headerName: headerName.controlTypeName,
      minWidth: calculateColumnWidth([headerName]).controlTypeName,
      width: columnWidths?.controlTypeName || 0,
      renderCell: (params: any) => {
        return (
          <Typography
            p={1}
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: params.row.palette,
              height: "100%",
              width: "100%",
            }}
          >
            <Link
              component={RouterLink}
              to={"/" + siteName + "/UserLogPage"}
              underline="none"
              state={{
                dataName: params.row.dataName,
              }}
              color={
                params.row.palette &&
                params.row.palette.split(".")[0] + ".contrastText"
              }
            >
              {params.row.controlTypeName}
            </Link>
          </Typography>
        );
      },
    },

    {
      field: "buttonLabel",
      headerName: headerName.buttonLabel,
      headerAlign: "center",
      minWidth: 120,
      width: 120,
      renderCell: (params: any) => (
        <ControlSendButton
          controlRecord={params.row}
          fetchRecords={fetchRecords}
        />
      ),
    },

    {
      field: "collectionDateTime",
      headerName: headerName.collectionDateTime,
      minWidth: calculateColumnWidth([headerName]).collectionDateTime,
      type: "Date",
      width: columnWidths?.collectionDateTime || 0,
      valueFormatter: (params: { value: Date }) => {
        if (params.value === null) {
          return "";
        }

        return format(params.value, "yyyy/MM/dd HH:mm:ss");
      },
    },
  ];

  return (
    <CustomContainer>
      <CustomSearchField
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <Box sx={gridContainerStyles}>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row.id}
          showColumnVerticalBorder
        />
      </Box>
    </CustomContainer>
  );
};

export default ControlListPage;
