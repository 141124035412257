import { Grid, TextField, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { insertSensorType } from "../api/InsertSensorType";
import { checkJwt } from "../api/TokenVerification";
import { updateSensorType } from "../api/UpdateSensorType";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { PageId } from "../constants/page";
import { AuthContext } from "../context/AuthContext";
import { SensorType } from "../models/SensorType"; // SensorTypeをインポートします。
import { CancelSaveButtons } from "./CancelSaveButtons";
import CustomModalSnackbar from "./CustomModalSnackbar";
import { MasterModal } from "./MasterModal";
import { yupMakerName, yupModel, yupSensorTypeName } from "./ValidationSchema";

interface SensorTypeModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  editingSensorType?: SensorType;
}

const SensorTypeValidationSchema = yup.object({
  sensorTypeName: yupSensorTypeName.required(),
  makerName: yupMakerName.nullable(),
  model: yupModel.nullable(),
});

function SensorTypeModal({
  open,
  onClose,
  onUpdate,
  editingSensorType,
}: SensorTypeModalProps) {
  const isUpdate = editingSensorType && editingSensorType.sensorTypeId != null;
  const [initialValues] = useState<SensorType>({});
  const auth = React.useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingSensorType || initialValues,
    validationSchema: SensorTypeValidationSchema,
    onSubmit: async () => {
      await checkJwt(auth.siteId);

      try {
        if (isUpdate) {
          await updateSensorType(formik.values, auth.siteId, auth.userId);
        } else {
          await insertSensorType(formik.values, auth.siteId, auth.userId);
        }
        formik.resetForm();
        setErrorMessage("");
        onUpdate();
        onClose();
      } catch (err) {
        const axiosError = err as AxiosError;
        const errNum = axiosError.response?.data;
        if (errNum === ErrorCodes.UniqueKey) {
          setErrorMessage("センサー種別名が重複しています。");
        } else {
          setErrorMessage(ErrorMessages.UnknownError);
        }
      }
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    setErrorMessage("");
    onClose();
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            {/* Title */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {
                  auth.pages?.find((page) => page.id === PageId.SensorTypePage)
                    ?.name
                }
              </Typography>
            </Grid>
            {/* Sensor Type Name */}
            <Grid item xs={12}>
              <TextField
                label="センサー種別名 *"
                name="sensorTypeName"
                value={formik.values.sensorTypeName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.sensorTypeName)}
                helperText={formik.errors.sensorTypeName}
                fullWidth
              />
            </Grid>
            {/* Maker Name */}
            <Grid item xs={12}>
              <TextField
                label="メーカー名"
                name="makerName"
                value={formik.values.makerName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.makerName)}
                helperText={formik.errors.makerName}
                fullWidth
              />
            </Grid>
            {/* Model */}
            <Grid item xs={12}>
              <TextField
                label="型式"
                name="model"
                value={formik.values.model ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.model)}
                helperText={formik.errors.model}
                fullWidth
              />
            </Grid>
            <CancelSaveButtons handleCancel={handleCancel} />
          </Grid>
        </form>
      </MasterModal>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}
export default SensorTypeModal;
