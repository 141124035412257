import { Box, Toolbar, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { AxiosError } from "axios";
import * as React from "react";
import { deleteSensorType } from "../api/DeleteSensorType";
import { getSensorTypes } from "../api/GetSensorTypes";
import { checkJwt } from "../api/TokenVerification";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { AuthContext } from "../context/AuthContext";
import LoadingContext from "../context/LoadingContext";
import { SensorType } from "../models/SensorType"; // Assuming SenserType.ts is in the same directory
import calculateColumnWidth from "../share/calculateColumnWidth";
import AddDeleteButton from "./AddDeleteButton";
import CustomMasterContainer from "./CustomMasterContainer";
import CustomPageSnackbar from "./CustomPageSnackbar";
import CustomSearchField from "./CustomSearchField";
import { gridContainerStyles } from "./CustomStyle";
import SensorTypeModal from "./SensorTypeModal";

type GridDataRow = SensorType & {
  id: number;
};
const headerName = {
  sensorTypeName: "センサー種別名",
  makerName: "メーカー名",
  model: "型式",
};

function SenserTypePage() {
  const initialValue: string = "";
  const [rows, setRows] = React.useState<GridDataRow[]>([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [editingSensorType, setEditingRow] = React.useState<SensorType>();
  const [selectedRows, setSelectedRows] = React.useState<GridRowId[]>([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [inputValue, setInputValue] = React.useState(initialValue); // <-- Update
  const [searchTerm, setSearchTerm] = React.useState(initialValue);
  const auth = React.useContext(AuthContext);
  const [columnWidths, setColumnWidths] = React.useState<any>();
  const { setLoading } = React.useContext(LoadingContext);

  const columns: GridColDef[] = [
    {
      field: "sensorTypeName",
      headerName: headerName.sensorTypeName,
      minWidth: calculateColumnWidth([headerName]).sensorTypeName,
      width: columnWidths?.sensorTypeName || 0,
    },
    {
      field: "makerName",
      headerName: headerName.makerName,
      minWidth: calculateColumnWidth([headerName]).makerName,
      width: columnWidths?.makerName || 0,
    },
    {
      field: "model",
      headerName: headerName.model,
      minWidth: calculateColumnWidth([headerName]).model,
      width: columnWidths?.model || 0,
    },
  ];

  const fetchData = React.useCallback(async () => {
    await checkJwt(auth.siteId);
    const records = await getSensorTypes(auth.siteId, auth.userId, searchTerm);
    if (records) {
      const rows: GridDataRow[] = records.map((record: any, i: number) => {
        return {
          id: i + 1,
          ...record,
        };
      });
      setRows(rows);
      setColumnWidths(calculateColumnWidth(rows));
    }
  }, [searchTerm, auth.siteId, auth.userId]);

  const fetchDataWithLoading = React.useCallback(async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  }, [fetchData, setLoading]);

  React.useEffect(() => {
    fetchDataWithLoading();
  }, [fetchDataWithLoading]);

  const handleRowEditStart = (rowId: GridRowId) => {
    const row = rows.find((row) => row.id === rowId);
    if (row) {
      setEditingRow(row);
      setOpenModal(true);
    }
  };

  const handleRowEditStop = () => {
    setEditingRow(undefined);
    setOpenModal(false);
  };

  const handleAddRow = () => {
    setEditingRow(undefined);
    setOpenModal(true);
  };

  const handleDeleteSelected = async (selectedRows: GridRowId[]) => {
    await checkJwt(auth.siteId);

    // 削除対象の siteId の配列を作成
    const RowsToDelete = selectedRows
      .map((rowId) => rows.find((row) => row.id === rowId)?.sensorTypeId)
      .filter((sensorId) => sensorId != null) as number[];

    if (RowsToDelete.length === 0) {
      setErrorMessage(ErrorMessages.SelectionError);
      return;
    }

    const deleteTasks = RowsToDelete.map((sensorTypeId) => {
      return deleteSensorType(sensorTypeId, auth.siteId, auth.userId);
    });

    try {
      await Promise.all(deleteTasks);
      fetchDataWithLoading();
      setSelectedRows([]);
    } catch (err) {
      const axiosError = err as AxiosError;
      const errNum = axiosError.response?.data;
      if (errNum === ErrorCodes.ForeignKey) {
        setErrorMessage(ErrorMessages.RecordInUseError);
      } else {
        setErrorMessage(ErrorMessages.UnknownError);
      }
    }
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearchTerm(inputValue);
    }
  };

  return (
    <>
      <CustomMasterContainer>
        <Box sx={{ mb: 1 }}>
          <Typography variant="subtitle1" component="div">
            検索条件
          </Typography>
        </Box>
        <CustomSearchField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Box sx={gridContainerStyles}>
          <DataGrid
            rows={rows}
            columns={columns}
            onRowClick={(param) => handleRowEditStart(param.id)}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={selectedRows}
            onRowSelectionModelChange={(newSelection) => {
              setSelectedRows(newSelection);
            }}
            showColumnVerticalBorder
            slots={{
              toolbar: () => (
                <Toolbar>
                  <AddDeleteButton
                    AddButtonOnClick={() => handleAddRow()}
                    DeleteButtonOnClick={() =>
                      handleDeleteSelected(selectedRows)
                    }
                  />
                </Toolbar>
              ),
            }}
          />
        </Box>
      </CustomMasterContainer>
      <SensorTypeModal
        open={openModal}
        onClose={handleRowEditStop}
        onUpdate={fetchDataWithLoading}
        editingSensorType={editingSensorType as SensorType}
      />
      <CustomPageSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}

export default SenserTypePage;
