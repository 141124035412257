import axios from "axios";
import { Notification } from "../models/Notification";

const url = process.env.REACT_APP_API_URL + "/GetNotifications";

async function getNotifications(siteId: number, userId: number) {
  const body = { siteId, userId };
  const response = await axios.post<Notification[]>(url, body);
  return response.data;
}

export default getNotifications;
