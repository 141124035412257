import { Box, Toolbar, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { AxiosError } from "axios";
import * as React from "react";
import { deleteDecode } from "../api/DeleteDecode";
import { getDecodes } from "../api/GetDecode";
import { checkJwt } from "../api/TokenVerification";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { AuthContext } from "../context/AuthContext";
import LoadingContext from "../context/LoadingContext";
import { Decode } from "../models/Decode";
import calculateColumnWidth from "../share/calculateColumnWidth";
import AddDeleteButton from "./AddDeleteButton";
import CustomMasterContainer from "./CustomMasterContainer";
import CustomPageSnackbar from "./CustomPageSnackbar";
import CustomSearchField from "./CustomSearchField";
import { gridContainerStyles } from "./CustomStyle";
import DecodeModal from "./DecodeModal";

type GridDataRow = Decode & {
  id: number;
};

const headerName = {
  decodeName: "デコード名",
  dataFormatName: "データフォーマット名",
  startPosition: "開始位置",
  endPosition: "終了位置",
  isAnserback: "観測値/アンサーバック",
  isCumulative: "瞬時値/積算値",
};

function DecodePage() {
  const initialValue: string = "";
  const [rows, setRows] = React.useState<GridDataRow[]>([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [editingDecode, setEditingDecode] = React.useState<Decode>();
  const [selectedRows, setSelectedRows] = React.useState<GridRowId[]>([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const auth = React.useContext(AuthContext);
  const [inputValue, setInputValue] = React.useState(initialValue); // <-- Update
  const [searchTerm, setSearchTerm] = React.useState(initialValue);
  const [columnWidths, setColumnWidths] = React.useState<any>();
  const { setLoading } = React.useContext(LoadingContext);

  const columns: GridColDef[] = [
    {
      field: "decodeName",
      headerName: headerName.decodeName,
      minWidth: calculateColumnWidth([headerName]).decodeName,
      width: columnWidths?.decodeName || 0,
    },
    {
      field: "dataFormatName",
      headerName: headerName.dataFormatName,
      minWidth: calculateColumnWidth([headerName]).dataFormatName,
      width: columnWidths?.dataFormatName || 0,
    },
    {
      field: "startPosition",
      headerName: headerName.startPosition,
      minWidth: calculateColumnWidth([headerName]).startPosition,
      width: columnWidths?.startPosition || 0,
    },
    {
      field: "endPosition",
      headerName: headerName.endPosition,
      minWidth: calculateColumnWidth([headerName]).endPosition,
      width: columnWidths?.endPosition || 0,
    },
    {
      field: "isAnserback",
      headerName: headerName.isAnserback,
      minWidth: calculateColumnWidth([headerName]).isAnserback,
      width: columnWidths?.isAnserback || 0,
      valueFormatter: (params) => (params.value ? "アンサーバック" : "観測値"),
    },
    {
      field: "isCumulative",
      headerName: headerName.isCumulative,
      minWidth: calculateColumnWidth([headerName]).isCumulative,
      width: columnWidths?.isCumulative || 0,
      valueFormatter: (params) => (params.value ? "瞬時値" : "積算値"),
    },
  ];

  const fetchData = React.useCallback(async () => {
    await checkJwt(auth.siteId);
    const records = await getDecodes(auth.siteId, auth.userId, searchTerm);

    if (records) {
      const rows: GridDataRow[] = records.map((record: any, i: number) => {
        return {
          id: i + 1,
          ...record,
        };
      });
      setRows(rows);
      setColumnWidths(calculateColumnWidth(rows));
    }
  }, [searchTerm, auth.siteId, auth.userId]);

  const fetchDataWithLoading = React.useCallback(async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  }, [fetchData, setLoading]);

  React.useEffect(() => {
    fetchDataWithLoading();
  }, [fetchDataWithLoading]);

  const handleRowEditStart = (rowId: GridRowId) => {
    const row = rows.find((row) => row.id === rowId);
    if (row) {
      setEditingDecode(row);
      setOpenModal(true);
    }
  };

  const handleRowEditStop = () => {
    setEditingDecode(undefined);
    setOpenModal(false);
  };

  const handleAddRow = () => {
    setEditingDecode(undefined);
    setOpenModal(true);
  };

  const handleDeleteSelected = async (selectedRows: GridRowId[]) => {
    await checkJwt(auth.siteId);

    // 削除対象の siteId の配列を作成
    const RowsToDelete = selectedRows
      .map((rowId) => rows.find((row) => row.id === rowId)?.decodeId)
      .filter((decodeId) => decodeId != null) as number[];

    if (RowsToDelete.length === 0) {
      setErrorMessage(ErrorMessages.SelectionError);
      return;
    }

    const deleteTasks = RowsToDelete.map((decodeId) => {
      return deleteDecode(decodeId, auth.siteId, auth.userId);
    });

    try {
      await Promise.all(deleteTasks);
      fetchDataWithLoading();
      setSelectedRows([]);
    } catch (err) {
      const axiosError = err as AxiosError;
      const errNum = axiosError.response?.data;
      if (errNum === ErrorCodes.ForeignKey) {
        setErrorMessage(ErrorMessages.RecordInUseError);
      } else {
        setErrorMessage(ErrorMessages.UnknownError);
      }
    }
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearchTerm(inputValue);
    }
  };

  return (
    <>
      <CustomMasterContainer>
        <Box sx={{ mb: 1 }}>
          <Typography variant="subtitle1" component="div">
            検索条件
          </Typography>
        </Box>
        <CustomSearchField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Box sx={gridContainerStyles}>
          <DataGrid
            rows={rows}
            columns={columns}
            onRowClick={(param) => handleRowEditStart(param.id)}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={selectedRows}
            onRowSelectionModelChange={(newSelection) => {
              setSelectedRows(newSelection);
            }}
            showColumnVerticalBorder
            slots={{
              toolbar: () => (
                <Toolbar>
                  <AddDeleteButton
                    AddButtonOnClick={() => handleAddRow()}
                    DeleteButtonOnClick={() =>
                      handleDeleteSelected(selectedRows)
                    }
                  />
                </Toolbar>
              ),
            }}
          />
        </Box>
      </CustomMasterContainer>
      <DecodeModal
        open={openModal}
        onClose={handleRowEditStop}
        onUpdate={fetchDataWithLoading}
        editingDecode={editingDecode as Decode}
      />
      <CustomPageSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}

export default DecodePage;
