import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

interface CancelSaveButtonsProps {
  handleCancel: () => void;
}

export function CancelSaveButtons({ handleCancel }: CancelSaveButtonsProps) {
  return (
    <Grid item xs={12} container spacing={3}>
      <Grid item xs={6}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          fullWidth
          sx={{ whiteSpace: "nowrap" }}
        >
          保存
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          onClick={handleCancel}
          type="button"
          variant="outlined"
          color="secondary"
          startIcon={<CancelIcon />}
          fullWidth
          sx={{ whiteSpace: "nowrap" }}
        >
          キャンセル
        </Button>
      </Grid>
    </Grid>
  );
}
