import axios from "axios";

const url = process.env.REACT_APP_API_URL + "/checkControlOneTimePassword";

export async function checkControlOneTimePassword(
  controlCardId: number,
  userId: number,
  password: string
) {
  const body = {
    controlCardId: controlCardId,
    userId: userId,
    password: password,
  };
  await axios.post(url, body);
}
