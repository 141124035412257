import axios from "axios";
import { Device } from "../models/Device";

const url = process.env.REACT_APP_API_URL + "/UpdateDevice";

export async function updateDevice(
  device: Device,
  state: boolean,
  siteId: number,
  userId: number
) {
  const body = {
    device,
    state,
    siteId,
    userId,
  };
  const response = await axios.post(url, body);
  return response.data;
}
