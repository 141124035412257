import axios from "axios";
import { SystemLog } from "../models/SystemLog";

const url = process.env.REACT_APP_API_URL + "/GetSystemLogs";

export async function getSystemLogs(
  siteId: number,
  userId: number,
  startDate: Date | null,
  endDate: Date | null,
  searchText?: string
) {
  try {
    const body = {
      siteId,
      userId,
      searchText,
      startDate,
      endDate,
    };

    const response = await axios.post(url, body);

    const data: any[] = response.data;

    return data.map((d) => {
      const convert: SystemLog = {
        ...d,
        occurrenceDateTime: new Date(d.occurrenceDateTime),
      };
      return convert;
    });
  } catch (error) {
    console.log(error);
    return;
  }
}
