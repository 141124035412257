import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { ObserveRecord } from "../models/ObserveRecord";

const ObserveCard = (props: ObserveRecord) => {
  const auth = useContext(AuthContext);
  const siteName = auth.siteName;
  const data = props;

  return (
    <Card sx={{ margin: "5px" }}>
      <CardContent sx={{ height: "85%" }}>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          width="256px"
          height="100%"
        >
          <Grid item>
            <Link
              component={RouterLink}
              to={"/" + siteName + "/DetailInformationPage"}
              underline="none"
              state={{ dataName: data.dataName }}
            >
              <CardHeader title={data.dataName} />
            </Link>
          </Grid>

          <Grid item>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                width="100%"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CardMedia
                  sx={{ pl: "20px", height: "80px", width: "80px" }}
                  component="img"
                  image={`${data.image}`}
                  title="image"
                />

                <Typography
                  align="center"
                  width="100%"
                  noWrap={true}
                  variant="h5"
                  bgcolor={data.palette}
                  color={
                    data.palette
                      ? data.palette?.split(".")[0] + ".contrastText"
                      : undefined
                  }
                >
                  <Link
                    component={RouterLink}
                    to={"/" + siteName + "/UserLogPage"}
                    underline="none"
                    state={{
                      dataName: data.dataName,
                    }}
                    color={
                      data.palette
                        ? data.palette.split(".")[0] + ".contrastText"
                        : undefined
                    }
                  >
                    {data.receiveDataTypeName}
                  </Link>
                </Typography>
              </Box>
            </Box>

            <Link
              component={RouterLink}
              to={"/" + siteName + "/GraphPage?id=" + data.sensorId}
              underline="none"
              state={{ sensorId: data.sensorId }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <Typography
                  noWrap={true}
                  variant="h4"
                  color={
                    data.value && data.hasMissing ? "error.main" : undefined
                  }
                >
                  {data.value == null ? "-" : data.value}
                </Typography>
                <Typography
                  variant="h6"
                  noWrap={true}
                  color={
                    data.value && data.hasMissing ? "error.main" : undefined
                  }
                >
                  {data.unit && "　" + data.unit}
                </Typography>
              </Box>
            </Link>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ObserveCard;
