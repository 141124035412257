import AddIcon from "@mui/icons-material/Add";
import {
  Grid,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AxiosError } from "axios";
import ja from "date-fns/locale/ja";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { getCalculations } from "../api/GetCalculations";
import { getDecodes } from "../api/GetDecode";
import { getDevices } from "../api/GetDevices";
import { getLocations } from "../api/GetLocations";
import { getSensorTypes } from "../api/GetSensorTypes";
import { insertSensor } from "../api/InsertSensor";
import { checkJwt } from "../api/TokenVerification";
import { updateSensor } from "../api/UpdateSensor";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { PageId } from "../constants/page";
import { AuthContext } from "../context/AuthContext";
import { Calculation } from "../models/Calculation";
import { Decode } from "../models/Decode";
import { Device } from "../models/Device";
import { Location } from "../models/Location";
import { Sensor } from "../models/Sensor";
import { SensorType } from "../models/SensorType";
import CalculationModal from "./CalculationModal";
import { CancelSaveButtons } from "./CancelSaveButtons";
import CustomAutoComplete from "./CustomAutoComplete";
import CustomModalSnackbar from "./CustomModalSnackbar";
import DecodeModal from "./DecodeModal";
import DeviceModal from "./DeviceModal";
import LocationModal from "./LocationModal";
import { MasterModal } from "./MasterModal";
import SensorTypeModal from "./SensorTypeModal";
import {
  yupPulse,
  yupRatingMaxDisplayValue,
  yupRatingMinDisplayValue,
  yupRolloverCorrectionMax,
  yupRolloverError,
  yupSensorName,
  yupSensorSerialNumber,
  yupSensorTypeId,
} from "./ValidationSchema";

interface SensorModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  editingSensor?: Sensor;
}

function SensorModal({
  open,
  onClose,
  onUpdate,
  editingSensor,
}: SensorModalProps) {
  const isUpdate = editingSensor && editingSensor.sensorId != null;
  const [sensorTypes, setSensorTypes] = useState<SensorType[]>();
  const [openSensorType, setOpenSensorType] = React.useState<boolean>(false);
  const [devices, setDevices] = useState<Device[]>();
  const [openDevice, setOpenDevice] = React.useState<boolean>(false);
  const [decodes, setDecodes] = useState<Decode[]>();
  const [calculations, setCalculations] = useState<Calculation[]>();
  const [openDecode, setOpenDecode] = React.useState<boolean>(false);
  const [openCalculation, setOpenCalculation] = React.useState<boolean>(false);
  const [ratingUnit, setRatingUnit] = useState<string>("");
  const [isCumulative, setIsCumulative] = useState<boolean>(false);
  const [locations, setLocations] = useState<Location[]>();
  const [openLocation, setOpenLocation] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");

  const auth = React.useContext(AuthContext);

  const SensorValidationSchema = yup.object({
    sensorTypeId: yupSensorTypeId.required(),
    sensorName: yupSensorName.required(),
    pulse: yupPulse.nullable(),
    ratingMinDisplayValue: yupRatingMinDisplayValue.nullable(),
    ratingMaxDisplayValue: yupRatingMaxDisplayValue.nullable(),
    rolloverError: isCumulative
      ? yupRolloverError.required()
      : yupRolloverError.nullable(),
    rolloverCorrectionMax: isCumulative
      ? yupRolloverCorrectionMax.required()
      : yupRolloverCorrectionMax.nullable(),
    sensorSerialNumber: yupSensorSerialNumber.nullable(),
  });

  const fetchData = useCallback(async () => {
    await checkJwt(auth.siteId);
    getSensorTypes(auth.siteId, auth.userId).then(setSensorTypes);
    getDevices(auth.siteId, auth.userId).then(setDevices);
    getDecodes(auth.siteId, auth.userId).then(setDecodes);
    getCalculations(auth.siteId, auth.userId).then(setCalculations);
    getLocations(auth.siteId, auth.userId).then(setLocations);
  }, [auth.siteId, auth.userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [initialValues] = useState<Sensor>({
    rolloverError: 1,
    state: true,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingSensor || initialValues,
    validationSchema: SensorValidationSchema,
    onSubmit: async () => {
      await checkJwt(auth.siteId);

      try {
        if (isUpdate) {
          await updateSensor(
            formik.values,
            editingSensor.state,
            auth.userId,
            auth.siteId
          );
        } else {
          await insertSensor(formik.values, auth.siteId, auth.userId);
        }

        formik.resetForm();
        setErrorMessage("");
        onUpdate();
        onClose();
      } catch (err) {
        const axiosError = err as AxiosError;
        const errNum = axiosError.response?.data;
        if (errNum === ErrorCodes.UniqueKey) {
          setErrorMessage("センサー名称、センサー種別が重複しています。");
        } else if (errNum === ErrorCodes.InvalidParamKey) {
          setErrorMessage(ErrorMessages.InvalidParamError);
        } else {
          setErrorMessage(ErrorMessages.UnknownError);
        }
      }
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    setErrorMessage("");
    onClose();
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const setUpDateChange = (newValue: Date | null) => {
    if (
      formik.values.removalDate &&
      newValue &&
      formik.values.removalDate < newValue
    ) {
      formik.setFieldValue("removalDate", newValue);
    }
    formik.setFieldValue("setUpDate", newValue || null);
  };

  const removalDateChange = (newValue: Date | null) => {
    if (
      formik.values.setUpDate &&
      newValue &&
      formik.values.setUpDate > newValue
    ) {
      formik.setFieldValue("setUpDate", newValue);
    }
    formik.setFieldValue("removalDate", newValue || null);
  };

  useEffect(() => {
    let topUnit = formik.values.ratingTopUnit
      ? formik.values.ratingTopUnit
      : "";
    let bottomUnit = "";

    const receivingCycle =
      devices &&
      devices
        .filter((device) => device.deviceId === formik.values.deviceId)
        .map((device) => device.receivingCycle)[0];

    if (receivingCycle) {
      if (receivingCycle < 60) {
        bottomUnit = "/" + receivingCycle + "秒";
      } else if (receivingCycle < 3600) {
        bottomUnit = "/" + receivingCycle / 60 + "分";
      } else if (receivingCycle < 86400) {
        bottomUnit = "/" + receivingCycle / 3600 + "時間";
      } else {
        bottomUnit = "/" + receivingCycle / 86400 + "日";
      }
    }
    if (isCumulative) {
      setRatingUnit(topUnit + bottomUnit);
    } else {
      setRatingUnit(topUnit);
    }
  }, [
    devices,
    formik.values.deviceId,
    formik.values.ratingTopUnit,
    isCumulative,
  ]);

  useEffect(() => {
    let receivingCycle: boolean | null = null;
    decodes?.forEach((element) => {
      if (element.decodeId === formik.values.decodeId) {
        receivingCycle = element.isCumulative;
      }
    });
    setIsCumulative(!!receivingCycle);
  }, [decodes, formik.values.decodeId]);

  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {
                  auth.pages?.find((page) => page.id === PageId.SensorPage)
                    ?.name
                }
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="センサー名 *"
                name="sensorName"
                value={formik.values.sensorName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.sensorName)}
                helperText={formik.errors.sensorName}
                fullWidth
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="センサー種別 *"
                  name="sensorTypeId"
                  error={formik.errors.sensorTypeId}
                  value={formik.values.sensorTypeId}
                  onChange={formik.handleChange}
                  arrangement={sensorTypes}
                  format={(sensorType) => sensorType.sensorTypeName}
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton
                  onClick={() => setOpenSensorType(true)}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="デバイス"
                  name="deviceId"
                  error={formik.errors.deviceId}
                  value={formik.values.deviceId}
                  onChange={formik.handleChange}
                  arrangement={devices}
                  format={(device) => device.iothubDeviceId}
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton onClick={() => setOpenDevice(true)} color="primary">
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="デコード"
                  name="decodeId"
                  error={formik.errors.decodeId}
                  value={formik.values.decodeId}
                  onChange={formik.handleChange}
                  arrangement={decodes}
                  format={(decode) => decode.decodeName}
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton onClick={() => setOpenDecode(true)} color="primary">
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="計算式"
                  name="calculationId"
                  error={formik.errors.calculationId}
                  value={formik.values.calculationId}
                  onChange={formik.handleChange}
                  arrangement={calculations}
                  format={(calculation) => calculation.calculationName}
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton
                  onClick={() => setOpenCalculation(true)}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="設置場所"
                  name="locationId"
                  error={formik.errors.locationId}
                  value={formik.values.locationId}
                  onChange={formik.handleChange}
                  arrangement={locations}
                  format={(location) => `${location.locationName}`}
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton
                  onClick={() => setOpenLocation(true)}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="パルス値"
                name="pulse"
                type="number"
                value={formik.values.pulse ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.pulse)}
                helperText={formik.errors.pulse}
                fullWidth
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <TextField
                  label="定格超過下限"
                  inputProps={{ inputMode: "numeric" }}
                  name="ratingMinDisplayValue"
                  type="text"
                  value={formik.values.ratingMinDisplayValue ?? ""}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.ratingMinDisplayValue)}
                  helperText={formik.errors.ratingMinDisplayValue}
                  fullWidth
                />
              </Grid>
              <Grid item xs={ratingUnit ? ratingUnit.length : 0}>
                <Typography noWrap={true} align="center">
                  {ratingUnit}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <TextField
                  label="定格超過上限"
                  inputProps={{ inputMode: "numeric" }}
                  name="ratingMaxDisplayValue"
                  type="text"
                  value={formik.values.ratingMaxDisplayValue ?? ""}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.ratingMaxDisplayValue)}
                  helperText={formik.errors.ratingMaxDisplayValue}
                  fullWidth
                />
              </Grid>
              <Grid item xs={ratingUnit ? ratingUnit.length : 0}>
                <Typography noWrap={true} align="center">
                  {ratingUnit}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                ロールオーバー時のカウンタ表示
              </Typography>
              <ToggleButtonGroup
                disabled={!isCumulative}
                color="primary"
                value={formik.values.rolloverError}
                exclusive
                onChange={(
                  _event: React.MouseEvent<HTMLElement>,
                  newValue: boolean | null
                ) => {
                  if (newValue !== null) {
                    formik.setFieldValue("rolloverError", newValue);
                  }
                }}
              >
                <ToggleButton value={1}>0に戻る</ToggleButton>
                <ToggleButton value={0}>1に戻る</ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            <Grid item xs={12}>
              <TextField
                disabled={!isCumulative}
                label="ロールオーバー補正値（カウンタ最大値） *"
                name="rolloverCorrectionMax"
                type="number"
                value={formik.values.rolloverCorrectionMax ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.rolloverCorrectionMax)}
                helperText={formik.errors.rolloverCorrectionMax}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="センサーシリアルナンバー"
                name="sensorSerialNumber"
                value={formik.values.sensorSerialNumber ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.sensorSerialNumber)}
                helperText={formik.errors.sensorSerialNumber}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ja}
              >
                <MobileDatePicker
                  label="設置日"
                  disabled={false}
                  value={formik.values.setUpDate}
                  onChange={setUpDateChange}
                  openTo="year"
                  format="yyyy/MM/dd"
                  views={["year", "month", "day"]}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ja}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MobileDatePicker
                  label="撤去日"
                  disabled={false}
                  value={formik.values.removalDate}
                  onChange={removalDateChange}
                  openTo="year"
                  format="yyyy/MM/dd"
                  views={["year", "month", "day"]}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ja}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MobileDatePicker
                  label="運用開始日"
                  disabled={false}
                  value={formik.values.operationStartDate}
                  onChange={(newValue: Date | null) => {
                    formik.setFieldValue(
                      "operationStartDate",
                      newValue || null
                    );
                  }}
                  openTo="year"
                  format="yyyy/MM/dd"
                  views={["year", "month", "day"]}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <Grid item>
                <Typography variant="subtitle1">状態</Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={formik.values.state}
                  exclusive
                  onChange={(
                    _event: React.MouseEvent<HTMLElement>,
                    newValue: boolean | null
                  ) => {
                    if (newValue !== null) {
                      formik.setFieldValue("state", newValue);
                    }
                  }}
                >
                  <ToggleButton value={true}>有効</ToggleButton>
                  <ToggleButton value={false}>無効</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>

            <CancelSaveButtons handleCancel={handleCancel} />

            <SensorTypeModal
              open={openSensorType}
              onClose={() => setOpenSensorType(false)}
              onUpdate={fetchData}
            />
            <DeviceModal
              open={openDevice}
              onClose={() => setOpenDevice(false)}
              onUpdate={fetchData}
            />
            <DecodeModal
              open={openDecode}
              onClose={() => setOpenDecode(false)}
              onUpdate={fetchData}
            />
            <CalculationModal
              open={openCalculation}
              onClose={() => setOpenCalculation(false)}
              onUpdate={fetchData}
            />
            <LocationModal
              open={openLocation}
              onClose={() => setOpenLocation(false)}
              onUpdate={fetchData}
            />
          </Grid>
        </form>
      </MasterModal>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}
export default SensorModal;
