import axios from "axios";

const url = process.env.REACT_APP_API_URL + "/DeleteSensorType";

export async function deleteSensorType(
  sensorTypeId: number,
  siteId: number,
  userId: number
) {
  const body = {
    sensorTypeId,
    siteId,
    userId,
  };
  const response = await axios.post(url, body);
  return response.data;
}
