import { createContext } from "react";
import { MenuInformation } from "../models/MenuInformation";
import { Page } from "../models/Page";
import { User } from "../models/User";

enum LogoutParams {
  manualLogout = 1,
  timeoutLogout = 2,
  lastWinsLogout = 3,
}

export const AuthContext = createContext({
  isLoggedIn: false,
  checked: false,
  userId: 0,
  userName: "",
  siteId: 0,
  siteName: "",
  tokenExpirationDate: null as null | undefined | Date,
  autoTimeoutDate: null as null | undefined | Date,
  menuInformations: null as null | MenuInformation[],
  pages: null as null | Page[],
  canCreate: false,
  canMonitor: false,
  canControl: false,
  canModify: false,
  permissionAbbreviation: null as null | string,
  user: null as null | User,
  login: (
    userId: number,
    autoLogoutSpan?: number,
    tokenExpiration?: number
  ) => {},
  logout: (logoutParam: LogoutParams, siteId: number) => {},
  setMenu: (menuInformations: MenuInformation[]) => {},
  setPages: (pages: Page[]) => {},
  setPermissionAbbreviation: (permissionAbbreviation: string) => {},
  setCanCreate: (canCreate: boolean) => {},
  setCanMonitor: (canMonitor: boolean) => {},
  setCanControl: (canControl: boolean) => {},
  setCanModify: (canModify: boolean) => {},
  setUserName: (userName: string) => {},
  setUser: (user: User) => {},
});
