// FanModal.tsx
import { Grid, TextField, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { insertFan } from "../api/InsertFan";
import { checkJwt } from "../api/TokenVerification";
import { updateFan } from "../api/UpdateFan";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { PageId } from "../constants/page";
import { AuthContext } from "../context/AuthContext";
import { Fan } from "../models/Fan"; // Import your Fan type
import { CancelSaveButtons } from "./CancelSaveButtons";
import CustomModalSnackbar from "./CustomModalSnackbar";
import { MasterModal } from "./MasterModal";
import { yupFanId, yupFanName } from "./ValidationSchema";

interface FanModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  editingFan?: Fan;
}

const FanValidationSchema = yup.object({
  fanId: yupFanId.required(),
  fanName: yupFanName.required(),
});

function FanModal({ open, onClose, onUpdate, editingFan }: FanModalProps) {
  const isUpdate = editingFan && editingFan.fanId !== null;
  const [errorMessage, setErrorMessage] = useState("");
  const auth = React.useContext(AuthContext);

  const [initialValues] = useState<Fan>({});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingFan || initialValues,
    validationSchema: FanValidationSchema,
    onSubmit: async () => {
      await checkJwt(auth.siteId);
      try {
        if (isUpdate) {
          await updateFan(formik.values, editingFan, auth.siteId, auth.userId);
        } else {
          await insertFan(formik.values, auth.siteId, auth.userId);
        }

        formik.resetForm();
        setErrorMessage("");
        onUpdate();
        onClose();
      } catch (err) {
        const axiosError = err as AxiosError;
        const errNum = axiosError.response?.data;
        if (errNum === ErrorCodes.UniqueKey) {
          setErrorMessage("FAN方式IDまたは、FAN名称が重複しています。");
        } else {
          setErrorMessage(ErrorMessages.UnknownError);
        }
      }
    },
  });

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleCancel = () => {
    formik.resetForm();
    setErrorMessage("");
    onClose();
  };

  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            {/* Title */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {auth.pages?.find((page) => page.id === PageId.FanPage)?.name}
              </Typography>
            </Grid>
            {/* FAN方式ID */}
            <Grid item xs={12}>
              <TextField
                label="FAN方式ID *"
                name="fanId"
                value={formik.values.fanId ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.fanId)}
                helperText={formik.errors.fanId}
                fullWidth
              />
            </Grid>
            {/* 名称 */}
            <Grid item xs={12}>
              <TextField
                label="名称 *"
                name="fanName"
                value={formik.values.fanName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.fanName)}
                helperText={formik.errors.fanName}
                fullWidth
              />
            </Grid>

            <CancelSaveButtons handleCancel={handleCancel} />
          </Grid>
        </form>
      </MasterModal>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}
export default FanModal;
