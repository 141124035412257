import DateRangeIcon from "@mui/icons-material/DateRange";
import {
  Box,
  Fade,
  Grid,
  IconButton,
  Modal,
  Stack,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns-tz";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { getSystemLogs } from "../api/GetSystemLogs";
import { checkJwt } from "../api/TokenVerification";
import { AuthContext } from "../context/AuthContext";
import LoadingContext from "../context/LoadingContext";
import { SystemLog } from "../models/SystemLog";
import calculateColumnWidth from "../share/calculateColumnWidth";
import CustomContainer from "./CustomContainer";
import CustomSearchField from "./CustomSearchField";
import DateTimePickers, { DateTimePickersFormat } from "./DateTimePickers";
import ExportButton from "./ExportButton";

type GridRow = SystemLog & {
  id: number;
  value: string;
};

const headerName = {
  systemLogId: "ログID",
  logLevel: "ログレベル",
  siteName: "サイト名",
  message: "ログタイトル",
  stackTrace: "ログ詳細",
  loginId: "ログインID",
  requestMethod: "リクエストメソッド",
  requestUrl: "リクエストURL",
  requestHeaders: "リクエストヘッダ",
  requestBody: "リクエストボディ",
  responseStatus: "レスポンスステータス",
  occurrenceDateTime: "発生日時",
};

const SystemLogPage = () => {
  const auth = useContext(AuthContext);
  const { setLoading } = useContext(LoadingContext);
  const [columnWidths, setColumnWidths] = useState<any>();

  const columns: GridColDef[] = [
    {
      field: "systemLogId",
      headerName: headerName.systemLogId,
      minWidth: calculateColumnWidth([headerName]).systemLogId,
      width: columnWidths?.systemLogId || 0,
    },
    {
      field: "logLevel",
      headerName: headerName.logLevel,
      minWidth: calculateColumnWidth([headerName]).logLevel,
      width: columnWidths?.logLevel || 0,
    },
    {
      field: "siteName",
      headerName: headerName.siteName,
      minWidth: calculateColumnWidth([headerName]).siteName,
      width: columnWidths?.siteName || 0,
    },
    {
      field: "message",
      headerName: headerName.message,
      minWidth: calculateColumnWidth([headerName]).message,
      width: columnWidths?.message || 0,
    },
    {
      field: "stackTrace",
      headerName: headerName.stackTrace,
      minWidth: calculateColumnWidth([headerName]).stackTrace,
      width: columnWidths?.stackTrace || 0,
    },
    {
      field: "loginId",
      headerName: headerName.loginId,
      minWidth: calculateColumnWidth([headerName]).loginId,
      width: columnWidths?.loginId || 0,
    },
    {
      field: "requestMethod",
      headerName: headerName.requestMethod,
      minWidth: calculateColumnWidth([headerName]).requestMethod,
      width: columnWidths?.requestMethod || 0,
    },
    {
      field: "requestUrl",
      headerName: headerName.requestUrl,
      minWidth: calculateColumnWidth([headerName]).requestUrl,
      width: columnWidths?.requestUrl || 0,
    },
    {
      field: "requestHeaders",
      headerName: headerName.requestHeaders,
      minWidth: calculateColumnWidth([headerName]).requestHeaders,
      width: columnWidths?.requestHeaders || 0,
    },
    {
      field: "requestBody",
      headerName: headerName.requestBody,
      minWidth: calculateColumnWidth([headerName]).requestBody,
      width: columnWidths?.requestBody || 0,
    },
    {
      field: "responseStatus",
      headerName: headerName.responseStatus,
      minWidth: calculateColumnWidth([headerName]).responseStatus,
      width: columnWidths?.responseStatus || 0,
    },
    {
      field: "occurrenceDateTime",
      headerName: headerName.occurrenceDateTime,
      minWidth: calculateColumnWidth([headerName]).occurrenceDateTime,
      width: columnWidths?.occurrenceDateTime || 0,
      valueFormatter: (params) => {
        return format(params.value, "yyyy/MM/dd HH:mm:ss");
      },
    },
  ];

  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 6);
  oneWeekAgo.setHours(oneWeekAgo.getHours() + 1);

  const nextHour = new Date();
  nextHour.setHours(nextHour.getHours() + 1);

  const [inputValue, setInputValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [rows, setRows] = useState<GridRow[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(oneWeekAgo);
  const [endDate, setEndDate] = useState<Date | null>(nextHour);
  const isSmallWidth = useMediaQuery("(max-width:800px)");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fetchRecords = useCallback(async () => {
    await checkJwt(auth.siteId);
    const records = await getSystemLogs(
      auth.siteId,
      auth.userId,
      startDate,
      endDate,
      searchTerm
    );
    if (records) {
      const row: GridRow[] = records.map((record: any, i: number) => {
        return {
          id: i + 1,
          ...record,
        };
      });
      setRows(row);
      setColumnWidths(calculateColumnWidth(row));
    }
  }, [searchTerm, auth.siteId, auth.userId, startDate, endDate]);

  useEffect(() => {
    setLoading(true);
    fetchRecords().finally(() => setLoading(false));
  }, [fetchRecords, setLoading]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearchTerm(inputValue);
    }
  };

  const csvData = rows.map((row) => ({
    ログID: row.systemLogId,
    ログレベル: row.logLevel || "",
    サイト名: row.siteName || "",
    ログタイトル: row.message || "",
    ログ詳細: row.value,
    ログインID: row.loginId || "",
    リクエストメソッド: row.requestMethod || "",
    リクエストURL: row.requestUrl || "",
    リクエストヘッダ: row.requestHeaders || "",
    リクエストボディ: row.requestBody || "",
    レスポンスステータス: row.responseStatus || "",
    発生日時: format(row.occurrenceDateTime, "yyyy/MM/dd HH:mm:ss"),
  }));

  return (
    <CustomContainer>
      <Grid container spacing={2}>
        <Grid item xs>
          <CustomSearchField
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </Grid>
        <Grid item xs="auto">
          <Stack
            direction="row"
            justifyContent="flex-end" // Align DateTimePickers to the right
            alignItems="center"
            spacing={2}
          >
            {isSmallWidth ? (
              <IconButton onClick={handleOpen} style={{ marginTop: "8px" }}>
                <DateRangeIcon />
              </IconButton>
            ) : (
              <DateTimePickers
                format={DateTimePickersFormat.HOUR}
                disabled={false}
                views={["year", "month", "day", "hours"]}
                openTo={"year"}
                timeFrom={startDate}
                timeTo={endDate}
                setTimeFrom={setStartDate}
                setTimeTo={setEndDate}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
      <Box sx={{ height: "calc(100% - 70px)", width: "100%", marginBottom: 2 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row.id}
          slots={{
            toolbar: () => (
              <Toolbar>
                <ExportButton
                  defaultFileName="SystemLogData.csv"
                  csvData={csvData}
                />
              </Toolbar>
            ),
          }}
          showColumnVerticalBorder
        />
      </Box>
      <Modal open={open} onClose={handleClose} closeAfterTransition>
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <DateTimePickers
              format={DateTimePickersFormat.DAY}
              disabled={false}
              views={["year", "month", "day"]}
              openTo={"year"}
              timeFrom={startDate}
              timeTo={endDate}
              setTimeFrom={setStartDate}
              setTimeTo={setEndDate}
            />
          </Box>
        </Fade>
      </Modal>
    </CustomContainer>
  );
};

export default SystemLogPage;
