import axios from "axios";
import { Image } from "../models/Image";

const url = process.env.REACT_APP_API_URL + "/GetImages";

export async function getImages(siteId: number, userId: number) {
  try {
    const body = {
      siteId,
      userId,
    };

    const response = await axios.post<Image[]>(url, body);
    return response.data;
  } catch (error) {
    return;
  } finally {
  }
}
