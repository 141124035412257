import axios from "axios";
import { ObserveRecord } from "../models/ObserveRecord";

const url = process.env.REACT_APP_API_URL + "/GetObserveRecords";

export async function getObserveRecords(
  userId: number,
  siteId: number,
  searchText?: string
): Promise<ObserveRecord[]> {
  const body = {
    userId: userId,
    siteId: siteId,
    now: new Date(),
    searchText: searchText,
  };

  const response = await axios.post<ObserveRecord[]>(url, body);
  return response.data.map((d) => {
    const convert: ObserveRecord = {
      ...d,
      collectionDateTime: d.collectionDateTime
        ? new Date(d.collectionDateTime)
        : d.collectionDateTime,
    };
    return convert;
  });
}
