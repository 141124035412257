import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  children: React.ReactNode;
};
export const AxiosErrorHandleProvider: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // axiosの共通エラーハンドリング記述
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        switch (error.response?.status) {
          case 401: // 検証失敗
            navigate("/LogoutPage", { state: { isLastLoginLogout: true } }); // navigateを使用してリダイレクトし、stateオブジェクトを渡します
            break;
          default:
            throw error; // その他のエラーは画面に結果を返す
        }
      }
    );
  }, [navigate]);

  return <>{children}</>;
};
