import axios from "axios";
import { GraphPattern } from "../models/GraphPattern";

const url = process.env.REACT_APP_API_URL + "/RegistGraphPatterns";

export async function registGraphPatterns(
  userId: number,
  siteId: number,
  pattern: GraphPattern
) {
  try {
    const body = {
      siteId,
      userId,
      pattern,
    };
    const response = await axios.post(url, body);
    return response.data;
  } catch (error) {
    return;
  } finally {
  }
}
