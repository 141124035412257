import { Container } from "@mui/material";
import React from "react";

interface CustomContainerProps {
  children: React.ReactNode;
}

function CustomListContainer({ children }: CustomContainerProps) {
  return (
    <Container
      sx={{
        marginTop: 2,
        height: "calc(100vh - 160px)",
        paddingLeft: 0,
        paddingRight: 0,
      }}
      maxWidth={false}
    >
      {children}
    </Container>
  );
}

export default CustomListContainer;
