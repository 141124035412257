import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useContext, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { ControlRecord } from "../models/ControlRecord";
import SendControlWithOneTimePasswordModal from "./SendControlWithPasswordModal";
import SendControlWithSitePasswordModal from "./SendControlWithoutPasswordModal";

interface ControlCardProps {
  controlRecord: ControlRecord;
  fetchRecords: () => Promise<void>;
}

const ControlCard = ({ controlRecord, fetchRecords }: ControlCardProps) => {
  const auth = useContext(AuthContext);
  const siteName = auth.siteName;
  const theme = useTheme();
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  const modal = (isOneTimePasswordRequired: boolean) => {
    if (isOneTimePasswordRequired) {
      return (
        <SendControlWithOneTimePasswordModal
          isOpen={isOpen}
          onClose={handleClose}
          controlRecord={controlRecord}
          fetchRecords={fetchRecords}
        />
      );
    } else {
      return (
        <SendControlWithSitePasswordModal
          isOpen={isOpen}
          onClose={handleClose}
          controlRecord={controlRecord}
          fetchRecords={fetchRecords}
        />
      );
    }
  };

  return (
    <Card sx={{ margin: "5px" }}>
      <CardContent sx={{ height: "85%" }}>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          width="256px"
          sx={{ height: "100%" }}
        >
          <Grid item>
            <CardHeader title={controlRecord.dataName} />
          </Grid>
          <Grid item>
            <Box
              width="100%"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <CardMedia
                sx={{ height: "80px", width: "80px" }}
                component="img"
                image={`${controlRecord.image}`}
                title="image"
              />
              <Typography
                align="center"
                width="100%"
                noWrap={true}
                variant="h5"
                bgcolor={controlRecord.palette}
                color={
                  controlRecord.palette
                    ? controlRecord.palette.split(".")[0] + ".contrastText"
                    : undefined
                }
              >
                <Link
                  component={RouterLink}
                  to={"/" + siteName + "/UserLogPage"}
                  underline="none"
                  color={
                    controlRecord.palette
                      ? controlRecord.palette.split(".")[0] + ".contrastText"
                      : undefined
                  }
                  state={{
                    dataName: controlRecord.dataName,
                  }}
                >
                  {controlRecord.controlTypeName}
                </Link>
              </Typography>
            </Box>
            <Box
              width="100%"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                size="large"
                onClick={() => setOpen(true)}
                disabled={controlRecord.isDisabled || !auth.canControl}
                sx={{
                  width: "100%",
                  bgcolor: theme.palette.primary.main,
                }}
              >
                <Typography variant="h5">
                  {controlRecord.buttonLabel}
                </Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
        {modal(controlRecord.isOneTimePasswordRequired)}
      </CardContent>
    </Card>
  );
};

export default ControlCard;
