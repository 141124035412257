import axios from "axios";

const url = process.env.REACT_APP_API_URL + "/GetReceiveDataDisplaySettings";

export async function getReceiveDataDisplaySettings(
  siteId: number,
  userId: number,
  searchTerm?: string
) {
  const body = {
    siteId,
    userId,
    searchTerm,
  };
  const response = await axios.post(url, body);
  return response.data;
}
