import AddIcon from "@mui/icons-material/Add";
import { Grid, IconButton, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { getDeviceTwins } from "../api/GetDeviceTwins";
import { getDevices } from "../api/GetDevices";
import getDirectMethods from "../api/GetDirectMethods";
import { getPayloads } from "../api/GetPayloads";
import { getSites } from "../api/GetSites";
import { insertControl } from "../api/InsertControl";
import { checkJwt } from "../api/TokenVerification";
import updateControl from "../api/UpdateControl";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { PageId } from "../constants/page";
import { AuthContext } from "../context/AuthContext";
import Control from "../models/Control";
import { Device } from "../models/Device";
import { DeviceTwin } from "../models/DeviceTwin";
import DirectMethod from "../models/DirectMethod";
import { Payload } from "../models/Payload";
import { Site } from "../models/Site";
import { CancelSaveButtons } from "./CancelSaveButtons";
import CustomAutoComplete from "./CustomAutoComplete";
import CustomModalSnackbar from "./CustomModalSnackbar";
import { componentSpacing } from "./CustomStyle";
import DeviceModal from "./DeviceModal";
import DeviceTwinModal from "./DeviceTwinModal";
import DirectMethodModal from "./DirectMethodModal";
import { MasterModal } from "./MasterModal";
import PayloadModal from "./PayloadModal";
import {
  yupDeviceId,
  yupDeviceTwinId,
  yupDirectMethodId,
  yupPayloadId,
  yupSiteId,
} from "./ValidationSchema";

interface Props {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  editingItem?: Control;
}

const ControlValidationSchema = yup.object({
  siteId: yupSiteId.required(),
  deviceId: yupDeviceId.required(),
  directMethodId: yupDirectMethodId.required(),
  deviceTwinId: yupDeviceTwinId.required(),
  payloadId: yupPayloadId.required(),
});

function ControlModal({ open, onClose, onUpdate, editingItem }: Props) {
  const auth = React.useContext(AuthContext);

  const isUpdate = editingItem && editingItem.controlId != null;
  const [sites, setSites] = useState<Site[]>([]);
  const [devices, setDevices] = useState<Device[]>([]);
  const [openDevice, setOpenDevice] = React.useState<boolean>(false);
  const [directMethods, setDirectMethods] = useState<DirectMethod[]>([]);
  const [openDirectMethod, setOpenDirectMethod] =
    React.useState<boolean>(false);
  const [deviceTwins, setDeviceTwins] = React.useState<DeviceTwin[]>([]);
  const [openDeviceTwin, setOpenDeviceTwin] = React.useState<boolean>(false);
  const [payloads, setPayloads] = React.useState<Payload[]>([]);
  const [openPayload, setOpenPayload] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchData = useCallback(async () => {
    await checkJwt(auth.siteId);
    setSites((await getSites(auth.siteId, auth.userId)) || []);
    setDevices((await getDevices(auth.siteId, auth.userId)) || []);
    setDirectMethods((await getDirectMethods(auth.siteId, auth.userId)) || []);
    setDeviceTwins((await getDeviceTwins(auth.siteId, auth.userId)) || []);
    setPayloads((await getPayloads(auth.siteId, auth.userId)) || []);
  }, [auth.siteId, auth.userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [initialValues] = useState<Control>({});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingItem || initialValues,
    validationSchema: ControlValidationSchema,
    onSubmit: async () => {
      await checkJwt(auth.siteId);
      try {
        if (isUpdate) {
          await updateControl(formik.values, auth.userId, auth.siteId);
        } else {
          await insertControl(formik.values, auth.siteId, auth.userId);
        }
        formik.resetForm();
        onUpdate();
        onClose();
      } catch (err) {
        const axiosError = err as AxiosError;
        const errNum = axiosError.response?.data;
        if (errNum === ErrorCodes.UniqueKey) {
          setErrorMessage("サイト名と、場所名が重複しています。");
        } else {
          setErrorMessage(ErrorMessages.UnknownError);
        }
      }
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {
                  auth.pages?.find((page) => page.id === PageId.ControlPage)
                    ?.name
                }
              </Typography>
            </Grid>

            {/* サイト */}

            <Grid item xs={12}>
              <CustomAutoComplete
                label="サイト *"
                name="siteId"
                error={formik.errors.siteId}
                value={formik.values.siteId}
                onChange={formik.handleChange}
                arrangement={sites}
                format={(site) => `${site.siteName}`}
              />
            </Grid>
            {/* デバイス */}
            <Grid
              item
              xs={12}
              sx={{
                ...componentSpacing,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="デバイス *"
                  name="deviceId"
                  error={formik.errors.deviceId}
                  value={formik.values.deviceId}
                  onChange={formik.handleChange}
                  arrangement={devices}
                  format={(device) => device.iothubDeviceId}
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton onClick={() => setOpenDevice(true)} color="primary">
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            {/* ダイレクトメソッド */}
            <Grid
              item
              xs={12}
              sx={{
                ...componentSpacing,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="ダイレクトメソッド *"
                  name="directMethodId"
                  error={formik.errors.directMethodId}
                  value={formik.values.directMethodId}
                  onChange={formik.handleChange}
                  arrangement={directMethods}
                  format={(directMethod) => `${directMethod.directMethodName}`}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  onClick={() => setOpenDirectMethod(true)}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            {/* デバイスツイン */}

            <Grid
              item
              xs={12}
              sx={{
                ...componentSpacing,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="デバイスツイン *"
                  name="deviceTwinId"
                  error={formik.errors.deviceTwinId}
                  value={formik.values.deviceTwinId}
                  onChange={formik.handleChange}
                  arrangement={deviceTwins}
                  format={(deviceTwin) => `${deviceTwin.deviceTwinName}`}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  onClick={() => setOpenDeviceTwin(true)}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            {/* ペイロード */}
            <Grid
              item
              xs={12}
              sx={{
                ...componentSpacing,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="ペイロード *"
                  name="payloadId"
                  error={formik.errors.payloadId}
                  value={formik.values.payloadId}
                  onChange={formik.handleChange}
                  arrangement={payloads}
                  format={(payload) => `${payload.payload}`}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  onClick={() => setOpenPayload(true)}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            <CancelSaveButtons handleCancel={handleCancel} />

            <DeviceModal
              open={openDevice}
              onClose={() => setOpenDevice(false)}
              onUpdate={fetchData}
            />
            <DirectMethodModal
              open={openDirectMethod}
              onClose={() => setOpenDirectMethod(false)}
              onUpdate={fetchData}
            />
            <DeviceTwinModal
              open={openDeviceTwin}
              onClose={() => setOpenDeviceTwin(false)}
              onUpdate={fetchData}
            />
            <PayloadModal
              open={openPayload}
              onClose={() => setOpenPayload(false)}
              onUpdate={fetchData}
            />
          </Grid>
        </form>
      </MasterModal>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={() => setErrorMessage("")}
      />
    </>
  );
}
export default ControlModal;
