import axios from "axios";
import { checkJwt } from "./TokenVerification";

const url = process.env.REACT_APP_API_URL + "/GetLogLevels";

export async function getLogLevels(siteId: number, userId: number) {
  await checkJwt(siteId);

  try {
    const body = {
      siteId,
      userId,
    };

    const response = await axios.post(url, body);
    return response.data;
  } catch (error) {
    return;
  } finally {
  }
}
