import axios, { AxiosError } from "axios";

export async function sendMail(loginId: string) {
  const body = {
    loginId: loginId,
  };
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/SendChangePasswordEmail",
    body,
    { withCredentials: true }
  );
  return response;
}

export async function changePassword(token: string, password: string) {
  try {
    const body = {
      token: token,
      password: password,
    };
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/ChangePassword",
      body,
      { withCredentials: true }
    );
    return response;
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response) {
      throw new Error("update failed");
    }
  }
}
