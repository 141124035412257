import { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import LoadingContext from "../context/LoadingContext";

type Props = {
  element: JSX.Element;
  path: string;
};

// 要ログイン時のみ遷移する
export const PrivateRoute = (prop: Props) => {
  const auth = useContext(AuthContext);
  const loadingContext = useContext(LoadingContext);

  useEffect(() => {
    loadingContext.setCheckJwt(!auth.checked);
  });

  if (!auth.checked) {
    return <></>;
  }

  if (auth.isLoggedIn) {
    return prop.element;
  }
  return <Navigate to="/LoginPage" />;
};

// 非ログイン時のみ遷移する
export const PublicRoute = (prop: Props) => {
  const auth = useContext(AuthContext);
  const loadingContext = useContext(LoadingContext);

  useEffect(() => {
    loadingContext.setCheckJwt(!auth.checked);
  });

  if (!auth.checked) {
    return <></>;
  }

  if (auth.isLoggedIn) {
    // もしログイン済みの場合、一旦ログアウトページに遷移させてクッキー（トークン）削除
    return <Navigate to="/LogoutPage" />;
  }
  return prop.element;
};
