import axios from "axios";

const url = process.env.REACT_APP_API_URL + "/DeleteGraphPattern";

export async function deleteGraphPattern(
  userId: number,
  siteId: number,
  graphPatternName?: string
) {
  try {
    const body = {
      userId: userId,
      siteId: siteId,
      graphPatternName: graphPatternName,
    };
    await axios.post(url, body);
  } catch (error) {
    return;
  } finally {
  }
}
