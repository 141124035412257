import React from "react";
import { AuthContext } from "../context/AuthContext";
import { useAuth } from "../hooks/auth-hook";
import { AxiosErrorHandleProvider } from "./AxiosErrorHandleProvider";

type Props = {
  children: React.ReactNode;
};
export const Providers: React.FC<Props> = (props) => {
  // const { menuInformations, setMenu } = useMenu();
  const {
    login,
    logout,
    setMenu,
    setPages,
    setPermissionAbbreviation,
    setCanCreate,
    setCanMonitor,
    setCanControl,
    setCanModify,
    setUserName,
    setUser,
    checked,
    userId,
    userName,
    siteId,
    siteName,
    autoTimeoutDate,
    tokenExpirationDate,
    menuInformations,
    pages,
    permissionAbbreviation,
    canCreate,
    canMonitor,
    canControl,
    canModify,
    user,
  } = useAuth();
  return (
    // <MenuContext.Provider
    //   value={{ menuInformations: menuInformations, setMenu: setMenu }}
    // >
    <AuthContext.Provider
      value={{
        isLoggedIn: Boolean(userId),
        checked: checked,
        userId: userId,
        userName: userName,
        siteId: siteId,
        siteName: siteName,
        autoTimeoutDate: autoTimeoutDate,
        tokenExpirationDate: tokenExpirationDate,
        menuInformations: menuInformations,
        pages,
        permissionAbbreviation,
        canCreate: canCreate,
        canMonitor: canMonitor,
        canControl: canControl,
        canModify: canModify,
        user: user,
        login: login,
        logout: logout,
        setMenu: setMenu,
        setPages,
        setPermissionAbbreviation,
        setCanCreate: setCanCreate,
        setCanMonitor: setCanMonitor,
        setCanControl: setCanControl,
        setCanModify: setCanModify,
        setUserName: setUserName,
        setUser: setUser,
      }}
    >
      <AxiosErrorHandleProvider>{props.children}</AxiosErrorHandleProvider>
    </AuthContext.Provider>
    // </MenuContext.Provider>
  );
};
