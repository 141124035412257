import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import AdminPage from "./AdminPage";
import AdminUserPage from "./AdminUserPage";
import AlarmPage from "./AlarmPage";
import CalculationPage from "./CalculationPage";
import ChangePasswordPage from "./ChangePasswordPage";
import ColorPage from "./ColorPage";
import ControlCardPage from "./ControlCardPage";
import ControlDetailInformationPage from "./ControlDetailInformationPage";
import ControlListPage from "./ControlListPage";
import ControlPage from "./ControlPage";
import ControlSettingPage from "./ControlSettingPage";
import ControlTypePage from "./ControlTypePage";
import DataFormatPage from "./DataFormatPage";
import DecodePage from "./DecodePage";
import DetailInformationPage from "./DetailInformationPage";
import DeviceAlarmSettingPage from "./DeviceAlarmSettingPage";
import DevicePage from "./DevicePage";
import DeviceTypePage from "./DeviceTypePage";
import DisplayGroupPage from "./DisplayGroupPage";
import FanPage from "./FanPage";
import GraphPage from "./GraphPage";
import Layout from "./Layout";
import ListPage from "./ListPage";
import LocationPage from "./LocationPage";
import LoginPage from "./LoginPage";
import LogoutPage from "./LogoutPage";
import MapPage from "./MapPage";
import NotFoundpage from "./NotFoundpage";
import ReceiveDataDisplaySettingPage from "./ReceiveDataDisplaySettingPage";
import ReceiveDataTypePage from "./ReceiveDataTypePage";
import { PrivateRoute, PublicRoute } from "./RouteAuthGuard";
import SendChangePasswordEmailPage from "./SendChangePasswordEmailPage";
import SensorAlarmSettingPage from "./SensorAlarmSettingPage";
import SensorPage from "./SensorPage";
import SensorTypePage from "./SensorTypePage";
import SiteLocationPage from "./SiteLocationPage";
import SitePage from "./SitePage";
import SystemAdminPage from "./SystemAdminPage";
import SystemLogPage from "./SystemLogPage";
import TopPage from "./TopPage";
import UserLogPage from "./UserLogPage";
import UserPage from "./UserPage";

const PARAM_SITE_NAME = "/:siteName";

export const RouterConfig: React.FC = () => {
  const components: { [key: string]: React.FC } = {
    LoginPage,
    LogoutPage,
    TopPage,
    GraphPage,
    MapPage,
    ListPage,
    ControlListPage,
    UserLogPage,
    SystemLogPage,
    AdminPage,
    SystemAdminPage,
    UserPage,
    SitePage,
    SiteLocationPage,
    LocationPage,
    SensorPage,
    SensorTypePage,
    DevicePage,
    DeviceTypePage,
    FanPage,
    AlarmPage,
    ControlTypePage,
    ReceiveDataTypePage,
    ColorPage,
    DataFormatPage,
    CalculationPage,
    SensorAlarmSettingPage,
    DeviceAlarmSettingPage,
    DisplayGroupPage,
    ReceiveDataDisplaySettingPage,
    DecodePage,
    DetailInformationPage,
    AdminUserPage,
    ControlDetailInformationPage,
    ControlCardPage,
    ControlSettingPage,
    ControlPage,
  };

  const auth = React.useContext(AuthContext);

  const isBasicPage = (url: string) => {
    return (
      url.indexOf("LoginPage") !== -1 ||
      url.indexOf("LogoutPage") !== -1 ||
      url.indexOf("SendChangePasswordEmailPage") !== -1 ||
      url.indexOf("NotFoundPage") !== -1 ||
      url.indexOf("ForbiddenPage") !== -1
    );
  };

  return (
    <div className="App">
      <Routes>
        {/* ログインページにリダイレクト */}
        <Route path="/" element={<Navigate to="/LoginPage" replace={true} />} />

        {/* もしログアウトせずにログイン画面開いた時にクッキー削除するためにPublicRoute設定 */}
        <Route
          path="/LoginPage"
          element={<PublicRoute path="/" element={<LoginPage />} />}
        />

        {/* パスワード再設定画面 */}
        <Route
          path="/SendChangePasswordEmailPage"
          element={<SendChangePasswordEmailPage />}
        />
        <Route path="/ChangePasswordPage" element={<ChangePasswordPage />} />

        {/* 要ログイン */}
        <Route
          path={PARAM_SITE_NAME}
          element={<PrivateRoute path="/" element={<Layout />} />}
        >
          {/* ログイン済みで遷移先なしの場合、NotFoundページでメニューだけは表示 */}
          <Route path={PARAM_SITE_NAME + "/*"} element={<NotFoundpage />} />
          {auth.pages &&
            auth.siteId !== -1 &&
            auth.pages
              .filter((page) => !isBasicPage(page.url))
              .map((page) => {
                const PageComponent = components[page.componentName];
                if (PageComponent) {
                  return (
                    <Route
                      key={page.url}
                      path={PARAM_SITE_NAME + page.url}
                      element={<PageComponent />}
                    />
                  );
                }
                return null;
              })}
        </Route>

        {/* ログアウト画面） */}
        <Route
          path="/LogoutPage"
          element={<PrivateRoute path="/" element={<LogoutPage />} />}
        />

        {/* 遷移先なしの場合 */}
        <Route path="*" element={<NotFoundpage />} />
      </Routes>
    </div>
  );
};
