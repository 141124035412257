import { Autocomplete, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { getPatternSelect } from "../api/GetGraphPatternSelect";
import { checkJwt } from "../api/TokenVerification";
import { AuthContext } from "../context/AuthContext";
import { GraphPattern } from "../models/GraphPattern";

type Props = {
  isModalOpen: boolean;
  selected: GraphPattern | null;
  setSelected: React.Dispatch<React.SetStateAction<GraphPattern | null>>;
};

const GraphPatternSelect = (props: Props) => {
  const auth = useContext(AuthContext);
  const { isModalOpen, selected, setSelected } = props;
  const [patterns, setPatterns] = useState<Array<GraphPattern>>([]);

  useEffect(() => {
    checkJwt(auth.siteId).then(() => {
      getPatternSelect(auth.userId, auth.siteId).then(setPatterns);
    });
  }, [isModalOpen, auth.userId, auth.siteId, selected]);

  const selectedValue = React.useMemo(() => {
    return (
      patterns.find(
        (pattern) => pattern.graphPatternName === selected?.graphPatternName
      ) ?? null
    );
  }, [selected, patterns]);

  return (
    <form>
      <Autocomplete
        fullWidth
        id="graphPatternName"
        options={patterns}
        value={selectedValue}
        getOptionLabel={(option) => option.graphPatternName ?? ""}
        onChange={(_, newValue) => {
          setSelected(newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label={"パターン選択"} fullWidth />
        )}
        sx={{ minWidth: "150px" }}
      />
    </form>
  );
};

export default GraphPatternSelect;
