import "./App.css";
import { Providers } from "./components/Providers";
import { RouterConfig } from "./components/RouteConfig";
import LoadingSpinner from "./components/LoadingSpinner";
import LoadingProvider from "./context/LoadingProvider";
import CsvDownloadSnackBar from "./components/CsvDownloadSnackBar";

const App = () => {
  return (
    <div className="App">
      <LoadingProvider>
        <Providers>
          <RouterConfig />
          <LoadingSpinner />
          <CsvDownloadSnackBar />
        </Providers>
      </LoadingProvider>
    </div>
  );
};

export default App;
