import axios from "axios";
import Control from "../models/Control";

const url = process.env.REACT_APP_API_URL + "/InsertControl";

export async function insertControl(
  control: Control,
  siteId: number,
  userId: number
) {
  const body = {
    control,
    siteId,
    userId,
  };

  const response = await axios.post(url, body);
  return response.data;
}
