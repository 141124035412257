import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { Avatar, Box, Container, Typography } from "@mui/material";

const NotFoundpage = () => {
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <DoNotDisturbIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          公開準備中です。
        </Typography>
      </Box>
    </Container>
  );
};

export default NotFoundpage;
