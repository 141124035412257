import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Typography,
} from "@mui/material";
import { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

function AdminPage() {
  const auth = useContext(AuthContext);
  const siteName = auth.siteName;

  return (
    <Box
      height="calc(100vh - 140px)"
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 3,
        flexWrap: "nowrap",
        overflow: "auto",
      }}
    >
      <Accordion defaultExpanded key={"site_user"} expanded={true}>
        <AccordionSummary sx={{ backgroundColor: "primary.light" }}>
          <Typography color={"primary.contrastText"}>10_TOP画面</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <Button
              component={RouterLink}
              to={`/${siteName}/AdminPage/DisplayGroupPage`}
              variant="contained"
              color="primary"
              sx={{ m: 1, whiteSpace: "nowrap", minWidth: "250px" }}
            >
              11_グループ設定
            </Button>
            <Button
              component={RouterLink}
              to={`/${siteName}/AdminPage/ReceiveDataDisplaySettingPage`}
              variant="contained"
              color="primary"
              sx={{ m: 1, whiteSpace: "nowrap", minWidth: "250px" }}
            >
              12_監視カード設定
            </Button>
            <Button
              component={RouterLink}
              to={`/${siteName}/AdminPage/ControlCardPage`}
              variant="contained"
              color="primary"
              sx={{ m: 1, whiteSpace: "nowrap", minWidth: "250px" }}
            >
              13_制御カード設定
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default AdminPage;
