import axios from "axios";
import Dataset from "../models/Dataset";
import { GraphPattern } from "../models/GraphPattern";

const url = process.env.REACT_APP_API_URL + "/GetGraphRecords";

export async function getGraphRecords(
  userId: number,
  siteId: number,
  pattern: GraphPattern | null,
  timeFrom: Date | null,
  timeTo: Date | null
) {
  try {
    const body = {
      userId,
      siteId,
      pattern,
      timeFrom,
      timeTo,
    };
    const response = await axios.post<{
      yAxis: any[];
      datasets: Dataset[];
      annotations: any[];
    }>(url, body);

    const data = response.data;

    data.datasets.forEach((dataset) => {
      dataset.data.forEach((point) => {
        point.x = new Date(point.x);
      });
    });

    return data;
  } catch (error) {
    return { yAxis: [], datasets: [], annotations: [] };
  } finally {
  }
}
