import axios from "axios";

const url = process.env.REACT_APP_API_URL + "/GetMapLocations";

export async function getMapLocations(userId: number, siteId: number) {
  try {
    const body = {
      userId,
      siteId,
    };
    const response = await axios.post(url, body);
    return response.data;
  } catch (error) {
    return;
  } finally {
  }
}
