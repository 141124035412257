import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button } from "@mui/material";

interface AddDeleteButtonProps {
  AddButtonOnClick: () => void;
  DeleteButtonOnClick: () => void;
}

function AddDeleteButton({
  AddButtonOnClick,
  DeleteButtonOnClick,
}: AddDeleteButtonProps) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Button
        onClick={AddButtonOnClick}
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
      >
        追加
      </Button>
      <Box width="10px" />
      <Button
        onClick={DeleteButtonOnClick}
        variant="contained"
        color="secondary"
        startIcon={<DeleteIcon />}
      >
        削除
      </Button>
    </div>
  );
}

export default AddDeleteButton;
