import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

function SystemAdminPage() {
  const auth = useContext(AuthContext);
  const siteName = auth.siteName;

  return (
    <Box
      height="calc(100vh - 140px)"
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 3,
        flexWrap: "nowrap",
        overflow: "auto",
      }}
    >
      <Accordion defaultExpanded key={"group1"} expanded={true}>
        <AccordionSummary sx={{ backgroundColor: "primary.light" }}>
          <Typography color={"primary.contrastText"}>
            10_サイト・ユーザー
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <Button
              component={RouterLink}
              to={`/${siteName}/SystemAdminPage/SitePage`}
              variant="contained"
              color="primary"
              sx={{ m: 1, whiteSpace: "nowrap", minWidth: "250px" }}
            >
              {"11_サイト"}
            </Button>
            <Button
              component={RouterLink}
              to={`/${siteName}/SystemAdminPage/UserPage`}
              variant="contained"
              color="primary"
              sx={{ m: 1, whiteSpace: "nowrap", minWidth: "250px" }}
            >
              {"12_ユーザー"}
            </Button>
            <Button
              component={RouterLink}
              to={`/${siteName}/SystemAdminPage/SiteLocationPage`}
              variant="contained"
              color="primary"
              sx={{ m: 1 }}
            >
              {"13_サイト位置"}
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded key={"group2"} expanded={true}>
        <AccordionSummary sx={{ backgroundColor: "primary.light" }}>
          <Typography color={"primary.contrastText"}>20_デバイス</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <Button
              component={RouterLink}
              to={`/${siteName}/SystemAdminPage/FanPage`}
              variant="contained"
              color="primary"
              sx={{ m: 1, whiteSpace: "nowrap", minWidth: "250px" }}
            >
              {"21_Fan"}
            </Button>
            <Button
              component={RouterLink}
              to={`/${siteName}/SystemAdminPage/DeviceTypePage`}
              variant="contained"
              color="primary"
              sx={{ m: 1, whiteSpace: "nowrap", minWidth: "250px" }}
            >
              {"22_デバイス種別"}
            </Button>
            <Button
              component={RouterLink}
              to={`/${siteName}/SystemAdminPage/LocationPage`}
              variant="contained"
              color="primary"
              sx={{ m: 1, whiteSpace: "nowrap", minWidth: "250px" }}
            >
              {"23_設置場所"}
            </Button>
            <Button
              component={RouterLink}
              to={`/${siteName}/SystemAdminPage/DevicePage`}
              variant="contained"
              color="primary"
              sx={{ m: 1, whiteSpace: "nowrap", minWidth: "250px" }}
            >
              {"24_デバイス"}
            </Button>
            <Button
              component={RouterLink}
              to={`/${siteName}/SystemAdminPage/DeviceAlarmSettingPage`}
              variant="contained"
              color="primary"
              sx={{ m: 1, whiteSpace: "nowrap", minWidth: "250px" }}
            >
              {"25_デバイス警報設定"}
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded key={"group3"} expanded={true}>
        <AccordionSummary sx={{ backgroundColor: "primary.light" }}>
          <Typography color={"primary.contrastText"}>30_データ</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <Button
              component={RouterLink}
              to={`/${siteName}/SystemAdminPage/ReceiveDataTypePage`}
              variant="contained"
              color="primary"
              sx={{ m: 1, whiteSpace: "nowrap", minWidth: "250px" }}
            >
              {"31_データ種別（監視）"}
            </Button>
            <Button
              component={RouterLink}
              to={`/${siteName}/SystemAdminPage/ControlTypePage`}
              variant="contained"
              color="primary"
              sx={{ m: 1, whiteSpace: "nowrap", minWidth: "250px" }}
            >
              {"32_データ種別（制御）"}
            </Button>
            <Button
              component={RouterLink}
              to={`/${siteName}/SystemAdminPage/DataFormatPage`}
              variant="contained"
              color="primary"
              sx={{ m: 1, whiteSpace: "nowrap", minWidth: "250px" }}
            >
              {"33_データフォーマット"}
            </Button>
            <Button
              component={RouterLink}
              to={`/${siteName}/SystemAdminPage/DecodePage`}
              variant="contained"
              color="primary"
              sx={{ m: 1, whiteSpace: "nowrap", minWidth: "250px" }}
            >
              {"34_デコード"}
            </Button>
            <Button
              component={RouterLink}
              to={`/${siteName}/SystemAdminPage/CalculationPage`}
              variant="contained"
              color="primary"
              sx={{ m: 1, whiteSpace: "nowrap", minWidth: "250px" }}
            >
              {"35_計算式"}
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded key={"group4"} expanded={true}>
        <AccordionSummary sx={{ backgroundColor: "primary.light" }}>
          <Typography color={"primary.contrastText"}>40_センサー</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <Button
              component={RouterLink}
              to={`/${siteName}/SystemAdminPage/SensorTypePage`}
              variant="contained"
              color="primary"
              sx={{ m: 1, whiteSpace: "nowrap", minWidth: "250px" }}
            >
              {"41_センサー種別"}
            </Button>
            <Button
              component={RouterLink}
              to={`/${siteName}/SystemAdminPage/SensorPage`}
              variant="contained"
              color="primary"
              sx={{ m: 1, whiteSpace: "nowrap", minWidth: "250px" }}
            >
              {"42_センサー"}
            </Button>
            <Button
              component={RouterLink}
              to={`/${siteName}/SystemAdminPage/SensorAlarmSettingPage`}
              variant="contained"
              color="primary"
              sx={{ m: 1, whiteSpace: "nowrap", minWidth: "250px" }}
            >
              {"43_センサー警報設定"}
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded key={"group5"} expanded={true}>
        <AccordionSummary sx={{ backgroundColor: "primary.light" }}>
          <Typography color={"primary.contrastText"}>50_TOP画面</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <Button
              component={RouterLink}
              to={`/${siteName}/SystemAdminPage/ControlPage`}
              variant="contained"
              color="primary"
              sx={{ m: 1, whiteSpace: "nowrap", minWidth: "250px" }}
            >
              {"51_制御種別"}
            </Button>
            <Button
              component={RouterLink}
              to={`/${siteName}/SystemAdminPage/ControlSettingPage`}
              variant="contained"
              color="primary"
              sx={{ m: 1, whiteSpace: "nowrap", minWidth: "250px" }}
            >
              {"52_制御ボタン設定"}
            </Button>
            <Button
              component={RouterLink}
              to={`/${siteName}/SystemAdminPage/ColorPage`}
              variant="contained"
              color="primary"
              sx={{ m: 1, whiteSpace: "nowrap", minWidth: "250px" }}
            >
              {"53_色定義"}
            </Button>
            <Button
              component={RouterLink}
              to={`/${siteName}/SystemAdminPage/AlarmPage`}
              variant="contained"
              color="primary"
              sx={{ m: 1, whiteSpace: "nowrap", minWidth: "250px" }}
            >
              {"54_警報色"}
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default SystemAdminPage;
