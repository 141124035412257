import { Grid, TextField, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { insertDataFormat } from "../api/InsertDataFormats";
import { checkJwt } from "../api/TokenVerification";
import { updateDataFormat } from "../api/UpdateDataFormats";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { PageId } from "../constants/page";
import { AuthContext } from "../context/AuthContext";
import { DataFormat } from "../models/DataFormat";
import { CancelSaveButtons } from "./CancelSaveButtons";
import CustomModalSnackbar from "./CustomModalSnackbar";
import { MasterModal } from "./MasterModal";
import {
  yupCollectionDateTimeColumnName,
  yupDataColumnName,
  yupDataDeviceIdColumnName,
  yupDataFormatName,
  yupDataRadix,
} from "./ValidationSchema";

interface DataFormatModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  editingDataFormat?: DataFormat;
}

const DataFormatValidationSchema = yup.object({
  dataFormatName: yupDataFormatName.required(),
  dataColumnName: yupDataColumnName.required(),
  dataRadix: yupDataRadix.required(),
  collectionDateTimeColumnName: yupCollectionDateTimeColumnName.required(),
  dataDeviceIdColumnName: yupDataDeviceIdColumnName.nullable(),
});

function DataFormatModal({
  open,
  onClose,
  onUpdate,
  editingDataFormat,
}: DataFormatModalProps) {
  const isUpdate = editingDataFormat && editingDataFormat.dataFormatId != null;
  const auth = React.useContext(AuthContext);

  const [initialValues] = useState<DataFormat>({});
  const [errorMessage, setErrorMessage] = useState("");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingDataFormat || initialValues,
    validationSchema: DataFormatValidationSchema,
    onSubmit: async () => {
      await checkJwt(auth.siteId);
      try {
        if (isUpdate) {
          await updateDataFormat(formik.values, auth.siteId, auth.userId);
        } else {
          await insertDataFormat(formik.values, auth.siteId, auth.userId);
        }
        formik.resetForm();
        setErrorMessage("");
        onUpdate();
        onClose();
      } catch (err) {
        const axiosError = err as AxiosError;
        const errNum = axiosError.response?.data;
        if (errNum === ErrorCodes.UniqueKey) {
          setErrorMessage("データフォーマット名が重複しています。");
        } else if (errNum === ErrorCodes.InvalidParamKey) {
          setErrorMessage(ErrorMessages.InvalidParamError);
        } else {
          setErrorMessage(ErrorMessages.UnknownError);
        }
      }
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    setErrorMessage("");
    onClose();
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            {/* Title */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {
                  auth.pages?.find((page) => page.id === PageId.DataFormatPage)
                    ?.name
                }
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="データフォーマット名 *"
                name="dataFormatName"
                value={formik.values.dataFormatName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.dataFormatName)}
                helperText={formik.errors.dataFormatName}
                fullWidth
                margin="normal"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="データ列名 *"
                name="dataColumnName"
                value={formik.values.dataColumnName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.dataColumnName)}
                helperText={formik.errors.dataColumnName}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="基数（2→2進数、16→16進数） *"
                name="dataRadix"
                type="number"
                value={formik.values.dataRadix ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.dataRadix)}
                helperText={formik.errors.dataRadix}
                fullWidth
              />
            </Grid>

            {/* Collection Date Time Column Name */}
            <Grid item xs={12}>
              <TextField
                label="収集時刻列名 *"
                name="collectionDateTimeColumnName"
                value={formik.values.collectionDateTimeColumnName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.collectionDateTimeColumnName)}
                helperText={formik.errors.collectionDateTimeColumnName}
                fullWidth
              />
            </Grid>
            {/* Collection Date Time Column Name */}
            <Grid item xs={12}>
              <TextField
                label="データデバイスID列名"
                name="dataDeviceIdColumnName"
                value={formik.values.dataDeviceIdColumnName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.dataDeviceIdColumnName)}
                helperText={formik.errors.dataDeviceIdColumnName}
                fullWidth
              />
            </Grid>
            <CancelSaveButtons handleCancel={handleCancel} />
          </Grid>
        </form>
      </MasterModal>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}
export default DataFormatModal;
