import { Box, Toolbar, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { AxiosError } from "axios";
import * as React from "react";
import { deleteReceiveDataDisplaySetting } from "../api/DeleteReceiveDataDisplaySetting";
import { getReceiveDataDisplaySettings } from "../api/GetReceiveDataDisplaySettings";
import { checkJwt } from "../api/TokenVerification";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { AuthContext } from "../context/AuthContext";
import LoadingContext from "../context/LoadingContext";
import { ReceiveDataDisplaySetting } from "../models/ReceiveDataDisplaySetting";
import calculateColumnWidth from "../share/calculateColumnWidth";
import AddDeleteButton from "./AddDeleteButton";
import CustomMasterContainer from "./CustomMasterContainer";
import CustomPageSnackbar from "./CustomPageSnackbar";
import CustomSearchField from "./CustomSearchField";
import { gridContainerStyles } from "./CustomStyle";
import ReceiveDataDisplaySettingModal from "./ReceiveDataDisplaySettingModal";

type GridDataRow = ReceiveDataDisplaySetting & {
  id: number;
};

const headerName = {
  sensorName: "センサー",
  receiveDataTypeName: "受信データ種別",
  displayGroupName: "表示グループ",
  dataName: "データ名称",
  displayTimeName: "表示時間",
  displayOrder: "並び順",
};

function ReceiveDataDisplaySettingPage() {
  const auth = React.useContext(AuthContext);
  const siteId = Number(auth.siteId);

  const [rows, setRows] = React.useState<GridDataRow[]>([]);
  const [editingRow, setEditingRow] =
    React.useState<ReceiveDataDisplaySetting | null>(null);
  const [selectedRows, setSelectedRows] = React.useState<GridRowId[]>([]);
  const [columnWidths, setColumnWidths] = React.useState<any>();
  const [inputValue, setInputValue] = React.useState("");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const { setLoading } = React.useContext(LoadingContext);

  const columns: GridColDef[] = [
    {
      field: "sensorName",
      headerName: headerName.sensorName,
      minWidth: calculateColumnWidth([headerName]).sensorName,
      width: columnWidths?.sensorName || 0,
      valueGetter: (params) =>
        `${params.row.iothubDeviceId}：${params.row.sensorName}`,
    },
    {
      field: "receiveDataTypeName",
      headerName: headerName.receiveDataTypeName,
      minWidth: calculateColumnWidth([headerName]).receiveDataTypeName,
      width: columnWidths?.receiveDataTypeName || 0,
      valueGetter: (params) =>
        `${params.row.receiveDataTypeName} (${params.row.unit || ""}${
          params.row.sign ? "," + params.row.sign : ""
        })`,
    },
    {
      field: "displayGroupName",
      headerName: headerName.displayGroupName,
      minWidth: calculateColumnWidth([headerName]).displayGroupName,
      width: columnWidths?.displayGroupName || 0,
    },
    {
      field: "dataName",
      headerName: headerName.dataName,
      minWidth: calculateColumnWidth([headerName]).dataName,
      width: columnWidths?.dataName || 0,
    },
    {
      field: "displayTimeName",
      headerName: headerName.displayTimeName,
      minWidth: calculateColumnWidth([headerName]).displayTimeName,
      width: columnWidths?.displayTimeName || 0,
    },
    {
      field: "displayOrder",
      headerName: headerName.displayOrder,
      minWidth: calculateColumnWidth([headerName]).displayOrder,
      width: columnWidths?.displayOrder || 0,
    },
  ];

  const fetchData = React.useCallback(async () => {
    await checkJwt(auth.siteId);
    const records = await getReceiveDataDisplaySettings(
      auth.siteId,
      auth.userId,
      searchTerm
    );
    if (records) {
      const rows: GridDataRow[] = records.map((record: any, i: number) => {
        return {
          id: i + 1,
          ...record,
        };
      });
      setRows(rows);
      setColumnWidths(calculateColumnWidth(rows));
    }
  }, [auth.siteId, auth.userId, searchTerm]);

  const fetchDataWithLoading = React.useCallback(async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  }, [fetchData, setLoading]);

  React.useEffect(() => {
    fetchDataWithLoading();
  }, [fetchDataWithLoading]);

  const handleRowEditStart = (rowId: GridRowId) => {
    const row = rows.find((row) => row.id === rowId);
    if (row) setEditingRow(row);
  };

  const handleRowEditStop = () => {
    setEditingRow(null);
  };

  const handleAddRow = () => {
    const receiveDataDisplaySetting: ReceiveDataDisplaySetting = {
      siteId,
      sensorId: null,
      displayGroupId: null,
      receiveDataTypeId: null,
      dataName: null,
      defaultDisplayTimeId: 1,
      displayOrder: null,
    };
    setEditingRow(receiveDataDisplaySetting);
  };

  const handleDeleteSelected = async (selectedRows: GridRowId[]) => {
    await checkJwt(auth.siteId);

    try {
      const RowsToDelete = selectedRows.map(
        (rowId) => rows.find((row) => row.id === rowId)?.sensorId
      ) as number[];

      if (RowsToDelete.length === 0) {
        setErrorMessage(ErrorMessages.SelectionError);
        return;
      }

      const deleteTasks = RowsToDelete.map((sensorId) =>
        deleteReceiveDataDisplaySetting(sensorId, auth.siteId, auth.userId)
      );
      await Promise.all(deleteTasks);
      fetchDataWithLoading();
      setSelectedRows([]);
    } catch (err) {
      const axiosError = err as AxiosError;
      const errNum = axiosError.response?.data;
      if (errNum === ErrorCodes.ForeignKey) {
        setErrorMessage(ErrorMessages.RecordInUseError);
      } else {
        setErrorMessage(ErrorMessages.UnknownError);
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearchTerm(inputValue);
    }
  };

  return (
    <>
      <CustomMasterContainer>
        <Box sx={{ mb: 1 }}>
          <Typography variant="subtitle1" component="div">
            検索条件
          </Typography>
        </Box>
        <CustomSearchField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Box sx={gridContainerStyles}>
          <DataGrid
            rows={rows}
            columns={columns}
            onRowClick={(param) => handleRowEditStart(param.id)}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={selectedRows}
            onRowSelectionModelChange={(newSelection) => {
              setSelectedRows(newSelection);
            }}
            showColumnVerticalBorder
            slots={{
              toolbar: () => (
                <Toolbar>
                  <AddDeleteButton
                    AddButtonOnClick={() => handleAddRow()}
                    DeleteButtonOnClick={() =>
                      handleDeleteSelected(selectedRows)
                    }
                  />
                </Toolbar>
              ),
            }}
          />
        </Box>
      </CustomMasterContainer>
      <ReceiveDataDisplaySettingModal
        open={!!editingRow}
        onClose={handleRowEditStop}
        onUpdate={fetchDataWithLoading}
        editingReceiveDataDisplaySetting={
          editingRow as ReceiveDataDisplaySetting
        }
      />
      <CustomPageSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={() => setErrorMessage("")}
      />
    </>
  );
}

export default ReceiveDataDisplaySettingPage;
