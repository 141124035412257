import React, { createContext } from "react";

interface LoadingContextType {
  isCheckJwt: boolean;
  setCheckJwt: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isCsvDownloading: boolean;
  setCsvDownloading: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoadingContext = createContext<LoadingContextType>({
  isCheckJwt: false,
  setCheckJwt: () => {},
  isLoading: false,
  setLoading: () => {},
  isCsvDownloading: false,
  setCsvDownloading: () => {},
});

export default LoadingContext;
