import { Button, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { ControlRecord } from "../models/ControlRecord";
import SendControlWithOneTimePasswordModal from "./SendControlWithPasswordModal";
import SendControlWithSitePasswordModal from "./SendControlWithoutPasswordModal";

interface Props {
  controlRecord: ControlRecord;
  fetchRecords: () => Promise<void>;
}

const ControlSendButton = ({ controlRecord, fetchRecords }: Props) => {
  const auth = useContext(AuthContext);
  const theme = useTheme();
  const [isOpen, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const modal = (isOneTimePasswordRequired: boolean) => {
    if (isOneTimePasswordRequired) {
      return (
        <SendControlWithOneTimePasswordModal
          isOpen={isOpen}
          onClose={handleClose}
          controlRecord={controlRecord}
          fetchRecords={fetchRecords}
          key={`control-card-${controlRecord.controlId}`}
        />
      );
    } else {
      return (
        <SendControlWithSitePasswordModal
          isOpen={isOpen}
          onClose={handleClose}
          controlRecord={controlRecord}
          fetchRecords={fetchRecords}
          key={`control-card-${controlRecord.controlId}`}
        />
      );
    }
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        disabled={controlRecord.isDisabled || !auth.canControl}
        sx={{
          width: "100%",
          bgcolor: theme.palette.primary.main,
        }}
      >
        {controlRecord.buttonLabel}
      </Button>
      {modal(controlRecord.isOneTimePasswordRequired)}
    </>
  );
};

export default ControlSendButton;
