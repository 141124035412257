import axios from "axios";

const url = process.env.REACT_APP_API_URL + "/GetPages";

export async function getPages(userId: number) {
  try {
    const body = {
      userId,
    };
    const response = await axios.post(url, body);
    return response.data;
  } catch (error) {
    return;
  } finally {
  }
}
