import KeyIcon from "@mui/icons-material/Key";
import {
  Avatar,
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { changePassword } from "../api/ChangePassword";
import { LoginPassword } from "../models/LoginPassword";
import CustomModalSnackbar from "./CustomModalSnackbar";
import { yupLoginPassword } from "./ValidationSchema";

const PasswordValidationSchema = yup.object({
  password1: yupLoginPassword.required(),
  password2: yup
    .string()
    .oneOf([yup.ref("password1")], "新規パスワードと同じ入力をしてください")
    .required(),
});

const ChangePasswordPage = () => {
  const [initialValues] = React.useState<LoginPassword>({
    password1: "",
    password2: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: PasswordValidationSchema,
    onSubmit: async () => {
      setIsLoading(true);
      try {
        const token = new URLSearchParams(location.search).get("token");
        await changePassword(
          token as string,
          formik.values.password1 as string
        );
        navigate("/LoginPage"); // ログイン成功時に初期遷移ページ取得
      } catch (error) {
        setErrorMessage(
          "パスワード更新に失敗しました。再度パスワード再設定メール送信からやり直してください。"
        );
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  return (
    <Container component="main" maxWidth="xs">
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <KeyIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            パスワード再設定
          </Typography>
          <TextField
            margin="normal"
            fullWidth
            label="新規パスワード"
            name="password1"
            type="password"
            autoFocus
            onChange={formik.handleChange}
            error={Boolean(formik.errors.password1)}
            helperText={formik.errors.password1}
          />
          <TextField
            margin="normal"
            fullWidth
            label="確認パスワード"
            name="password2"
            type="password"
            onChange={formik.handleChange}
            error={Boolean(formik.errors.password2)}
            helperText={formik.errors.password2}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoading}
          >
            {"再設定"}
          </Button>
          <CustomModalSnackbar
            open={errorMessage !== ""}
            message={errorMessage}
            onClose={handleAlertClose}
          />
        </Box>
      </form>
    </Container>
  );
};

export default ChangePasswordPage;
