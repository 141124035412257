import { Box, Toolbar, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { AxiosError } from "axios";
import * as React from "react";
import { deleteControlCard } from "../api/DeleteControlCard";
import { getControlCards } from "../api/GetControlCards";
import { checkJwt } from "../api/TokenVerification";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { AuthContext } from "../context/AuthContext";
import LoadingContext from "../context/LoadingContext";
import { ControlCard } from "../models/ControlCard";
import calculateColumnWidth from "../share/calculateColumnWidth";
import AddDeleteButton from "./AddDeleteButton";
import ControlCardModal from "./ControlCardModal";
import CustomMasterContainer from "./CustomMasterContainer";
import CustomPageSnackbar from "./CustomPageSnackbar";
import CustomSearchField from "./CustomSearchField";
import { gridContainerStyles } from "./CustomStyle";

type DataGridRow = ControlCard & {
  id: number;
};

const headerName = {
  controlTypeName: "制御種別",
  displayGroupName: "表示グループ",
  dataName: "設備名称",
  displayOrder: "表示順",
  isOneTimePasswordRequired: "ワンタイムパスワード",
};

function ControlCardPage() {
  const initialValue: string = "";
  const [rows, setRows] = React.useState<DataGridRow[]>([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [editingItem, setEditingItem] = React.useState<ControlCard>();
  const [selectedRows, setSelectedRows] = React.useState<GridRowId[]>([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const auth = React.useContext(AuthContext);
  const [columnWidths, setColumnWidths] = React.useState<any>();
  const [inputValue, setInputValue] = React.useState(initialValue); // <-- Update
  const [searchTerm, setSearchTerm] = React.useState(initialValue);
  const { setLoading } = React.useContext(LoadingContext);

  const columns: GridColDef[] = [
    {
      field: "controlTypeName",
      headerName: headerName.controlTypeName,
      minWidth: calculateColumnWidth([headerName]).controlTypeName,
      width: columnWidths?.controlTypeName || 0,
    },
    {
      field: "displayGroupName",
      headerName: headerName.displayGroupName,
      minWidth: calculateColumnWidth([headerName]).displayGroupName,
      width: columnWidths?.displayGroupName || 0,
    },
    {
      field: "dataName",
      headerName: headerName.dataName,
      minWidth: calculateColumnWidth([headerName]).dataName,
      width: columnWidths?.dataName || 0,
    },
    {
      field: "displayOrder",
      headerName: headerName.displayOrder,
      minWidth: calculateColumnWidth([headerName]).displayOrder,
      width: columnWidths?.displayOrder || 0,
    },
    {
      field: "isOneTimePasswordRequired",
      headerName: headerName.isOneTimePasswordRequired,
      minWidth: calculateColumnWidth([headerName]).isOneTimePasswordRequired,
      width: columnWidths?.isOneTimePasswordRequired || 0,
      valueFormatter: (params) => (params.value ? "有効" : "無効"),
    },
  ];

  //DBからデータを取得している
  const fetchData = React.useCallback(async () => {
    await checkJwt(auth.siteId);
    const records = await getControlCards(auth.siteId, auth.userId, searchTerm);
    if (records) {
      const row: DataGridRow[] = records.map((record: any, i: number) => {
        return {
          id: i + 1,
          ...record,
        };
      });
      setRows(row);
      setColumnWidths(calculateColumnWidth(row));
    }
  }, [auth.siteId, auth.userId, searchTerm]);

  const fetchDataWithLoading = React.useCallback(async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  }, [fetchData, setLoading]);

  React.useEffect(() => {
    fetchDataWithLoading();
  }, [fetchDataWithLoading]);

  const handleRowEditStart = (rowId: GridRowId) => {
    const row = rows.find((row) => row.id === rowId);
    if (row) {
      setEditingItem(row);
      setOpenModal(true);
    }
  };

  const handleRowEditStop = () => {
    setEditingItem(undefined);
    setOpenModal(false);
  };

  const handleAddItem = () => {
    setEditingItem(undefined);
    setOpenModal(true);
  };

  const handleDeleteSelected = async (selectedRows: GridRowId[]) => {
    await checkJwt(auth.siteId);

    // 削除対象の siteId の配列を作成
    const RowsToDelete = selectedRows
      .map((rowId) => rows.find((row) => row.id === rowId)?.controlCardId)
      .filter((locationId) => locationId != null) as number[];

    if (RowsToDelete.length === 0) {
      setErrorMessage(ErrorMessages.SelectionError);
      return;
    }

    const deleteTasks = RowsToDelete.map((locationId) => {
      return deleteControlCard(locationId, auth.siteId, auth.userId);
    });

    try {
      await Promise.all(deleteTasks);
      fetchDataWithLoading();
      setSelectedRows([]);
    } catch (err) {
      const axiosError = err as AxiosError;
      const errNum = axiosError.response?.data;
      if (errNum === ErrorCodes.ForeignKey) {
        setErrorMessage(ErrorMessages.RecordInUseError);
      } else {
        setErrorMessage(ErrorMessages.UnknownError);
      }
    }
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearchTerm(inputValue);
    }
  };

  return (
    <>
      <CustomMasterContainer>
        <Box sx={{ mb: 1 }}>
          <Typography variant="subtitle1" component="div">
            検索条件
          </Typography>
        </Box>
        <CustomSearchField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />

        <Box sx={gridContainerStyles}>
          <DataGrid
            rows={rows}
            columns={columns}
            onRowClick={(param) => handleRowEditStart(param.id)}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={selectedRows}
            onRowSelectionModelChange={(newSelection) => {
              setSelectedRows(newSelection);
            }}
            showColumnVerticalBorder
            slots={{
              toolbar: () => (
                <Toolbar>
                  <AddDeleteButton
                    AddButtonOnClick={() => handleAddItem()}
                    DeleteButtonOnClick={() =>
                      handleDeleteSelected(selectedRows)
                    }
                  />
                </Toolbar>
              ),
            }}
          />
        </Box>
      </CustomMasterContainer>
      <ControlCardModal
        open={openModal}
        onClose={handleRowEditStop}
        onUpdate={fetchDataWithLoading}
        editingItem={editingItem}
      />
      <CustomPageSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}

export default ControlCardPage;
