import "./Layout.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import {
  Avatar,
  FormControl,
  Grid,
  Icon,
  MenuItem,
  Select,
  Link,
  useMediaQuery,
} from "@mui/material";
import { getSites } from "../api/GetSites";
import { Site } from "../models/Site";
import { Link as RouterLink } from "react-router-dom";
import { getUser } from "../api/GetUser";
import { User } from "../models/User";
import AdminUserModal from "./AdminUserModal";

function stringToColor(string: string) {
  let hash = 0;
  let i: number;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
function stringAvatar(name: string) {
  const avatarName = name
    .replace("　", " ")
    .split(" ")
    .map((name) => name[0])
    .join("");
  return {
    sx: {
      bgcolor: stringToColor(avatarName),
    },
    children: `${avatarName}`,
  };
}
function Layout() {
  const [open, setOpen] = useState(false);
  const [sites, setSites] = useState<Site[]>([]);
  const [title, setTitle] = useState("YONKEI CONNECT");
  const location = useLocation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const isSmallWidth = useMediaQuery("(max-width: 600px)");

  document.title = title;

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const auth = React.useContext(AuthContext);

  const getTitleName = useCallback(() => {
    const pageName = location.pathname.split("/").pop();
    const pages = auth.pages;
    const page = pages?.find((page) => page.url.split("/").pop() === pageName);
    if (page) {
      return page.name;
    }
    return "";
  }, [location.pathname, auth.pages]);

  useEffect(() => {
    setTitle(`YONKEI CONNECT - ${getTitleName()}`);
  }, [getTitleName]);

  useEffect(() => {
    if (auth.user?.userId === auth.userId) {
      auth.setUserName(auth.user.userName ? auth.user.userName : "");
    }
  }, [auth.user]);

  const fetchSites = useCallback(async () => {
    if (auth.canCreate) {
      const fetchedSites = await getSites(auth.siteId, auth.userId);
      setSites(fetchedSites || []);
    }
  }, [auth.canCreate, auth.siteId, auth.userId]);

  useEffect(() => {
    fetchSites();
  }, [fetchSites]);

  function GetSiteName() {
    if (!auth.canCreate) {
      return (
        <Typography variant="body1" noWrap>
          <Link
            sx={{ color: "primary.contrastText" }}
            component={RouterLink}
            to={"/" + auth.siteName + "/TopPage"}
            underline="none"
          >
            {auth.siteName}
          </Link>
        </Typography>
      );
    } else {
      return (
        <FormControl variant="standard">
          <Select
            value={auth.siteId.toString()}
            displayEmpty
            sx={{
              color: "primary.contrastText",
            }}
            inputProps={{ "aria-label": "Without label" }}
          >
            {siteItems()}
          </Select>
        </FormControl>
      );
    }
  }

  const siteItems = () => {
    if (sites) {
      return sites.map((site) => (
        <MenuItem
          value={site.siteId?.toString()}
          onClick={() => {
            navigate("/" + site.siteName + "/TopPage");
          }}
          key={site.siteId}
        >
          {site.siteName}
        </MenuItem>
      ));
    } else {
      return <></>;
    }
  };

  const handleAvatarClick = async () => {
    const users = await getUser(auth.siteId, auth.userId, false);
    const user = users ? users[0] : null;
    setEditingUser(user);
    setOpenModal(true);
  };

  const setUserName = (userName: string) => {
    auth.userName = userName;
  };

  const handleRowEditStop = () => {
    setOpenModal(false);
  };

  const menus = useCallback(() => {
    if (auth.menuInformations) {
      return auth.menuInformations.map((menu) => (
        <ListItem
          disablePadding
          component={RouterLink}
          to={"/" + auth.siteName + menu.url}
          onClick={toggleOpen}
          key={menu.id}
        >
          <ListItemButton>
            <ListItemIcon>
              <Icon>{menu.icon}</Icon>
            </ListItemIcon>
            <ListItemText primary={"　" + menu.name + "　"} />
          </ListItemButton>
        </ListItem>
      ));
    } else {
      return <></>;
    }
  }, [auth.menuInformations, auth.siteName, toggleOpen]);

  return (
    <div className="Layout">
      <header>
        <Box>
          <AppBar>
            <Toolbar>
              <Grid container direction="row" alignItems="center">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    title="image"
                    src={`${process.env.PUBLIC_URL}/${
                      isSmallWidth
                        ? "conect_mark.jpg"
                        : "YONKEICONECT_yokoshiro.jpg"
                    }`}
                    alt="ロゴ"
                    style={{ maxHeight: "40px" }}
                    onClick={() => {
                      navigate("/" + auth.siteName + "/TopPage");
                    }}
                  />

                  {!isSmallWidth && (
                    <Typography variant="body1" m={2} noWrap>
                      {getTitleName()}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {GetSiteName()}
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Typography
                    variant="body1"
                    minWidth={"40px"}
                    align="right"
                    noWrap
                  >
                    {auth.permissionAbbreviation}
                  </Typography>

                  <Avatar
                    {...stringAvatar(auth.userName)}
                    onClick={handleAvatarClick}
                  />
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleOpen}
                  >
                    <MenuIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Toolbar>
          </AppBar>
        </Box>
      </header>
      <aside>
        <Drawer anchor="right" open={open} onClose={toggleOpen}>
          <Box
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            <Grid container direction="column" sx={{ minHeight: "100vh" }}>
              <Grid item xs>
                <List>{menus()}</List>
              </Grid>
              <Grid item xs="auto">
                <List>
                  <ListItem
                    disablePadding
                    component={RouterLink}
                    to={"/LogoutPage"}
                    onClick={toggleOpen}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <Icon>{"logout"}</Icon>
                      </ListItemIcon>
                      <ListItemText primary={"　ログアウト　"} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Box>
        </Drawer>
      </aside>
      <Toolbar />
      {isSmallWidth && (
        <Typography ml={1} color="primary" variant="subtitle2">
          {getTitleName()}
        </Typography>
      )}
      <main>
        <Outlet />
      </main>
      <AdminUserModal
        open={openModal}
        onClose={handleRowEditStop}
        title={"ユーザー情報編集"}
        editingAdminUser={editingUser as User}
        // setUserName={setUserName}
      />
    </div>
  );
}

export default Layout;
