import axios from "axios";
import { Sensor } from "../models/Sensor";

const url = process.env.REACT_APP_API_URL + "/UpdateSensor";

export async function updateSensor(
  sensor: Sensor,
  state: boolean,
  userId: number,
  siteId: number
) {
  const body = {
    sensor,
    state,
    userId,
    siteId,
  };
  const response = await axios.post(url, body);
  return response;
}
