import { Box, Link, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns-tz";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { getObserveRecords } from "../api/GetObserveRecords";
import { checkJwt } from "../api/TokenVerification";
import { AuthContext } from "../context/AuthContext";
import LoadingContext from "../context/LoadingContext";
import { ObserveRecord } from "../models/ObserveRecord";
import calculateColumnWidth from "../share/calculateColumnWidth";
import { sortRecord } from "../share/sort";
import CustomContainer from "./CustomContainer";
import CustomSearchField from "./CustomSearchField";
import { gridContainerStyles } from "./CustomStyle";

type GridRow = ObserveRecord & {
  id: number;
  value: string;
  locationName: string;
};

const headerName = {
  id: "No",
  locationName: "設置場所",
  dataName: "データ名称",
  receiveDataTypeName: "項目",
  value: "観測値[単位]",
  state: "状態",
  collectionDateTime: "最終更新日",
};

// Main Component
const ListPage = () => {
  const auth = useContext(AuthContext);
  const siteName = auth.siteName;
  const { setLoading } = useContext(LoadingContext);
  const [columnWidths, setColumnWidths] = useState<any>();

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "No",
      minWidth: calculateColumnWidth([headerName]).id,
      width: columnWidths?.id || 0,
    },
    {
      field: "locationName",
      headerName: headerName.locationName,
      minWidth: calculateColumnWidth([headerName]).locationName,
      width: columnWidths?.locationName || 0,
    },
    {
      field: "dataName",
      headerName: headerName.dataName,
      minWidth: calculateColumnWidth([headerName]).dataName,
      width: columnWidths?.dataName || 0,
      renderCell: (params: any) => {
        return (
          <Link
            component={RouterLink}
            to={"/" + siteName + "/DetailInformationPage"}
            underline="none"
            state={{ dataName: params.row.dataName }}
          >
            {params.row.dataName}
          </Link>
        );
      },
    },
    {
      field: "receiveDataTypeName",
      headerName: headerName.receiveDataTypeName,
      minWidth: calculateColumnWidth([headerName]).receiveDataTypeName,
      width: columnWidths?.receiveDataTypeName || 0,
      renderCell: (params: any) => {
        return (
          <Typography
            p={1}
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: params.row.palette,
              height: "100%",
              width: "100%",
            }}
          >
            <Link
              component={RouterLink}
              to={"/" + siteName + "/UserLogPage"}
              underline="none"
              state={{
                dataName: params.row.dataName,
              }}
              color={
                params.row.palette &&
                params.row.palette.split(".")[0] + ".contrastText"
              }
            >
              {params.row.receiveDataTypeName}
            </Link>
          </Typography>
        );
      },
    },
    {
      field: "value",
      headerName: headerName.value,
      minWidth: calculateColumnWidth([headerName]).value,
      width: columnWidths?.value || 0,
      renderCell: (params: any) => {
        return (
          <Link
            component={RouterLink}
            to={"/" + siteName + "/GraphPage?id=" + params.row.sensorId}
            underline="none"
            state={{ sensorId: params.row.sensorId }}
          >
            {params.row.value}
          </Link>
        );
      },
      type: "number",
    },
    {
      field: "collectionDateTime",
      headerName: headerName.collectionDateTime,
      type: "Date",
      minWidth: calculateColumnWidth([headerName]).collectionDateTime,
      width: columnWidths?.collectionDateTime || 0,
      valueFormatter: (params: { value: Date }) => {
        if (params.value === null) {
          return "";
        }
        return format(params.value, "yyyy/MM/dd HH:mm:ss");
      },
    },
  ];
  const [inputValue, setInputValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [rows, setRows] = useState<GridRow[]>([]);

  const fetchRecords = useCallback(async () => {
    await checkJwt(auth.siteId);
    const records = await getObserveRecords(
      auth.userId,
      auth.siteId,
      searchTerm
    );
    if (records) {
      const row: GridRow[] = records
        .sort(sortRecord)
        .map((record: any, i: number) => {
          return {
            ...record,
            id: i + 1,
            value:
              (record.value == null ? "- " : record.value + " ") +
              (record.unit != null ? record.unit : ""),
          };
        });
      setRows(row);
      setColumnWidths(calculateColumnWidth(row));
    }
  }, [auth.siteId, auth.userId, searchTerm]);

  useEffect(() => {
    setLoading(true);
    fetchRecords().finally(() => setLoading(false));

    const timer = setInterval(fetchRecords, 5000);
    return () => clearInterval(timer);
  }, [fetchRecords, setLoading]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearchTerm(inputValue);
    }
  };

  return (
    <CustomContainer>
      <CustomSearchField
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <Box sx={gridContainerStyles}>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row.id}
          showColumnVerticalBorder
        />
      </Box>
    </CustomContainer>
  );
};

export default ListPage;
