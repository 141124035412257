import axios from "axios";
import { DeviceAlarmSetting } from "../models/DeviceAlarmSetting";

const url = process.env.REACT_APP_API_URL + "/InsertDeviceAlarmSetting";

export async function insertDeviceAlarmSetting(
  deviceAlarmSetting: DeviceAlarmSetting,
  siteId: number,
  userId: number
) {
  const body = {
    deviceAlarmSetting,
    siteId,
    userId,
  };
  const response = await axios.post(url, body);
  return response.data;
}
