import { CircularProgress } from "@mui/material";
import { useContext } from "react";
import LoadingContext from "../context/LoadingContext";

const LoadingSpinner = () => {
  const { isCheckJwt, isLoading } = useContext(LoadingContext);

  if (!isCheckJwt && !isLoading) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <CircularProgress color="secondary" />
    </div>
  );
};

export default LoadingSpinner;
