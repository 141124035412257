export enum PageId {
  LoginPage = 1,
  LogoutPage = 2,
  SendChangePasswordEmailPage = 3,
  ForbiddenPage = 4,
  TopPage = 5,
  GraphPage = 6,
  MapPage = 7,
  ListPage = 8,
  ControlListPage = 9,
  UserLogPage = 10,
  SystemLogPage = 11,
  AdminPage = 12,
  DisplayGroupPage = 13,
  ControlDisplaySettingPage = 14,
  ReceiveDataDisplaySettingPage = 15,
  SystemAdminPage = 16,
  LocationPage = 17,
  UserPage = 18,
  SitePage = 19,
  SensorPage = 20,
  SensorTypePage = 21,
  DevicePage = 22,
  DeviceTypePage = 23,
  FanPage = 24,
  AlarmPage = 25,
  ControlTypePage = 26,
  ReceiveDataTypePage = 27,
  ColorPage = 28,
  DataFormatPage = 29,
  CalculationPage = 30,
  SensorAlarmSettingPage = 31,
  DeviceAlarmSettingPage = 32,
  UserNotificationSettingPage = 33,
  DecodePage = 34,
  AdminUserPage = 35,
  ControlDetailInformationPage = 36,
  DetailInformationPage = 37,
  ControlAnserbackSettingPage = 38,
  ControlCardPage = 39,
  ControlPage = 40,
  ControlSettingPage = 41,
  SiteLocationPage = 42,
}
