import { Alert, Snackbar, SnackbarCloseReason } from "@mui/material";
import React from "react";

interface CustomSnackbarProps {
  open: boolean;
  message: string;
  onClose: () => void;
}

function CustomModalSnackbar({ open, message, onClose }: CustomSnackbarProps) {
  const handleAlertCloseForAlert = (
    _event: React.SyntheticEvent<Element, Event>
  ) => {
    onClose();
  };

  const handleAlertCloseForSnackbar = (
    _event: React.SyntheticEvent<Element, Event> | Event,
    reason: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    onClose();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleAlertCloseForSnackbar}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <Alert
        onClose={handleAlertCloseForAlert}
        severity="error"
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default CustomModalSnackbar;
