import axios from "axios";
import { Decode } from "../models/Decode";

const url = process.env.REACT_APP_API_URL + "/InsertDecode";

export async function insertDecode(
  decode: Decode,
  siteId: number,
  userId: number
) {
  const body = {
    decode,
    siteId,
    userId,
  };
  const response = await axios.post(url, body);
  return response.data;
}
