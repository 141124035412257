import axios from "axios";
import { DataFormat } from "../models/DataFormat";

const url = process.env.REACT_APP_API_URL + "/UpdateDataFormat";

export async function updateDataFormat(
  dataFormat: DataFormat,
  siteId: number,
  userId: number
) {
  const body = {
    dataFormat,
    siteId,
    userId,
  };
  const response = await axios.post(url, body);
  return response.data;
}
