import axios from "axios";
import { Sensor } from "../models/Sensor";

const url = process.env.REACT_APP_API_URL + "/GetSensors";

export async function getSensors(
  siteId: number,
  userId: number,
  searchText?: string
) {
  try {
    const body = { siteId, userId, searchText };

    const response = await axios.post(url, body);
    const data: any[] = response.data;
    return data.map((d) => {
      const convert: Sensor = {
        ...d,
        setUpDate: d.setUpDate && new Date(d.setUpDate),
        removalDate: d.removalDate && new Date(d.removalDate),
        operationStartDate:
          d.operationStartDate && new Date(d.operationStartDate),
        state: Boolean(d.state),
      };
      return convert;
    });
  } catch (error) {
    return;
  } finally {
  }
}
