import DateRangeIcon from "@mui/icons-material/DateRange";
import {
  Box,
  Fade,
  Grid,
  IconButton,
  Modal,
  Stack,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns-tz";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { getUserLogs } from "../api/GetUserLogs";
import { checkJwt } from "../api/TokenVerification";
import { AuthContext } from "../context/AuthContext";
import LoadingContext from "../context/LoadingContext";
import { UserLog } from "../models/UserLog";
import calculateColumnWidth from "../share/calculateColumnWidth";
import CustomContainer from "./CustomContainer";
import CustomSearchField from "./CustomSearchField";
import DateTimePickers, { DateTimePickersFormat } from "./DateTimePickers";
import ExportButton from "./ExportButton";

type GridRow = UserLog & {
  id: number;
  value: string;
};

const headerName = {
  id: "No",
  occurrenceDateTime: "発生日時",
  logLevelName: "イベント",
  dataName: "名称",
  typeName: "項目",
  details: "内容",
  status: "状態",
};

// Main Component
const UserLogPage: React.FC = () => {
  const auth = useContext(AuthContext);
  const { setLoading } = useContext(LoadingContext);
  const location = useLocation();
  const [columnWidths, setColumnWIdths] = useState<any>();

  // Check if location.state exists before trying to access the property
  const initialValue: string = location.state?.dataName || "";

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: headerName.id,
      minWidth: calculateColumnWidth([headerName]).id,
      width: columnWidths?.id || 0,
    },
    {
      field: "occurrenceDateTime",
      headerName: headerName.occurrenceDateTime,
      minWidth: calculateColumnWidth([headerName]).occurrenceDateTime,
      width: columnWidths?.occurrenceDateTime || 0,
      valueFormatter: (params) => {
        return format(params.value, "yyyy/MM/dd HH:mm:ss");
      },
    },
    {
      field: "logLevelName",
      headerName: headerName.logLevelName,
      minWidth: calculateColumnWidth([headerName]).logLevelName,
      width: columnWidths?.logLevelName || 0,
    },
    {
      field: "dataName",
      headerName: headerName.dataName,
      minWidth: calculateColumnWidth([headerName]).dataName,
      width: columnWidths?.dataName || 0,
      valueFormatter: (params) => (params.value === null ? "-" : params.value),
    },
    {
      field: "typeName",
      headerName: headerName.typeName,
      minWidth: calculateColumnWidth([headerName]).typeName,
      width: columnWidths?.typeName || 0,
      valueFormatter: (params) => (params.value === null ? "-" : params.value),
    },
    {
      field: "details",
      headerName: headerName.details,
      minWidth: calculateColumnWidth([headerName]).details,
      width: columnWidths?.details || 0,
    },
    {
      field: "status",
      headerName: headerName.status,
      minWidth: calculateColumnWidth([headerName]).status,
      width: columnWidths?.status || 0,
      valueFormatter: (params) => (params.value === null ? "-" : params.value),
    },
  ];

  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 6);
  oneWeekAgo.setHours(oneWeekAgo.getHours() + 1);

  const nextHour = new Date();
  nextHour.setHours(nextHour.getHours() + 1);

  const [inputValue, setInputValue] = useState(initialValue);
  const [searchTerm, setSearchTerm] = useState(initialValue);
  const [rows, setRows] = useState<GridRow[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(oneWeekAgo);
  const [endDate, setEndDate] = useState<Date | null>(nextHour);
  const isSmallWidth = useMediaQuery("(max-width:800px)");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fetchRecords = useCallback(async () => {
    await checkJwt(auth.siteId);
    const records = await getUserLogs(
      auth.siteId,
      auth.userId,
      searchTerm,
      startDate ? startDate : undefined,
      endDate ? endDate : undefined
    );
    if (records) {
      const row: GridRow[] = records.map((record: any, i: number) => {
        return {
          id: i + 1,
          ...record,
        };
      });

      setRows(row);
      setColumnWIdths(calculateColumnWidth(row));
    }
  }, [auth.siteId, auth.userId, searchTerm, startDate, endDate]);

  useEffect(() => {
    setLoading(true);
    fetchRecords().then(() => setLoading(false));
  }, [fetchRecords, setLoading]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearchTerm(inputValue);
    }
  };

  const csvData = rows.map((row) => ({
    No: row.id || "",
    発生日時: format(row.occurrenceDateTime, "yyyy/MM/dd HH:mm:ss"),
    イベント: row.title || "",
    名称: row.dataName || "-",
    項目: row.typeName || "",
    内容: row.details || "",
    状態: row.status || "-",
  }));

  return (
    <CustomContainer>
      <Grid container spacing={2}>
        <Grid item xs>
          <CustomSearchField
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </Grid>
        <Grid item xs="auto">
          <Stack
            direction="row"
            justifyContent="flex-end" // Align DateTimePickers to the right
            alignItems="center"
            spacing={2}
          >
            {isSmallWidth ? (
              <IconButton onClick={handleOpen} style={{ marginTop: "8px" }}>
                <DateRangeIcon />
              </IconButton>
            ) : (
              <DateTimePickers
                format={DateTimePickersFormat.HOUR}
                disabled={false}
                views={["year", "month", "day", "hours"]}
                openTo={"year"}
                timeFrom={startDate}
                timeTo={endDate}
                setTimeFrom={setStartDate}
                setTimeTo={setEndDate}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
      <Box sx={{ height: "calc(100% - 70px)", width: "100%", marginBottom: 2 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row.id}
          slots={{
            toolbar: () => (
              <Toolbar>
                <ExportButton
                  defaultFileName="UserLogData.csv"
                  csvData={csvData}
                />
              </Toolbar>
            ),
          }}
          showColumnVerticalBorder
        />
      </Box>
      <Modal open={open} onClose={handleClose} closeAfterTransition>
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <DateTimePickers
              format={DateTimePickersFormat.DAY}
              disabled={false}
              views={["year", "month", "day"]}
              openTo={"year"}
              timeFrom={startDate}
              timeTo={endDate}
              setTimeFrom={setStartDate}
              setTimeTo={setEndDate}
            />
          </Box>
        </Fade>
      </Modal>
    </CustomContainer>
  );
};

export default UserLogPage;
