import axios from "axios";
import { SensorType } from "../models/SensorType";

const url = process.env.REACT_APP_API_URL + "/UpdateSensorType";

export async function updateSensorType(
  sensorType: SensorType,
  siteId: number,
  userId: number
) {
  const body = {
    sensorType,
    siteId,
    userId,
  };
  const response = await axios.post(url, body);
  return response.data;
}
