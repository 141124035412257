import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom"; // react-router-domからuseLocationをインポートします
import { AuthContext } from "../context/AuthContext";

enum LogoutParams {
  manualLogout = 1,
  timeoutLogout = 2,
  lastWinsLogout = 3,
}

const LogoutPage = () => {
  const auth = useContext(AuthContext);
  const location = useLocation(); // useLocationフックを使用してlocationオブジェクトを取得します

  useEffect(() => {
    const handleLogout = () => {
      try {
        // location.state.isLastLoginLogoutがtrueの場合はLogoutParams.lastWinsLogoutを渡し、それ以外の場合はLogoutParams.manualLogoutを渡します
        const logoutParam = location.state?.isLastLoginLogout
          ? LogoutParams.lastWinsLogout
          : LogoutParams.manualLogout;
        auth.logout(logoutParam, auth.siteId);
      } catch (error) {
        console.error("ログアウト失敗: ", error);
      }
    };
    handleLogout();
  }, [auth, location.state]); // useEffectの依存性配列にlocation.stateを追加します

  return <></>;
};

export default LogoutPage;
