import axios from "axios";

const url = process.env.REACT_APP_API_URL + "/GetControlRadioButtons";

export async function getControlRadioButtons(
  userId: number,
  siteId: number,
  controlCardId: number
) {
  try {
    const body = {
      userId,
      siteId,
      controlCardId,
    };

    const response = await axios.post(url, body);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}
