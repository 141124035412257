import { Box, Toolbar, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import * as React from "react";
import { deleteDeviceAlarmSetting } from "../api/DeleteDeviceAlarmSetting";
import { getDeviceAlarmSettings } from "../api/GetDeviceAlarmSettings";
import { checkJwt } from "../api/TokenVerification";
import ErrorMessages from "../constants/errorMessages";
import { AuthContext } from "../context/AuthContext";
import LoadingContext from "../context/LoadingContext";
import { DeviceAlarmSetting } from "../models/DeviceAlarmSetting";
import calculateColumnWidth from "../share/calculateColumnWidth";
import AddDeleteButton from "./AddDeleteButton";
import CustomMasterContainer from "./CustomMasterContainer";
import CustomPageSnackbar from "./CustomPageSnackbar";
import CustomSearchField from "./CustomSearchField";
import { gridContainerStyles } from "./CustomStyle";
import DeviceAlarmSettingModal from "./DeviceAlarmSettingModal";

type GridDataRow = DeviceAlarmSetting & {
  id: number;
};

const headerName = {
  iothubDeviceId: "デバイス",
  sensorName: "センサー",
  alarmName: "警報",
  equalitySign: "等号",
  threshold: "しきい値",
};

function DeviceAlarmSettingPage() {
  const initialValue: string = "";
  const [rows, setRows] = React.useState<GridDataRow[]>([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [editingDeviceAlarmSetting, setEditingDeviceAlarmSetting] =
    React.useState<DeviceAlarmSetting>();
  const [selectedRows, setSelectedRows] = React.useState<GridRowId[]>([]);
  const [inputValue, setInputValue] = React.useState(initialValue); // <-- Update
  const [searchTerm, setSearchTerm] = React.useState(initialValue);
  const auth = React.useContext(AuthContext);
  const [columnWidths, setColumnWidths] = React.useState<any>();
  const [errorMessage, setErrorMessage] = React.useState("");
  const { setLoading } = React.useContext(LoadingContext);

  const columns: GridColDef[] = [
    {
      field: "iothubDeviceId",
      headerName: headerName.iothubDeviceId,
      minWidth: calculateColumnWidth([headerName]).iothubDeviceId,
      width: columnWidths?.iothubDeviceId || 0,
    },
    {
      field: "sensorName",
      headerName: headerName.sensorName,
      minWidth: calculateColumnWidth([headerName]).sensorName,
      width: columnWidths?.sensorName || 0,
    },
    {
      field: "alarmName",
      headerName: headerName.alarmName,
      minWidth: calculateColumnWidth([headerName]).alarmName,
      width: columnWidths?.alarmName || 0,
    },
    {
      field: "equalitySign",
      headerName: headerName.equalitySign,
      minWidth: calculateColumnWidth([headerName]).equalitySign,
      width: columnWidths?.equalitySign || 0,
    },
    {
      field: "threshold",
      headerName: headerName.threshold,
      minWidth: calculateColumnWidth([headerName]).threshold,
      width: columnWidths?.threshold || 0,
    },
  ];

  const fetchData = React.useCallback(async () => {
    await checkJwt(auth.siteId);
    const records = await getDeviceAlarmSettings(
      auth.siteId,
      auth.userId,
      searchTerm
    );
    if (records) {
      const rows: GridDataRow[] = records.map((record: any, i: number) => {
        return {
          id: i + 1,
          ...record,
        };
      });
      setRows(rows);
      setColumnWidths(calculateColumnWidth(rows));
    }
  }, [searchTerm, auth.siteId, auth.userId]);

  const fetchDataWithLoading = React.useCallback(async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  }, [fetchData, setLoading]);

  React.useEffect(() => {
    fetchDataWithLoading();
  }, [fetchDataWithLoading]);

  const handleRowEditStart = (rowId: GridRowId) => {
    const row = rows.find((row) => row.id === rowId);
    if (row) {
      setEditingDeviceAlarmSetting(row);
      setOpenModal(true);
    }
  };

  const handleRowEditStop = () => {
    setEditingDeviceAlarmSetting(undefined);
    setOpenModal(false);
  };

  const handleAddRow = () => {
    setEditingDeviceAlarmSetting(undefined);
    setOpenModal(true);
  };

  const handleDeleteSelected = async (selectedRows: GridRowId[]) => {
    await checkJwt(auth.siteId);

    const RowsToDelete = selectedRows
      .map((rowId) => rows.find((row) => row.id === rowId))
      .filter((row): row is GridDataRow => row !== undefined);

    if (RowsToDelete.length === 0) {
      setErrorMessage(ErrorMessages.SelectionError);
      return;
    }

    const deleteTasks = RowsToDelete.map((row) =>
      deleteDeviceAlarmSetting(
        row.alarmId!,
        row.sensorId!,
        row.deviceId!,
        auth.siteId,
        auth.userId
      )
    );

    await Promise.all(deleteTasks);
    fetchDataWithLoading();
    setSelectedRows([]);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearchTerm(inputValue);
    }
  };

  return (
    <>
      <CustomMasterContainer>
        <Box sx={{ mb: 1 }}>
          <Typography variant="subtitle1" component="div">
            検索条件
          </Typography>
        </Box>
        <CustomSearchField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Box sx={gridContainerStyles}>
          <DataGrid
            rows={rows}
            columns={columns}
            onRowClick={(param) => handleRowEditStart(param.id)}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={selectedRows}
            onRowSelectionModelChange={(newSelection) => {
              setSelectedRows(newSelection);
            }}
            showColumnVerticalBorder
            slots={{
              toolbar: () => (
                <Toolbar>
                  <AddDeleteButton
                    AddButtonOnClick={() => handleAddRow()}
                    DeleteButtonOnClick={() =>
                      handleDeleteSelected(selectedRows)
                    }
                  />
                </Toolbar>
              ),
            }}
          />
        </Box>
      </CustomMasterContainer>
      <DeviceAlarmSettingModal
        open={openModal}
        onClose={handleRowEditStop}
        onUpdate={fetchDataWithLoading}
        editingDeviceAlarmSetting={
          editingDeviceAlarmSetting as DeviceAlarmSetting
        }
      />
      <CustomPageSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={() => setErrorMessage("")}
      />
    </>
  );
}

export default DeviceAlarmSettingPage;
