import axios from "axios";

const url = process.env.REACT_APP_API_URL + "/GetGraphPatternModal";

export async function getGraphPatternModal(userId: number, siteId: number) {
  try {
    const body = {
      userId: userId,
      siteId: siteId,
    };
    const response = await axios.post(url, body);
    return response.data;
  } catch (error) {
    return;
  } finally {
  }
}
