import { Grid, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { getUsersNotification } from "../api/GetUsersNotification";
import { UserNotification } from "../models/UserNotification";

import { AxiosError } from "axios";
import { getLogLevels } from "../api/GetLogLevels";
import { checkJwt } from "../api/TokenVerification";
import { updateAdminUser } from "../api/UpdateAdminUser";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { PageId } from "../constants/page";
import { AuthContext } from "../context/AuthContext";
import { User } from "../models/User";
import { LogLevel } from "../models/LogLevel";
import { CancelSaveButtons } from "./CancelSaveButtons";
import CustomModalSnackbar from "./CustomModalSnackbar";
import { MasterModal } from "./MasterModal";
import {
  yupEmail,
  yupLoginId,
  yupLoginPassword,
  yupNotificationStartTime,
  yupNotificationStopTime,
  yupPhoneNumber,
  yupUserName,
} from "./ValidationSchema";
import NotificationSetting from "./NotificationSetting";

interface AdminUserProps {
  open: boolean;
  onClose: () => void;
  title: string;
  editingAdminUser?: User;
}

const AdminUserSchema = yup.object({
  userName: yupUserName.required(),
  loginId: yupLoginId.required(),
  email: yupEmail.required(),
  phoneNumber: yupPhoneNumber,
  password: yupLoginPassword,
  notificationStartTime: yupNotificationStartTime.nullable(),
  notificationStopTime: yupNotificationStopTime
    .test(
      "both null/require",
      "通知開始時間／通知停止時間は片方のみの設定は出来ません",
      function (this: yup.TestContext, value: string | undefined | null) {
        if (!value) {
          return !this.parent.notificationStartTime;
        } else {
          return !!this.parent.notificationStartTime;
        }
      }
    )
    .test(
      "same time",
      "通知開始時間／通知停止時間は同じ時刻を設定出来ません",
      function (this: yup.TestContext, value: string | undefined | null) {
        if (!!value && value === this.parent.notificationStartTime) {
          return false;
        } else {
          return true;
        }
      }
    )
    .nullable(),
});
function UserNotificationSettingModal({
  open,
  onClose,
  title,
  editingAdminUser,
}: AdminUserProps) {
  const [errorMessage, setErrorMessage] = useState("");
  const auth = React.useContext(AuthContext);
  // Inside your component
  const [passwordInput, setPasswordInput] = useState("****");
  const [logLevels, setLogLevels] = useState<LogLevel[]>();
  const [notifications, setNotifications] = useState<UserNotification[]>();
  const handleFocus = () => {
    if (passwordInput === "****") {
      setPasswordInput("");
    }
  };

  const handleBlur = () => {
    if (passwordInput === "") {
      setPasswordInput("****");
    }
  };

  const fetchData = React.useCallback(async () => {
    await checkJwt(auth.siteId);
    setLogLevels(await getLogLevels(auth.siteId, auth.userId));
    setNotifications(
      await getUsersNotification(
        auth.siteId,
        auth.userId,
        editingAdminUser?.userId
      )
    );
  }, [auth.siteId, auth.userId, editingAdminUser]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [initialValues] = useState<User>({
    sendNotificationFlag: false,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingAdminUser || initialValues,
    validationSchema: AdminUserSchema,
    onSubmit: async () => {
      await checkJwt(auth.siteId);

      try {
        await updateAdminUser(
          formik.values,
          auth.siteId,
          auth.userId,
          notifications
            ?.filter((n) => n.isSelected)
            .map((n) => n.notificationId)
        );

        formik.resetForm();
        setPasswordInput("****"); // reset the password field
        setErrorMessage("");
        onClose();
        auth.setUser(formik.values);
      } catch (err) {
        const axiosError = err as AxiosError;
        const errNum = axiosError.response?.data;
        if (errNum === ErrorCodes.UniqueKey) {
          setErrorMessage("ログインIDが重複しています。");
        } else {
          setErrorMessage(ErrorMessages.UnknownError);
        }
      }
    },
  });

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleCancel = () => {
    formik.resetForm();
    setPasswordInput("****"); // reset the password field
    setErrorMessage("");
    onClose();
  };

  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            {/* Title */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {!!title
                  ? title
                  : auth.pages?.find((page) => page.id === PageId.AdminUserPage)
                      ?.name}
              </Typography>
            </Grid>
            {/* ユーザー名 */}
            <Grid item xs={12}>
              <TextField
                label="ユーザー名 *"
                name="userName"
                value={formik.values.userName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.userName)}
                helperText={formik.errors.userName}
                fullWidth
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="ログインID *"
                name="loginId"
                value={formik.values.loginId ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.loginId)}
                helperText={formik.errors.loginId}
                fullWidth
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="パスワード"
                type="password"
                name="password"
                value={passwordInput ?? ""}
                onChange={(event) => {
                  formik.handleChange(event);
                  setPasswordInput(event.target.value);
                }}
                error={Boolean(formik.errors.password)}
                helperText={formik.errors.password}
                fullWidth
                autoComplete="new-password"
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="メールアドレス *"
                name="email"
                value={formik.values.email ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.email)}
                helperText={formik.errors.email}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="電話番号 *"
                name="phoneNumber"
                value={formik.values.phoneNumber ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.phoneNumber)}
                helperText={formik.errors.phoneNumber}
                fullWidth
              />
            </Grid>
            <NotificationSetting
              logLevels={logLevels}
              notifications={notifications}
              setNotifications={setNotifications}
              formik={formik}
            ></NotificationSetting>

            <CancelSaveButtons handleCancel={handleCancel} />
          </Grid>
        </form>
      </MasterModal>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}
export default UserNotificationSettingModal;
