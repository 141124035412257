import {
  Grid,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { getDeviceTypes } from "../api/GetDeviceTypes";
import { updateDevice } from "../api/UpdateDevice";

import AddIcon from "@mui/icons-material/Add";

import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AxiosError } from "axios";
import ja from "date-fns/locale/ja";
import React from "react";
import { getLocations } from "../api/GetLocations";
import { insertDevice } from "../api/InsertDevice";
import { checkJwt } from "../api/TokenVerification";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { PageId } from "../constants/page";
import { AuthContext } from "../context/AuthContext";
import { Device } from "../models/Device";
import { DeviceType } from "../models/DeviceType";
import { Location } from "../models/Location";
import { CancelSaveButtons } from "./CancelSaveButtons";
import CustomAutoComplete from "./CustomAutoComplete";
import CustomModalSnackbar from "./CustomModalSnackbar";
import DeviceTypeModal from "./DeviceTypeModal";
import LocationModal from "./LocationModal";
import { MasterModal } from "./MasterModal";
import {
  yupCommunicationFailuresSecond,
  yupDataDeviceId,
  yupDeviceTypeId,
  yupIothubDeviceId,
  yupIothubName,
  yupMissingMarginSecond,
  yupPacCode,
  yupReceivingCycle,
  yupSensorCount,
  yupSerialNumber,
} from "./ValidationSchema";

interface DeviceModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  editingDevice?: Device;
}

const validationSchema = yup.object({
  deviceTypeId: yupDeviceTypeId.required(),
  iothubName: yupIothubName.required(),
  iothubDeviceId: yupIothubDeviceId.required(),
  dataDeviceId: yupDataDeviceId.nullable(),
  receivingCycle: yupReceivingCycle.nullable(),
  missingMarginSecond: yupMissingMarginSecond.nullable(),
  communicationFailuresSecond: yupCommunicationFailuresSecond.nullable(),
  serialNumber: yupSerialNumber.nullable(),
  pacCode: yupPacCode.nullable(),
  sensorCount: yupSensorCount.nullable(),
});

function DeviceModal({
  open,
  onClose,
  onUpdate,
  editingDevice,
}: DeviceModalProps) {
  const isUpdate = editingDevice && editingDevice.deviceId != null;
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>();
  const [openDeviceType, setOpenDeviceType] = React.useState<boolean>(false);
  const [locations, setLocations] = useState<Location[]>();
  const [openLocation, setOpenLocation] = React.useState<boolean>(false);
  const auth = React.useContext(AuthContext);

  const [errorMessage, setErrorMessage] = useState("");

  const fetchData = useCallback(async () => {
    await checkJwt(auth.siteId);
    getDeviceTypes(auth.siteId, auth.userId).then(setDeviceTypes);
    getLocations(auth.siteId, auth.userId).then(setLocations);
  }, [auth.siteId, auth.userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [initialValues] = useState<Device>({
    state: true,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingDevice || initialValues,
    validationSchema: validationSchema,
    onSubmit: async () => {
      await checkJwt(auth.siteId);

      try {
        if (isUpdate) {
          await updateDevice(
            formik.values,
            editingDevice.state,
            auth.siteId,
            auth.userId
          );
        } else {
          await insertDevice(formik.values, auth.siteId, auth.userId);
        }
        formik.resetForm();
        setErrorMessage("");
        onUpdate();
        onClose();
      } catch (err) {
        const axiosError = err as AxiosError;
        const errNum = axiosError.response?.data;
        if (errNum === ErrorCodes.UniqueKey) {
          setErrorMessage(
            "iothub名、iothubデバイス名、データデバイス名が重複しています。"
          );
        } else if (errNum === ErrorCodes.InvalidParamKey) {
          setErrorMessage(ErrorMessages.InvalidParamError);
        } else {
          setErrorMessage(ErrorMessages.UnknownError);
        }
      }
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    setErrorMessage("");
    onClose();
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const setUpDateChange = (newValue: Date | null) => {
    if (
      formik.values.removalDate &&
      newValue &&
      formik.values.removalDate < newValue
    ) {
      formik.setFieldValue("removalDate", newValue);
    }
    formik.setFieldValue("setUpDate", newValue || null);
  };

  const removalDateChange = (newValue: Date | null) => {
    if (
      formik.values.setUpDate &&
      newValue &&
      formik.values.setUpDate > newValue
    ) {
      formik.setFieldValue("setUpDate", newValue);
    }
    formik.setFieldValue("removalDate", newValue || null);
  };

  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {
                  auth.pages?.find((page) => page.id === PageId.DevicePage)
                    ?.name
                }
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="デバイス種別 *"
                  name="deviceTypeId"
                  error={formik.errors.deviceTypeId}
                  value={formik.values.deviceTypeId}
                  onChange={formik.handleChange}
                  arrangement={deviceTypes}
                  format={(deviceType) =>
                    `${deviceType.deviceName} ${deviceType.makerName}`
                  }
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton
                  onClick={() => setOpenDeviceType(true)}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            {/* IotHub Name */}
            <Grid item xs={12}>
              <TextField
                label="IotHub名"
                name="iothubName"
                value={formik.values.iothubName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.iothubName)}
                helperText={formik.errors.iothubName}
                fullWidth
              />
            </Grid>

            {/* IothubデバイスID */}
            <Grid item xs={12}>
              <TextField
                label="IothubデバイスID"
                name="iothubDeviceId"
                value={formik.values.iothubDeviceId ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.iothubDeviceId)}
                helperText={formik.errors.iothubDeviceId}
                fullWidth
              />
            </Grid>

            {/* データデバイス名 */}
            <Grid item xs={12}>
              <TextField
                label="データデバイス名"
                name="dataDeviceId"
                value={formik.values.dataDeviceId ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.dataDeviceId)}
                helperText={formik.errors.dataDeviceId}
                fullWidth
              />
            </Grid>

            {/* 受信周期（秒） */}
            <Grid item xs={12}>
              <TextField
                label="受信周期（秒）"
                name="receivingCycle"
                type="number"
                value={formik.values.receivingCycle ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.receivingCycle)}
                helperText={formik.errors.receivingCycle}
                fullWidth
              />
            </Grid>

            {/* 欠測余白時間（秒） */}
            <Grid item xs={12}>
              <TextField
                label="欠測余白時間（秒）"
                name="missingMarginSecond"
                type="number"
                value={formik.values.missingMarginSecond ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.missingMarginSecond)}
                helperText={formik.errors.missingMarginSecond}
                fullWidth
              />
            </Grid>

            {/* 通信障害判定時間（秒） */}
            <Grid item xs={12}>
              <TextField
                label="通信障害判定時間（秒）"
                name="communicationFailuresSecond"
                type="number"
                value={formik.values.communicationFailuresSecond ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.communicationFailuresSecond)}
                helperText={formik.errors.communicationFailuresSecond}
                fullWidth
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="設置場所"
                  name="locationId"
                  error={formik.errors.locationId}
                  value={formik.values.locationId}
                  onChange={formik.handleChange}
                  arrangement={locations}
                  format={(location) => `${location.locationName}`}
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton
                  onClick={() => setOpenLocation(true)}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="製造番号"
                name="serialNumber"
                value={formik.values.serialNumber ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.serialNumber)}
                helperText={formik.errors.serialNumber}
                fullWidth
              />
            </Grid>

            {/* PACコード */}
            <Grid item xs={12}>
              <TextField
                label="PACコード"
                name="pacCode"
                value={formik.values.pacCode ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.pacCode)}
                helperText={formik.errors.pacCode}
                fullWidth
              />
            </Grid>

            {/* 収容センサー台数 */}
            <Grid item xs={12}>
              <TextField
                label="収容センサー台数"
                type="number"
                name="sensorCount"
                value={formik.values.sensorCount ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.sensorCount)}
                helperText={formik.errors.sensorCount}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ja}
              >
                <MobileDatePicker
                  label="設置日"
                  disabled={false}
                  value={formik.values.setUpDate}
                  onChange={setUpDateChange}
                  openTo="year"
                  format="yyyy/MM/dd"
                  views={["year", "month", "day"]}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ja}
              >
                <MobileDatePicker
                  label="撤去日"
                  disabled={false}
                  value={formik.values.removalDate}
                  onChange={removalDateChange}
                  openTo="year"
                  format="yyyy/MM/dd"
                  views={["year", "month", "day"]}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <Grid item>
                <Typography variant="subtitle1">状態</Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={formik.values.state}
                  exclusive
                  onChange={(
                    _event: React.MouseEvent<HTMLElement>,
                    newValue: boolean | null
                  ) => {
                    if (newValue !== null) {
                      formik.setFieldValue("state", newValue);
                    }
                  }}
                >
                  <ToggleButton value={true}>有効</ToggleButton>
                  <ToggleButton value={false}>無効</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>

            <CancelSaveButtons handleCancel={handleCancel} />
            <LocationModal
              open={openLocation}
              onClose={() => setOpenLocation(false)}
              onUpdate={fetchData}
            />
            <DeviceTypeModal
              open={openDeviceType}
              onClose={() => setOpenDeviceType(false)}
              onUpdate={fetchData}
              maxItemKeys={
                deviceTypes &&
                Math.max(...deviceTypes.map((row) => Number(row.deviceTypeId)))
              }
            />
          </Grid>
        </form>
      </MasterModal>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}
export default DeviceModal;
