import { Grid, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { insertDirectMethod } from "../api/InsertDirectMethod";
import { checkJwt } from "../api/TokenVerification";
import { updateDirectMethod } from "../api/UpdateDirectMethod";
import { AuthContext } from "../context/AuthContext";
import DirectMethod from "../models/DirectMethod";
import { CancelSaveButtons } from "./CancelSaveButtons";
import { componentSpacing } from "./CustomStyle";
import { MasterModal } from "./MasterModal";
import { yupLatitude, yupLocationName, yupLongitude } from "./ValidationSchema";

interface Props {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  editingDirectMethod?: DirectMethod;
}

const DirectMethodModalValidationSchema = yup.object().shape({
  locationName: yupLocationName.required(),
  latitude: yupLatitude,
  longitude: yupLongitude,
});

function DirectMethodModal({
  open,
  onClose,
  onUpdate,
  editingDirectMethod,
}: Props) {
  const isUpdate =
    editingDirectMethod && editingDirectMethod.directMethodId != null;

  const [initialValues] = useState<DirectMethod>({
    directMethodId: null,
    directMethodName: null,
  });
  const auth = React.useContext(AuthContext);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingDirectMethod || initialValues,
    validationSchema: DirectMethodModalValidationSchema,
    onSubmit: async () => {
      await checkJwt(auth.siteId);
      if (isUpdate) {
        await updateDirectMethod(formik.values, auth.siteId, auth.userId);
      } else {
        await insertDirectMethod(formik.values, auth.siteId, auth.userId);
      }
      formik.resetForm();
      onUpdate();
      onClose();
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <MasterModal open={open}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              ダイレクトメソッド
            </Typography>
          </Grid>

          <Grid item xs={12} sx={componentSpacing}>
            <TextField
              label="ダイレクトメソッド *"
              name="directMethodName"
              value={formik.values.directMethodName || ""}
              onChange={formik.handleChange}
              error={
                formik.touched.directMethodName &&
                Boolean(formik.errors.directMethodName)
              }
              helperText={
                formik.touched.directMethodName
                  ? formik.errors.directMethodName
                  : ""
              }
              fullWidth
            />
          </Grid>

          <CancelSaveButtons handleCancel={handleCancel} />
        </Grid>
      </form>
    </MasterModal>
  );
}
export default DirectMethodModal;
