import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Leaflet, { LatLng, Point } from "leaflet";
import "leaflet/dist/leaflet.css";
import _ from "lodash";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  TileLayerProps,
  useMap,
} from "react-leaflet";
import "../App.css";
import { getControlRecords } from "../api/GetControlRecords";
import { getImages } from "../api/GetImages";
import { getMapLocations } from "../api/GetMapLocations";
import { getMapSiteLocation } from "../api/GetMapSiteLocation";
import { getObserveRecords } from "../api/GetObserveRecords";
import { checkJwt } from "../api/TokenVerification";
import { AuthContext } from "../context/AuthContext";
import { ControlRecord } from "../models/ControlRecord";
import { Image } from "../models/Image";
import { MapLocation } from "../models/MapLocation";
import { MapSiteLocation } from "../models/MapSiteLocation";
import { ObserveRecord } from "../models/ObserveRecord";
import { sortRecord } from "../share/sort";
import ControlCard from "./ControlCard";
import ObserveCard from "./ObserveCard";
import LoadingContext from "../context/LoadingContext";

let isChangedPosition = false;

interface SetCenterButtonProps {
  position: LatLng;
  zoom?: number;
}

function SetCenter(props: SetCenterButtonProps) {
  const map = useMap();
  if (isChangedPosition) {
    map.setView(props.position, props.zoom);
    isChangedPosition = false;
  }
  return null;
}

function SetCenterButton(props: SetCenterButtonProps) {
  const map = useMap();
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => map.setView(props.position, props.zoom)}
    >
      初期位置
    </Button>
  );
}

// パレットで使用可能なトークン、モード
type paletteToken =
  | "error"
  | "info"
  | "observedAbnormal"
  | "primary"
  | "secondary"
  | "success"
  | "warning";
type paletteMode = "dark" | "light" | "main";

const MapPage = () => {
  const auth = useContext(AuthContext);
  const { setLoading } = useContext(LoadingContext);
  const [initializing, setInitializing] = useState(true);
  const [siteLocation, setSiteLocation] = useState<MapSiteLocation>();
  const [locations, setLocations] = useState<MapLocation[]>([]);
  const position = new LatLng(35.6585805, 139.742858); // 初期値：東京タワー
  const normalUrl: TileLayerProps = {
    // 通常地図
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    opacity: 1,
  };

  const aerialPhotographUrl: TileLayerProps = {
    // 航空写真
    url: "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg",
    attribution:
      '&copy; <a href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a> contributors',
    opacity: 1,
  };
  const minZoom = 6;
  const [zoom] = useState<number>(18);
  const [records, setRecords] = useState<(ObserveRecord | ControlRecord)[]>([]);
  const [images, setImages] = useState<Image[]>();
  const [tileLayerUrl, setTileLayerUrl] = useState<TileLayerProps>(normalUrl);

  const theme = useTheme();
  const normalPinColor = theme.palette["success"]["main"]; // 正常時のピン色
  const html = (size: number, color: string) => {
    return `<span class="material-icons" style="color: ${color}; font-size: ${size}px; text-shadow: 0px 0px 2px black, 0px 0px 2px black, 0px 0px 2px black;">location_on</span>`;
  };
  const customIcon = (color: string | undefined, size: number) => {
    return Leaflet.divIcon({
      className: "",
      html: html(
        size,
        color
          ? theme.palette[color?.split(".")[0] as paletteToken][
              color?.split(".")[1] as paletteMode
            ]
          : normalPinColor
      ),
      iconAnchor: [size / 2, size],
      popupAnchor: [0, (-size * 4) / 5],
    });
  };

  const setPosition = (siteLocation: MapSiteLocation | undefined) => {
    return !!siteLocation &&
      !!siteLocation.siteLatitude &&
      !!siteLocation.siteLongitude
      ? new LatLng(siteLocation.siteLatitude, siteLocation.siteLongitude)
      : position;
  };

  const setZoom = (siteLocation: MapSiteLocation | undefined) => {
    return !!siteLocation && !!siteLocation.zoom ? siteLocation.zoom : zoom;
  };

  const fetchImages = useCallback(async () => {
    setImages(await getImages(auth.siteId, auth.userId));
  }, [auth.siteId, auth.userId]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  const fetchRecords = useCallback(async () => {
    if (!images) {
      return;
    }

    await checkJwt(auth.siteId);
    const records: any[] = [];
    await Promise.all([
      getObserveRecords(auth.userId, auth.siteId).then((observeRecords) => {
        records.push(...observeRecords);
      }),
      getControlRecords(auth.userId, auth.siteId).then((controlRecords) => {
        records.push(...controlRecords);
      }),
    ]);
    records.forEach((record) => {
      const image = images?.find(
        (image) =>
          image.receiveDataTypeId === record.receiveDataTypeId ||
          image.controlTypeId === record.controlTypeId
      );
      if (image) {
        record.image = image.image;
      }
    });
    setRecords(records.sort(sortRecord));

    // ピン色変更
    getMapLocations(auth.userId, auth.siteId).then(
      (locations: MapLocation[]) => {
        if (locations) {
          setLocations(locations);
        }
      }
    );
    setLoading(false);
    setInitializing(false);
  }, [auth.siteId, auth.userId, images]);

  useEffect(() => {
    setLoading(true);
    getMapSiteLocation(auth.userId, auth.siteId).then((newSiteLocation) => {
      if (newSiteLocation) {
        if (!_.isEqual(newSiteLocation, siteLocation)) {
          isChangedPosition = true;
          setSiteLocation(newSiteLocation);
        }
      }
    });
  }, [auth.userId, auth.siteId, siteLocation]);

  useEffect(() => {
    fetchRecords();

    const timer = setInterval(fetchRecords, 5000);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRecords]);

  const changeTileLayerUrl = () => {
    if (_.isEqual(tileLayerUrl, normalUrl)) {
      setTileLayerUrl(aerialPhotographUrl);
    } else {
      setTileLayerUrl(normalUrl);
    }
  };

  if (initializing) {
    return <></>;
  }
  return (
    <Box height="calc(100vh - 65px)" sx={{ overflow: "hidden" }}>
      <MapContainer id="map" center={position} minZoom={minZoom} zoom={zoom}>
        <>
          <TileLayer
            attribution={tileLayerUrl.attribution}
            url={tileLayerUrl.url}
            opacity={tileLayerUrl.opacity}
          />

          <div className="leaflet-top leaflet-right">
            <div className="leaflet-control leaflet-bar">
              {/* 画面描画直後の初期位置指定 */}
              <SetCenter
                position={setPosition(siteLocation)}
                zoom={setZoom(siteLocation)}
              ></SetCenter>

              {/* 初期位置ボタン */}
              <SetCenterButton
                position={setPosition(siteLocation)}
                zoom={setZoom(siteLocation)}
              ></SetCenterButton>
              {/* 地図切替ボタン */}
              <Button
                variant="contained"
                color="primary"
                onClick={changeTileLayerUrl}
              >
                地図切替
              </Button>
            </div>
          </div>

          {locations
            .filter((location) => location.latitude && location.longitude)
            .map((location) => {
              return (
                <Marker
                  key={location.locationId}
                  position={[
                    Number(location.latitude),
                    Number(location.longitude),
                  ]}
                  icon={customIcon(location.palette, 60)}
                >
                  <Popup offset={new Point(0, -10)}>
                    <Box
                      sx={{
                        overflow: "auto",
                        height: "270px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {records
                          .filter(
                            (record) =>
                              record.locationId === location.locationId
                          )
                          .map((record) => {
                            if (
                              (record as ObserveRecord).receiveDataTypeName !==
                              undefined
                            ) {
                              const observedRecord = record as ObserveRecord;
                              return (
                                <ObserveCard
                                  {...observedRecord}
                                  key={`map-observed-card-${observedRecord.sensorId}`}
                                />
                              );
                            } else if (
                              (record as ControlRecord).controlTypeName !==
                              undefined
                            ) {
                              const controlRecord = record as ControlRecord;
                              return (
                                <ControlCard
                                  controlRecord={controlRecord}
                                  fetchRecords={fetchRecords}
                                  key={`map-control-card-${controlRecord.controlId}`}
                                />
                              );
                            } else {
                              return <></>;
                            }
                          })}
                      </Box>
                    </Box>
                  </Popup>
                </Marker>
              );
            })}
        </>
      </MapContainer>
    </Box>
  );
};

export default MapPage;
