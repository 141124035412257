import {
  Box,
  Button,
  Fade,
  Grid,
  IconButton,
  Modal,
  Slider,
  Stack,
  useMediaQuery,
} from "@mui/material";

import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Colors,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Tooltip,
  registerables,
} from "chart.js";

import "chartjs-adapter-luxon";
import Annotation from "chartjs-plugin-annotation";
import zoomPlugin from "chartjs-plugin-zoom";
import Encoding from "encoding-japanese";
import Papa from "papaparse";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Chart } from "react-chartjs-2";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { getGraphCSV } from "../api/GetGraphCSV";
import { getGraphRecords } from "../api/GetGraphRecords";
import { checkJwt } from "../api/TokenVerification";
import { AuthContext } from "../context/AuthContext";
import LoadingContext from "../context/LoadingContext";
import { GraphPattern } from "../models/GraphPattern";
import { YAxis } from "../models/YAxis";
import CustomContainer from "./CustomContainer";
import DateTimePickers from "./DateTimePickers";
import { GraphPatternModal } from "./GraphPatternModal";
import GraphPatternSelect from "./GraphPatternSelect";

import DateRangeIcon from "@mui/icons-material/DateRange";
import EditIcon from "@mui/icons-material/Edit";
import ExpandIcon from "@mui/icons-material/Expand";
import GetAppIcon from "@mui/icons-material/GetApp";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useLocation } from "react-router-dom";
import Dataset from "../models/Dataset";

ChartJS.register(
  ...registerables,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  TimeScale,
  Annotation,
  zoomPlugin,
  Colors,
);

function GraphPage() {
  const auth = useContext(AuthContext);
  const { setLoading, setCsvDownloading } = useContext(LoadingContext);
  const location = useLocation();
  const defaultSensorId = Number(
    new URLSearchParams(location.search).get("id"),
  );

  const now = new Date();
  now.setMinutes(0, 0, 0);

  const maxSelectablePeriod = new Date(now);
  maxSelectablePeriod.setDate(maxSelectablePeriod.getDate() + 2);
  maxSelectablePeriod.setHours(maxSelectablePeriod.getHours() + 1);

  const nextHour = new Date(now);
  nextHour.setHours(nextHour.getHours() + 1);

  const [timeTo, setTimeTo] = useState<Date | null>(nextHour);

  const dayBefore = new Date(nextHour);
  dayBefore.setDate(dayBefore.getDate() - 1);
  const [timeFrom, setTimeFrom] = useState<Date | null>(dayBefore);

  const twoYearsAgo = new Date(nextHour);
  twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);

  const [datasets, setDatasets] = useState<Array<Dataset>>([]);
  const [annotations, setAnnotations] = useState<any[]>();
  const [pattern, setPattern] = useState<GraphPattern | null>(
    defaultSensorId
      ? {
          graphPatternName: "",
          sensorIds: [defaultSensorId],
        }
      : null,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalSubmit = () => {
    setIsModalOpen(false);
  };

  const [yAxis, setYAxis] = useState<YAxis[]>([]);
  const { isCsvDownloading } = useContext(LoadingContext);
  const isSmallWidth = useMediaQuery("(max-width: 1100px)");
  const [isVisibleAnnotation, setVisibleAnnotation] = useState<boolean>(true);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fetchGraph = useCallback(async () => {
    if (timeFrom === null || timeTo === null) {
      return;
    }

    handleShowAll();
    setLoading(true);

    const graphRecords = await getGraphRecords(
      auth.userId,
      auth.siteId,
      pattern,
      timeFrom,
      timeTo,
    );

    setYAxis(graphRecords.yAxis);
    setDatasets(graphRecords.datasets);
    setAnnotations(graphRecords.annotations);

    setLoading(false);
  }, [auth.siteId, auth.userId, pattern, timeFrom, timeTo, setLoading]);

  useEffect(() => {
    checkJwt(auth.siteId).then(fetchGraph);
  }, [auth.siteId, fetchGraph]);

  const chartData = useMemo(
    () => ({
      datasets,
    }),

    [datasets],
  );

  const yAxisOptions = useMemo(() => {
    return Object.fromEntries(
      yAxis.map((item: YAxis) => {
        const data = datasets.filter((d) => d.yAxisID === item.yAxisID);
        const values: number[] = [];
        data.forEach((d) =>
          d.data.forEach((v) => {
            if (!!v.y) {
              values.push(v.y);
            }
          }),
        );
        annotations?.forEach((annotation) => {
          values.push(annotation.yMin);
          values.push(annotation.yMax);
        });
        if (item.ratingMinDisplayValue) {
          values.push(item.ratingMinDisplayValue);
        }
        if (item.ratingMaxDisplayValue) {
          values.push(item.ratingMaxDisplayValue);
        }

        const min = Math.min(...values.filter((value) => !isNaN(value)));
        const max = Math.max(...values.filter((value) => !isNaN(value)));
        const range = max - min;

        return [
          item.yAxisID,
          {
            min: min - (range === 0 ? 1 : range) * 0.1,
            max: max + (range === 0 ? 1 : range) * 0.1,
            title: {
              display: true,
              text: `${item.receiveDataType}`,
            },
          },
        ];
      }),
    );
  }, [datasets, yAxis, annotations]);

  const options: ChartOptions<"scatter" | "bar"> = useMemo(
    () => ({
      maintainAspectRatio: false,
      showLine: true,
      animation: false,
      scales: {
        x: {
          type: "time",
          time: {
            displayFormats: {
              hour: "d日 H時",
            },
            minUnit: "hour",
          },
          min: timeFrom?.getTime(),
          max: timeTo?.getTime(),
        },
        ...yAxisOptions,
      },

      plugins: {
        colors: {
          forceOverride: true,
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "x",
            modifierKey: "ctrl",
          },

          zoom: {
            drag: {
              enabled: true,
              borderColor: "rgb(54, 162, 235)",
              borderWidth: 1,
              backgroundColor: "rgba(54, 162, 235, 0.3)",
            },
            mode: "x",

            onZoomStart: function ({ chart, event }) {
              const canvasPosition = chart.canvas.getBoundingClientRect();
              if (
                event instanceof MouseEvent &&
                event.clientY - canvasPosition.top < chart.chartArea.top
              ) {
                return false;
              }
            },
            onZoomComplete: ({ chart }) => {
              if (timeFrom && timeTo) {
                const xScale = chart.scales["x"];
                const minPercent =
                  (((xScale.min as number) - timeFrom.getTime()) /
                    (timeTo.getTime() - timeFrom.getTime())) *
                  100;
                const maxPercent =
                  (((xScale.max as number) - timeFrom.getTime()) /
                    (timeTo.getTime() - timeFrom.getTime())) *
                  100;
                setSliderValues([minPercent, maxPercent]);
              }
            },
          },
        },
        annotation: {
          annotations:
            isVisibleAnnotation && datasets.length > 0 ? annotations : [],
        },
      },
    }),

    [
      timeFrom,
      timeTo,
      datasets,
      yAxisOptions,
      annotations,
      isVisibleAnnotation,
    ],
  );

  const chartRef =
    useRef<
      ChartJSOrUndefined<
        "scatter" | "bar",
        { x: Date; y: number | null }[],
        Date
      >
    >(null);

  const [sliderValues, setSliderValues] = useState([0, 100]);

  useEffect(() => {
    if (
      chartRef &&
      Array.isArray(sliderValues) &&
      chartRef.current?.options.scales &&
      timeFrom &&
      timeTo
    ) {
      const displayRangeStart =
        timeFrom.getTime() +
        ((timeTo.getTime() - timeFrom.getTime()) * sliderValues[0]) / 100;

      const displayRangeEnd =
        timeFrom.getTime() +
        ((timeTo.getTime() - timeFrom.getTime()) * sliderValues[1]) / 100;

      chartRef.current.zoomScale(
        "x",
        { min: displayRangeStart, max: displayRangeEnd },
        "default",
      );
    }
  }, [sliderValues, timeFrom, timeTo, isVisibleAnnotation]);

  const handleShowAll = () => {
    setSliderValues([0, 100]);
  };

  async function downloadCsv(
    siteId: number,
    userId: number,
    pattern: GraphPattern | null,
    timeFrom: Date | null,
    timeTo: Date | null,
  ) {
    const getFileName = () => {
      const filename = window.prompt(
        "CSVファイル名を入力してください。",
        "graphData.csv",
      );

      return filename;
    };

    const fileName = getFileName();

    if (!fileName) {
      return;
    }
    setCsvDownloading(true);
    const csvContent = await getGraphCSV(
      userId,
      siteId,
      true,
      pattern,
      timeFrom,
      timeTo,
    );
    const csvString = Papa.unparse(csvContent);

    // BOMをcsvStringの先頭に追加します
    const csvStringWithBOM = csvString;
    // Convert csvStringWithBOM to Shift_JIS using Encoding.js
    const shiftJisArray = Encoding.convert(csvStringWithBOM, {
      to: "SJIS",
      from: "UNICODE",
      type: "array",
    });

    // Create blob from Shift_JIS array
    const csvBlob = new Blob([new Uint8Array(shiftJisArray)], {
      type: "text/csv;charset=shift_jis;",
    });
    const csvURL = URL.createObjectURL(csvBlob);
    const tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", fileName);
    tempLink.click();

    setCsvDownloading(false);
  }

  return (
    <CustomContainer>
      <Grid
        sx={{
          height: "calc(100vh - 240px)",
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs>
            <Stack direction={"row"} spacing={1} alignItems="center">
              <GraphPatternSelect
                isModalOpen={isModalOpen}
                selected={pattern}
                setSelected={setPattern}
              />

              {!isSmallWidth && (
                <Button
                  variant="contained"
                  onClick={() => setIsModalOpen(true)}
                  sx={{ whiteSpace: "nowrap" }}
                >
                  グラフ選択
                </Button>
              )}

              <GraphPatternModal
                isOpen={isModalOpen}
                onClose={handleModalSubmit}
                pattern={pattern}
                setPattern={setPattern}
              />
              {!isSmallWidth && (
                <Button
                  variant="contained"
                  onClick={() => setVisibleAnnotation(!isVisibleAnnotation)}
                  disabled={!annotations || annotations.length === 0}
                >
                  閾値ライン表示
                </Button>
              )}
              {isSmallWidth && (
                <Stack direction="row">
                  <IconButton onClick={() => setIsModalOpen(true)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => setVisibleAnnotation(!isVisibleAnnotation)}
                    disabled={!annotations || annotations.length === 0}
                  >
                    <LinearScaleIcon />
                  </IconButton>
                  <IconButton onClick={handleOpen}>
                    <DateRangeIcon />
                  </IconButton>
                  <IconButton onClick={fetchGraph}>
                    <RestartAltIcon />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      downloadCsv(
                        auth.siteId,
                        auth.userId,
                        pattern,
                        timeFrom,
                        timeTo,
                      )
                    }
                  >
                    <GetAppIcon />
                  </IconButton>
                </Stack>
              )}
            </Stack>
          </Grid>

          <Grid item xs>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              {!isSmallWidth && (
                <DateTimePickers
                  format={"yyyy年MM月dd日 HH時"}
                  disabled={false}
                  views={["year", "month", "day", "hours"]}
                  openTo={"year"}
                  timeFrom={timeFrom}
                  timeTo={timeTo}
                  setTimeFrom={setTimeFrom}
                  setTimeTo={setTimeTo}
                  minSelectablePeriod={twoYearsAgo}
                  maxSelectablePeriod={maxSelectablePeriod}
                />
              )}

              {!isSmallWidth && (
                <>
                  <Button
                    variant="contained"
                    onClick={fetchGraph}
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    表示更新
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ whiteSpace: "nowrap", width: 70 }}
                    onClick={() =>
                      downloadCsv(
                        auth.siteId,
                        auth.userId,
                        pattern,
                        timeFrom,
                        timeTo,
                      )
                    }
                    disabled={isCsvDownloading}
                  >
                    保存
                  </Button>
                </>
              )}
            </Stack>
          </Grid>
        </Grid>

        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            sx={{ ml: 2 }}
            justifyContent="center"
            alignItems="center"
          >
            <Slider
              value={sliderValues}
              onChange={(_event, value) => {
                setSliderValues(value as number[]);
              }}
              valueLabelDisplay="auto"
            />
            <IconButton
              sx={{ ml: 1, transform: "rotate(90deg)" }}
              onClick={() => {
                handleShowAll();
              }}
            >
              <ExpandIcon fontSize="large" color="primary" />
            </IconButton>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <Chart
            type="scatter"
            ref={chartRef}
            data={chartData}
            options={options}
          />
        </Box>
      </Grid>
      <Modal open={open} onClose={handleClose} closeAfterTransition>
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <DateTimePickers
              format={"yyyy年MM月dd日 HH時"}
              disabled={false}
              views={["year", "month", "day", "hours"]}
              openTo={"year"}
              timeFrom={timeFrom}
              timeTo={timeTo}
              setTimeFrom={setTimeFrom}
              setTimeTo={setTimeTo}
              minSelectablePeriod={twoYearsAgo} // 選択可能期間の最小値を2023年1月1日に指定
              maxSelectablePeriod={nextHour} //現在時刻+1の時刻を設定
            />
          </Box>
        </Fade>
      </Modal>
    </CustomContainer>
  );
}

export default React.memo(GraphPage);
