import axios, { AxiosError } from "axios";
import { Payload } from "../models/Payload";

const url = process.env.REACT_APP_API_URL + "/InsertPayload";

export async function insertPayload(
  payload: Payload,
  siteId: number,
  userId: number
) {
  try {
    const body = {
      payload,
      siteId,
      userId,
    };
    const response = await axios.post(url, body);
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response && axiosError.response.status === 409) {
      throw new Error("Unique key violation");
    }
  } finally {
  }
}
