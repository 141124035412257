import axios from "axios";
import Control from "../models/Control";

const url = process.env.REACT_APP_API_URL + "/UpdateControl";

async function updateControl(control: Control, userId: number, siteId: number) {
  const body = {
    control,
    userId,
    siteId,
  };
  const response = await axios.post(url, body);
  return response.data;
}

export default updateControl;
