import { Grid, TextField, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { insertColor } from "../api/InsertColor";
import { checkJwt } from "../api/TokenVerification";
import { updateColor } from "../api/UpdateColor";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { PageId } from "../constants/page";
import { AuthContext } from "../context/AuthContext";
import { Color } from "../models/Color";
import { CancelSaveButtons } from "./CancelSaveButtons";
import CustomModalSnackbar from "./CustomModalSnackbar";
import { componentSpacing } from "./CustomStyle";
import { MasterModal } from "./MasterModal";
import { yupPalette, yupPriority } from "./ValidationSchema";

interface ColorModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  editingColor?: Color;
}

function ColorModal({
  open,
  onClose,
  onUpdate,
  editingColor,
}: ColorModalProps) {
  const isUpdate = editingColor && editingColor.colorId != null;
  const auth = React.useContext(AuthContext);

  const [initialValues] = useState<Color>({});
  const [errorMessage, setErrorMessage] = useState("");

  const ColorValidationSchema = yup.object({
    palette: yupPalette.required(),
    priority: yupPriority.required(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingColor || initialValues,
    validationSchema: ColorValidationSchema,
    onSubmit: async () => {
      await checkJwt(auth.siteId);
      try {
        if (isUpdate) {
          await updateColor(formik.values, auth.siteId, auth.userId);
        } else {
          await insertColor(formik.values, auth.siteId, auth.userId);
        }
        formik.resetForm();
        setErrorMessage("");
        onUpdate();
        onClose();
      } catch (err) {
        const axiosError = err as AxiosError;
        const errNum = axiosError.response?.data;
        if (errNum === ErrorCodes.UniqueKey) {
          setErrorMessage("配色パターンまたは優先順位が重複しています。");
        } else {
          setErrorMessage(ErrorMessages.UnknownError);
        }
      }
    },
  });

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleCancel = () => {
    formik.resetForm();
    setErrorMessage("");
    onClose();
  };

  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            {/* Title */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {auth.pages?.find((page) => page.id === PageId.ColorPage)?.name}
              </Typography>
            </Grid>
            {/* Palette */}
            <Grid item xs={12} sx={componentSpacing}>
              <TextField
                label="配色パターン *"
                name="palette"
                value={formik.values.palette ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.palette)}
                helperText={formik.errors.palette}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={componentSpacing}>
              <TextField
                label="優先順位 *"
                name="priority"
                type="number"
                value={formik.values.priority ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.priority)}
                helperText={formik.errors.priority}
                fullWidth
              />
            </Grid>
            <CancelSaveButtons handleCancel={handleCancel} />
          </Grid>
        </form>
      </MasterModal>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}
export default ColorModal;
