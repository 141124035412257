import AddIcon from "@mui/icons-material/Add";
import {
  Grid,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { getFans } from "../api/GetFans";
import { insertDeviceType } from "../api/InsertDeviceType";
import { checkJwt } from "../api/TokenVerification";
import { updateDeviceType } from "../api/UpdateDeviceType";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { PageId } from "../constants/page";
import { AuthContext } from "../context/AuthContext";
import { DeviceType } from "../models/DeviceType";
import { Fan } from "../models/Fan";
import { CancelSaveButtons } from "./CancelSaveButtons";
import CustomAutoComplete from "./CustomAutoComplete";
import CustomModalSnackbar from "./CustomModalSnackbar";
import FanModal from "./FanModal";
import { MasterModal } from "./MasterModal";
import {
  yupDeviceName,
  yupDeviceType,
  yupFanId,
  yupMakerName,
  yupSensorCapacity,
  yupTerminalModel,
} from "./ValidationSchema";

interface DeviceModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  editingDeviceType?: DeviceType;
  maxItemKeys?: number;
}

const DeviceTypeValidationSchema = yup.object().shape({
  fanId: yupFanId.required(),
  deviceName: yupDeviceName.required(),
  deviceType: yupDeviceType.nullable(),
  makerName: yupMakerName.nullable(),
  terminalModel: yupTerminalModel.nullable(),
  sensorCapacity: yupSensorCapacity.nullable(),
});

function DeviceTypeModal({
  open,
  onClose,
  onUpdate,
  editingDeviceType,
}: DeviceModalProps) {
  const isUpdate = editingDeviceType && editingDeviceType.deviceTypeId != null;
  const [fans, setFan] = useState<Fan[]>();
  const [openFan, setOpenFan] = React.useState<boolean>(false);
  const auth = React.useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");

  const [initialValues] = useState<DeviceType>({
    hasExternalSensor: true,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingDeviceType || initialValues,
    validationSchema: DeviceTypeValidationSchema,
    onSubmit: async () => {
      await checkJwt(auth.siteId);

      try {
        if (isUpdate) {
          await updateDeviceType(formik.values, auth.siteId, auth.userId);
        } else {
          await insertDeviceType(formik.values, auth.siteId, auth.userId);
        }
        formik.resetForm();
        setErrorMessage("");
        onUpdate();
        onClose();
      } catch (err) {
        const axiosError = err as AxiosError;
        const errNum = axiosError.response?.data;
        if (errNum === ErrorCodes.UniqueKey) {
          setErrorMessage(
            "FAN方式ID、デバイス名、装置タイプ、メーカー名、型式が重複しています。"
          );
        } else if (errNum === ErrorCodes.InvalidParamKey) {
          setErrorMessage(ErrorMessages.InvalidParamError);
        } else {
          setErrorMessage(ErrorMessages.UnknownError);
        }
      }
    },
  });

  const fetchData = useCallback(async () => {
    await checkJwt(auth.siteId);
    getFans(auth.siteId, auth.userId).then(setFan);
  }, [auth.siteId, auth.userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCancel = () => {
    formik.resetForm();
    setErrorMessage("");
    onClose();
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };
  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {
                  auth.pages?.find((page) => page.id === PageId.DeviceTypePage)
                    ?.name
                }
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="FAN *"
                  name="fanId"
                  error={formik.errors.fanId}
                  value={formik.values.fanId}
                  onChange={formik.handleChange}
                  arrangement={fans}
                  format={(fan) => `${fan.fanId}`}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={() => setOpenFan(true)} color="primary">
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="デバイス名 *"
                name="deviceName"
                value={formik.values.deviceName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.deviceName)}
                helperText={formik.errors.deviceName}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="装置タイプ"
                name="deviceType"
                value={formik.values.deviceType ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.deviceType)}
                helperText={formik.errors.deviceType}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="メーカー名"
                name="makerName"
                value={formik.values.makerName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.makerName)}
                helperText={formik.errors.makerName}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="型式"
                name="terminalModel"
                value={formik.values.terminalModel ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.terminalModel)}
                helperText={formik.errors.terminalModel}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Grid item>
                <Typography variant="subtitle1">外付センサー有無</Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={formik.values.hasExternalSensor}
                  exclusive
                  onChange={(
                    _event: React.MouseEvent<HTMLElement>,
                    newValue: boolean
                  ) => {
                    formik.setFieldValue("hasExternalSensor", newValue);
                  }}
                >
                  <ToggleButton value={true}>有効</ToggleButton>
                  <ToggleButton value={false}>無効</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="収容可能センサー情報数"
                name="sensorCapacity"
                type="number"
                value={formik.values.sensorCapacity ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.sensorCapacity)}
                helperText={formik.errors.sensorCapacity}
                fullWidth
              />
            </Grid>

            <CancelSaveButtons handleCancel={handleCancel} />
            <FanModal
              open={openFan}
              onClose={() => setOpenFan(false)}
              onUpdate={fetchData}
            />
          </Grid>
        </form>
      </MasterModal>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}
export default DeviceTypeModal;
