import axios from "axios";

const url = process.env.REACT_APP_API_URL + "/GetUsersNotification";

export async function getUsersNotification(
  siteId: number,
  loginUserId: number,
  userId?: number | null
) {
  try {
    const body = {
      siteId,
      loginUserId,
      userId,
    };

    const response = await axios.post(url, body);
    const data: any[] = response.data;

    return data.map((d) => {
      return {
        ...d,
        isSelected: Boolean(d.isSelected),
      };
    });
  } catch (error) {
    return;
  } finally {
  }
}
