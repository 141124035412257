import axios from "axios";

const url = process.env.REACT_APP_API_URL + "/sendControlOneTimePasswordEmail";

export async function sendControlOneTimePasswordEmail(
  controlCardId: number,
  userId: number
) {
  const body = {
    controlCardId: controlCardId,
    userId: userId,
  };
  await axios.post(url, body);
}
