import axios from "axios";

const url = process.env.REACT_APP_API_URL + "/DeleteFan";

export async function deleteFan(fanId: string, siteId: number, userId: number) {
  const body = {
    fanId,
    siteId,
    userId,
  };
  const response = await axios.post(url, body);
  return response.data;
}
