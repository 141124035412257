import axios from "axios";

const url = process.env.REACT_APP_API_URL + "/GetThresholds";

export async function getThresholds(
  siteId: number,
  displayTimeId?: number,
  sensorId?: number,
  userId?: number
) {
  try {
    const body = {
      siteId,
      displayTimeId,
      sensorId,
      userId,
    };

    const response = await axios.post(url, body);
    return response.data;
  } catch (error) {
    return;
  } finally {
  }
}
