import EmailIcon from "@mui/icons-material/Email";
import {
  Avatar,
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { sendMail } from "../api/ChangePassword";

const SendChangePasswordEmailPage = () => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };
  const [loginId, setLoginId] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  const submit = async () => {
    setIsLoading(true);
    sendMail(loginId)
      .then((response) => {
        if (!response) {
          throw new Error("Not send email");
        }
        navigate("/LoginPage"); // パスワード再設定メール送信成功時はログインページに遷移
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsError(true);
        setIsLoading(false);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <EmailIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          パスワード再設定
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="loginId"
            label="ログインID"
            name="loginId"
            autoComplete="loginId"
            autoFocus
            onChange={(e) => setLoginId(e.target.value)}
            error={isError}
            helperText={isError ? "ユーザが見つかりません" : ""}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={submit}
            disabled={isLoading}
          >
            {"メールを送る"}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default SendChangePasswordEmailPage;
