import { Stack } from "@mui/material";
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateOrTimeView } from "@mui/x-date-pickers/internals";
import ja from "date-fns/locale/ja";
import React from "react";

export const DateTimePickersFormat = {
  DAY: "yyyy年MM月dd日",
  HOUR: "yyyy年MM月dd日 HH時",
};

type Props = {
  format: string;
  openTo: DateOrTimeView;
  views: DateOrTimeView[];
  disabled: boolean;
  timeFrom: Date | null;
  timeTo: Date | null;
  setTimeFrom: React.Dispatch<React.SetStateAction<Date | null>>;
  setTimeTo: React.Dispatch<React.SetStateAction<Date | null>>;
  minSelectablePeriod?: Date | null; // 選択可能期間の最小値
  maxSelectablePeriod?: Date | null; // 選択可能期間の最大値
};

function DateTimePickers(props: Props) {
  // 選択可能期間が指定されている場合、その値を使用する
  const minDateTime = props.minSelectablePeriod || new Date(0); // 選択可能期間の最小値が指定されていない場合、1970年1月1日を使用する
  const maxDateTime =
    props.maxSelectablePeriod || new Date(new Date().setHours(24, 0, 0, 0));

  let width;
  switch (props.format) {
    case DateTimePickersFormat.DAY:
      width = "150px";
      break;
    case DateTimePickersFormat.HOUR:
      width = "190px";
      break;
  }

  const timeFromChange = (newValue: Date | null) => {
    if (newValue != null) {
      newValue.setMinutes(0);
      newValue.setSeconds(0);
      newValue.setMilliseconds(0);
    }
    if (props.timeTo != null && newValue != null && props.timeTo < newValue) {
      // 開始日より前の終了日を選択した場合、開始日も更新
      props.setTimeTo(newValue);
    }
    props.setTimeFrom(newValue);
  };
  const timeToChange = (newValue: Date | null) => {
    if (newValue != null) {
      newValue.setMinutes(0);
      newValue.setSeconds(0);
      newValue.setMilliseconds(0);
    }
    if (
      props.timeFrom != null &&
      newValue != null &&
      props.timeFrom > newValue
    ) {
      // 終了日より後の開始日を選択した場合、終了日も更新
      props.setTimeFrom(newValue);
    }
    props.setTimeTo(newValue);
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);

  // PC上では入力補助強制しない場合はMobileDateTimePickerをDateTimePickerに置き換えて下さい
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={2}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
        <MobileDateTimePicker
          label="開始"
          minDateTime={minDateTime} // 選択可能期間の最小値から入力可能
          maxDateTime={maxDateTime} // 選択可能期間の最大値まで入力可能
          value={props.timeFrom}
          onChange={timeFromChange}
          openTo="year"
          format={props.format}
          views={props.views}
          disabled={props.disabled}
          sx={{ width: width }} // 追加
        />

        <MobileDateTimePicker
          label="終了"
          minDateTime={minDateTime} // 選択可能期間の最小値から入力可能
          maxDateTime={maxDateTime} // 選択可能期間の最大値まで入力可能
          value={props.timeTo}
          onChange={timeToChange}
          openTo="year"
          format={props.format}
          views={props.views}
          disabled={props.disabled}
          sx={{ width: width }} // 追加
        />
      </LocalizationProvider>
    </Stack>
  );
}

export default DateTimePickers;
