import axios from "axios";

const url = process.env.REACT_APP_API_URL + "/SendMessage";

export async function sendMessage(
  userId: number,
  siteId: number,
  controlCardId: number,
  controlId: number
) {
  const body = {
    siteId,
    userId,
    controlCardId,
    controlId,
  };
  await axios.post(url, body, { withCredentials: true });
}
