import * as yup from "yup";
import * as ja from "yup-locale-ja";

yup.setLocale(ja.suggestive);

const ERROR_MESSAGES = {
  NUMBER: "数値を入力してください。",
  PHONE: "有効な電話番号を入力してください。",
  ALPHANUMERIC_ONLY: "半角英数字のみ有効です",
  ASCII_CHARACTERS: "半角文字を入力してください",
  LOGIN_PASSWORD:
    "半角の英大文字、英小文字、数字をそれぞれ最低1文字ずつ含む8～50文字にしてください",
  SITE_NAME: "/、?、#、%は使用できません",
  MAX_LENGTH_3: "3桁以内で入力してください",
  MAX_LENGTH_10: "10桁以内で入力してください",
  MAX_LENGTH_20: "20桁以内で入力してください",
  MAX_LENGTH_50: "50桁以内で入力してください",
  POSITIVE_NUMBER: "0以上で入力してください",
  INTEGER: "整数で入力してください",
  TIME: "時刻で入力してください",
  EXACT_LENGTH_3: "3桁で入力してください",
};

const PATTERNS = {
  PHONE: /^[0-9-+]*$/,
  ALPHANUMERIC_ONLY: /^[0-9a-zA-Z_\-+@%&^*|{}~<>:;\\/\\=]*$/,
  ASCII_CHARACTERS: /^[ -~]*$/,
  LOGIN_PASSWORD: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]{8,50}$/,
  SITE_NAME: /^(?!.*(\/|\?|#|%)).+$/,
  INTEGER_OR_DECIMAL: /^-?[0-9]+(\.[0-9]+)?$/,
};

export const yupSensorTypeId = yup.string();
export const yupSensorName = yup.string().max(50);
export const yupPulse = yup
  .number()
  .typeError(ERROR_MESSAGES.NUMBER)
  .min(0, ERROR_MESSAGES.POSITIVE_NUMBER)
  .integer(ERROR_MESSAGES.INTEGER);

export const yupRatingMinDisplayValue = yup
  .string()
  .matches(PATTERNS.INTEGER_OR_DECIMAL, ERROR_MESSAGES.NUMBER)
  .test("len", ERROR_MESSAGES.MAX_LENGTH_50, (val) => {
    if (val) {
      return String(val).length <= 50;
    }
    return true;
  });

export const yupRatingMaxDisplayValue = yup
  .string()
  .matches(PATTERNS.INTEGER_OR_DECIMAL, ERROR_MESSAGES.NUMBER)
  .test("len", ERROR_MESSAGES.MAX_LENGTH_50, (val) => {
    if (val) {
      return String(val).length <= 50;
    }
    return true;
  });

export const yupRolloverError = yup.number();
export const yupRolloverCorrectionMax = yup
  .number()
  .typeError(ERROR_MESSAGES.NUMBER)
  .min(0, ERROR_MESSAGES.POSITIVE_NUMBER)
  .integer(ERROR_MESSAGES.INTEGER);
export const yupSensorSerialNumber = yup
  .string()
  .max(50)
  .matches(PATTERNS.ALPHANUMERIC_ONLY, ERROR_MESSAGES.ALPHANUMERIC_ONLY);
export const yupAlarmName = yup.string().max(25);
export const yupColorId = yup.string();
export const yupCalculationName = yup.string().max(50);
export const yupCalculation = yup
  .string()
  .matches(PATTERNS.ASCII_CHARACTERS, ERROR_MESSAGES.ASCII_CHARACTERS);
export const yupPalette = yup
  .string()
  .max(20)
  .matches(PATTERNS.ASCII_CHARACTERS, ERROR_MESSAGES.ASCII_CHARACTERS);
export const yupControlId = yup.number();
export const yupEqulitySignId = yup.number();
export const yupButtonLabel = yup.string().max(20);
export const yupPayload = yup.string();
export const yupConfirmationMessage = yup.string().max(100);

export const yupPriority = yup.number().typeError(ERROR_MESSAGES.NUMBER);

export const yupDisplayGroupId = yup.string();
export const yupControlTypeId = yup.string();
export const yupDataName = yup.string().max(50);

export const yupDisplayOrder = yup
  .number()
  .typeError(ERROR_MESSAGES.NUMBER)
  .min(0, ERROR_MESSAGES.POSITIVE_NUMBER)
  .integer(ERROR_MESSAGES.INTEGER);

export const yupDirectMethodId = yup.string();
export const yupControlTypeName = yup.string().max(50);
export const yupImage = yup.string();
export const yupDataFormatName = yup.string().max(50);
export const yupDataColumnName = yup
  .string()
  .max(30)
  .matches(PATTERNS.ALPHANUMERIC_ONLY, ERROR_MESSAGES.ALPHANUMERIC_ONLY);
export const yupDataRadix = yup.number().typeError(ERROR_MESSAGES.NUMBER);
export const yupCollectionDateTimeColumnName = yup
  .string()
  .max(20)
  .matches(PATTERNS.ALPHANUMERIC_ONLY, ERROR_MESSAGES.ALPHANUMERIC_ONLY);
export const yupDecodeName = yup.string().max(50);
export const yupStartPosition = yup.number();
export const yupEndPosition = yup.number().typeError(ERROR_MESSAGES.NUMBER);
export const yupAlarmId = yup.number().typeError(ERROR_MESSAGES.NUMBER);
export const yupDeviceId = yup.number().typeError(ERROR_MESSAGES.NUMBER);
export const yupSensorId = yup.number().typeError(ERROR_MESSAGES.NUMBER);
export const yupEqualitySignId = yup.number().typeError(ERROR_MESSAGES.NUMBER);
export const yupThreshold = yup
  .string()
  .matches(PATTERNS.INTEGER_OR_DECIMAL, ERROR_MESSAGES.NUMBER)
  .test("len", ERROR_MESSAGES.MAX_LENGTH_20, (val) => {
    if (val) {
      return String(val).length <= 20;
    }
    return true;
  });
export const yupDeviceName = yup.string().max(50);
export const yupDeviceType = yup.string().max(50);
export const yupMakerName = yup.string().max(50);
export const yupTerminalModel = yup
  .string()
  .max(50)
  .matches(PATTERNS.ALPHANUMERIC_ONLY, ERROR_MESSAGES.ALPHANUMERIC_ONLY);
export const yupSensorCapacity = yup
  .number()
  .typeError(ERROR_MESSAGES.NUMBER)
  .min(0, ERROR_MESSAGES.POSITIVE_NUMBER)
  .integer(ERROR_MESSAGES.INTEGER);

export const yupLocationName = yup.string().max(50);
export const yupLatitude = yup
  .string()
  .matches(PATTERNS.INTEGER_OR_DECIMAL, ERROR_MESSAGES.NUMBER)
  .test("len", ERROR_MESSAGES.MAX_LENGTH_10, (val) => {
    if (val) {
      return String(val).length <= 10;
    }
    return true;
  });
export const yupLongitude = yup
  .string()
  .matches(PATTERNS.INTEGER_OR_DECIMAL, ERROR_MESSAGES.NUMBER)
  .test("len", ERROR_MESSAGES.MAX_LENGTH_10, (val) => {
    if (val) {
      return String(val).length <= 10;
    }
    return true;
  });

export const yupZoom = yup.number().min(0).max(18);
export const yupDisplayGroupName = yup.string().max(50);

export const yupDisplayGroupOrder = yup
  .number()
  .typeError(ERROR_MESSAGES.NUMBER)
  .min(0, ERROR_MESSAGES.POSITIVE_NUMBER)
  .integer(ERROR_MESSAGES.INTEGER);

export const yupFanId = yup
  .string()
  .length(3, ERROR_MESSAGES.EXACT_LENGTH_3)
  .matches(PATTERNS.ALPHANUMERIC_ONLY, ERROR_MESSAGES.ALPHANUMERIC_ONLY);
export const yupFanName = yup.string().max(50);
export const yupReceiveDataTypeId = yup.string();
export const yupReceiveDataTypeName = yup.string().max(50);
export const yupUnit = yup.string().max(20);
export const yupSign = yup
  .string()
  .max(5)
  .matches(PATTERNS.ALPHANUMERIC_ONLY, ERROR_MESSAGES.ALPHANUMERIC_ONLY);
export const yupOneTimePassword = yup.string();
export const yupDisplayTimeId = yup.string();
export const yupUpperLimit2Threshold = yup
  .number()
  .typeError(ERROR_MESSAGES.NUMBER);
export const yupUpperLimit1Threshold = yup
  .number()
  .typeError(ERROR_MESSAGES.NUMBER);
export const yupLowerLimit1Threshold = yup
  .number()
  .typeError(ERROR_MESSAGES.NUMBER);
export const yupLowerLimit2Threshold = yup
  .number()
  .typeError(ERROR_MESSAGES.NUMBER);
export const yupDefaultDisplayTimeId = yup.string();
export const yupState = yup.string();
export const yupSensorTypeName = yup.string().max(50);
export const yupModel = yup
  .string()
  .max(50)
  .matches(PATTERNS.ALPHANUMERIC_ONLY, ERROR_MESSAGES.ALPHANUMERIC_ONLY);
export const yupSiteName = yup
  .string()
  .matches(PATTERNS.SITE_NAME, { message: ERROR_MESSAGES.SITE_NAME })
  .max(50);
export const yupCustomerName = yup.string().max(50);
export const yupResponsiblePersonName = yup.string().max(50);
export const yupAddress = yup.string().max(100);
export const yupPhoneNumber = yup
  .string()
  .max(15)
  .matches(PATTERNS.PHONE, { message: ERROR_MESSAGES.PHONE });
export const yupSystemName = yup.string().max(50);
export const yupUrl = yup.string().url().max(256);
export const yupAutoLogoutSpan = yup
  .number()
  .typeError(ERROR_MESSAGES.NUMBER)
  .min(0, ERROR_MESSAGES.POSITIVE_NUMBER)
  .integer(ERROR_MESSAGES.INTEGER);
export const yupTokenExpiration = yup
  .number()
  .required()
  .typeError(ERROR_MESSAGES.NUMBER)
  .min(0, ERROR_MESSAGES.POSITIVE_NUMBER)
  .integer(ERROR_MESSAGES.INTEGER);
export const yupUserName = yup.string().max(50);
export const yupEmail = yup.string().email().max(100);
export const yupLoginPassword = yup
  .string()
  .matches(PATTERNS.LOGIN_PASSWORD, { message: ERROR_MESSAGES.LOGIN_PASSWORD });
export const yupLoginId = yup
  .string()
  .max(10)
  .matches(PATTERNS.ALPHANUMERIC_ONLY, ERROR_MESSAGES.ALPHANUMERIC_ONLY);

export const yupPermissionId = yup.string();
export const yupSiteId = yup.string();
export const yupUserId = yup.string();
export const yupGraphPatternName = yup.string();

export const yupDeviceTypeId = yup.string();
export const yupIothubName = yup
  .string()
  .max(50)
  .matches(PATTERNS.ALPHANUMERIC_ONLY, ERROR_MESSAGES.ALPHANUMERIC_ONLY);
export const yupIothubDeviceId = yup
  .string()
  .max(100)
  .matches(PATTERNS.ALPHANUMERIC_ONLY, ERROR_MESSAGES.ALPHANUMERIC_ONLY);
export const yupReceivingCycle = yup
  .number()
  .typeError(ERROR_MESSAGES.NUMBER)
  .min(0, ERROR_MESSAGES.POSITIVE_NUMBER)
  .integer(ERROR_MESSAGES.INTEGER);

export const yupMissingMarginSecond = yup
  .number()
  .typeError(ERROR_MESSAGES.NUMBER)
  .min(0, ERROR_MESSAGES.POSITIVE_NUMBER)
  .integer(ERROR_MESSAGES.INTEGER);

export const yupCommunicationFailuresSecond = yup
  .number()
  .typeError(ERROR_MESSAGES.NUMBER)
  .min(0, ERROR_MESSAGES.POSITIVE_NUMBER)
  .integer(ERROR_MESSAGES.INTEGER);
export const yupSerialNumber = yup
  .string()
  .max(50)
  .matches(PATTERNS.ALPHANUMERIC_ONLY, ERROR_MESSAGES.ALPHANUMERIC_ONLY);
export const yupPacCode = yup
  .string()
  .max(50)
  .matches(PATTERNS.ALPHANUMERIC_ONLY, ERROR_MESSAGES.ALPHANUMERIC_ONLY);
export const yupSensorCount = yup
  .number()
  .typeError(ERROR_MESSAGES.NUMBER)
  .min(0, ERROR_MESSAGES.POSITIVE_NUMBER)
  .integer(ERROR_MESSAGES.INTEGER);
export const yupOffsetId = yup.number();
export const yupControlSend = yup.number();
export const yupControlCardId = yup.number().typeError(ERROR_MESSAGES.NUMBER);
export const yupRadioButtonName = yup.string().max(50);
export const yupRadioButtonOrder = yup
  .number()
  .typeError(ERROR_MESSAGES.NUMBER)
  .min(0, ERROR_MESSAGES.POSITIVE_NUMBER)
  .integer(ERROR_MESSAGES.INTEGER);
export const yupDeviceTwinId = yup.number();
export const yupPayloadId = yup.number();
export const yupDeviceTwinName = yup
  .string()
  .max(50)
  .matches(PATTERNS.ALPHANUMERIC_ONLY, ERROR_MESSAGES.ALPHANUMERIC_ONLY);
export const yupDeviceTwin = yup
  .string()
  .matches(PATTERNS.ALPHANUMERIC_ONLY, ERROR_MESSAGES.ALPHANUMERIC_ONLY);
export const yupDataFormatId = yup.number();
export const yupDataDeviceIdColumnName = yup
  .string()
  .max(20)
  .matches(PATTERNS.ALPHANUMERIC_ONLY, ERROR_MESSAGES.ALPHANUMERIC_ONLY);
export const yupMinimum = yup
  .string()
  .matches(PATTERNS.INTEGER_OR_DECIMAL, ERROR_MESSAGES.NUMBER)
  .test("len", ERROR_MESSAGES.MAX_LENGTH_20, (val) => {
    if (val) {
      return String(val).length <= 20;
    }
    return true;
  });
export const yupMaximum = yup
  .string()
  .matches(PATTERNS.INTEGER_OR_DECIMAL, ERROR_MESSAGES.NUMBER)
  .test("len", ERROR_MESSAGES.MAX_LENGTH_20, (val) => {
    if (val) {
      return String(val).length <= 20;
    }
    return true;
  });
export const yupDataDeviceId = yup
  .string()
  .max(100)
  .matches(PATTERNS.ALPHANUMERIC_ONLY, ERROR_MESSAGES.ALPHANUMERIC_ONLY);

export const yupNotificationStartTime = yup.string();
export const yupNotificationStopTime = yup.string();
export const yupNotificationId = yup.number();
