import { Grid, TextField, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { insertDisplayGroup } from "../api/InsertDisplayGroup";
import { checkJwt } from "../api/TokenVerification";
import { updateDisplayGroup } from "../api/UpdateDisplayGroup";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { PageId } from "../constants/page";
import { AuthContext } from "../context/AuthContext";
import { DisplayGroup } from "../models/DisplayGroup";
import { CancelSaveButtons } from "./CancelSaveButtons";
import CustomModalSnackbar from "./CustomModalSnackbar";
import { componentSpacing } from "./CustomStyle";
import { MasterModal } from "./MasterModal";
import { yupDisplayGroupName, yupDisplayGroupOrder } from "./ValidationSchema";

interface DisplayGroupModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  editingDisplayGroup?: DisplayGroup;
}

const DisplayGroupModalValidationSchema = yup.object().shape({
  displayGroupName: yupDisplayGroupName.required(),
  displayGroupOrder: yupDisplayGroupOrder.nullable(),
});

function DisplayGroupModal({
  open,
  onClose,
  onUpdate,
  editingDisplayGroup,
}: DisplayGroupModalProps) {
  const auth = React.useContext(AuthContext);
  const siteId = Number(auth.siteId);
  const isUpdate =
    editingDisplayGroup && editingDisplayGroup.displayGroupId != null;
  const [errorMessage, setErrorMessage] = useState("");

  const [initialValues] = useState<DisplayGroup>({
    siteId: siteId,
    displayGroupId: null,
    displayGroupName: null,
    displayGroupOrder: null,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingDisplayGroup || initialValues,
    validationSchema: DisplayGroupModalValidationSchema,
    onSubmit: async () => {
      await checkJwt(auth.siteId);

      try {
        if (isUpdate) {
          await updateDisplayGroup(formik.values, auth.siteId, auth.userId);
        } else {
          await insertDisplayGroup(formik.values, auth.siteId, auth.userId);
        }
        formik.resetForm();
        onUpdate();
        onClose();
      } catch (err) {
        const axiosError = err as AxiosError;
        const errNum = axiosError.response?.data;
        if (errNum === ErrorCodes.UniqueKey) {
          setErrorMessage("表示グループ名が重複しています。");
        } else {
          setErrorMessage(ErrorMessages.UnknownError);
        }
      }
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            {/* Title */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {
                  auth.pages?.find(
                    (page) => page.id === PageId.DisplayGroupPage
                  )?.name
                }
              </Typography>
            </Grid>

            <Grid item xs={12} sx={componentSpacing}>
              <TextField
                label="表示グループ名 *"
                name="displayGroupName"
                value={formik.values.displayGroupName || ""}
                onChange={formik.handleChange}
                error={
                  formik.touched.displayGroupName &&
                  Boolean(formik.errors.displayGroupName)
                }
                helperText={
                  formik.touched.displayGroupName
                    ? formik.errors.displayGroupName
                    : ""
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sx={componentSpacing}>
              <TextField
                label="表示順"
                name="displayGroupOrder"
                type="number"
                value={formik.values.displayGroupOrder ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.displayGroupOrder)}
                helperText={formik.errors.displayGroupOrder}
                fullWidth
              />
            </Grid>

            <CancelSaveButtons handleCancel={handleCancel} />
          </Grid>
        </form>
      </MasterModal>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={() => setErrorMessage("")}
      />
    </>
  );
}
export default DisplayGroupModal;
