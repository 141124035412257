import { Grid, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { insertPayload } from "../api/InsertPayload";
import { checkJwt } from "../api/TokenVerification";
import { AuthContext } from "../context/AuthContext";
import { Payload } from "../models/Payload";
import { CancelSaveButtons } from "./CancelSaveButtons";
import CustomModalSnackbar from "./CustomModalSnackbar";
import { MasterModal } from "./MasterModal";
import { yupPayload } from "./ValidationSchema";

interface PayloadModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  editingItem?: Payload;
}

const LocationValidationSchema = yup.object().shape({
  payload: yupPayload.required(),
});

function PayloadModal({
  open,
  onClose,
  onUpdate,
  editingItem,
}: PayloadModalProps) {
  const isUpdate = editingItem && editingItem.payloadId != null;
  const auth = React.useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");

  const [initialValues] = useState<Payload>({});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingItem || initialValues,
    validationSchema: LocationValidationSchema,
    onSubmit: async () => {
      await checkJwt(auth.siteId);

      try {
        if (isUpdate) {
          // await updatePayload(formik.values, auth.siteId, auth.userId);
        } else {
          await insertPayload(formik.values, auth.siteId, auth.userId);
        }
        formik.resetForm();
        onUpdate();
        onClose();
      } catch (error) {
        // Handle the error if it is not an instance of Error
        setErrorMessage("未知のエラーが発生しました。");
      }
    },
  });

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleCancel = () => {
    formik.resetForm();
    setErrorMessage("");
    onClose();
  };

  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {!isUpdate && (
                <Typography variant="h6" gutterBottom>
                  ペイロード
                </Typography>
              )}
            </Grid>

            {/* ペイロード */}
            <Grid item xs={12}>
              <TextField
                label="ペイロード"
                name="payload"
                value={formik.values.payload ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.payload)}
                helperText={formik.errors.payload}
                fullWidth
              />
            </Grid>

            <CancelSaveButtons handleCancel={handleCancel} />
          </Grid>
        </form>
      </MasterModal>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}
export default PayloadModal;
