import axios from "axios";

const url = process.env.REACT_APP_API_URL + "/DeleteSite";

export async function deleteSite(siteId: number, userId: number) {
  const body = {
    siteId,
    userId,
  };
  const response = await axios.post(url, body, { withCredentials: true });
  return response.data;
}
