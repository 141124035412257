import axios, { AxiosError } from "axios";
import { ReceiveDataDisplaySetting } from "../models/ReceiveDataDisplaySetting";

const url = process.env.REACT_APP_API_URL + "/UpdateReceiveDataDisplaySetting";

export async function updateReceiveDataDisplaySetting(
  receiveDataDisplaySetting: ReceiveDataDisplaySetting,
  siteId: number,
  userId: number
) {
  try {
    const body = {
      receiveDataDisplaySetting,
      siteId,
      userId,
    };
    const response = await axios.post(url, body);
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response && axiosError.response.status === 409) {
      throw new Error("Unique key violation");
    }

    throw error;
  } finally {
  }
}
