import { Alert, Snackbar } from "@mui/material";
import { useContext } from "react";
import LoadingContext from "../context/LoadingContext";

const CsvDownloadSnackBar = () => {
  const loadingContext = useContext(LoadingContext);

  return (
    <Snackbar open={loadingContext.isCsvDownloading}>
      <Alert severity="info" variant="filled" sx={{ width: "100%" }}>
        CSVダウンロード中
      </Alert>
    </Snackbar>
  );
};

export default CsvDownloadSnackBar;
