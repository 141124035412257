export const sortRecord = (a: any, b: any) => {
  if (a.displayGroupOrder === b.displayGroupOrder) {
    if (a.displayOrder === null) {
      return 1;
    } else if (b.displayOrder === null) {
      return -1;
    } else if (a.displayOrder === b.displayOrder) {
      return 0;
    } else {
      return a.displayOrder - b.displayOrder;
    }
  } else if (a.displayGroupOrder === null) {
    return 1;
  } else if (b.displayGroupOrder === null) {
    return -1;
  } else {
    return a.displayGroupOrder - b.displayGroupOrder;
  }
};
