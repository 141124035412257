import {
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useCallback, useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { getOffsets } from "../api/GetOffsets";
import { insertSite } from "../api/InsertSite";
import { checkJwt } from "../api/TokenVerification";
import { updateSite } from "../api/UpdateSite";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { PageId } from "../constants/page";
import { AuthContext } from "../context/AuthContext";
import { Offset } from "../models/Offset";
import { Site } from "../models/Site";
import { CancelSaveButtons } from "./CancelSaveButtons";
import CustomAutoComplete from "./CustomAutoComplete";
import CustomModalSnackbar from "./CustomModalSnackbar";
import { MasterModal } from "./MasterModal";
import {
  yupAddress,
  yupAutoLogoutSpan,
  yupCustomerName,
  yupOffsetId,
  yupPhoneNumber,
  yupResponsiblePersonName,
  yupSiteName,
  yupSystemName,
  yupTokenExpiration,
  yupUrl,
} from "./ValidationSchema";

interface SiteModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  editingSite?: Site;
}

const SiteValidationSchema = yup.object({
  siteName: yupSiteName.required(),
  customerName: yupCustomerName.required(),
  responsiblePersonName: yupResponsiblePersonName.required(),
  address: yupAddress.required(),
  phoneNumber: yupPhoneNumber.required(),
  systemName: yupSystemName.required(),
  url: yupUrl.required(),
  autoLogoutSpan: yupAutoLogoutSpan.required(),
  tokenExpiration: yupTokenExpiration,
  offsetId: yupOffsetId.required(),
});

function SiteModal({ open, onClose, onUpdate, editingSite }: SiteModalProps) {
  const isUpdate = editingSite && editingSite.siteId != null;
  const [offsets, setOffsets] = useState<Offset[]>();
  const auth = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchData = useCallback(async () => {
    await checkJwt(auth.siteId);
    getOffsets(auth.siteId, auth.userId).then(setOffsets);
  }, [auth.siteId, auth.userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [initialValues] = useState<Site>({
    stateFlag: true,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingSite || initialValues,
    validationSchema: SiteValidationSchema,
    onSubmit: async () => {
      await checkJwt(auth.siteId);

      try {
        if (isUpdate) {
          await updateSite(formik.values, auth.siteId, auth.userId);
        } else {
          await insertSite(formik.values, auth.siteId, auth.userId);
        }
        formik.resetForm();
        setErrorMessage("");
        onUpdate();
        onClose();
      } catch (err) {
        const axiosError = err as AxiosError;
        const errNum = axiosError.response?.data;

        if (errNum === ErrorCodes.UniqueKey) {
          setErrorMessage("サイト名が重複しています。");
        } else if (errNum === ErrorCodes.InvalidParamKey) {
          setErrorMessage(ErrorMessages.InvalidParamError);
        } else {
          setErrorMessage(ErrorMessages.UnknownError);
        }
      }
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    setErrorMessage("");
    onClose();
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {auth.pages?.find((page) => page.id === PageId.SitePage)?.name}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="サイト名 *"
                name="siteName"
                value={formik.values.siteName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.siteName)}
                helperText={formik.errors.siteName}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="お客様名 *"
                name="customerName"
                value={formik.values.customerName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.customerName)}
                helperText={formik.errors.customerName}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="担当者名 *"
                name="responsiblePersonName"
                value={formik.values.responsiblePersonName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.responsiblePersonName)}
                helperText={formik.errors.responsiblePersonName}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="住所 *"
                name="address"
                value={formik.values.address ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.address)}
                helperText={formik.errors.address}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="電話番号 *"
                name="phoneNumber"
                value={formik.values.phoneNumber ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.phoneNumber)}
                helperText={formik.errors.phoneNumber}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="システム名称 *"
                name="systemName"
                value={formik.values.systemName ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.systemName)}
                helperText={formik.errors.systemName}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="URL *"
                name="url"
                value={formik.values.url ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.url)}
                helperText={formik.errors.url}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="自動ログアウト間隔（分） *"
                name="autoLogoutSpan"
                type="number"
                value={formik.values.autoLogoutSpan ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.autoLogoutSpan)}
                helperText={formik.errors.autoLogoutSpan}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="トークン有効期限（分） *"
                name="tokenExpiration"
                type="number"
                value={formik.values.tokenExpiration ?? ""}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.tokenExpiration)}
                helperText={formik.errors.tokenExpiration}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <CustomAutoComplete
                label="オフセット *"
                name="offsetId"
                error={formik.errors.offsetId}
                value={formik.values.offsetId}
                onChange={formik.handleChange}
                arrangement={offsets}
                format={(offset) => offset.offsetName}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid item>
                <Typography variant="subtitle1">状態</Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={formik.values.stateFlag}
                  exclusive
                  onChange={(
                    _event: React.MouseEvent<HTMLElement>,
                    newValue: boolean | null
                  ) => {
                    if (newValue !== null) {
                      formik.setFieldValue("stateFlag", newValue);
                    }
                  }}
                >
                  <ToggleButton value={true}>有効</ToggleButton>
                  <ToggleButton value={false}>無効</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>

            <CancelSaveButtons handleCancel={handleCancel} />
          </Grid>
        </form>
      </MasterModal>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}
export default SiteModal;
