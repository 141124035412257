import axios from "axios";
import { ControlCard } from "../models/ControlCard";

const url = process.env.REACT_APP_API_URL + "/UpdateControlCard";

export async function updateControlCard(
  controlCard: ControlCard,
  siteId: number,
  userId: number
) {
  const body = {
    controlCard,
    siteId,
    userId,
  };
  const response = await axios.post(url, body);
  return response.data;
}
