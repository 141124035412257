import axios from "axios";
import { DetailInformation } from "../models/DetailInformation";

const url = process.env.REACT_APP_API_URL + "/UpdateDetailInformation";

export async function updateDetailInformation(
  siteId: number,
  userId: number,
  detailInformation: DetailInformation
) {
  const body = {
    siteId,
    userId,
    detailInformation,
  };
  const response = await axios.post(url, body);
  return response.data;
}
