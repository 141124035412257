import axios from "axios";

const url = process.env.REACT_APP_API_URL + "/DeleteDevice";

export async function deleteDevice(
  deviceId: number,
  siteId: number,
  userId: number
) {
  const body = {
    deviceId,
    siteId,
    userId,
  };
  const response = await axios.post(url, body);
  return response.data;
}
