import axios from "axios";
import { getPages } from "./GetPages";
axios.defaults.withCredentials = true;

enum LogoutParams {
  manualLogout = 1,
  timeoutLogout = 2,
  lastWinsLogout = 3,
}
export async function login(
  loginId: string,
  password: string,
  isForce: boolean
) {
  const body = {
    loginId,
    password,
    isForce,
  };
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/Login",
    body,
    { withCredentials: true }
  );

  response.data = {
    ...response.data,
    pages: await getPages(response.data.userId),
  };

  return response;
}

export async function logout(logoutParams: LogoutParams, siteId: number) {
  let body;
  try {
    const storedData = JSON.parse(localStorage.getItem("userData") as string);
    body = {
      userId: storedData.userId,
      siteId: siteId,
      loggerParams: logoutParams,
    };
  } finally {
    await axios.post(process.env.REACT_APP_API_URL + "/Logout", body, {
      withCredentials: true,
    });
    return;
  }
}

//jwtの検証
export async function checkJwt(siteId?: number) {
  const storedData = JSON.parse(localStorage.getItem("userData") as string);
  const body = {
    userId: storedData.userId,
    siteId,
  };
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/TokenVerification",
    body,
    { withCredentials: true }
  );

  response.data = {
    ...response.data,
    pages: await getPages(storedData.userId),
  };
  return response;
}
