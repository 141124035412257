import AddIcon from "@mui/icons-material/Add";
import { Grid, IconButton, TextField, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { getSensors } from "../api/GetSensors";
import { getSites } from "../api/GetSites";
import { insertSiteLocation } from "../api/InsertSiteLocation";
import { checkJwt } from "../api/TokenVerification";
import { updateSiteLocation } from "../api/UpdateSiteLocation";
import ErrorCodes from "../constants/errorCodes";
import ErrorMessages from "../constants/errorMessages";
import { PageId } from "../constants/page";
import { AuthContext } from "../context/AuthContext";
import { Sensor } from "../models/Sensor";
import { Site } from "../models/Site";
import { SiteLocation } from "../models/SiteLocation";
import { CancelSaveButtons } from "./CancelSaveButtons";
import CustomAutoComplete from "./CustomAutoComplete";
import CustomModalSnackbar from "./CustomModalSnackbar";
import { MasterModal } from "./MasterModal";
import SensorModal from "./SensorModal";
import SiteModal from "./SiteModal";
import { yupSensorId, yupSiteId, yupZoom } from "./ValidationSchema";

interface SiteLocationModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  editingSiteLocation?: SiteLocation;
}

const SiteLocationValidationSchema = yup.object().shape({
  siteId: yupSiteId.required(),
  sensorId: yupSensorId.required(),
  zoom: yupZoom.required(),
});

function SiteLocationModal({
  open,
  onClose,
  onUpdate,
  editingSiteLocation,
}: SiteLocationModalProps) {
  const isUpdate = editingSiteLocation && editingSiteLocation.siteId != null;
  const auth = React.useContext(AuthContext);
  const [sites, setSites] = useState<Site[]>();
  const [openSite, setOpenSite] = React.useState<boolean>(false);
  const [sensors, setSensors] = useState<Sensor[]>();
  const [openSensor, setOpenSensor] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchData = React.useCallback(async () => {
    await checkJwt(auth.siteId);
    getSites(auth.siteId, auth.userId).then(setSites);
    getSensors(auth.siteId, auth.userId).then(setSensors);
  }, [auth.siteId, auth.userId]);

  const [initialValues] = useState<SiteLocation>({
    zoom: 18,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingSiteLocation || initialValues,
    validationSchema: SiteLocationValidationSchema,
    onSubmit: async () => {
      await checkJwt(auth.siteId);
      try {
        if (isUpdate) {
          await updateSiteLocation(formik.values, auth.siteId, auth.userId);
        } else {
          await insertSiteLocation(formik.values, auth.siteId, auth.userId);
        }
        formik.resetForm();
        onUpdate();
        onClose();
      } catch (err) {
        const axiosError = err as AxiosError;
        const errNum = axiosError.response?.data;
        if (errNum === ErrorCodes.UniqueKey) {
          setErrorMessage("サイト名と、場所名が重複しています。");
        } else {
          setErrorMessage(ErrorMessages.UnknownError);
        }
      }
    },
  });

  useEffect(() => {
    fetchData();
  }, [fetchData, formik.handleChange]);

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleCancel = () => {
    formik.resetForm();
    setErrorMessage("");
    onClose();
  };

  return (
    <>
      <MasterModal open={open}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {
                  auth.pages?.find(
                    (page) => page.id === PageId.SiteLocationPage
                  )?.name
                }
              </Typography>
            </Grid>
            {/* SiteLocation Name */}
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="サイト名 *"
                  name="siteId"
                  error={formik.errors.siteId}
                  value={formik.values.siteId}
                  onChange={formik.handleChange}
                  arrangement={sites}
                  format={(site) => site.siteName}
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton onClick={() => setOpenSite(true)} color="primary">
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={14}>
                <CustomAutoComplete
                  label="センサー名 *"
                  name="sensorId"
                  error={formik.errors.sensorId}
                  value={formik.values.sensorId}
                  onChange={formik.handleChange}
                  arrangement={sensors}
                  format={(sensor) =>
                    `（${sensor.sdLocationName}）${sensor.sensorName}：${sensor.iothubDeviceId}`
                  }
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton onClick={() => setOpenSite(true)} color="primary">
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="初期拡大率 *"
                name="zoom"
                value={formik.values.zoom ?? "18"}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.zoom)}
                helperText={formik.touched.zoom ? formik.errors.zoom : ""}
                fullWidth
              />
            </Grid>

            <CancelSaveButtons handleCancel={handleCancel} />
            <SiteModal
              open={openSite}
              onClose={() => setOpenSite(false)}
              onUpdate={fetchData}
            />
            <SensorModal
              open={openSensor}
              onClose={() => setOpenSensor(false)}
              onUpdate={fetchData}
            />
          </Grid>
        </form>
      </MasterModal>
      <CustomModalSnackbar
        open={errorMessage !== ""}
        message={errorMessage}
        onClose={handleAlertClose}
      />
    </>
  );
}
export default SiteLocationModal;
